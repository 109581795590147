import React from "react";
import {ContactTypes, DefaultContactType, DefaultImage, DefaultLinks, DefaultPrimaryAction, DefaultText} from "../../../core/constants";


import HeaderThreeCol_Preview from "./components/HeaderThreeCol_Preview";

const HeaderThreeCol = ({logo, links, primary_action, opening_hours, opening_hours_action, contact_types, closed_label, context}) => {
    return null;
};

HeaderThreeCol.defaultProps = {
    logo: DefaultImage,
    links: DefaultLinks,
    primary_action: DefaultPrimaryAction,
    closed_label: DefaultText,
    opening_hours: [],
    opening_hours_action: DefaultPrimaryAction,
    contact_types: [
        {...DefaultContactType, type: ContactTypes.Mail},
        {...DefaultContactType, type: ContactTypes.Telephone},
    ],
};

HeaderThreeCol.human = {
    "nl": "",
    "fr": "",
    "en": "",
};

HeaderThreeCol.preview = () => { return <HeaderThreeCol_Preview /> };

export default HeaderThreeCol;
