import React from 'react';
import {DefaultImage, DefaultText, DefaultTitle} from "../../../core/constants";
import {Image, MarkupText} from "../../../core";
import FeatureThreeColWithTitle_Preview from "./components/FeatureThreeColWithTitle_Preview";

const FeatureThreeColWithTitle = ({title, image1, title1, description1, image2, title2, description2, image3, title3, description3, context}) => {
    return (
        <div className="flex item-center justify-center bg-white pt-10 pb-10 md:pt-30">
            <div className="container flex flex-col">
                <h2 className="text-3xl md:text-5xl font-bold mb-5 md:mb-10 text-center uppercase text-primary">{title}</h2>

                <div className="flex flex-col md:flex-row justify-between mb-10">
                    <div className="flex flex-col items-center justify-center pr-5 md:pr-16 w-full md:w-1/3 pl-5 md:pl-0">
                        <Image url={(image1) ? image1 : ''} style={{height: 90, width: 90}}/>
                        <div className="flex items-center justify-center my-2 card-title text-center text-lg md:text-2xl" style={{height: 54, minHeight: 54}}>{title1}</div>
                        <div className="text-center text-sm md:text-md"><MarkupText text={description1} context={context}/></div>
                    </div>
                    <div className="flex flex-col items-center justify-center pr-5 md:pr-16 w-full md:w-1/3 pl-5 md:pl-0">
                        <Image url={(image2) ? image2 : ''} style={{height: 90, width: 90}}/>
                        <div className="flex items-center justify-center my-2 card-title text-center text-lg md:text-2xl" style={{height: 54, minHeight: 54}}>{title2}</div>
                        <div className="text-center text-sm md:text-md"><MarkupText text={description2} context={context}/></div>
                    </div>
                    <div className="flex flex-col items-center justify-center pr-5 md:pr-16 w-full md:w-1/3 pl-5 md:pl-0">
                        <Image url={(image3) ? image3 : ''} style={{height: 90, width: 90}}/>
                        <div className="flex items-center justify-center my-2 card-title text-center text-lg md:text-2xl" style={{height: 54, minHeight: 54}}>{title3}</div>
                        <div className="text-center text-sm md:text-md"><MarkupText text={description3} context={context}/></div>
                    </div>
                </div>
            </div>
        </div>
    );
};


FeatureThreeColWithTitle.defaultProps = {
    title: DefaultTitle,
    text: DefaultText,
    image1: DefaultImage,
    title1: DefaultTitle,
    description1: DefaultText,
    image2: DefaultImage,
    title2: DefaultTitle,
    description2: DefaultText,
    image3: DefaultImage,
    title3: DefaultTitle,
    description3: DefaultText,
};

FeatureThreeColWithTitle.human = {
    "nl": "",
    "fr": "",
    "en": "",
};

FeatureThreeColWithTitle.preview = () => { return <FeatureThreeColWithTitle_Preview /> };

export default FeatureThreeColWithTitle;
