import React, {useState} from 'react';
import CarouselDEPRICATED from "../../Carousel-DEPRICATED";
import {navigatePage} from "../../../utils";
import LoadingIndicator from "../../indicators/LoadingIndicator";
import Fade from "react-reveal/Fade";

const VehicleBlock = ({vehicle, vehicleDetailsUrl, loading, step, contactMail, reservation_days, reservationReference, deposit, context, paymentLogos, reservation}) => {
    const [showDescription, setShowDescription] = useState(false);

    const userContent = () => {
        return <div className="text-gray-600 text-sm">
            <strong>Vermijd dat iemand je voor is.</strong><br/>Wij reserveren deze wagen <strong>{reservation_days} dagen voor u.</strong><br/><br/>Na reservering spreken we samen met jou een afhaalmoment af.
        </div>
    }

    const paymentContent = () => {
        return <div className="text-gray-600 text-sm">
            <strong>Deze wagen is bijna van u!</strong><br/><br/>Geen zorgen, meteen na betaling zetten wij deze wagen opzij voor u.<br/><br/><strong>U kan de wagen binnen de {reservation_days} dagen
            ophalen.</strong>
        </div>
    }

    const confirmationContent = () => {
        return <div className="flex flex-col">
            <div className="text-gray-600 text-sm mb-5">De wagen is <strong>{reservation_days} dagen voor u gereserveerd!</strong><br/><strong>Maak een afspraak</strong> om de auto te bezichtigen of
                kom langs.
            </div>
            {contactMail !== "" &&
            <a href={`mailto:${contactMail}?SUBJECT=Reservatie &BODY=Beste,%0D%0A%0D%0AIk heb een wagen gereserveerd (${vehicle.identification}) en ik zou graag een afspraak willen maken.%0D%0A%0D%0AMet vriendelijke groeten`}
               className="flex flex-row bg-primary text-white hover:text-gray-300 rounded-none text-center text-sm font-bold border-2 border-gray-800 cursor-pointer outline-none">
                <div className="flex flex-row justify-between w-full">
                    <div className="py-3 px-4"> Maak een afspraak</div>
                    <div className="p-3 icon-wrapper bg-gray-800">
                        <div className="w-4 h-4"><i className="fad fa-arrow-right" aria-hidden="true"/></div>
                    </div>
                </div>
            </a>}
        </div>
    }

    return (
        <div className='flex flex-col flex-1'>
            <div className="flex flex-col justify-between">
                <div className="overflow-hidden">
                    <CarouselDEPRICATED images={vehicle.images}
                                        category={vehicle.category}
                                        showFavoritesIcon={false}
                                        reference={vehicle.reference}
                                        detailPageUrl={`vehicle?reference=${vehicle.reference}`}
                                        label={vehicle.label}
                    />
                </div>

                <div className="flex justify-between items-center mt-3 text-primary capitalize hover:text-gray-800 cursor-pointer outline-none"
                     onClick={() => navigatePage(context, vehicleDetailsUrl, `reference=${vehicle.reference}`, null, true)}>
                    <div className="text-1xl">{vehicle.make} {vehicle.model}</div>
                    <div><i className="fad fa-chevron-double-right"/></div>
                </div>

                <div className="horizontal-line my-5"/>

                <div className="flex flex-col flex-1">
                    {loading && <LoadingIndicator small/>}
                    {!loading && <React.Fragment>
                        {step === 1 && userContent()}
                        {step === 2 && paymentContent()}
                        {step === 3 && confirmationContent()}
                    </React.Fragment>}
                </div>


            </div>




            <div className="flex flex-col flex-1 justify-end">
                <div className="horizontal-line my-5"/>
                <div className="flex w-full justify-between items-center">
                    <div className="text-primary text-xl flex items-center">Reservatiekost <i className="fal fa-question-circle ml-1 text-sm text-gray-700 hover:text-gray-900 cursor-pointer"
                                                                                              onClick={() => setShowDescription(!showDescription)}/></div>
                    <div className="flex flex-col">
                        <div className="text-primary text-2xl mb-1">€ {deposit}</div>
                        <div className="text-gray-600 text-sm">incl. 21% btw</div>
                    </div>
                </div>

                <Fade when={showDescription} duration={100} collapse>
                    <div className="text-gray-600 text-sm mt-5">
                        Voorshot op uw aankoop.<br/>Wij verbinden ons ertoe de wagen niet te verkopen aan derden gedurende {reservation_days} reservatiedagen.
                    </div>
                </Fade>

                {paymentLogos.length > 0 && step <= 3 && paymentLogos.some(pl => pl !== '') &&
                <div className="bg-white -ml-5 -mr-5 -mb-5 p-5">
                    <div className="text-gray-800 text-md">Betalingsmogelijkheden</div>
                    <div className="flex flex-wrap">
                        {paymentLogos.filter(pl => pl !== '').map((paymentLogo, i) => {
                            return <img key={i} src={paymentLogo} alt="Payment" style={{maxHeight: 45}} className="mr-2 mt-2"/>
                        })}
                    </div>
                </div>}

            </div>
        </div>
    );
};

export default VehicleBlock;
