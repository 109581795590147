import React from "react";
import './MarkupText.css';
import draftToHtml from 'draftjs-to-html';
import {parseHtml} from "../../utils";

const MarkupText = ({text, itemProp, data}) => {
    return (text && text !== undefined) ? <div className="htmlContent font-body" itemProps={itemProp} dangerouslySetInnerHTML={{__html: parseHtml(draftToHtml(text), data)}}></div> : null;
};

MarkupText.defaultProps = {
    data: undefined,
}

export default MarkupText;
