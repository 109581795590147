import React, {useEffect, useState} from "react"
import LoadingIndicator from "../components/indicators/LoadingIndicator";
import VehicleFavoriteBtn from "./vehicle/vehicle-favorites/VehicleFavoriteBtn";


const CarouselDEPRICATED = ({images, reference, category, label, onImageLoad, context, showFavorites=false, arrows=undefined, extraCategory}) => {
    const [vehImages, setVehImages] = useState([]);
    const [index, setIndex] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const isArray = images instanceof Array;
        let n_images = isArray ? images : [{"uri": images.uri}];
        setVehImages(n_images);
        setLoading(false)
    }, [images]);

    const onPrevClick = (e, currentIndex) => {
        e.stopPropagation();
        const newIndex = currentIndex > 0 ? currentIndex - 1 : vehImages.length - 1;
        setIndex(newIndex);
    };

    const onNextClick = (e, currentIndex) => {
        e.stopPropagation();
        const newIndex = currentIndex + 1 < vehImages.length ? currentIndex + 1 : 0;
        setIndex(newIndex);
    };

    const renderDefaultArrows = () => {
        if (vehImages.length > 1) {
            return <div className="flex absolute bottom-0 right-0 mr-1 mb-1 text-white">
                <div className="flex items-center justify-center cursor-pointer z-20 mr-1" style={{width: 30, height: 30, background: 'rgba(0,0,0,0.6)'}} onClick={e => onPrevClick(e, index)}>
                    <i className="fad fa-chevron-left text-xs "/>
                </div>

                <div className="flex items-center justify-center cursor-pointer z-20" style={{width: 30, height: 30, background: 'rgba(0,0,0,0.6)'}} onClick={e => onNextClick(e, index)}>
                    <i className="fad fa-chevron-right text-xs "/>
                </div>
            </div>
        }

        return null
    }

    const renderTraderArrows = () => {
        if (vehImages.length > 1) {
            return <React.Fragment>
                <div className="flex items-center justify-center cursor-pointer z-20 absolute left-0 vertical-align-center" style={{width: 25, height: 25, background: 'rgba(255,255,255,0.6)'}} onClick={e => onPrevClick(e, index)}>
                    <i className="fad fa-chevron-left text-xs "/>
                </div>

                <div className="flex items-center justify-center cursor-pointer z-20 absolute right-0 vertical-align-center" style={{width: 25, height: 25, background: 'rgba(255,255,255,0.6)'}} onClick={e => onNextClick(e, index)}>
                    <i className="fad fa-chevron-right text-xs "/>
                </div>
            </React.Fragment>
        }

        return null
    }

    const renderArrows = () => {
        if (vehImages.length > 1) {
            return arrows === undefined ? renderDefaultArrows() : arrows(e => onPrevClick(e, index), e => onNextClick(e, index))
        }

        return null
    }

    const defaultVehicleImage = require("../assets/vehicle-default.jpg");
    return (
        <div className="relative">
            {loading && <LoadingIndicator/>}
            {!loading && <div className="relative">
                <div style={{minHeight: 220}}>
                    <img src={vehImages ? (vehImages[index] ? vehImages[index].uri : defaultVehicleImage) : defaultVehicleImage} alt={reference} style={{maxWidth: "100%"}} onLoad={onImageLoad}/>
                </div>
                {renderArrows()}
                {showFavorites && <div className="absolute" style={{bottom: 15, right: 55}}><VehicleFavoriteBtn context={context} reference={reference}/></div>}

                {(category || extraCategory) && <div className="flex absolute top-0" style={{zIndex: 19}}>
                    {category  && <div className="flex flex-row items-center justify-center h-10">
                        <div className="flex items-center justify-start px-5 text-white capitalize h-full" style={{borderBottomRightRadius: 30, borderTopRightRadius: 30, background: "rgba(0,0,0,0.6)"}}>{category}</div>
                    </div>}

                    {extraCategory && <div className={`flex flex-row items-center justify-center h-10 ${category !== null ? "ml-2" : ""}`}>
                        <div className="flex items-center justify-start px-5 text-white capitalize h-full" style={{borderBottomRightRadius: 30, borderTopRightRadius: 30, borderBottomLeftRadius: category ? 30 : 0, borderTopLeftRadius: category ? 30 : 0, backgroundColor: "#3B91D3"}}>{extraCategory}</div>
                    </div>}
                </div>}
            </div>}
        </div>
    )
};

CarouselDEPRICATED.defaultProps = {
    label: null,
    category: null,
    extraCategory: undefined
};

export default CarouselDEPRICATED
