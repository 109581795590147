import React, {useContext} from 'react';
import {find} from "lodash";
import {ButtonWithIcon, SelectField, InputField, DatePickerField} from "../../../input";
import {ACTIONS, DispatchContext, StateContext, TakeOverFormFields} from "../Takeover";
import {useMakes} from "../../../../hooks/useMakes";
import {useModels} from "../../../../hooks/useModels";
import {fuels} from "../../../../constants";

const StepOne = ({context}) => {
    const dispatch = useContext(DispatchContext);
    const {errors, vehicle} = useContext(StateContext);

    const {makes, loading: makesLoading, error: makesError} = useMakes();
    const {models, loading: modelsLoading, error: modelsError} = useModels(vehicle[TakeOverFormFields.make].id);

    const onHandleChange = (field, value) => {
        dispatch({type: ACTIONS.SET_VEHICLE, field, payload: value})
        dispatch({type: ACTIONS.REMOVE_ERROR, field})
    }

    const hasValue = (field) => {
        return vehicle[field] !== ''
    }

    const choseMake = () => {
        const gettingMakes = makesLoading || makes.length < 1;
        const error = makesError ? "De merken konden niet gefetched worden" : errors[TakeOverFormFields.make]
        let options = [];
        if(!gettingMakes){
            makes.map(make => options.push({id: make.id, text: make.name}));
        }

        return <SelectField label="Kies merk"
                            containerClass="w-full px-0 mb-6"
                            disabled={gettingMakes}
                            text={gettingMakes ? "Loading makes..." : "Kies merk"}
                            loading={gettingMakes}
                            required={true}
                            options={options}
                            selectedOption={hasValue(TakeOverFormFields.make) ? vehicle[TakeOverFormFields.make].id : null}
                            onChange={e => {
                                const selectedMake = find(makes, make => parseInt(make.id) === parseInt(e.target.value));
                                onHandleChange(TakeOverFormFields.make, selectedMake);
                                onHandleChange(TakeOverFormFields.model, '');
                            }}
                            errorText={error}/>
    };

    const choseModel = () => {
        const modelsAvailable = hasValue(TakeOverFormFields.make) && !modelsLoading && models !== null && models.length > 0
        const gettingModels = modelsLoading
        const isDisabled = !hasValue(TakeOverFormFields.make) || gettingModels;

        const error = modelsError ? "De modellen konden niet gefetched worden" : errors[TakeOverFormFields.model]
        let options = [];
        if(modelsAvailable && !gettingModels){
            models.map(model => options.push({id: parseInt(model.id), text: model.name}));
        }

        return <SelectField label="Kies model"
                            containerClass="w-full px-0 mb-6"
                            disabled={isDisabled}
                            text={gettingModels ? "Loading models..." : "Kies model"}
                            loading={gettingModels}
                            required={true}
                            options={options}
                            selectedOption={hasValue(TakeOverFormFields.model) ? vehicle[TakeOverFormFields.model].id : null}
                            onChange={e => {
                                const selectedModel = find(models, model => parseInt(model.id) === parseInt(e.target.value));
                                onHandleChange(TakeOverFormFields.model, selectedModel)
                            }}
                            errorText={error}/>
    };

    const choseFuels = () => {
        let options = [];
        fuels.map(fuel => options.push({id: fuel, text: fuel}));
        return <SelectField label="Kies brandstof"
                            containerClass="w-full px-0 mb-6"
                            text="Kies brandstof"
                            required={true}
                            options={options}
                            selectedOption={vehicle[TakeOverFormFields.fuel] ? vehicle[TakeOverFormFields.fuel] : null}
                            onChange={e => onHandleChange(TakeOverFormFields.fuel, e.target.value)}
                            errorText={errors[TakeOverFormFields.fuel]}/>
    };

    const choseFirstRegistration = () => {
        return <DatePickerField containerClass="w-full px-0 mb-6"
                                label="Eerste inschrijving"
                                required={true}
                                noFuture={true}
                                value={vehicle[TakeOverFormFields.firstRegistration]}
                                onChange={date => onHandleChange(TakeOverFormFields.firstRegistration, date)}
                                calendarIcon="fad fa-calendar-check"
        />
    };

    const isValid = () => {
        let isValid = true;
        if (!vehicle.make) {
            dispatch({type: ACTIONS.SET_ERROR, field: [TakeOverFormFields.make], payload: 'Het merk moet gekozen worden.'})
            isValid = false;
        }

        if (!vehicle.model) {
            dispatch({type: ACTIONS.SET_ERROR, field: [TakeOverFormFields.model], payload: 'Het model moet gekozen worden.'})
            isValid = false;
        }

        if (!vehicle.fuel) {
            dispatch({type: ACTIONS.SET_ERROR, field: [TakeOverFormFields.fuel], payload: 'De brandstof moet gekozen worden.'})
            isValid = false;
        }

        if (!vehicle.licencePlate) {
            dispatch({type: ACTIONS.SET_ERROR, field: [TakeOverFormFields.licencePlate], payload: 'De nummerplaat moet gekozen worden.'})
            isValid = false;
        }

        if (!vehicle.mileage) {
            dispatch({type: ACTIONS.SET_ERROR, field: [TakeOverFormFields.mileage], payload: 'De kilometerstand moet gekozen worden.'})
            isValid = false;
        }
        return isValid
    };

    const onSubmit = (e) => {
        e.preventDefault();

        if (isValid()) {
            dispatch({type: ACTIONS.NEXT_STEP})
        }
    };

    return (
        <form action="" onSubmit={e => onSubmit(e)} className=" flex flex-col  items-center">
            {choseMake()}
            {choseModel()}
            {choseFuels()}
            {choseFirstRegistration()}
            <div className="flex flex-col md:flex-row w-full">
                <InputField containerClass="w-full px-0 mb-6 mr-0 md:mr-1"
                            inputClass="rounded-none"
                            label="Nummerplaat"
                            required={true}
                            placeholder="1-ABC-725"
                            value={vehicle[TakeOverFormFields.licencePlate]}
                            onChange={(value) => onHandleChange(TakeOverFormFields.licencePlate, value)}
                            errorText={errors[TakeOverFormFields.licencePlate]}/>
                <InputField containerClass="w-full px-0 mb-6  ml-0 md:ml-1"
                            inputClass="rounded-none"
                            onlyNumbers={true}
                            required={true}
                            label="Kilometerstand"
                            placeholder="35000"
                            value={vehicle[TakeOverFormFields.mileage]}
                            onChange={(value) => onHandleChange(TakeOverFormFields.mileage, value)}
                            errorText={errors[TakeOverFormFields.mileage]}/>
            </div>
            <div className="flex justify-end w-full px-3 mt-6">
                <ButtonWithIcon onClick={(e) => onSubmit(e)}
                                text="Volgende"
                                icon="fad fa-chevron-right"
                                iconPosition="right"
                />

            </div>


        </form>
    );
};

export default StepOne;
