import React from 'react';
import {formatPrice} from "../../../../core/utils";
import PhotoSlider from "../../../../core/components/PhotoSlider";
import Fade from "react-reveal/Fade";
import {forEach, max, min, sortBy} from "lodash";
import Slider from "rc-slider";
import {preview_vehicle} from "../../../../core/constants";


const VehicleDetails_Preview = () => {
    const hasBrutoPrice = (preview_vehicle.brutoPrice && preview_vehicle.brutoPrice) > 0;
    const finance_rates = preview_vehicle.financeRates || [];
    const financing = true;
    const financePrice = 218.19
    const deposit = 20
    const monthsDuration = 36
    const deposits = sortBy(finance_rates.map(fr => parseInt(fr.deposit)).filter((value, index, self) => self.indexOf(value) === index));
    const months = sortBy(finance_rates.map(fr => parseInt(fr.months)).filter((value, index, self) => self.indexOf(value) === index));

    let monthMarks = {}
    let depositMarks = {}
    forEach(months, value => monthMarks = Object.assign(monthMarks, {[value]: ''}));
    forEach(deposits, value => depositMarks = Object.assign(depositMarks, {[value]: ''}));

    const buttonsAndPrice = () => {
        return <div className="flex ">
            <div className="flex flex-col mr-3" style={{width: 300}}>
                <div className="flex w-full">
                    <div className="bg-primary text-white hover:text-gray-400 text-sm py-2 px-4 rounded-none w-full text-center cursor-pointer outline-none">Reserveren</div>
                </div>

                <div className="flex flex-row  mt-2">
                    <button className="bg-primary w-full text-white hover:text-gray-400 text-sm py-2 px-2 mr-2 rounded-none outline-none">Testrit aanvragen</button>
                    <button className="bg-primary w-full text-white hover:text-gray-400 text-sm py-2 px-2 rounded-none cursor-pointer outline-none">Meer informatie</button>
                </div>

            </div>

            <div className="flex flex-col self-center justify-center text-black py-3 border-l border-transparent md:border-0 mr-0 md:mr-2" style={{width: 250, minHeight: 76}}>
                <div className="flex items-center justify-end">
                    <div className="flex items-center">
                        <span className={`font-thin mr-2  w-full ${(hasBrutoPrice) ? 'text-sm line-through' : 'text-3xl'}`}>{formatPrice(preview_vehicle.price)}</span>
                        {hasBrutoPrice &&
                        <span className="text-3xlfont-extralight mr-2 ">{formatPrice(preview_vehicle.brutoPrice)}</span>}
                    </div>
                    <span className="self-end text-xsfont-extralight " style={{paddingBottom: 3}}>incl. BTW</span>
                </div>
                < div className="flex items-center justify-end text-center">
                    <span className="self-end text-smfont-extralight text-black" style={{paddingBottom: 3}}>{(preview_vehicle.vatRegime) ? 'BTW aftrekbaar' : ''}</span>
                </div>
            </div>
        </div>
    }

    const renderPrivateCustomerSliders = () => {
        return (
            <div className="">
                <div className="text-lg mb-5">Bereken uw plan</div>
                <div className="text-sm text-gray-600 mb-3">Voorschot</div>
                <Slider min={parseInt(min(deposits))}
                        max={parseInt(max(deposits))}
                        defaultValue={deposit}
                        value={deposit}
                        step={null}
                        marks={depositMarks}
                        dotStyle={{display: 'none'}}/>

                <div className="w-full flex justify-end text-sm mt-2 text-gray-600">{deposit} %</div>


                <div className="text-sm text-gray-600 mb-3">Duurtijd van de financiering</div>
                <Slider min={parseInt(min(months))}
                        max={parseInt(max(months))}
                        defaultValue={monthsDuration}
                        value={monthsDuration}
                        step={null}
                        marks={monthMarks}
                        dotStyle={{display: 'none'}}
                        />
                <div className="w-full flex justify-end text-sm mt-2 text-gray-600">{monthsDuration} m</div>
            </div>
        )
    };

    return (
        <div className="flex flex-col my-5 px-5">
            <div className="flex mb-5 justify-center md:justify-between" style={{letterSpacing: "0.03rem", fontWeight: 500, fontSize: 14}}>
                <div className="flex items-center justify-center hover:text-gray-900 mt-1 text-center cursor-pointer outline-none uppercase text-black">
                    <i className="fal fa-chevron-left mr-2" style={{fontSize: "0.6rem"}}/> Terug naar het overzicht
                </div>

                <div className="hidden md:flex">
                    <div className="flex items-center justify-center mr-5 uppercase text-black cursor-pointer">
                        <i className="fal fa-chevron-left mr-2" style={{fontSize: "0.6rem"}}/> Vorige
                    </div>
                    <div className="flex items-center justify-center uppercase text-black cursor-pointer">
                        Volgende <i className="flex self-center fal fa-chevron-right ml-2" style={{fontSize: "0.6rem"}}/>
                    </div>
                </div>

            </div>
            <div id="veh-detail-header" className="sticky-header">
                <div className="flex justify-center items-center hidden md:block header2">
                    <div className="flex flex-row justify-between items-center w-full pl-0 relative text-black mb-5 border-b border-primary bg-gray-100 pb-2 pt-2">

                        <i className="fal fa-chevron-left text-4xl mr-2 ml-2 cursor-pointer text-black"/>
                        <div className="flex flex-col w-full self-center items-center">
                            <div className="flex flex-col w-full self-start pl-2 text-2xl capitalize truncate">
                                <div>{preview_vehicle.make} {preview_vehicle.model}</div>
                                <div className="text-md truncate">{preview_vehicle.version}</div>
                            </div>

                            <div className="flex w-full self-start text-xs pl-2 mt-1 ">
                                <span className="text-gray-600 text-xs mr-1">Referentie:</span>{preview_vehicle.identification}
                            </div>
                        </div>

                        <i className="fal fa-chevron-right text-4xl ml-2 mr-2 cursor-pointer text-black"/>
                    </div>
                    <div className="flex w-full items-center justify-center mb-5 lg-mb-0">{buttonsAndPrice()}</div>
                </div>
            </div>


            <div className="flex flex-col md:flex-row ">
                <div className="hidden md:block w-full md:w-1/2 -mr-5 md:mr-0 pr-0 md:pr-5">
                    <div className="bg-white">
                        <PhotoSlider images={preview_vehicle.images} onePhotoSlider={true} showThumbs={true} photosSorted={true}/>
                    </div>
                </div>
                <div className="preview_vehicle-detail-right-side flex flex-1 mt-5 md:mt-0 relative">
                    <div className="flex flex-col relative w-full">
                        <div className="md:hidden bg-white mb-3">
                            <div className="flex flex-col">
                                <div className="flex w-full mb-2">
                                    <div className="bg-primary border-2 border-transparent text-white hover:bg-white hover:border-2 hover:border-primary hover:text-primary py-2 px-4 rounded-none w-full text-center cursor-pointer outline-none">
                                        Reserveren
                                    </div>
                                </div>
                                <div className="bg-white flex ">
                                    <div className="flex flex-1 mr-2">
                                        <button className="bg-gray-900 w-full text-white hover:text-gray-400 font-medium text-sm py-2 px-2 rounded-none mb-2 outline-none">
                                            Testrit aanvragen
                                        </button>
                                    </div>
                                    <div className="flex flex-1 cursor-pointer outline-none" style={{width: '100%'}}>
                                        <div className="bg-gray-900 w-full text-white hover:text-gray-400 font-medium text-sm py-2 px-2 rounded-none mb-2 outline-none text-center">
                                            Meer informatie
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {financing && finance_rates.length > 0 && <div className="flex flex-col md:flex-row border border-gray-100 items-center mb-5">
                            <div className="flex flex-col w-full md:w-5/8 p-5 ">
                                <div className="block md:hidden mb-8 text-center w-full text-gray-600">Let op, geld lenen kost ook geld.</div>
                                <div className="w-full">{renderPrivateCustomerSliders()}</div>
                                <div className="hidden md:block mt-8 text-center w-full text-gray-600">Let op, geld lenen kost ook geld.</div>
                            </div>
                            <div className="flex flex-col items-center justify-start w-full md:w-3/8 bg-gray-100 p-5">
                                <span className="mb-5 text-lg">Per maand</span>
                                <div className="flex items-center mb-12">
                                    <div className="flex self-end text-xl md:text-3xl "><Fade spy={financePrice} duration={200}>{formatPrice(financePrice)}</Fade>*</div>
                                    <span className="flex self-end text-smfont-extralight ml-2 text-gray-700" style={{paddingBottom: 3}}>{preview_vehicle.vat_label}</span>
                                </div>
                                <button className="w-full bg-primary text-white hover:text-gray-400 font-medium text-sm py-2 px-2 rounded-none mb-12">
                                    Een vrijblijvende offerte aanvragen
                                </button>
                                <div className="flex flex-col text-sm text-black text-center">
                                    <div>Wij nemen alle wagens over.</div>
                                    <div className="hover:text-black mt-1 text-center underline cursor-pointer outline-none">
                                        Bereken de waarde van uw auto hier.
                                    </div>
                                </div>
                            </div>

                        </div>}
                    </div>
                </div>


            </div>
        </div>
    );
};

export default VehicleDetails_Preview;
