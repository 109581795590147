import React, {useContext, useEffect, useState} from 'react';
import ErrorIndicator from "../../../indicators/ErrorIndicator";
import SuccessIndicator from "../../../indicators/SuccessIndicator";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import StepFive from "./StepFive";
import {ACTIONS, DispatchContext, StateContext, TakeOverFormFields} from "../Takeover";
import Title from "./Title";
import {getWindowPathLocation, scrollTo} from "../../../../utils";
import {useVehicleTakeOver} from "../../../../hooks/useVehicleTakeOver";

const TakeoverWizard = ({context, handlePrivacy}) => {
    const dispatch = useContext(DispatchContext);
    const {step, vehicle, user} = useContext(StateContext);
    const {submit, loading: sending} = useVehicleTakeOver();
    const [sendSuccess, setSendSuccess] = useState(false);
    const [sendFailed, setSendFailed] = useState(false);

    const onComplete = async () => {
        setSendSuccess(false);
        setSendFailed(false);


        const jsonData = JSON.stringify({
            user,
            vehicle: {
                ...vehicle,
                [TakeOverFormFields.make]: vehicle[TakeOverFormFields.make].name,
                [TakeOverFormFields.model]: vehicle[TakeOverFormFields.model].name,
                [TakeOverFormFields.firstRegistration]: vehicle[TakeOverFormFields.firstRegistration].toLocaleDateString()
            },
            origin: getWindowPathLocation(context),
        });

        submit(jsonData)
            .then(result => {
                if (result) {
                    setSendSuccess(true);
                    setSendFailed(false);
                    setTimeout(() => {
                        dispatch({type: ACTIONS.RESET_ALL});
                        setSendSuccess(false);
                        setSendFailed(false);

                    }, 5000);
                } else {
                    setSendSuccess(false);
                    setSendFailed(true);
                }
            })
    };

    useEffect(() => {
        const elem = document.getElementById('takeover-container');
        if(elem){
            scrollTo(elem)
        }
    }, [step])


    return (
        <div id="takeover-container" className="py-10 md:py-15 items-center justify-center flex">
            <div className="container flex items-center justify-center">
                <div className={`${step === 3 || step === 4 ? "w-full" : "w-full md:w-2/3"}`}>
                    <Title step={step}/>
                    <div className="w-full mb-6 md:mb-0 mt-6 ">
                        {!sendSuccess && sendFailed && <ErrorIndicator error="Probleem met het verzenden van uw aanvraag."/>}
                        {sendSuccess && !sendFailed && <SuccessIndicator success="De overname aanvraag is verstuurd."/>}
                    </div>

                    <div className="border border-gray-200 rounded px-5 pt-6 pb-8 mb-4 mt-6">
                        {step === 1 && <StepOne context={context}/>}
                        {step === 2 && <StepTwo context={context}/>}
                        {step === 3 && <StepThree context={context}/>}
                        {step === 4 && <StepFour context={context}/>}
                        {step === 5 && <StepFive context={context}
                                                 handlePrivacy={handlePrivacy}
                                                 sending={sending}
                                                 sendSuccess={sendSuccess}
                                                 onConfirm={() => onComplete()}/>}
                    </div>
                </div>

            </div>
        </div>
    );
};

export default TakeoverWizard;
