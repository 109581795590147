import React from 'react';
import {getBodyIcon, getStickiesOffset} from "../../../../core/utils";
import Sort from "../../../../core/components/vehicle/vehicles-list/filter/Sort";
import {map, orderBy} from "lodash";
import {preview_context, preview_vehicle} from "../../../../core/constants";
import RangeSlider from "../../../../core/components/vehicle/vehicles-list/filter/RangeSlider";
import VehicleCard from "../../../../core/components/vehicle/VehicleCard";

const makes = [
    {key: 'audi', text: 'Audi', value: 25},
    {key: 'skoda', text: 'Skoda', value: 13},
    {key: 'volkswagen', text: 'Volkswagen', value: 20},
]

const fuels = [
    {text: 'Diesel', value: 25},
    {text: 'Benzine', value: 38},
    {text: 'Elektrish', value: 2}
]

const bodies = [
    {key: 'station_wagon', text: 'Break', value: 40},
    {key: 'convertible', text: 'Cabriolet', value: 2},
    {key: 'coupe', text: 'Coupe', value: 23}
]

const vehicles = [preview_vehicle, preview_vehicle]

const Vehicles_Preview = () => {
    return (
        <div className="flex flex-col">
            <div className="py-0 px-5 flex flex-col">

                <div className="horizontal-line "/>
                <div className="flex flex-row -mx-5 md:mx-0">

                    <div id="filters-left" className="hidden md:block absolute md:sticky z-40 md:z-10 mr-5 mt-5 bg-white filters-left-container" style={{top: 0}}>
                        <div className="flex flex-col px-5 md:px-0 pb-5 md:pb-16">
                            <div className="flex flex-col">

                                <div className="font-bold mb-3 bg-gray-100 p-2">Merk en model</div>
                                <div className="pl-2 pr-4">
                                    {map(makes, (make) => {
                                        return (
                                            <div key={`filter-make-${make.key}`} className="flex flex-col text-gray-900 my-1">
                                                <div className="flex flex-row py-1 hover:bg-gray-100 cursor-pointer hover:text-primary" >
                                                    <div className="flex items-center" style={{minWidth: 35}}>
                                                        <i className={`icon-${(make.key.includes('_')) ? make.key.split('_')[0] : make.key.toLowerCase()}`}/>
                                                    </div>
                                                    <div className="flex flex-row justify-between flex-1 relative">
                                                        <div className="capitalize">{make.text}</div>
                                                        <div>{make.value}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="horizontal-line my-5"/>
                            <div className="flex flex-col">
                                <div className="font-bold mb-3 bg-gray-100 p-2">Brandstof</div>
                                <div className="pl-2 pr-4">
                                    {map(orderBy(fuels, 'text'), (item) => {
                                        return (
                                            <div key={`filter-fuel-${item.key}`} className="flex flex-col text-gray-900 mb-1">
                                                <div className="flex flex-row justify-between flex-1 relative py-1 cursor-pointer hover:text-primary hover:bg-gray-100">
                                                    <div className="capitalize">{item.text}</div>
                                                    <div>{item.value}</div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="horizontal-line my-5"/>
                            <div className="flex flex-col">
                                <div className="font-bold mb-3 bg-gray-100 p-2">Carrosserie</div>
                                <div className="pl-2 pr-4">
                                    {map(orderBy(bodies, 'text'), (item) => {
                                        return (
                                            <div key={`filter-body-${item.key}`} className="flex flex-col text-gray-900 mb-1">
                                                <div className="flex flex-row justify-between flex-1 relative py-1 cursor-pointer hover:text-primary hover:bg-gray-100">
                                                    <div className="flex flex-row">
                                                        <div style={{minWidth: 65}}><i className={getBodyIcon(item.key)}/></div>
                                                        {item.text}
                                                    </div>
                                                    <div>{item.value}</div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="horizontal-line my-5"/>
                            <div className="flex flex-col">
                                <div className="font-bold mb-3 bg-gray-100 p-2">Prijs</div>
                                <div className="ml-5" style={{marginRight: "0.8rem"}}>
                                    <RangeSlider
                                        min={8000}
                                        max={130000}
                                        step={1000}
                                        disabled={true}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>


                    <div className="flex flex-col w-full" >
                        <div className="w-full">
                            <div className="flex flex-col top-container top-0">
                                <div className="pt-0 md:pt-5 bg-white">
                                    <div className="filters-bar flex flex-col border border-gray-100 ">
                                        <div className="flex flex-row text-gray-600 items-center justify-between  bg-gray-100 p-2">
                                            <div className="flex flex-row">
                                                <div>65 wagens</div>
                                            </div>
                                            <div className="flex flex-row items-center">
                                                <Sort/>
                                                <div className="ml-5 block md:hidden"><i className="far fa-filter hover:text-primary"/></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="divider" className="hidden md:block bg-white border-b border-transparent w-full" style={{height: 20}}/>
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="vehicles-list-preview flex flex-row flex-wrap justify-between">
                                {map(vehicles, (vehicle, index) => {
                                    return <VehicleCard key={vehicle.reference}
                                                        vehicle={vehicle}
                                                        context={preview_context}

                                    />
                                })}
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    );
};

export default Vehicles_Preview;
