import React, {useState} from "react";
import {settingsMenuItems} from "../../../data/constants";
import {Link} from "react-router-dom";
import SettingsDropDown from "./SettingsDropDown";

export const HeaderMenu = () => {


    return (
        <SettingsDropDown />
    )
};

export default HeaderMenu;
