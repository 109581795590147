import React from 'react';
import LoadingIndicator from "../../components/indicators/LoadingIndicator";

const SelectField = ({containerClass, label, text, selectedOption, options, onChange, errorText, required, loading, disabled, selectClass, style}) => {
    const hasError = errorText !== undefined;
    let bg = loading || disabled ? "bg-gray-200 border-gray-400" : "bg-white border-gray-400";
    bg = hasError ? "bg-red-100 border-red-400" : bg;
    const defaultValue = null;
    return (
        <div className={`${containerClass}`} style={style}>
            {label ? <label className={`block capitalize tracking-wide text-sm font-bold mb-2 ${hasError ? "text-red-400" : "text-gray-700"}`}> {label} {required ? "(*)" : ""}</label> : ""}
            <div className="relative" style={{height: "inherit"}}>
                <select
                    className={`block appearance-none w-full ${bg} border text-gray-700 ${selectClass} py-3 px-4 rounded-none leading-tight focus:outline-none focus:bg-white focus:border-primary h-full text-sm md:text-lg`}
                    disabled={disabled}
                    value={selectedOption !== undefined && selectedOption !== null ? selectedOption : ""}
                    onChange={(e) => onChange(e)}>
                    <option value="">{text}</option>
                    {!loading && options.map((option, i) => {
                        return <option key={i} value={option.id}>{option.text}</option>
                    })}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    {loading ? <LoadingIndicator small={true}/> : <i className="fad fa-chevron-down"/>}
                </div>
            </div>
            {hasError && <div className="text-red-400 text-xs">{errorText}</div>}
        </div>
    );
};

SelectField.defaultProps = {
    selectClass: "py-1 md:py-3",
    style: {}
};

export default SelectField;
