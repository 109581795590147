import React, {useState, useEffect, useContext} from 'react';
import {DefaultAction, DefaultImage, FilterTypes as filterTypes, FilterTypes, SortByFilterLabelsTranslation} from "../../../constants";
import {find, cloneDeep, filter, includes, keys, pullAll, map, forEach, uniq, orderBy, shuffle, slice} from 'lodash';
import Sticky from "react-stickynode"
import List from "./List";
import {filtersFromSearch, getFilteredVehicles, getFilterLabel} from "./filter/functions";
import {useVehiclesData} from "../../../hooks/useVehiclesData";
import {LoadingIndicator, ErrorIndicator} from "../../indicators";
import {b64DecodeUnicode, getStickiesOffset, navigateAction} from "../../../utils";
import Filters from "./filter/Filters";
import DataContext from "../../../store/DataContext";
import Modal from 'react-modal';
import './custom.css';
import ListFilterTopBar from "./ListFilterTopBar";
import {Image} from "../../../index";
import {Scrollbars} from "react-custom-scrollbars";
import NoResultsIndicator from "../../indicators/NoResultsIndicator";
import SegmentsTopBar from "./segments/SegmentsTopBar";
import SegmentSelector from "./segments/SegmentSelector";
import {useSegmentsData} from "./segments/useSegmentsData";
import {countVehiclesForSegment, filtersToClearSegment, mapSegmentsWithPriority} from "./segments/functions";
import {LegalContent} from "../../legal";
import useWindow from "../../../hooks/useWindow";
import {useIsMobile} from "../../../hooks/useIsMobile";


const customStyles = {
    content: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        padding: 0
    },
    overlay: {}
};

const autoSorters = [FilterTypes.price, FilterTypes.mileage, FilterTypes.year]


const sortVehiclesByMakeOrder = (vehicles, makeOrder, sorter) => {

    if (makeOrder === undefined) {
        return vehicles
    }

    const result = [];
    let sorted_vehicles = [];
    const tmp_vehicles = filter(vehicles, (v) => !v.sold)
    if (sorter) {
        const field = sorter.value
        const order = sorter.ascending ? 'asc' : 'desc'
        sorted_vehicles = orderBy(tmp_vehicles, [field], [order]);
    } else {
        sorted_vehicles = shuffle(tmp_vehicles);
    }

    forEach(makeOrder, (make) => {
        const tmp_make_vehicles = filter(sorted_vehicles, (v) => v.makeKey === make)
        const vehicle_for_make = (tmp_make_vehicles && tmp_make_vehicles.length > 0) ? tmp_make_vehicles[0] : undefined

        if (vehicle_for_make) {
            result.push(vehicle_for_make)
        }
    })
    const others = pullAll(sorted_vehicles, result)
    let tmp = [...result, ...others]
    tmp = slice(tmp, 0, 10)
    return map(tmp, (v) => ({reference: v.reference}))
}

const vehiclesBySegment = (vehicles, segments, makeOrder, sorter) => {
    let result = {}
    forEach(segments, (segment) => {
        const vehiclesForSegment = filter(vehicles, (veh) => veh.segmentKey === segment.key)
        result[segment.key] = sortVehiclesByMakeOrder(vehiclesForSegment, makeOrder, sorter)
    })
    return result
}

const mapVehiclesBySegmentByOrder = (order, allVehicles) => {

    // This is nessesary when images are lazy loaded ....
    // Otherwise the new images are nog visible in the segment selector

    const result = {...order}

    forEach(keys(result), (segment) => {
        const tmp = []
        forEach(result[segment], (vehicle) => {
            const v = find(allVehicles, (v) => v.reference === vehicle.reference)
            tmp.push(v)
        })
        result[segment] = tmp;
    })
    return result;
}

const ListAndFilters = ({query, custom_card_image, custom_card_action, advertise_image, onVehicleClick, context, renderVehicle, totalInvisibleCards = 4, vehiclesFilter = null, renderFilters, renderFiltersTopBar, renderNoSearchResults, renderCarSearchAgentBanner, renderMobileFilters, renderSegmentsTopBar, modalContentStyle, modalOverlayStyle, renderSegment}) => {
    const {vehicles, filterData, loading: vehiclesLoading, error: vehiclesError} = useVehiclesData(context.culture, query, vehiclesFilter);
    const {segments: segmentsConfiguration, loading: segmentsLoading, error: segmentsError} = useSegmentsData(context)
    const contextData = useContext(DataContext);
    const [mobileFilterModalVisible, setMobileFilterModalVisible] = useState(false);
    const {traceUserAction} = useContext(DataContext);
    const [traceTimeout, setTraceTimeout] = useState(0);
    const loading = vehiclesLoading || segmentsLoading;
    const error = vehiclesError || segmentsError;
    const [vehiclesBySegmentCached, setVehiclesBySegmentCached] = useState(undefined);

    const currentWindow = useWindow()
    const currentSearch = (currentWindow) ? currentWindow.location.search : ""
    // for mobile filter popup
    // if (!context.builder) {
    //     try {
    //         Modal.setAppElement('#___gatsby');
    //     }catch (e) {
    //         console.log(e)
    //     }
    //
    // }



    const modalStyles = {
        content: {...customStyles.content, ...modalContentStyle},
        overlay: {...customStyles.overlay, ...modalOverlayStyle}
    };

    const filters = contextData.filters;
    const sorter = contextData.sorter;

    useEffect(() => {
        if (filterData && (currentSearch && currentSearch !== "")) {
            const filters = filtersFromSearch(filterData, currentSearch)
            contextData.setFilters(filters);
        }
        else if (filterData && (currentSearch === "") && contextData.lastSelectedVehicle === undefined) {
            contextData.setFilters([])
        }
    }, [filterData, currentSearch]);


    useEffect(() => {
        if (contextData.scrollOffset !== 0) {
            window.scrollTo(0, contextData.scrollOffset);
            contextData.setScrollOffset(0);
        }

        console.log(contextData.lastSelectedVehicle);
        if(contextData.lastSelectedVehicle === undefined) {
            clearFilters()
        }
        contextData.setLastSelectedVehicle(undefined)
    }, []);

    useEffect(() => {
        setVehiclesBySegmentCached(undefined);
        if (contextData.scrollOffset === 0) {
            window.setTimeout(() => {
                window.scrollTo(0, 0)

            }, 200);
        }
    }, [filters, sorter]);

    useEffect(() => {
        if (filters && filters.length > 0) {
            handleTracing()
        }
    }, [filters]);

    const handleTracing = () => {
        if (traceTimeout !== 0) {
            clearTimeout(traceTimeout)
        }

        const tracingTimeout = setTimeout(() => {
                const data = map(filters, (filter => (
                    {
                        name: filter.type,
                        value: filter.value,

                    })));
                traceUserAction('vehicles-filter', JSON.stringify(data));

                clearTimeout(traceTimeout)
            },
            5000
        );

        setTraceTimeout(tracingTimeout);
    };

    const toggleSmallFilters = () => {
        setMobileFilterModalVisible(!mobileFilterModalVisible);
    };

    const addFilter = (filterItem) => {

        let newFilters = []
        const hasSegmentInFilters = hasSegmentFilter(filters)
        // if (hasSegmentInFilters) {
        //   newFilters = [...filter(filters, (f) => f.type !== FilterTypes.segment)];

        if (hasSegmentInFilters && includes(filtersToClearSegment, filterItem.type)) {
            newFilters = [...filter(filters, (f) => f.type !== FilterTypes.segment)];
        } else if (hasSegmentInFilters && filterItem.type !== FilterTypes.segment) {
            newFilters = [...filter(filters, (f) => !includes(filtersToClearSegment, f.type))];
        } else {
            newFilters = [...filters]
        }

        if (Array.isArray(filterItem)) {
            forEach(filterItem, (i) => {
                newFilters.push(i)
            })
        } else {
            newFilters.push({...filterItem});
        }


        contextData.setFilters(newFilters);

        if (includes(autoSorters, filterItem.type)) {
            const label = SortByFilterLabelsTranslation[filterItem.type]
            const sortByFilter = {
                type: FilterTypes.sortBy,
                value: filterItem.type,
                label: label[context.culture],
                ascending: true
            };
            contextData.setSorter(sortByFilter);
        }


    };

    const removeFilter = (delFilter) => {
        let newFilters = []

        if (delFilter.value) {
            newFilters = filter(filters, (f) => f.value !== delFilter.value);
        } else {
            newFilters = filter(filters, (f) => f.type !== delFilter.type);
        }

        if (delFilter.type === 'make') {
            // delete also all selected models
            newFilters = filter(newFilters, (f) => f.additional !== delFilter.value);
        }

        contextData.setFilters(newFilters);
    };

    const updateFilter = (filter) => {
        let copy = cloneDeep(filters);
        const existingFilter = find(copy, f => f.type === filter.type);
        existingFilter.value = filter.value;
        existingFilter.label = filter.label;
        contextData.setFilters(copy);
    };


    const handleSegmentClick = (segment) => {
        const hasSegmentFilter = find(filters, (f) => f.type === filterTypes.segment);
        const selectedSegmentIsCurrentFilter = find(filters, (f) => f.type === filterTypes.segment && f.value === segment.key);

        if (selectedSegmentIsCurrentFilter) {
            removeFilter({type: FilterTypes.segment})
        } else {
            if (hasSegmentFilter) {
                updateFilter({type: filterTypes.segment, value: segment.key, label: segment.label})
            } else {
                addFilter({
                    type: filterTypes.segment,
                    value: segment.key,
                    label: getFilterLabel(filterTypes.segment, segment.name, segment.key),
                })
            }
        }
    }

    const clearFilters = () => {
        contextData.setFilters([]);
        contextData.setSorter(undefined);
    };

    const handleVehicleClick = (vehicle) => {
        contextData.setScrollOffset(window.pageYOffset);
        contextData.setLastSelectedVehicle(vehicle.reference);
        onVehicleClick(vehicle)
    };

    const getUniqueSegmentKeys = (vehicles) => {
        return uniq(map(vehicles, (v) => v.segmentKey))
    }

    const hasSegmentFilter = (filters) => {
        const filter = find(filters, (f) => f.type === FilterTypes.segment)
        return (filter) ? true : false
    }

    if (loading || error !== null) {
        return (
            <div className="flex flex-col">
                <div className="py-0 px-5 flex flex-col h-screen">
                    {loading && <LoadingIndicator/>}
                    {!loading && error !== null && <ErrorIndicator error={error}/>}
                </div>
            </div>
        )
    }

    let tmpVehicles = vehicles;
    if(context.builder && tmpVehicles.length > 0) {
        tmpVehicles = tmpVehicles.slice(0,10)
    }

    const filtered_vehicles = getFilteredVehicles(filters, sorter, tmpVehicles);
    const filtered_vehicles_segments_keys = getUniqueSegmentKeys(filtered_vehicles);
    const segment_selector_visible = context.vehicleSegmentation && filtered_vehicles_segments_keys.length > 1 && !hasSegmentFilter(filters)
    //const all_segments = filter(filterData.segments, (s) => s.key !== "");
    const all_segments = orderBy(mapSegmentsWithPriority(map(filter(filterData.segments, (s) => s.key !== ""), (s) => {
        const count = countVehiclesForSegment(s, filters, tmpVehicles)
        return {
            ...s,
            count: count,
        }
    }), segmentsConfiguration), ['priority']);
    const currentSegmentFilter = find(filters, (f) => f.type === filterTypes.segment);
    const currentSegment = find(all_segments, (s) => s.key === ((currentSegmentFilter) ? currentSegmentFilter.value : ""));
    const top_bar_filters = filter(filters, (f) => f.type !== filterTypes.segment);

    let vehicles_by_segment = undefined;
    if (!vehiclesBySegmentCached) {
        const tmp = vehiclesBySegment(filtered_vehicles, all_segments, (filterData.dataSettings && filterData.dataSettings.makeOrder) ? filterData.dataSettings.makeOrder : undefined, sorter)
        setVehiclesBySegmentCached(tmp)

        // This is nessesary when images are lazy loaded ....
        // Otherwise the new images are nog visible in the segment selector
        vehicles_by_segment = mapVehiclesBySegmentByOrder(tmp, tmpVehicles)
    } else {
        // This is nessesary when images are lazy loaded ....
        // Otherwise the new images are nog visible in the segment selector
        vehicles_by_segment = mapVehiclesBySegmentByOrder(vehiclesBySegmentCached, tmpVehicles);
    }


    const renderNoResultsBlock = () => {
        if (renderNoSearchResults && typeof renderNoSearchResults === "function") {
            return renderNoSearchResults(filters, removeFilter, clearFilters)
        }

        return <NoResultsIndicator message="Geen wagens gevonden"/>
    }

    return (
        <div className="flex flex-col">
            <div className="py-0 px-5 flex flex-col" style={{minHeight: "100vh"}}>

                <div className="flex flex-row -mx-5 md:mx-0">

                    <div id="filters-left" style={{top: getStickiesOffset() + 20}} className="hidden md:block absolute md:sticky z-40 md:z-10 mr-5 mt-5 bg-white filters-left-container">
                        <Scrollbars>
                            {renderFilters && typeof renderFilters === "function"
                                ? renderFilters(filterData, filters, addFilter, removeFilter, updateFilter)
                                :
                                <Filters data={filterData}
                                         filters={filters}
                                         onAddFilter={addFilter}
                                         onRemoveFilter={removeFilter}
                                         onUpdateFilter={updateFilter}
                                />
                            }
                        </Scrollbars>
                    </div>

                    <div id="boris" className="vehicles-list-container flex flex-col w-full">


                        {filtered_vehicles.length > 0 &&
                        <React.Fragment>

                            {advertise_image && advertise_image.image &&
                            <div className="hidden md:block cursor-pointer mb-5" onClick={() => navigateAction(context, advertise_image.action)} style={{maxHeight: 250}}>
                                <Image url={(advertise_image.image) ? advertise_image.image : undefined} style={{width: '100%', height: '100%', objectFit: 'cover'}}/>
                            </div>}

                            <div className="w-full">
                                <Sticky enabled={true} top={getStickiesOffset()} bottomBoundary='.bottom-container' activeClass="bg-white w-full boris top-filters-sticky" innerZ={21}>

                                    {
                                        renderFiltersTopBar && typeof renderFiltersTopBar === "function"
                                            ? renderFiltersTopBar(top_bar_filters, sorter, filtered_vehicles, removeFilter, clearFilters, toggleSmallFilters, context)
                                            : <ListFilterTopBar filters={top_bar_filters}
                                                                sorter={sorter}
                                                                filteredVehicles={filtered_vehicles}
                                                                onClearFilters={clearFilters}
                                                                onToggleSmallFilters={toggleSmallFilters}
                                                                onRemoveFilter={removeFilter}
                                            />
                                    }

                                    {context.vehicleSegmentation && filters.length !== 0 &&
                                    <React.Fragment>
                                        {
                                            renderSegmentsTopBar && typeof renderSegmentsTopBar === "function"
                                                ? renderSegmentsTopBar(all_segments, currentSegment, handleSegmentClick)
                                                : <SegmentsTopBar segments={all_segments}
                                                                  currentSegment={currentSegment}
                                                                  onClick={handleSegmentClick}/>
                                        }
                                    </React.Fragment>}


                                </Sticky>
                            </div>

                            {segment_selector_visible &&
                            <SegmentSelector segments={all_segments}
                                             context={context}
                                             vehiclesBySegment={vehicles_by_segment}
                                             renderVehicle={renderVehicle}
                                             onVehicleClick={(v) => handleVehicleClick(v)}
                                             onClick={handleSegmentClick}
                                             renderSegment={renderSegment}

                            />}

                            {!segment_selector_visible &&
                            <List vehicles={filtered_vehicles}
                                  custom_card_image={custom_card_image}
                                  custom_card_action={custom_card_action}
                                  context={context}
                                  totalInvisibleCards={totalInvisibleCards}
                                  onVehicleClick={(vehicle) => handleVehicleClick(vehicle)}
                                  renderVehicle={renderVehicle}
                                  renderCarSearchAgentBanner={renderCarSearchAgentBanner}
                            />}

                            {context.financing &&
                            <div className="font-secondary text-left text-gray-400 px-0 my-5 md:my-10 px-5 md:px-0">
                                <LegalContent context={context}
                                              renderContent={(loading, content) => {
                                                  return <div className="">
                                                      {loading && <div className="flex w-full items-center justify-center"><LoadingIndicator/></div>}
                                                      {!loading && content === null &&
                                                      <div className="flex w-full items-center justify-center"><ErrorIndicator error={t('Something went wrong.')}/></div>}
                                                      {!loading && content !== null && <div className="flex">
                                                          <div className="mr-1">*</div>
                                                          <div dangerouslySetInnerHTML={{__html: b64DecodeUnicode(content)}}/>
                                                      </div>}
                                                  </div>
                                              }}/>
                            </div>}

                        </React.Fragment>}

                        {filtered_vehicles.length <= 0 && renderNoResultsBlock()}
                    </div>


                </div>

            </div>
            <Modal
                isOpen={mobileFilterModalVisible}
                style={modalStyles}
                appElement={document.getElementById('root')}
            >
                {renderMobileFilters && typeof renderMobileFilters === 'function'
                    ? renderMobileFilters(filterData, filters, addFilter, removeFilter, updateFilter, clearFilters, toggleSmallFilters, filtered_vehicles.length)
                    : <div className="bg-white h-screen flex-col">
                        <div className="small-filter-title flex md:hidden sticky top-0 items-center justify-between pl-7 pr-5 mb-3 py-2 bg-gray-100 z-10">
                            <div>Filters</div>
                            <div className="flex md:flex bg-red-400 sticky top-0 rounded-full flex items-center justify-center z-50"
                                 onClick={() => toggleSmallFilters()}
                                 style={{width: 40, height: 40}}><i
                                className="far fa-times"/></div>
                        </div>
                        <Filters data={filterData}
                                 filters={filters}
                                 onAddFilter={addFilter}
                                 onRemoveFilter={removeFilter}
                                 onUpdateFilter={updateFilter}
                        />
                    </div>}


            </Modal>


            <div id="bottom-container" className="opacity-0 bottom-container"/>
        </div>
    );
};

ListAndFilters.defaultProps = {
    custom_card_image: DefaultImage,
    custom_card_action: DefaultAction,
    advertise_banner: DefaultImage,
    modalOverlayStyle: {},
    modalContentStyle: {}
};


export default ListAndFilters;
