const fonts = ["https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,600", "https://fonts.googleapis.com/css?family=Merriweather&display=swap"];
const icons = ['https://kit.fontawesome.com/394cd21ac4.js'];
const css = [];
// const css = ['//cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.css', '//cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick-theme.css'];

const urls = {
    home: '/',
    vehicles: 'stock',
    vehicleDetail: 'vehicle',
    vehicleReservation: 'reservation',
    vehicleTakeOver: 'takeover',
    vehicleCompare: 'vehicle-compare',
    search: 'search',
    contact: 'contact',
    privacy: 'privacy',
    login: 'login',
    resetPassword: 'reset-password',
    register: 'register',
    account: 'account'
};

export default {
    defaultCulture: 'nl',
    urls: urls,
    fonts: fonts,
    icons: icons,
    css: css,
}
