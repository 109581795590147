import { useState } from 'react';
import fetch from 'cross-fetch'
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";

export const DealerFileUploadMutation = `mutation VehicleTakeOverFileUpload($file: Upload!) { 
        vehicleTakeOverFileUpload(file: $file) { 
            ok                
            url
        }
    }`;


export function useDealerFileUpload() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { executeRecaptcha } = useGoogleReCaptcha();

    const submit = async (file, cb, error_cb) => {
        if (!executeRecaptcha) {
            return;
        }
        setLoading(true);

        const token = await executeRecaptcha('dealer_upload_file');

        const operation = {
            query: DealerFileUploadMutation,
            variables: {
                file: null
            }
        };

        const map = {
            "0": ["variables.file"]
        };

        const body = new FormData();
        body.append("operations", JSON.stringify(operation));
        body.append("map", JSON.stringify(map));
        body.append(0, file);


        fetch(
            '/graphql', {
                method: 'POST',
                headers: {"Captcha" : token},
                body: body
            })
            .then(res => res.json())
            .then(result => {
                const {vehicleTakeOverFileUpload} = result.data;
                setLoading(false);
                if(vehicleTakeOverFileUpload.ok) {
                    return cb(vehicleTakeOverFileUpload.url);
                } else {
                    setError(vehicleTakeOverFileUpload.error)
                    return error_cb(vehicleTakeOverFileUpload.error);
                }
            })
            .catch(error => {
                setError("ERROR");
                setLoading(false);
                return false;
            });
    };

    return {
        submit,
        loading,
        error
    };
}
