import React, {useContext, useEffect, useState} from "react";
import {useMutation} from "graphql-hooks";
import {
    useLocation,
    useHistory
} from "react-router-dom";
import {routes} from "../../data/constants";
import {StateContext} from "../../App";

export const AUTH_MUTATION = `
    mutation Auth($username: String!, $password: String!) {
        auth(username: $username, password: $password) {          
           user {
              firstName
              lastName
              email
            }
        }
    }`;


export const Login = ({onAuthenticated}) => {
    const state = useContext(StateContext);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [authMutation] = useMutation(AUTH_MUTATION);
    const [errors, setErrors] = useState({});

    let location = useLocation();
    let history = useHistory();

    useEffect(() => {
        if (state.session && state.session.authenticated) {
            history.push(routes.HOME);
        }
    }, []);

    const handleLogin = (e) => {
        e.preventDefault();

        authMutation({
            variables: {
                username: username,
                password: password,
            }
        }).then((result) => {
            if (result.data && result.data.auth) {
                const {auth} = result.data;
                if (auth.user) {
                    onAuthenticated(auth.user)
                    history.push(routes.HOME)
                }
            } else if (result.error && result.error.graphQLErrors && result.error.graphQLErrors.length > 0) {
                const error = result.error.graphQLErrors[0];
                if (error.code === '401') {
                    let tmpErrors = {...errors};
                    tmpErrors['auth'] = "Ongeldig email adress of wachtwoord";
                    setErrors(tmpErrors)
                } else {
                    let tmpErrors = {...errors};
                    tmpErrors['auth'] = error.message;
                    setErrors(tmpErrors)
                }
            } else {
                let tmpErrors = {...errors};
                tmpErrors['auth'] = 'Something went wrong';
                setErrors(tmpErrors);
            }
        });
    };

    return (
        <React.Fragment>
            <div className="builder-flex builder-flex-row builder-h-16 builder-bg-white builder-border-b builder-border-gray-400  builder-top-0 builder-left-0 builder-right-0"
                 style={{height: 65, minHeight: 65}}>
                <div className="builder-flex builder-items-center builder-ml-4 builder-justify-center builder-text-blue-800" style={{width: 300}}>
                    <div className="builder-flex builder-items-center">
                        <img className="builder-cursor-pointer" src={require("../../assets/img/logo.png")} onClick={() => history.push(routes.HOME)}/>
                    </div>
                </div>
            </div>

            <section className="builder-py-8">
                <div className="builder-w-full builder-max-w-sm builder-mx-auto">
                    <form>
                        <div className="builder-mb-4">
                            <input
                                className="builder-appearance-none builder-block builder-w-full builder-py-3 builder-px-4 builder-leading-tight builder-text-gray-700 builder-bg-gray-200 builder-focus:bg-white builder-border builder-border-gray-200 builder-focus:border-gray-500 builder-rounded builder-focus:outline-none"
                                type="text" placeholder="Username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value.toLowerCase())}/>
                        </div>
                        <div className="mb-4">
                            <input
                                className="builder-appearance-none builder-block builder-w-full builder-py-3 builder-px-4 builder-leading-tight builder-text-gray-700 builder-bg-gray-200 builder-focus:bg-white builder-border builder-border-gray-200 builder-focus:border-gray-500 builder-rounded builder-focus:outline-none"
                                type="password" placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}/>
                        </div>
                        <div className="mb-4">
                            <button
                                className="builder-inline-block builder-w-full builder-py-4 builder-px-8 builder-leading-none builder-text-white builder-bg-primary builder-hover:bg-indigo-600 builder-rounded builder-shadow"
                                onClick={handleLogin}>Aanmelden
                            </button>
                        </div>
                    </form>

                    {Object.keys(errors).length > 0 && errors['auth'] !== "" &&
                    <div className="bg-red-200 text-red-800 p-4 w-full mb-4">
                        {errors['auth']}
                    </div>}
                </div>
            </section>
        </React.Fragment>
    )
};

export default Login;
