import {omit, cloneDeep, find, orderBy, map, keyBy, filter, forEach} from 'lodash'
import {v4} from "uuid";
import {parseFields} from "../utils";

export const ACTIONS = {
    PAGE_ADD: 'PAGE_ADD',
    PAGE_DELETE: 'PAGE_DELETE',
    PAGE_META_CHANGE: 'PAGE_META_CHANGE',


    PAGE_BLOCK_ADD: 'PAGE_BLOCK_ADD',
    PAGE_BLOCK_ORDER: 'PAGE_BLOCK_ORDER',
    PAGE_BLOCK_DELETE: 'PAGE_BLOCK_DELETE',
    PAGE_BLOCK_CONTENT_CHANGE: 'PAGE_BLOCK_CONTENT_CHANGE',
    PAGE_BLOCK_COMPONENT_CHANGE: 'PAGE_BLOCK_COMPONENT_CHANGE',
    PAGE_BLOCK_CONTENT_COPY: 'PAGE_BLOCK_CONTENT_COPY',


    PAGE_SELECT: 'PAGE_SELECT',
    BLOCK_SELECT: 'BLOCK_SELECT',
    UNDO: 'UNDO',
    DATA_SET: 'DATA_SET',
    LANGUAGES_CHANGE: "LANGUAGES_CHANGE",
    CONTENT_COPY: 'CONTENT_COPY',

    SESSION_SET: 'SESSION_SET',
    LAST_ACTION_SET: 'LAST_ACTION_SET',
};


const initialPage = {
    id: v4(),
    url: 'index',
    blocks: [],
};

const initialHistory = {
    pages: keyBy([{...initialPage}], 'id'),
    languages: [],
    currentPageId: undefined,
    currentBlockId: undefined,

};

export const initialState = {
    history: [{...initialHistory}],
    loading: true,
    contentTypeDefinitions: undefined,
    session: undefined,
    lastAction: undefined,
};


export const rootReducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.PAGE_ADD:
        case ACTIONS.PAGE_DELETE:
        case ACTIONS.PAGE_META_CHANGE:
        case ACTIONS.PAGE_BLOCK_ADD:
        case ACTIONS.PAGE_BLOCK_DELETE:
        case ACTIONS.PAGE_BLOCK_ORDER:
        case ACTIONS.PAGE_BLOCK_CONTENT_CHANGE:
        case ACTIONS.PAGE_BLOCK_COMPONENT_CHANGE:
        case ACTIONS.PAGE_BLOCK_CONTENT_COPY:
        case ACTIONS.PAGE_SELECT:
        case ACTIONS.BLOCK_SELECT:
        case ACTIONS.LANGUAGES_CHANGE:
        case ACTIONS.CONTENT_COPY:
        case ACTIONS.UNDO:
            return {
                ...state,
                history: historyReducer(state.history, action)
            };
        case ACTIONS.DATA_SET:
            return {
                ...state,
                history: historyReducer(state.history, action),
                loading: false,
                contentTypeDefinitions: action.payload.contentTypeDefinitions,
                dealerships: action.payload.dealerships,
                dealershipLocationTypes: action.payload.dealershipLocationTypes,
            };

        case ACTIONS.SESSION_SET:
            return {
                ...state,
                session: action.payload.session,
            };
        case ACTIONS.LAST_ACTION_SET:
            return {
                ...state,
                lastAction: Date.now()
            };
        default:
            return state;
    }
};


const historyReducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.PAGE_ADD:
            return [
                ...state,
                {
                    ...state[state.length - 1],
                    pages: pagesReducer(state[state.length - 1].pages, action),
                    currentPageId: action.payload.id
                }
            ];
        case ACTIONS.PAGE_DELETE:
            return [
                ...state,
                {
                    ...state[state.length - 1],
                    pages: pagesReducer(state[state.length - 1].pages, action),
                    currentPageId: find(state[state.length - 1].pages, (page) => page.url === 'index').id,
                }
            ];
        case ACTIONS.PAGE_BLOCK_ADD:
        case ACTIONS.PAGE_BLOCK_DELETE:
        case ACTIONS.PAGE_BLOCK_ORDER:
        case ACTIONS.PAGE_BLOCK_CONTENT_CHANGE:
        case ACTIONS.PAGE_BLOCK_COMPONENT_CHANGE:
        case ACTIONS.PAGE_BLOCK_CONTENT_COPY:
        case ACTIONS.PAGE_META_CHANGE:
            return [
                ...state,
                {
                    ...state[state.length - 1],
                    pages: pagesReducer(state[state.length - 1].pages, action),
                }
            ];
        case ACTIONS.PAGE_SELECT:
            return [
                ...state,
                {
                    ...state[state.length - 1],
                    currentPageId: action.payload.id
                }
            ];
        case ACTIONS.BLOCK_SELECT:
            return [
                ...state,
                {
                    ...state[state.length - 1],
                    currentBlockId: action.payload.id
                }
            ];
        case ACTIONS.UNDO:
            if (state.length > 1) {
                return state.slice(0, state.length - 1);
            } else {
                return state;
            }
        case ACTIONS.LANGUAGES_CHANGE:
            const languagesCopy = [...state[state.length - 1].languages];
            const language = find(languagesCopy, (l) => l.code === action.payload.languageCode);
            if (language) {
                language.available = action.payload.available;
            }

            return [
                ...state,
                {
                    ...state[state.length - 1],
                    languages: languagesCopy
                }
            ];
        case ACTIONS.CONTENT_COPY:
            return [
                ...state,
                {
                    ...state[state.length - 1],
                    pages: keyBy(map(state[state.length - 1].pages, (page) => ({
                        ...page,
                        blocks: map(page.blocks, (block) => {
                            const contentToAdd = map(block.content, (content) => ({
                                ...content,
                                id: v4(),
                                culture: action.payload.to,
                                fields: map(content.fields, (field) => ({
                                    ...field
                                }))
                            }));

                            return {
                                ...block,
                                content: [
                                    ...block.content,
                                    ...contentToAdd,
                                ]
                            }
                        })
                    })), 'id')
                }
            ];
        case ACTIONS.DATA_SET:
            const {pages, languages} = action.payload;
            const converted = map(pages, (page) => {
                const blocks_ordered = orderBy(page.blocks, ['order'], ['asc']);

                return {
                    id: page.id,
                    url: page.url,
                    meta: map(page.meta, (meta) => {
                        return {
                            id: meta.id,
                            title: meta.title,
                            description: meta.description,
                            language: meta.language.toLowerCase(),
                        }
                    }),
                    blocks: map(blocks_ordered, (block, index) => {
                        return {
                            id: block.id,
                            contentType: block.contentType,
                            componentName: block.variant,
                            content: map(block.content, (content) => {
                                return {
                                    id: content.id,
                                    culture: content.culture,
                                    fields: parseFields(content.fields),
                                }
                            })
                        }
                    })
                }
            });
            return [{
                ...initialHistory,
                pages: keyBy((converted.length > 0) ? converted : [{...initialPage}], 'id'),
                languages: languages,
                currentPageId: (pages.length > 0) ? pages[0].id : initialPage.id
            }];
        default:
            return state;
    }
};

function pagesReducer(state, action) {
    switch (action.type) {
        case ACTIONS.PAGE_ADD:
            let blocksCopy = []
            if (action.payload.copyId) {
                blocksCopy = map(state[action.payload.copyId].blocks, (block) => ({
                    ...block,
                    id: v4(),
                    content: map(block.content, (content) => ({
                        ...content,
                        id: v4(),
                    }))
                }))
            }

            return {
                ...state,
                [action.payload.id]: {
                    id: action.payload.id,
                    url: action.payload.url,
                    blocks: blocksCopy,
                }
            };
        case ACTIONS.PAGE_DELETE:
            return omit(state, [action.payload.id]);
        case ACTIONS.PAGE_BLOCK_ADD:
        case ACTIONS.PAGE_BLOCK_DELETE:
        case ACTIONS.PAGE_BLOCK_ORDER:
        case ACTIONS.PAGE_BLOCK_CONTENT_CHANGE:
        case ACTIONS.PAGE_BLOCK_COMPONENT_CHANGE:
        case ACTIONS.PAGE_BLOCK_CONTENT_COPY:
            return {
                ...state,
                [action.payload.pageId]: {
                    ...state[action.payload.pageId],
                    blocks: blocksReducer(state[action.payload.pageId].blocks, action),
                }
            };
        case ACTIONS.PAGE_META_CHANGE:
            return {
                ...state,
                [action.payload.pageId]: {
                    ...state[action.payload.pageId],
                    meta: metaReducer(state[action.payload.pageId].meta, action),
                },
            };
        default:
            return state;
    }
}


function blocksReducer(state, action) {
    let copy = [];
    let blockToEdit = null;
    switch (action.type) {
        case ACTIONS.PAGE_BLOCK_ADD:
            copy = [...state];
            copy.splice(action.payload.index, 0, {
                id: v4(),
                componentName: action.payload.component.name,
                contentType: action.payload.component.contentType,
                content: []
            });
            return copy;
        case ACTIONS.PAGE_BLOCK_DELETE:
            return filter(state, (block) => block.id !== action.payload.blockId);
        case ACTIONS.PAGE_BLOCK_ORDER:
            copy = [...state];
            const {block, removedIndex, addedIndex} = action.payload;

            let itemToAdd = block;
            if (removedIndex !== null) {
                itemToAdd = copy.splice(removedIndex, 1)[0];
            }

            if (addedIndex !== null) {
                copy.splice(addedIndex, 0, itemToAdd);
            }
            return copy;

        case ACTIONS.PAGE_BLOCK_CONTENT_CHANGE:
            copy = cloneDeep(state);
            blockToEdit = find(copy, (block) => block.id === action.payload.blockId);
            const content = find(blockToEdit.content, (content) => content.culture === action.payload.culture);

            if (!content) {
                blockToEdit.content = [
                    ...blockToEdit.content,
                    {
                        id: v4(),
                        culture: action.payload.culture,
                        fields: [
                            {
                                name: action.payload.field.name,
                                type: action.payload.field.type,
                                value: action.payload.value
                            }
                        ]
                    }
                ]
            } else {
                const field = find(content.fields, (field) => field.name === action.payload.field.name);
                if (!field) {
                    content.fields = [
                        ...content.fields,
                        {
                            name: action.payload.field.name,
                            type: action.payload.field.type,
                            value: action.payload.value
                        }
                    ]
                } else {
                    field.value = action.payload.value;
                }
            }
            return copy;

        case ACTIONS.PAGE_BLOCK_COMPONENT_CHANGE:
            copy = cloneDeep(state);
            blockToEdit = find(copy, (block) => block.id === action.payload.blockId);
            blockToEdit.componentName = action.payload.componentName;
            return copy;

        case ACTIONS.PAGE_BLOCK_CONTENT_COPY:
            copy = cloneDeep(state);
            blockToEdit = find(copy, (block) => block.id === action.payload.blockId);

            blockToEdit.content = []
            forEach(action.payload.copyBlock.content, (copyContent) => {
                blockToEdit.content.push({
                    id: v4(),
                    culture: copyContent.culture,
                    fields: copyContent.fields,
                });
            });

            return copy;


        default:
            return state;
    }
}


function metaReducer(state, action) {
    let copy = [];
    switch (action.type) {
        case ACTIONS.PAGE_META_CHANGE:
            copy = cloneDeep(state);
            const metaToEdit = find(copy, (meta) => meta.id === action.payload.meta.id);

            if (metaToEdit) {
                metaToEdit.title = action.payload.meta.title;
                metaToEdit.description = action.payload.meta.description;
            } else {
                copy.push({
                    id: action.payload.meta.id,
                    language: action.payload.culture,
                    title: action.payload.meta.title,
                    description: action.payload.meta.description,
                })
            }

            return copy;
        default:
            return state;
    }
}
