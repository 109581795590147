import React from 'react';
import TextInput from "../../builder/editors/components/TextInput";
import TextArea from "../../builder/editors/components/TextArea";

const Analytics = ({settings, isSuperUSer, onChange}) => {
    return (
        <div >
            <div className="builder-bg-white builder-border builder-border-gray-200 builder-ring-1 builder-ring-black builder-ring-opacity-5 builder-mb-4">
                <div className="builder-py-4 builder-border-b builder-border-gray-200 builder-bg-gray-100">
                    <div className="builder-text-lg builder-leading-6 builder-font-medium builder-text-gray-900 builder-px-4">Google</div>
                </div>

                <div className="builder-flex builder-p-4">
                    <div className="builder-flex builder-w-full builder-flex-col">
                        <div className="builder-flex builder-flex-col builder-w-1/2 builder-mb-4">
                            <label className="builder-text-sm builder-text-gray-500 builder-mb-1">Google identification</label>
                            <TextInput value={settings.GOOGLE_IDENTIFICATION}
                                       placeHolder='Google identification'
                                       onChanged={value => onChange({...settings, GOOGLE_IDENTIFICATION: value})}/>
                        </div>
                        <div className="builder-flex builder-flex-col builder-w-1/2">
                            <label className="builder-text-sm builder-text-gray-500 builder-mb-1 builder-ml-2">Google analytics ID</label>
                            <TextInput value={settings.GOOGLE_ANALYTICS_ID}
                                      placeHolder='Google analytics ID'
                                      onChanged={value => onChange({...settings, GOOGLE_ANALYTICS_ID: value})}/>
                        </div>

                    </div>
                </div>
            </div>


            <div className="builder-bg-white builder-border builder-border-gray-200 builder-ring-1 builder-ring-black builder-ring-opacity-5 builder-mb-4">
                <div className="builder-py-4 builder-border-b builder-border-gray-200 builder-bg-gray-100">
                    <div className="builder-text-lg builder-leading-6 builder-font-medium builder-text-gray-900 builder-px-4">Hotjar</div>
                </div>

                <div className="builder-flex builder-p-4">
                    <div className="builder-flex builder-w-full builder-flex-col">
                        <div className="builder-flex builder-flex-col builder-w-1/2 builder-mb-4">
                            <label className="builder-text-sm builder-text-gray-500 builder-mb-1">ID</label>
                            <TextInput value={settings.HOTJAR_ID}
                                       placeHolder='ID'
                                       onChanged={value => onChange({...settings, HOTJAR_ID: value})}/>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
};

export default Analytics;
