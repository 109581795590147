import {v4} from "uuid";

const LOCAL_STORAGE_KEY = 'AUTRALIS_DEALER';

export const session_default = {
    id: v4(),
    lastVisit: null,
};

export const sessionFromStorage = () => {
    const fromStorage = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
    return {
        ...session_default,
        ...fromStorage,
    }
};

export const sessionToStorage = (session) => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({
        id: session.id,
        lastVisit: session.lastLogin,
    }));
};

export const initSession = () => {
    let session_from_storage = sessionFromStorage();

    if(session_from_storage === null) {
        session_from_storage = {
            ...session_default,
        };
    }

    session_from_storage = {
        ...session_from_storage,
        lastVisit: Date.now()
    };

    sessionToStorage(session_from_storage);
    return session_from_storage;
};

// export const clearStorage = () => {
//     try {
//         localStorage.removeItem(LOCAL_STORAGE_KEY);
//     } catch (error) {
//         localStorage.clear();
//     }
// };




