import React from 'react';
import {DefaultTeamMember, DefaultText} from "../../../core/constants";
import {map} from "lodash";
import {MarkupText, Image} from "../../../core";
import Member from "./components/Member";
import {getEmptyContainers} from "../../../core/utils";
import ContactTeamMember_Preview from "./components/ContactTeamMember_Preview";

const ContactTeamMembers = ({members, description, context}) => {
    const emptyContainers = getEmptyContainers(3, members.length)
    return (
        <div className="bg-gray-100  py-10 md:py-30 px-0 lg:px-5 ">
            {description && description !== "" &&
            <div className="flex flex-1 items-center justify-center text-center mb-10 bg-gray-100">
                <h1 className="h2">
                    <div className="text-md mt-5"><MarkupText text={description} context={context}/></div>
                </h1>
            </div>}

            <div className="flex items-center justify-center relative">
                <div className="flex flex-1 team-cards container flex-wrap justify-between">
                    {map(members, member => <Member context={context} member={member} />)}

                    {emptyContainers.map((ec, i) => {
                        return <div key={i} className="team-card flex flex-col shadow bg-white p-7 opacity-0"/>
                    })}
                </div>
            </div>
        </div>
    )
};

ContactTeamMembers.defaultProps = {
    members: [
        {...DefaultTeamMember},
        {...DefaultTeamMember},
        {...DefaultTeamMember},
        {...DefaultTeamMember},
    ],
    description: DefaultText,
};

ContactTeamMembers.human = {
    "nl": "Team members en hun contact gegevens",
    "fr": "",
    "en": "",
};

ContactTeamMembers.preview = () => { return <ContactTeamMember_Preview /> };

export default ContactTeamMembers;
