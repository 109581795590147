import React, {useContext, useState} from 'react';
import {find, filter} from 'lodash';
import ImageContainer from "./ImageContainer";
import {ButtonWithIcon} from "../../../input";
import {ACTIONS, DispatchContext, StateContext, TakeOverFormFields} from "../Takeover";

const damageContainers = [
    {type: "damage-one", overlay: "extra", className: "w-full md:w-1/5 mb-2 md:mb-0", required: false, key: "damage1.jpg"},
    {type: "damage-two", overlay: "extra", className: "w-full md:w-1/5 mb-2 md:mb-0", required: false, key: "damage2.jpg"},
    {type: "damage-three", overlay: "extra", className: "w-full md:w-1/5 mb-2 md:mb-0", required: false, key: "damage3.jpg"},
    {type: "damage-four", overlay: "extra", className: "w-full md:w-1/5 mb-2 md:mb-0", required: false},
    {type: "damage-five", overlay: "extra", className: "w-full md:w-1/5 mb-2", required: false},
    {type: "damage-six", overlay: "extra", className: "w-full md:w-1/5 mb-2 md:mb-0", required: false},
    {type: "damage-seven", overlay: "extra", className: "w-full md:w-1/5 mb-2 md:mb-0", required: false},
    {type: "damage-eight", overlay: "extra", className: "w-full md:w-1/5 mb-2 md:mb-0", required: false},
    {type: "damage-nine", overlay: "extra", className: "w-full md:w-1/5 mb-2 md:mb-0", required: false},
    {type: "damage-tin", overlay: "extra", className: "w-full md:w-1/5 mb-2 md:mb-0", required: false},
];


const StepFour = ({context}) => {
    const [isUploading, setIsUploading] = useState(false);
    const [currentActive, setCurrentActive] = useState(null);

    const dispatch = useContext(DispatchContext);
    const {vehicle, errors} = useContext(StateContext);



    const isValid = () => {
        let isValid = true;
        let errors = {};
        dispatch({type: ACTIONS.SET_ERROR, field: TakeOverFormFields.damages, payload: {}})
        const requiredImages = filter(damageContainers, imgContainer => imgContainer.required);
        requiredImages.map(reqImg => {
            const imgAdded = find(vehicle[TakeOverFormFields.damages], file => file.imageType === reqImg.type) !== undefined;
            if (!imgAdded) {
                errors[reqImg.type] = "Deze photo is verplicht.";
                isValid = false
            }
        });

        dispatch({type: ACTIONS.SET_ERROR, field: TakeOverFormFields.damages, payload: errors})
        return isValid
    };



    const onSubmit = (e) => {
        e.preventDefault();

        if (isValid()) {
            dispatch({type: ACTIONS.NEXT_STEP})
        }
    };

    const onBack = () => {
        dispatch({type: ACTIONS.PREV_STEP})
    };

    const onUpload = (uploadedFile) => {
        dispatch({type: ACTIONS.SET_DAMAGES, field: TakeOverFormFields.damages, payload: uploadedFile})
    };

    return (
        <form action="" onSubmit={(e) => onSubmit(e)} className="images-container flex flex-col">
            <div className="flex flex-col md:flex-row flex-wrap">
                {damageContainers.map(imgContainer => {
                    const oldFile = vehicle[TakeOverFormFields.damages] ? find(vehicle[TakeOverFormFields.damages], dam => dam.imageType === imgContainer.type) : undefined;
                    const error = errors[TakeOverFormFields.damages] !== undefined ? errors[TakeOverFormFields.damages][imgContainer.type] : undefined;
                    return <ImageContainer key={imgContainer.type}
                                           className={imgContainer.className}
                                           type={imgContainer.type}
                                           overlay={imgContainer.overlay}
                                           showIconOverlay={true}
                                           iconOverlay="fal fa-camera-retro"
                                           onUpload={(file) => onUpload(file)}
                                           error={error}
                                           required={imgContainer.required}
                                           helpImage={imgContainer.key}
                                           setIsUploading={value => setIsUploading(value)}
                                           isUploading={isUploading}
                                           setCurrentActive={type => setCurrentActive(type)}
                                           currentActive={currentActive}
                                           img={oldFile !== undefined ? oldFile : null}
                    />
                })}
            </div>

            <div className="flex justify-between w-full mt-6">
                <ButtonWithIcon disabled={isUploading}
                                onClick={(e) => onBack(e)}
                                text="Terug"
                                icon="fad fa-chevron-left"
                                iconPosition="left"
                />

                <ButtonWithIcon disabled={isUploading}
                                onClick={(e) => onSubmit(e)}
                                text="Volgende"
                                icon="fad fa-chevron-right"
                                iconPosition="right"
                />
            </div>

        </form>
    );
};

export default StepFour;
