import React, {useContext, useEffect, useState} from "react";
import {find, map, values, findIndex, filter} from "lodash";
import {Menu} from "./components/Menu";
import {
    blockSelectHandler, dataSetHandler, pageBlockComponentChangedHandler,
    pageBlockContentChange, pageBlockContentCopy,
    pageBlockCreationHandler,
    pageBlockDeleteHandler,
    pageBlockOrderChangedHandler,
    pageCreationHandler,
    pageDeleteHandler, pageMetaChange, pageSelectHandler
} from "../../data/actions";
import {DataQuery} from "../../data/graphql";
import Dropdown from "../common/Dropdown";
import WebsiteContainer from "./components/WebsiteContainer";
import {useManualQuery, useQuery} from 'graphql-hooks'
import PageSelector from "./components/PageSelector";
import PageMetaData from "./components/PageMetaData";
import Header from "../Header";
import {StateContext, DispatchContext} from "../../App";
import BlockEditor from "./components/BlockEditor";
import {LoadingIndicator} from "../common";
import HeaderMenu from './components/HeaderMenu';
import themeConfig from '../../dealer-theme/theme/config';

const getWidthByDevice = (device) => {
    switch (device) {
        case 'tablet':
            return 778;
        case 'mobile':
            return 375;
        default:
            return '100%'
    }
};

const Builder = ({token}) => {
    const state = useContext(StateContext);
    const dispatch = useContext(DispatchContext);

    const currentState = state.history[state.history.length - 1];
    const currentPage = (currentState.pages[currentState.currentPageId]) ? currentState.pages[currentState.currentPageId] : undefined;
    const currentBlock = (currentPage) ? find(currentPage.blocks, (block) => block.id === currentState.currentBlockId) : undefined;
    const [deviceType, setDeviceType] = useState('desktop');
    const [currentLanguage, setCurrentLanguage] = useState('nl');
    // const {, error: loadingError, data} = useQuery(DataQuery);
    const [fetchData, {loading: initialLoading, error: loadingError, data}] = useManualQuery(DataQuery)
    const [seoVisible, setSeoVisible] = useState(false);
    const loading = initialLoading;

    useEffect(() => {
        if(!state.contentTypeDefinitions) {
            fetchData()
        }
    }, []);

    useEffect(() => {
        if (data) {
            dataSetHandler(dispatch, data);
        }
    }, [data]);

    useEffect(() => {
        if (seoVisible) {
            setSeoVisible(false)
        }
    }, [currentState.currentBlockId]);

    const context = {
        culture: currentLanguage,
        builder: true,
        multiLanguage: false,
        urls: themeConfig.urls,
        mapBoxToken: "pk.eyJ1IjoiYXV0cmFsaXMiLCJhIjoiY2toeXR2emhwMDk0OTJ6cWh0Y3JjeG5kaSJ9.YCvAWa5Wyqof0wPdueve5w"
    };

    const handleContentChange = (blockId, field, value, culture) => {
        pageBlockContentChange(dispatch, currentState.currentPageId, blockId, field, value, culture);
    };

    const handlePageMetaChange = (meta) => {
        pageMetaChange(dispatch, currentState.currentPageId, currentLanguage, meta);
    };

    const handleCopyBlockContent = (blockId, copyBlock, culture) => {
        pageBlockContentCopy(dispatch, currentState.currentPageId, blockId, copyBlock, culture);
    };

    const handleEditContent = (blockId) => {
        blockSelectHandler(dispatch, blockId);
    };

    const handleDeleteBlock = (blockId) => {
        pageBlockDeleteHandler(dispatch, currentState.currentPageId, blockId);
    };

    const handleBlockUp = (blockId) => {
        const block = find(currentPage.blocks, (block) => block.id === blockId);
        const currentIndex = findIndex(currentPage.blocks, (block) => block.id === blockId);
        pageBlockOrderChangedHandler(dispatch, currentState.currentPageId, currentIndex, currentIndex - 1, block);
    };

    const handleBlockDown = (blockId) => {
        const block = find(currentPage.blocks, (block) => block.id === blockId);
        const currentIndex = findIndex(currentPage.blocks, (block) => block.id === blockId);
        pageBlockOrderChangedHandler(dispatch, currentState.currentPageId, currentIndex, currentIndex + 1, block);
    };

    const handleChangeBlockVariant = (blockId, variant) => {
        pageBlockComponentChangedHandler(dispatch, currentState.currentPageId, blockId, variant)
    };

    const handleDrop = (e) => {
        if (e.removedIndex === null && e.addedIndex === null) return;

        if (e.addedIndex !== null && e.removedIndex === null) {
            pageBlockCreationHandler(dispatch, currentState.currentPageId, e.addedIndex, e.payload);
        } else if (e.addedIndex !== null && e.removedIndex !== null) {
            pageBlockOrderChangedHandler(dispatch, currentState.currentPageId, e.removedIndex, e.addedIndex, e.payload);
        }
    };

    const handleAddPage = (url, copyFrom) => {
        const existingPage = find(values(currentState.pages), (page) => page.url === url);
        if (!existingPage) {
            pageCreationHandler(dispatch, url, copyFrom);
        } else {
            pageSelectHandler(dispatch, existingPage.id)
        }
    };

    const handleDeletePage = (id) => {
        pageDeleteHandler(dispatch, id)

    };

    return (
        <div className="builder-flex builder-flex-1 builder-flex-col builder-h-screen builder-font-body builder-text-sm">
            <Header renderMenu={() => <HeaderMenu/>}/>

            <div className="builder-relative builder-flex builder-flex-1 " style={{height: 'calc(100% - 48px)'}}>
                {loadingError &&
                <div className="builder-flex builder-flex-1 builder-justify-center builder-items-center builder-h-20 builder-bg-red-200 builder-text-red-800">
                    <span>Error loading website. Please contact administrator</span>
                </div>}

                {!loadingError && !loading &&
                <div className=" builder-flex builder-flex-1 builder-h-full " style={{backgroundColor: '#dee2ec'}}>

                    <Menu context={context}/>

                    <div className="builder-flex builder-flex-1 builder-p-2 builder-justify-center">
                        <div className={`builder-flex builder-flex-col builder-m-4 builder-bg-gray-100 overflow-scroll `}
                             style={{width: '100%', maxWidth: 1334}}>

                            <div className="builder-border builder-border-gray-400 builder-flex builder-flex-row builder-justify-between builder-items-center builder-p-2" style={{height: 50}}>
                                <div className="builder-flex builder-pl-2 builder-w-1/3">
                                    <div className="builder-rounded-full builder-bg-gray-300 builder-w-4 builder-h-4 builder-mr-2"/>
                                    <div className="builder-rounded-full builder-bg-gray-300 builder-w-4 builder-h-4 builder-mr-2"/>
                                    <div className="builder-rounded-full builder-bg-gray-300 builder-w-4 builder-h-4 "/>
                                </div>
                                <div className="builder-flex builder-flex-1 builder-w-2/3 builder-justify-center builder-items-center">
                                    <PageSelector pages={currentState.pages}
                                                  value={(currentPage) ? currentPage.id : ""}
                                                  onSelectPage={(item) => pageSelectHandler(dispatch, item.key)}
                                                  onDeletePage={(item) => handleDeletePage(item.key)}
                                                  onCreatePage={(url, copyFrom) => handleAddPage(url, copyFrom)}

                                    />
                                </div>


                                <div className="builder-flex builder-w-1/3 builder-justify-end builder-items-center">
                                    <div
                                        className={`builder-flex builder-items-center builder-justify-center builder-mr-1 builder-cursor-pointer`}
                                        style={{width: 30}}
                                        onClick={() => {
                                            setSeoVisible(true)
                                        }}
                                    >
                                        <i className="fas fa-chart-line"></i>
                                    </div>


                                    <div style={{width: 50}}>
                                        <Dropdown minWidth={50}
                                                  borderClassName=""
                                                  defaultBgColor="builder-gray-400"
                                                  options={map(filter(currentState.languages, (l) => l.available), (language) => {
                                                      return {
                                                          value: language.code,
                                                          key: language.code,
                                                      }
                                                  })}
                                                  value={currentLanguage}
                                                  renderItem={(item, i) => {
                                                      return (
                                                          <div
                                                              className="builder-flex builder-justify-between builder-px-2 builder-py-3 builder-border-b builder-border-gray-300 builder-ml-2 builder-mr-2 builder-text-sm hover:builder-bg-gray-100"
                                                              onClick={() => setCurrentLanguage(item.value)}>
                                                              <div className="builder-cursor-pointer">
                                                                  {item.value}
                                                              </div>

                                                          </div>
                                                      )
                                                  }}/>
                                    </div>

                                    <div
                                        className={`builder-flex builder-items-center builder-justify-center builder-mr-1 builder-cursor-pointer ${(deviceType === 'desktop') ? 'builder-text-normal' : 'builder-text-gray-300'}`}
                                        style={{width: 30}}
                                        onClick={() => setDeviceType("desktop")}
                                    >
                                        <i className="fas fa-desktop-alt"/>
                                    </div>
                                    <div
                                        className={`builder-flex builder-items-center builder-justify-center builder-mr-1 builder-cursor-pointer  ${(deviceType === 'tablet') ? 'builder-text-normal' : 'builder-text-gray-300'}`}
                                        style={{width: 30}} onClick={() => setDeviceType("tablet")}
                                    >
                                        <i className="fas fa-tablet-alt"/>
                                    </div>
                                    <div
                                        className={`builder-flex builder-items-center builder-justify-center builder-mr-1 builder-cursor-pointer ${(deviceType === 'mobile') ? 'builder-text-normal' : 'builder-text-gray-300'}`}
                                        style={{width: 30}} onClick={() => setDeviceType("mobile")}
                                    >
                                        <i className="fas fa-mobile-alt"/>
                                    </div>
                                </div>

                            </div>

                            <div className="builder-flex builder-flex-1 builder-justify-center">

                                <div className="builder-flex" style={{width: getWidthByDevice(deviceType), backgroundColor: "#fafafa"}}>

                                    <WebsiteContainer page={currentPage}
                                                      context={context}
                                                      onDrop={handleDrop}
                                                      onEditContent={handleEditContent}
                                                      onDelete={handleDeleteBlock}
                                                      onMoveUp={handleBlockUp}
                                                      onMoveDown={handleBlockDown}
                                                      onChangeBlockVariant={handleChangeBlockVariant}
                                    />
                                </div>
                            </div>
                        </div>


                    </div>


                    <div className="builder-bg-white builder-overflow-scroll" style={{width: 500, boxShadow: "rgba(0, 0, 0, 0.15) -2px 4px 8px",}}>

                        {currentBlock && !seoVisible &&
                        <BlockEditor block={currentBlock}
                                     currentPage={currentPage}
                                     currentLanguage={currentLanguage}
                                     contentTypeDefinitions={state.contentTypeDefinitions}
                                     onCopyBlock={(blockId, copyBlock, currentLanguage) => handleCopyBlockContent(blockId, copyBlock, currentLanguage)}
                                     pages={currentState.pages}
                                     onContentChange={(blockId, field, value, currentLanguage) => handleContentChange(blockId, field, value, currentLanguage)}

                        />
                        }

                        {((currentPage && !currentBlock) || (seoVisible)) &&
                        <PageMetaData page={currentPage.url}
                                      meta={(currentPage.meta) ? find(currentPage.meta, (meta) => meta.language === currentLanguage) : undefined}
                                      onChange={handlePageMetaChange}/>}
                    </div>
                </div>}

                {loading &&
                <LoadingIndicator/>}
            </div>
        </div>
    );
};


export default Builder;
