import React from 'react';
import {DefaultImage, DefaultText, DefaultTitle} from "../../../core/constants";
import {Image, MarkupText} from "../../../core";
import FeatureThreeCol_Preview from "./components/FeatureThreeCol_Preview";

const FeatureThreeCol = ({image, title, description, image2, title2, description2, image3, title3, description3, context}) => {
    return (
        <div className="flex item-center justify-center bg-white pb-10">
            <div className="container flex flex-col md:flex-row">
                <div className="flex flex-col items-center justify-center pr-5 md:pr-16 w-full md:w-1/3 pl-5 md:pl-0">
                    <Image url={(image) ? image : ''} style={{height: 90, width: 90}}/>
                    <div className="flex items-center justify-center my-2 card-title text-center text-lg md:text-2xl" style={{height: 54, minHeight: 54}}>{title}</div>
                    <div className="text-center text-sm md:text-md"><MarkupText text={description} context={context}/></div>
                </div>
                <div className="flex flex-col items-center justify-center pr-5 md:pr-16 w-full md:w-1/3 pl-5 md:pl-0">
                    <Image url={(image2) ? image2 : ''} style={{height: 90, width: 90}}/>
                    <div className="flex items-center justify-center my-2 card-title text-center text-lg md:text-2xl" style={{height: 54, minHeight: 54}}>{title2}</div>
                    <div className="text-center text-sm md:text-md"><MarkupText text={description2} context={context}/></div>
                </div>
                <div className="flex flex-col items-center justify-center pr-5 md:pr-16 w-full md:w-1/3 pl-5 md:pl-0">
                    <Image url={(image3) ? image3 : ''} style={{height: 90, width: 90}}/>
                    <div className="flex items-center justify-center my-2 card-title text-center text-lg md:text-2xl" style={{height: 54, minHeight: 54}}>{title3}</div>
                    <div className="text-center text-sm md:text-md"><MarkupText text={description3} context={context}/></div>
                </div>
            </div>
        </div>
    );
};


FeatureThreeCol.defaultProps = {
    image: DefaultImage,
    title: DefaultTitle,
    description: DefaultText,
    image2: DefaultImage,
    title2: DefaultTitle,
    description2: DefaultText,
    image3: DefaultImage,
    title3: DefaultTitle,
    description3: DefaultText,
};

FeatureThreeCol.human = {
    "nl": "",
    "fr": "",
    "en": "",
};

FeatureThreeCol.preview = () => { return <FeatureThreeCol_Preview /> };

export default FeatureThreeCol;
