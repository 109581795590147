import React from 'react';

const Spacing_White = () => {
    return (
        <div className="hidden md:flex py-6 bg-white">
            <div className="opacity-0">.</div>
        </div>
    );
};

Spacing_White.human = {
    "nl": "",
    "fr": "",
    "en": "",
};

Spacing_White.preview = Spacing_White;

export default Spacing_White;
