import React from 'react';

const ConfirmBox = ({onCancel, onConfirm, text}) => {
    return (
        <div className="builder-fixed builder-left-0 builder-right-0 builder-top-0 builder-bottom-0 builder-flex builder-items-center builder-justify-center" style={{background: "rgba(0, 0, 0, 0.6)", zIndex: 9999999999}}>
            <div className="builder-bg-white md:builder-w-1/3 builder-w-1/2 builder-border builder-shadow-lg">
                <div className="builder-flex builder-items-center builder-justify-center builder-bg-gray-100 md:builder-text-base builder-text-sm builder-border-b builder-p-2 builder-h-24">
                    <p>{text}</p>
                </div>
                <div className="builder-p-2 builder-flex builder-justify-end builder-items-center builder-rounded-b">
                    <div className="builder-mr-2 builder-cursor-pointer hover:builder-text-gray-600" onClick={onCancel}>Cancel</div>
                    <div className="builder-bg-white builder-border builder-border-gray-300 builder-px-4 builder-py-2 builder-cursor-pointer hover:builder-bg-gray-100" onClick={onConfirm}>
                        <i className="mr-1 fal fa-check"/> Confirm
                    </div>
                </div>
            </div>
        </div>
    );
};

ConfirmBox.defaultProps = {
    text: "Are you sure? All unsaved changes will be lost."

}

export default ConfirmBox;
