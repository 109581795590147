export const DataQuery = `{
    pages {
      id      
      url    
      meta {
        id
        title
        description
        language
      }  
      blocks {
        id        
        order
        contentType
        variant
        content
      }
    }
    
    languages {
        id
        code
        available    
    }
    
    contentTypeDefinitions {
      name      
      fields {
        name
        type
        label
        description
      }
    }
    
   dealerships {
      id
      name   
      reference   
    }
  
   dealershipLocationTypes {
    id
    name    
  }   
}`;

export const DealershipTypeTypesQuery = `{
    dealershipLocationTypes {
        id
        name
        description
      }
}`


export const LanguagesQuery = `{    
    languages {
        id
        code
        available    
    }   
}`;


export const DealershipQuery =
    `query ($id: String!) { 
        dealership(id: $id) { 
            id
            name
            address
            postal
            city
            reference
            email
            facebook
            twitter
            instagram
            linkedin
            fax
            tel 
            url
            image
            openingsHours {
                uuid
                day
                start
                stop
                type {
                  id
                  name
                  description
                }
            }
            closingDays {
                uuid
                start
                stop
                description
                type {
                  id
                  name
                  description
                }
            } 
        }
    }`;

export const DealershipsQuery = `{
  dealerships {
    id
    name
    address
    postal
    city
    reference
    facebook
    twitter
    instagram
    linkedin
    email
    fax
    tel 
    url
    image
    openingsHours {
        uuid
        day
        start
        stop
        type {
          id
          name
          description
        }
    }
    closingDays {
        uuid
        start
        stop
        description
        type {
           id
           name
           description
        }
    }
  }    
}`;

export const SettingsQuery = `{
    settings
    languages {
        id
        code
        available    
    }
}`;

export const DealershipUpdateMutation =
    `mutation DealershipUpdate($data: DealershipInputType!) { 
        dealershipUpdate(data: $data) { 
            ok 
            dealership {
                id
                name
                address
                postal
                city
                reference
                facebook
                twitter
                instagram
                linkedin
                email
                fax
                tel 
                url
                image
                openingsHours {
                    uuid
                    day
                    start
                    stop
                    type {
                      id
                      name
                      description
                    }
                }
                closingDays {
                    uuid
                    start
                    stop
                    description
                    type {
                       id
                       name
                       description
                    }
                }
            }
        }
    }`;


export const SiteUpdateMutation =
    `mutation SiteUpdate($data: SiteInputType!) { 
        siteUpdate(data: $data) { 
            ok 
        }
    }`;

export const LanguagesUpdateMutation =
    `mutation LanguagesUpdate($data: LanguagesInputType!) { 
        languagesUpdate(data: $data) { 
            ok 
        }
    }`;

export const SettingsUpdateMutation =
    `mutation SettingsUpdate($data: SettingsInputType!) { 
        settingsUpdate(data: $data) { 
            ok 
        }
    }`;


export const FileUploadMutation =
    `mutation FileUpload($file: Upload!) { 
        fileUpload(file: $file) { 
            ok 
            uuid
            location
            url            
        }
    }`;

export const PublishMutation =
    `mutation Publish { 
        publish { 
            ok          
        }
    }`;
