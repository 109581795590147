import React, {useContext} from 'react';
import {navigatePage} from "../../../utils";
import DataContext from "../../../store/DataContext";


const VehicleCompareBanner = ({context, renderContent}) => {
    const {vehicleCompareReferences} = useContext(DataContext);

    const handleCompare = (e) => {
        e.preventDefault();
        navigatePage(context, context.urls.vehicleCompare);
    };

    if(!vehicleCompareReferences || vehicleCompareReferences.length === 0 || !context.vehicleCompare) {
        return null;
    }

    return <div className="vehicles-compare-parent" style={{zIndex: 99999}}>
        {renderContent && typeof renderContent === 'function'
            ? renderContent(vehicleCompareReferences.length, handleCompare)
            : <div className="flex items-center justify-center z-100 fixed bottom-0 w-full vehicles-compare-parent">
                <div className="vehicles-compare-box container flex justify-end">
                    <div className="vehicles-compare-button primary-button relative" onClick={handleCompare}>
                        {vehicleCompareReferences.length > 0 && <div className="vehicles-compare-counter flex items-center justify-center absolute bg-color_three" style={{width: 30, height: 30, top: -15, right: -5, borderRadius: 30}}>{vehicleCompareReferences.length}</div>}
                        <div className="p-2 flex items-center justify-center">
                            <span>Wagens vergelijken</span>
                            <i className="far fa-arrow-right ml-2"/>
                        </div>
                    </div>
                </div>
            </div>}
    </div>
};


export default VehicleCompareBanner;
