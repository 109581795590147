import React, {useContext, useEffect, useState} from "react"
import {filter, find, map, isEmpty, orderBy} from "lodash"
import RangeSlider from "./RangeSlider"
import {getBodyIcon} from "../../../../utils";
import {FilterTypes} from "../../../../constants";
import {getFilterLabel} from "./functions";
import './Filters.css';

const Filters = ({filters, onAddFilter, onRemoveFilter, onUpdateFilter, data: {makes, models, fuels, bodies, dealerships, categories, gearboxes, seats, doors, prices, monthlyPrices, mileages, years, labels}}) => {
        const [expandedMake, setExpandedMake] = useState(undefined);

        const handleFilterChanged = (type, item, additionalData = undefined) => {
            const currentFilter = find(filters, (f) => f.type === type && f.value === item.key);

            if (currentFilter) {
                onRemoveFilter(currentFilter)
            } else {
                onAddFilter({
                    type: type,
                    value: item.key,
                    label: getFilterLabel(type, item.text, item.key),
                    additional: additionalData,
                })
            }
        };

        const handleSliderChanged = (item) => {
            const currentFilter = find(filters, (f) => f.type === item.type);

            if (currentFilter) {
                onUpdateFilter(currentFilter, item.values);
            } else {
                onAddFilter({
                    type: item.type,
                    value: item.values,
                    label: getFilterLabel(item.type, item.text, item.values)
                });
            }
        };

        return (
            <React.Fragment>
                <div className="flex flex-col px-5 md:px-0 pb-5 md:pb-16">
                    <div className="flex flex-col">

                        <div className="font-bold mb-3 bg-gray-100 p-2">Merk en model</div>
                        <div className="pl-2 pr-4">
                            {map(makes, (make) => {
                                const modelsForMake = filter(models, (model) => model.custom === make.key);
                                const expanded = expandedMake === make.key;

                                return (
                                    <div key={`filter-make-${make.key}`} className="flex flex-col text-gray-900 my-1">
                                        <div className="flex flex-row py-1 hover:bg-gray-100 cursor-pointer hover:text-primary" onClick={() => {
                                            handleFilterChanged(FilterTypes.make, make);
                                            setExpandedMake(make.key);
                                        }}>
                                            <div className="flex items-center" style={{minWidth: 35}}>
                                                <i className={`icon-${(make.key.includes('_')) ? make.key.split('_')[0] : make.key.toLowerCase()}`}/>
                                            </div>
                                            <div className="flex flex-row justify-between flex-1 relative">
                                                <div className="capitalize">{make.text}</div>
                                                <div>{make.value}</div>
                                            </div>
                                        </div>
                                        <div className={`mb-3 ${expanded ? "block" : "hidden"}`} style={{marginLeft: 35}}>
                                            {map(modelsForMake, (model) => {
                                                return (
                                                    <div key={`filter-model-${model.key}`} className="flex flex-col text-gray-900 mb-1">
                                                        <div className="flex flex-row justify-between flex-1 relative py-1 cursor-pointer hover:text-primary hover:bg-gray-100 text-sm"
                                                             onClick={() => handleFilterChanged(FilterTypes.modelFamily, model, make.key)}>
                                                            <div className="capitalize">{model.text}</div>
                                                            <div>{model.value}</div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="horizontal-line my-5"/>
                    <div className="flex flex-col">
                        <div className="font-bold mb-3 bg-gray-100 p-2">Brandstof</div>
                        <div className="pl-2 pr-4">
                            {map(orderBy(fuels, 'text'), (item) => {
                                return (
                                    <div key={`filter-fuel-${item.key}`} className="flex flex-col text-gray-900 mb-1">
                                        <div className="flex flex-row justify-between flex-1 relative py-1 cursor-pointer hover:text-primary hover:bg-gray-100"
                                             onClick={() => handleFilterChanged(FilterTypes.fuel, item)}>
                                            <div className="capitalize">{item.text}</div>
                                            <div>{item.value}</div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="horizontal-line my-5"/>
                    <div className="flex flex-col">
                        <div className="font-bold mb-3 bg-gray-100 p-2">Transmissie</div>
                        <div className="pl-2 pr-4">
                            {map(orderBy(gearboxes, 'text'), (item) => {
                                return (
                                    <div key={`filter-gearbox-${item.key}`} className="flex flex-col text-gray-900 mb-1">
                                        <div className="flex flex-row justify-between flex-1 relative py-1 cursor-pointer hover:text-primary hover:bg-gray-100"
                                             onClick={() => handleFilterChanged(FilterTypes.transmission, item)}>
                                            <div className="capitalize">{item.text}</div>
                                            <div>{item.value}</div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="horizontal-line my-5"/>

                    <div className="flex flex-col">
                        <div className="font-bold mb-3 bg-gray-100 p-2">Carrosserie</div>
                        <div className="pl-2 pr-4">
                            {map(orderBy(bodies, 'text'), (item) => {
                                return (
                                    <div key={`filter-body-${item.key}`} className="flex flex-col text-gray-900 mb-1">
                                        <div className="flex flex-row justify-between flex-1 relative py-1 cursor-pointer hover:text-primary hover:bg-gray-100"
                                             onClick={() => handleFilterChanged(FilterTypes.body, item)}>
                                            <div className="flex flex-row">
                                                <div style={{minWidth: 65}}><i className={getBodyIcon(item.key)}/></div>
                                                {item.text}
                                            </div>
                                            <div>{item.value}</div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="horizontal-line my-5"/>

                    {categories !== undefined && !isEmpty(categories) && <React.Fragment>
                        <div className="flex flex-col">
                            <div className="font-bold mb-3 bg-gray-100 p-2">Categorie</div>
                            <div className="pl-2 pr-4">
                                {map(orderBy(categories, 'text'), (item) => {
                                    return (
                                        <div key={`filter-category-${item.key}`} className="flex flex-col text-gray-900 mb-1">
                                            <div className="flex flex-row justify-between flex-1 relative py-1 cursor-pointer hover:text-primary hover:bg-gray-100"
                                                 onClick={() => handleFilterChanged(FilterTypes.category, item)}>
                                                <div className="capitalize">{item.text}</div>
                                                <div>{item.value}</div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="horizontal-line my-5"/>
                    </React.Fragment>}

                    {labels !== undefined && !isEmpty(labels) && <React.Fragment>
                        <div className="flex flex-col">
                            <div className="font-bold mb-3 bg-gray-100 p-2">Label</div>
                            <div className="pl-2 pr-4">
                                {map(orderBy(labels, 'text'), (item) => {
                                    return (
                                        <div key={`filter-category-${item.key}`} className="flex flex-col text-gray-900 mb-1">
                                            <div className="flex flex-row justify-between flex-1 relative py-1 cursor-pointer hover:text-primary hover:bg-gray-100"
                                                 onClick={() => handleFilterChanged(FilterTypes.label, item)}>
                                                <div className="capitalize">{item.text}</div>
                                                <div>{item.value}</div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="horizontal-line my-5"/>
                    </React.Fragment>}

                    {dealerships !== undefined && !isEmpty(dealerships) && dealerships.length > 1 && <React.Fragment>
                        <div className="flex flex-col">
                            <div className="font-bold mb-3 bg-gray-100 p-2">Vestiging</div>
                            <div className="pl-2 pr-4">
                                {map(orderBy(dealerships, 'text'), (item) => {
                                    return (
                                        <div key={`filter-category-${item.key}`} className="flex flex-col text-gray-900 mb-1">
                                            <div className="flex flex-row justify-between flex-1 relative py-1 cursor-pointer hover:text-primary hover:bg-gray-100"
                                                 onClick={() => handleFilterChanged(FilterTypes.dealership, item)}>
                                                <div className="capitalize">{item.text}</div>
                                                <div>{item.value}</div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="horizontal-line my-5"/>
                    </React.Fragment>}

                    <div className="flex flex-col">
                        <div className="font-bold mb-3 bg-gray-100 p-2">Deuren</div>
                        <div className="pl-2 pr-4">
                            {map(orderBy(doors, 'text'), (item) => {
                                return (
                                    <div key={`filter-doors-${item.key}`} className="flex flex-col text-gray-900 mb-1">
                                        <div className="flex flex-row justify-between flex-1 relative py-1 cursor-pointer hover:text-primary hover:bg-gray-100"
                                             onClick={() => handleFilterChanged(FilterTypes.doors, item)}>
                                            <div className="capitalize">{item.text} deuren</div>
                                            <div>{item.value}</div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="horizontal-line my-5"/>
                    <div className="flex flex-col">
                        <div className="font-bold mb-3 bg-gray-100 p-2">Zitplaatsen</div>
                        <div className="pl-2 pr-4"> {map(orderBy(seats, 'text'), (item) => {
                            return (
                                <div key={`filter-seats-${item.key}`} className="flex flex-col text-gray-900 mb-1">
                                    <div className="flex flex-row justify-between flex-1 relative py-1 cursor-pointer hover:text-primary hover:bg-gray-100"
                                         onClick={() => handleFilterChanged(FilterTypes.seats, item)}>
                                        <div className="capitalize">{item.text} zitplaatsen</div>
                                        <div>{item.value}</div>
                                    </div>
                                </div>
                            )
                        })}</div>
                    </div>

                    <div className="horizontal-line my-5"/>
                    <div className="flex flex-col">
                        <div className="font-bold mb-3 bg-gray-100 p-2">Prijs</div>
                        <div className="ml-5" style={{marginRight: "0.8rem"}}>
                            <RangeSlider
                                min={-Math.round(-(prices.min / 1000)) * 1000}
                                max={Math.round(prices.max / 1000) * 1000}
                                onChange={handleSliderChanged}
                                type={FilterTypes.price}
                                step={1000}
                                filters={filters}
                            />
                        </div>
                    </div>
                    <div className="horizontal-line my-5"/>

                    {monthlyPrices.min > 0 && monthlyPrices.max > 0 &&
                    <React.Fragment>
                        <div className="flex flex-col">
                            <div className="font-bold mb-3 bg-gray-100 p-2">Maandelijks budget</div>
                            <div className="ml-5" style={{marginRight: "0.8rem"}}>
                                <RangeSlider
                                    min={monthlyPrices.min}
                                    max={monthlyPrices.max}
                                    onChange={handleSliderChanged}
                                    type={FilterTypes.monthlyPrice}
                                    filters={filters}
                                />
                            </div>
                        </div>

                        <div className="horizontal-line my-5"/>
                    </React.Fragment>}

                    <div className="flex flex-col">
                        <div className="font-bold mb-3 bg-gray-100 p-2">Kilometers</div>
                        <div className="ml-5" style={{marginRight: "0.8rem"}}>
                            <RangeSlider
                                min={mileages.min}
                                max={mileages.max}
                                onChange={handleSliderChanged}
                                type={FilterTypes.mileage}
                                filters={filters}
                            />
                        </div>
                    </div>

                    <div className="horizontal-line my-5"/>
                    <div className="flex flex-col">
                        <div className="font-bold mb-3 bg-gray-100 p-2">Jaar</div>
                        <div className="ml-5" style={{marginRight: "0.8rem"}}>
                            <RangeSlider
                                min={years.min}
                                max={years.max}
                                onChange={handleSliderChanged}
                                type={FilterTypes.year}
                                filters={filters}
                            />
                        </div>
                    </div>


                </div>
            </React.Fragment>
        )
    }
;

export default Filters
