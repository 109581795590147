import React from 'react';
import VehicleSearchBgVideoCore from "./components/VehicleSearchBgVideoCore";


const VehicleSearchBgVideo = ({logo, title, video, context}) => {
    return <VehicleSearchBgVideoCore logo={logo}
                                     title={title}
                                     videos={video}
                                     context={context}/>
};

VehicleSearchBgVideo.human = {
    "nl": "",
    "fr": "",
    "en": "",
};

VehicleSearchBgVideo.preview = VehicleSearchBgVideo;

export default VehicleSearchBgVideo;
