import {getUrlParams} from "../../../../utils";
import {formatNumber, formatPrice} from "../../../../utils";
import {FilterTypes, UrlFilterTypes} from "../../../../constants";
import {filter, find, flatten, includes, keys, map, orderBy, union, forEach} from "lodash";


export const filterItem = (filter, vehicle) => {
    switch (filter.type) {
        case FilterTypes.make:
            let makes = filter.values;
            return makes.some(make => make === vehicle.makeKey);
        case FilterTypes.model:
            let models = filter.values;
            return models.some(model => model === vehicle.modelFamilyKey);
        case FilterTypes.makeModel:
            let makeModels = filter.values;
            const arrMakes = map(filter.values, (i) => i.substring(0, i.indexOf("|")));
            return makeModels.some(mm => mm === `${vehicle.makeKey}|${vehicle.modelKey}`) || !arrMakes.some(make => make === vehicle.makeKey);
        case FilterTypes.makeModelFamily:
            let makeModelFamilies = filter.values;
            const arrMakes2 = map(filter.values, (i) => i.substring(0, i.indexOf("|")));
            return makeModelFamilies.some(mm => mm === `${vehicle.makeKey}|${vehicle.modelFamilyKey}`) || !arrMakes2.some(make => make === vehicle.makeKey);
        case FilterTypes.fuel:
            let fuels = filter.values;
            return fuels.some(fuel => fuel === vehicle.fuelKey);
        case FilterTypes.transmission:
            let transmissions = filter.values;
            return transmissions.some(transmission => transmission === vehicle.gearboxKey);
        case FilterTypes.doors:
            let doors = filter.values;
            return doors.some(door => parseInt(door) === vehicle.doors);
        case FilterTypes.doorsRange:
            let doorsRange = filter.value;
            return doorsRange[0] <= vehicle.doors && vehicle.doors <= doorsRange[1];
        case FilterTypes.seats:
            let seats = filter.values;
            return seats.some(seat => parseInt(seat) === vehicle.seats);
        case FilterTypes.seatsRange:
            let seatsRange = filter.value;
            if (seatsRange[0] && seatsRange[1]) {
                return parseInt(seatsRange[0]) <= vehicle.seats && vehicle.seats <= parseInt(seatsRange[1]);
            } else {
                if (seatsRange[0]) return parseInt(seatsRange[0]) <= vehicle.seats;
                if (seatsRange[1]) return vehicle.seats <= parseInt(seatsRange[1]);
            }
        case FilterTypes.body:
            let bodies = filter.values;
            return bodies.some(body => body === vehicle.bodyKey);
        case FilterTypes.dealership:
            let dealerships = filter.values;
            return dealerships.some(dealership => dealership === vehicle.dealershipKey);
        case FilterTypes.label:
            let labels = filter.values;
            return labels.some(label => includes(vehicle.labels, label));
        case FilterTypes.category:
            let categories = filter.values;
            return categories.some(cat => cat === vehicle.categoryKey);
        case FilterTypes.price:
            let prices = filter.value;
            return prices[0] <= vehicle.price && vehicle.price <= prices[1];
        case FilterTypes.monthlyPrice:
            let monthlyPrices = filter.value;
            return parseFloat(monthlyPrices[0]) <= parseFloat(vehicle.monthlyPrice) && parseFloat(vehicle.monthlyPrice) <= parseFloat(monthlyPrices[1]);
        case FilterTypes.mileage:
            let mileages = filter.value;
            if (mileages[0] && mileages[1]) {
                return parseInt(mileages[0]) <= vehicle.mileage && vehicle.mileage <= parseInt(mileages[1]);
            } else {
                if (mileages[0]) return parseInt(mileages[0]) <= vehicle.mileage;
                if (mileages[1]) return vehicle.mileage <= parseInt(mileages[1]);
            }
        case FilterTypes.year:
            let years = filter.value;
            if (years[0] && years[1]) {
                return parseInt(years[0]) <= vehicle.firstRegistrationYear && vehicle.firstRegistrationYear <= parseInt(years[1]);
            } else {
                if (years[0]) return parseInt(years[0]) <= vehicle.firstRegistrationYear;
                if (years[1]) return vehicle.firstRegistrationYear <= parseInt(years[1]);
            }
        case FilterTypes.vat:
            return vehicle.vatRegime === filter.value;
        case FilterTypes.equipments:
            return filter.values.some(feature => includes(vehicle.equipmentKeys, feature));
        case FilterTypes.exteriorColor:
            return filter.values.some(extColor => extColor === vehicle.exteriorColorKey);
        case FilterTypes.interiorColor:
            return filter.values.some(intColor => intColor === vehicle.interiorColorKey);
        case FilterTypes.interiorUpholstery:
            return filter.values.some(uh => uh === vehicle.interiorUpholsteryKey);
        case FilterTypes.pk:
            let pk = filter.value;
            if (pk[0] && pk[1]) {
                return parseInt(pk[0]) <= vehicle.hp && vehicle.hp <= parseInt(pk[1]);
            } else {
                if (pk[0]) return parseInt(pk[0]) <= vehicle.hp;
                if (pk[1]) return vehicle.hp <= parseInt(pk[1]);
            }
        case FilterTypes.kw:
            let kw = filter.value;
            if (kw[0] && kw[1]) {
                return parseInt(kw[0]) <= vehicle.kw && vehicle.kw <= parseInt(kw[1]);
            } else {
                if (kw[0]) return parseInt(kw[0]) <= vehicle.kw;
                if (kw[1]) return vehicle.kw <= parseInt(kw[1]);
            }
        case FilterTypes.identification:
            let identification = filter.value;
            return vehicle.identification.toString() === identification.value.toString();
        case FilterTypes.co2:
            let co2 = filter.values;
            return co2.some(value => parseInt(value) === vehicle.co2);
        case FilterTypes.co2Range:
            let co2Range = filter.value;
            if (co2Range[0] && co2Range[1]) {
                return parseInt(co2Range[0]) <= vehicle.co2 && vehicle.co2 <= parseInt(co2Range[1]);
            } else {
                if (co2Range[0]) return parseInt(co2Range[0]) <= vehicle.co2;
                if (co2Range[1]) return vehicle.co2 <= parseInt(co2Range[1]);
            }
        case FilterTypes.euroNorm:
            let euroNorms = filter.values;
            return euroNorms.some(value => value === vehicle.euroNorm);
        case FilterTypes.segment:
            let segment = filter.value;
            return vehicle.segmentKey === segment;
        case FilterTypes.favorite:
            return vehicle.isFavorite;
        default:
            return true
    }
};

const getMakesFromFilters = (filters) => {
    const makes = flatten(map(filter(filters, (f) => f.type === FilterTypes.make), (f) => f.value));
    const modelMakes = flatten(map(filter(filters, (f) => f.type === FilterTypes.modelFamily), (f) => f.additional));
    return union(makes, modelMakes);
};


export const getFilteredVehicles = (filters, sorter, vehicles) => {
    let filtered_vehicles = vehicles;

    if (filters.length > 0) {
        const groupedFilters = [];

        const makes = getMakesFromFilters(filters);
        if (makes.length > 0) groupedFilters.push({type: FilterTypes.make, values: makes});

        const makeModels = flatten(map(filter(filters, (f) => f.type === FilterTypes.model), (f) => `${f.additional}|${f.value}`));
        if (makeModels.length > 0) groupedFilters.push({type: FilterTypes.makeModel, values: makeModels});

        const makeModelFamilies = flatten(map(filter(filters, (f) => f.type === FilterTypes.modelFamily), (f) => `${f.additional}|${f.value}`));
        if (makeModelFamilies.length > 0) groupedFilters.push({type: FilterTypes.makeModelFamily, values: makeModelFamilies});

        const fuels = flatten(map(filter(filters, (f) => f.type === FilterTypes.fuel), (f) => f.value));
        if (fuels.length > 0) groupedFilters.push({type: FilterTypes.fuel, values: fuels});

        const gearboxes = flatten(map(filter(filters, (f) => f.type === FilterTypes.transmission), (f) => f.value));
        if (gearboxes.length > 0) groupedFilters.push({type: FilterTypes.transmission, values: gearboxes});

        const bodies = flatten(map(filter(filters, (f) => f.type === FilterTypes.body), (f) => f.value));
        if (bodies.length > 0) groupedFilters.push({type: FilterTypes.body, values: bodies});

        const categories = flatten(map(filter(filters, (f) => f.type === FilterTypes.category), (f) => f.value));
        if (categories.length > 0) groupedFilters.push({type: FilterTypes.category, values: categories});

        const doors = flatten(map(filter(filters, (f) => f.type === FilterTypes.doors), (f) => f.value));
        if (doors.length > 0) groupedFilters.push({type: FilterTypes.doors, values: doors});

        const doorsRange = find(filters, (f) => f.type === FilterTypes.doorsRange);
        if (doorsRange) groupedFilters.push(doorsRange);

        const seats = flatten(map(filter(filters, (f) => f.type === FilterTypes.seats), (f) => f.value));
        if (seats.length > 0) groupedFilters.push({type: FilterTypes.seats, values: seats});

        const price = find(filters, (f) => f.type === FilterTypes.price);
        if (price) groupedFilters.push(price);

        const monthlyPrice = find(filters, (f) => f.type === FilterTypes.monthlyPrice);
        if (monthlyPrice) groupedFilters.push(monthlyPrice);

        const year = find(filters, (f) => f.type === FilterTypes.year);
        if (year) groupedFilters.push(year);

        const mileage = find(filters, (f) => f.type === FilterTypes.mileage);
        if (mileage) groupedFilters.push(mileage);

        const dealerships = flatten(map(filter(filters, (f) => f.type === FilterTypes.dealership), (f) => f.value));
        if (dealerships.length > 0) groupedFilters.push({type: FilterTypes.dealership, values: dealerships});

        const labels = flatten(map(filter(filters, (f) => f.type === FilterTypes.label), (f) => f.value));
        if (labels.length > 0) groupedFilters.push({type: FilterTypes.label, values: labels});

        const vatFilter = find(filters, (f) => f.type === FilterTypes.vat);
        if (vatFilter) groupedFilters.push({type: FilterTypes.vat, value: true});

        const equipments = flatten(map(filter(filters, (f) => f.type === FilterTypes.equipments), (f) => f.value));
        if (equipments.length > 0) groupedFilters.push({type: FilterTypes.equipments, values: equipments});

        const exteriorColors = flatten(map(filter(filters, (f) => f.type === FilterTypes.exteriorColor), (f) => f.value));
        if (exteriorColors.length > 0) groupedFilters.push({type: FilterTypes.exteriorColor, values: exteriorColors});

        const interiorColors = flatten(map(filter(filters, (f) => f.type === FilterTypes.interiorColor), (f) => f.value));
        if (interiorColors.length > 0) groupedFilters.push({type: FilterTypes.interiorColor, values: interiorColors});

        const interiorUpholsteries = flatten(map(filter(filters, (f) => f.type === FilterTypes.interiorUpholstery), (f) => f.value));
        if (interiorUpholsteries.length > 0) groupedFilters.push({type: FilterTypes.interiorUpholstery, values: interiorUpholsteries});

        const identification = find(filters, (f) => f.type === FilterTypes.identification);
        if (identification) groupedFilters.push({type: FilterTypes.identification, value: identification});

        const pks = find(filters, (f) => f.type === FilterTypes.pk);
        if (pks) groupedFilters.push(pks);

        const kws = find(filters, (f) => f.type === FilterTypes.kw);
        if (kws) groupedFilters.push(kws);

        const euroNorms = flatten(map(filter(filters, (f) => f.type === FilterTypes.euroNorm), (f) => f.value));
        if (euroNorms.length > 0) groupedFilters.push({type: FilterTypes.euroNorm, values: euroNorms});

        const c02 = flatten(map(filter(filters, (f) => f.type === FilterTypes.co2), (f) => f.value));
        if (c02.length > 0) groupedFilters.push({type: FilterTypes.co2, values: c02});

        const seatsRange = find(filters, (f) => f.type === FilterTypes.seatsRange);
        if (seatsRange) groupedFilters.push(seatsRange);

        const co2Range = find(filters, (f) => f.type === FilterTypes.co2Range);
        if (co2Range) groupedFilters.push(co2Range);

        const segment = find(filters, (f) => f.type === FilterTypes.segment);
        if (segment) groupedFilters.push({type: FilterTypes.segment, value: segment.value});

        const favorite = find(filters, (f) => f.type === FilterTypes.favorite);
        if (favorite) groupedFilters.push({type: FilterTypes.favorite, value: true});

        filtered_vehicles = vehicles.filter((vehicle) => groupedFilters.every((filter) => filterItem(filter, vehicle)));
    }

    if (sorter) {
        filtered_vehicles = orderBy(filtered_vehicles, [sorter.value], [(sorter.ascending) ? 'asc' : 'desc'])
    }

    return filtered_vehicles;


};

export const filtersFromSearch = (data, search) => {
    const filters = [];
    const parameters = getUrlParams(search);
    const ranges = [UrlFilterTypes.priceFrom, UrlFilterTypes.priceTo, UrlFilterTypes.yearFrom, UrlFilterTypes.yearTo, UrlFilterTypes.mileageFrom, UrlFilterTypes.mileageTo, UrlFilterTypes.monthlyFrom, UrlFilterTypes.monthlyTo];

    map(keys(parameters), (key) => {
        const term = key;
        const value = parameters[key].toLowerCase();

        if (includes(keys(UrlFilterTypes), term)) {
            let filter = undefined;
            let formatted_value = "";
            let tmpValue = undefined;

            switch (term) {
                case UrlFilterTypes.make:
                    const make = find(data.makes, (make) => make.key === value);
                    if (make) {
                        filters.push({
                            type: FilterTypes.make,
                            value: value,
                            label: getFilterLabel(FilterTypes.make, make.text, value)
                        })
                    }
                    break;
                case UrlFilterTypes.model:
                    const tmpMake = find(filters, (f) => f.type === FilterTypes.make);
                    filters.push({
                        type: FilterTypes.modelFamily,
                        value: value,
                        label: getFilterLabel(FilterTypes.modelFamily, value, value),
                        additional: tmpMake.value
                    })
                    break;
                case UrlFilterTypes.model2:
                    const tmpMake2 = find(filters, (f) => f.type === FilterTypes.make);
                    filters.push({
                        type: FilterTypes.model,
                        value: value,
                        label: getFilterLabel(FilterTypes.model, value, value),
                        additional: tmpMake2.value
                    })
                    break;
                case UrlFilterTypes.fuel:
                    const fuel = find(data.fuels, (fuel) => fuel.key === value);
                    if (fuel) {
                        filters.push({
                            type: FilterTypes.fuel,
                            value: value,
                            label: getFilterLabel(FilterTypes.fuel, fuel.text, value)
                        })
                    }
                    break;
                case UrlFilterTypes.fuels:
                    const extractedFuels = [...value.split(',')];
                    const fuels = data.fuels.filter(fuel => extractedFuels.includes(fuel.key));
                    if (fuels.length > 0) {
                        fuels.forEach(fuel => filters.push({
                            type: FilterTypes.fuel,
                            value: fuel.key,
                            label: getFilterLabel(FilterTypes.fuel, fuel.text, fuel.key)
                        }));
                    }
                    break;
                case UrlFilterTypes.transmission:
                    const gearbox = find(data.gearboxes, (gearbox) => gearbox.key === value);
                    if (gearbox) {
                        filters.push({
                            type: FilterTypes.transmission,
                            value: value,
                            label: getFilterLabel(FilterTypes.transmission, gearbox.text, value)
                        })
                    }
                    break;
                case UrlFilterTypes.body:
                    const body = find(data.bodies, (body) => body.key === value);
                    if (body) {
                        filters.push({
                            type: FilterTypes.body,
                            value: value,
                            label: getFilterLabel(FilterTypes.body, body.text, value)
                        })
                    }
                    break;
                case UrlFilterTypes.category:
                    const category = find(data.categories, (category) => category.key === value);
                    if (category) {
                        filters.push({
                            type: FilterTypes.category,
                            value: value,
                            label: getFilterLabel(FilterTypes.category, category.text, value)
                        })
                    }
                    break;
                case UrlFilterTypes.categories:
                    const extractedCategories = [...value.split(',')];
                    const categories = data.categories.filter(cat => extractedCategories.includes(cat.key))
                    if (categories.length > 0) {
                        categories.forEach(category => filters.push({
                            type: FilterTypes.category,
                            value: category.key,
                            label: getFilterLabel(FilterTypes.category, category.text, category.key)
                        }));
                    }
                    break;
                case UrlFilterTypes.doors:
                    const door = find(data.doors, (door) => door.key === value);
                    if (door) {
                        filters.push({
                            type: FilterTypes.doors,
                            value: value,
                            label: getFilterLabel(FilterTypes.doors, door.text, value)
                        })
                    }
                    break;
                case UrlFilterTypes.seats:
                    const seat = find(data.seats, (seat) => seat.key === value);
                    if (seat) {
                        filters.push({
                            type: FilterTypes.seats,
                            value: value,
                            label: getFilterLabel(FilterTypes.seats, seat.text, value)
                        })
                    }
                    break;
                case UrlFilterTypes.priceFrom:
                    filter = find(filters, (f) => f.type === FilterTypes.price);
                    formatted_value = parseInt(value) || 0;
                    if (filter) {
                        filter.value = [formatted_value, filter.value[1]]
                        filter.label = getFilterLabel(FilterTypes.price, "", filter.value)
                    } else {
                        tmpValue = [formatted_value, 999999];
                        filters.push({
                            type: FilterTypes.price,
                            value: tmpValue,
                            label: getFilterLabel(FilterTypes.price, "", tmpValue)
                        });
                    }
                    break;
                case UrlFilterTypes.priceTo:
                    filter = find(filters, (f) => f.type === FilterTypes.price);
                    formatted_value = parseInt(value) || 999999;
                    if (filter) {
                        filter.value = [filter.value[0], formatted_value];
                        filter.label = getFilterLabel(FilterTypes.price, "", filter.value)
                    } else {
                        tmpValue = [0, formatted_value];
                        filters.push({
                            type: FilterTypes.price,
                            value: tmpValue,
                            label: getFilterLabel(FilterTypes.price, "", tmpValue)
                        });
                    }
                    break;
                case UrlFilterTypes.yearFrom:
                    filter = find(filters, (f) => f.type === FilterTypes.year);
                    formatted_value = parseInt(value) || 2000;
                    if (filter) {
                        filter.value = [formatted_value, filter.value[1]];
                        filter.label = getFilterLabel(FilterTypes.year, "", filter.value)
                    } else {
                        tmpValue = [formatted_value, new Date().getFullYear()];
                        filters.push({
                            type: FilterTypes.year,
                            value: tmpValue,
                            label: getFilterLabel(FilterTypes.year, "", tmpValue)
                        });
                    }
                    break;
                case UrlFilterTypes.yearTo:
                    filter = find(filters, (f) => f.type === FilterTypes.year);
                    formatted_value = parseInt(value) || new Date().getFullYear();
                    if (filter) {
                        filter.value = [filter.value[0], formatted_value]
                        filter.label = getFilterLabel(FilterTypes.year, "", filter.value)
                    } else {
                        tmpValue = [2000, formatted_value];
                        filters.push({
                            type: FilterTypes.year,
                            value: tmpValue,
                            label: getFilterLabel(FilterTypes.year, "", tmpValue)
                        });
                    }
                    break;
                case UrlFilterTypes.mileageFrom:
                    filter = find(filters, (f) => f.type === FilterTypes.mileage);
                    formatted_value = parseInt(value) || 0;
                    if (filter) {
                        filter.value = [formatted_value, filter.value[1]];
                        filter.label = getFilterLabel(FilterTypes.mileage, "", filter.value)
                    } else {
                        tmpValue = [formatted_value, 200000];
                        filters.push({
                            type: FilterTypes.mileage,
                            value: tmpValue,
                            label: getFilterLabel(FilterTypes.mileage, "", tmpValue)
                        });
                    }
                    break;
                case UrlFilterTypes.mileageTo:
                    filter = find(filters, (f) => f.type === FilterTypes.mileage);
                    formatted_value = parseInt(value) || 200000;
                    if (filter) {
                        filter.value = [filter.value[0], formatted_value]
                        filter.label = getFilterLabel(FilterTypes.mileage, "", filter.value)
                    } else {
                        tmpValue = [0, formatted_value];
                        filters.push({
                            type: FilterTypes.mileage,
                            value: tmpValue,
                            label: getFilterLabel(FilterTypes.mileage, "", tmpValue)
                        });
                    }
                    break;
                case UrlFilterTypes.monthlyFrom:
                    filter = find(filters, (f) => f.type === FilterTypes.monthlyPrice);
                    formatted_value = parseInt(value) || 0;
                    if (filter) {
                        filter.value = [formatted_value, filter.value[1]]
                        filter.label = getFilterLabel(FilterTypes.monthlyPrice, "", filter.value)
                    } else {
                        tmpValue = [formatted_value, 1500];
                        filters.push({
                            type: FilterTypes.monthlyPrice,
                            value: tmpValue,
                            label: getFilterLabel(FilterTypes.monthlyPrice, "", tmpValue)
                        });
                    }
                    break;
                case UrlFilterTypes.monthlyTo:
                    filter = find(filters, (f) => f.type === FilterTypes.monthlyPrice);
                    formatted_value = parseInt(value) || 1500;
                    if (filter) {
                        filter.value = [filter.value[0], formatted_value]
                        filter.label = getFilterLabel(FilterTypes.monthlyPrice, "", filter.value)
                    } else {
                        tmpValue = [0, formatted_value];
                        filters.push({
                            type: FilterTypes.monthlyPrice,
                            value: tmpValue,
                            label: getFilterLabel(FilterTypes.monthlyPrice, "", tmpValue)
                        });
                    }
                    break;
                case UrlFilterTypes.dealership:
                    const dealership = find(data.dealerships, (dealership) => dealership.key === value.toString());
                    if (dealership) {
                        filters.push({
                            type: FilterTypes.dealership,
                            value: value,
                            label: getFilterLabel(FilterTypes.dealership, dealership.text, value)
                        })
                    }
                    break;
                case UrlFilterTypes.segment:
                    const segment = find(data.segments, (segment) => segment.key === value.toString());
                    if (segment) {
                        filters.push({
                            type: FilterTypes.segment,
                            value: value,
                            label: ""
                        })
                    }
                    break;
                case UrlFilterTypes.favorite:
                    filters.push({
                        type: FilterTypes.favorite,
                        value: value === 'true',
                        label: getFilterLabel(FilterTypes.favorite, "", value)
                    })
                    break;
                default:
                    break;
            }
        }
    });
    return filters

};


export const getFilterLabel = (type, text, value) => {
    switch (type) {
        case FilterTypes.seats:
            return `${text} zitplaatsen`;
        case FilterTypes.doors:
            return `${text} deuren`;
        case FilterTypes.price:
        case FilterTypes.monthlyPrice:

            return `${formatPrice(value[0])} - ${formatPrice(value[1])}`;
        case FilterTypes.mileage:
            return `${formatNumber(value[0])} km - ${formatNumber(value[1])} km`;
        case FilterTypes.year:
            return `${value[0]} - ${value[1]}`;
        case FilterTypes.pk:
            if (value[0] && value[1]) {
                return `${value[0]} pk - ${value[1]} pk`;
            } else {
                if (value[0]) return `Van ${value[0]} pk`;
                if (value[1]) return `Tot ${value[1]} pk`;
            }
        case FilterTypes.kw:
            if (value[0] && value[1]) {
                return `${value[0]} kw - ${value[1]} kw`;
            } else {
                if (value[0]) return `Van ${value[0]} kw`;
                if (value[1]) return `Tot ${value[1]} kw`;
            }

        case FilterTypes.seatsRange:
            if (value[0] && value[1]) {
                return `tussen ${value[0]} en ${value[1]} zitplaatsen`;
            } else {
                if (value[0]) return `van ${value[0]} zitplaatsen`;
                if (value[1]) return `tot ${value[1]} zitplaatsen`;
            }
        case FilterTypes.identification:
            return `Ref ${text}`;
        case FilterTypes.favorite:
            return `Favorieten`;
        case FilterTypes.model:
        case FilterTypes.modelFamily:
            return (value) ? value.replace("_", " ") : value
        default:
            return text;

    }

};
