import React from 'react';

const Button = ({disabled, type, className, onClick, loading, loadingText, text, lightForm}) => {
    return (
        // <button type={type} className={`flex justify-center bg-primary border-2 border-primary text-white hover:bg-white hover:border-2 hover:border-primary hover:text-black rounded-none w-full text-center text-md font-medium py-3 px-20 rounded-none cursor-pointer button-primary ${className}`} disabled={disabled} onClick={onClick}>
        <button type={type} className={`flex justify-center  ${lightForm ? "bg-white text-black hover:text-gray-800" : "bg-primary text-white hover:text-gray-400"}  rounded-none w-full text-center text-md font-medium py-3 px-20 rounded-none cursor-pointer button-primary ${className}`} disabled={disabled} onClick={onClick}>
            {loading ? loadingText : text}
        </button>
    );
};

Button.defaultProps = {
    disabled: false,
    loading: false,
    loadingText: "Bezig...",
    type: "text",
    lightForm: false
};

export default Button;
