import React, {useEffect, useRef, useState} from 'react';
import {DefaultImage, DefaultText, DefaultTitle} from "../../../core/constants";
import {BasicContactForm, Image} from "../../../core";
import {navigatePage} from "../../../core/utils";
import ContactFormTwoCol_ImageLeft_Preview from "./components/ContactFormTwoCol_ImageLeft_Preview";

const ContactFormTwoCol_ImageLeft = ({title, text, image, expert_name, expert_title, context}) => {
    const [height, setHeight] = useState(0);
    const imageRef = useRef(null);

    const getImageHeight = () => {
        if (imageRef && imageRef.current) {
            setHeight(imageRef.current.clientHeight)
        }
    }

    useEffect(() => {
        function handleResize() {
            if (imageRef && imageRef.current) {
                setHeight(imageRef.current.clientHeight)
            }
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const bigScreens = () => {
        return <div className="flex items-center justify-center w-full bg-black relative py-0 sm:py-10 md:py-0 border-b" style={{borderColor: "rgb(255,255,255, 0.6)", minHeight: height === 0 ? "auto" : height}}>
            <div className="flex items-center justify-center w-full relative" style={{minHeight: height === 0 ? "auto" : height}}>
                <div className="container flex flex-row items-center justify-center" style={{minHeight: height === 0 ? "auto" : height}}>
                    <div className="flex items-center justify-center w-6/12 absolute left-0 pr-8" ref={imageRef}>
                        <Image url={(image) ? image : undefined} style={{maxWidth: '100%'}} onLoad={getImageHeight}/>

                    </div>
                    <div className="lg:w-6/12 opacity-0"/>
                    <div className="flex w-full flex-col lg:w-6/12 items-center lg:items-start justify-center px-5 lg:px-0 py-5">
                        <div className="w-full">
                            <div className="">
                                <div className="flex flex-1 flex-col">
                                    <div className="mb-1 lg:mb-3 text-2xl lg-text-4xl text-white">{title}</div>
                                    <div className="mb-8 lg:mb-8 lg:px-0 text-white">{text}</div>

                                </div>

                                <BasicContactForm context={context}
                                                  lightForm={true}
                                                  handlePrivacy={() => navigatePage(context, context.urls.privacy, null, null, true)}/>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    const smallScreens = () => {
        return <div className="flex items-center justify-center w-full bg-black relative py-0 sm:py-10 md:py-0 border-b" style={{borderColor: "rgb(255,255,255, 0.6)"}}>
            <div className="flex items-center justify-center w-full relative">
                <div className="flex flex-col">
                    <div className="flex items-center justify-center">
                        <Image url={(image) ? image : undefined} style={{maxWidth: '100%'}}/>

                    </div>
                    <div className="flex w-full flex-col items-center justify-center p-5">
                        <div className="light-form">
                            <div className="">
                                <div className="flex flex-1 flex-col">
                                    <div className="mb-1 lg:mb-3 text-2xl lg-text-4xl text-white">{title}</div>
                                    <div className="mb-8 lg:mb-8 lg:px-0 text-white">{text}</div>

                                </div>

                                <BasicContactForm context={context}
                                                  lightForm={true}
                                                  handlePrivacy={() => navigatePage(context, context.urls.privacy, null, null, true)}/>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    return (<div>
            <div className="hidden lg:flex">{bigScreens()}</div>
            <div className="flex lg:hidden">{smallScreens()}</div>
        </div>
        // <div className="flex items-center justify-center w-full bg-black relative py-0 sm:py-10 md:py-0 border-b" style={{borderColor: "rgb(255,255,255, 0.6)", height: height === 0 ? "auto" : height}}>
        //     <div className="flex items-center justify-center w-full relative" style={{height: height === 0 ? "auto" : height}}>
        //         <div className="container flex flex-col lg:flex-row" style={{height: height === 0 ? "auto" : height}}>
        //             <div className="flex items-center justify-center lg:w-6/12 absolute left-0 pr-0 lg:pr-8" ref={imageRef}>
        //                 <Image url={(image) ? image : undefined} style={{maxWidth: '100%'}} onLoad={getImageHeight}/>
        //
        //             </div>
        //             <div className="lg:w-6/12 opacity-0" />
        //             <div className="flex w-full flex-col lg:w-6/12 items-center lg:items-start justify-center px-5 lg:px-0 my-5 lg:my-0 " >
        //                 <div className="w-full">
        //                     <div className="">
        //                         <div className="flex flex-1 flex-col">
        //                             <div className="mb-1 lg:mb-3 text-2xl lg-text-4xl text-white">{title}</div>
        //                             <div className="mb-8 lg:mb-8 lg:px-0 text-white">{text}</div>
        //
        //                         </div>
        //
        //                         <BasicContactForm context={context}
        //                                           lightForm={true}
        //                                           handlePrivacy={() => navigatePage(context, context.urls.privacy, null, null, true)}/>
        //                     </div>
        //
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    );
};

ContactFormTwoCol_ImageLeft.defaultProps = {
    title: DefaultTitle,
    text: DefaultText,
    image: DefaultImage,
    expert_name: "",
    expert_title: "",
};

ContactFormTwoCol_ImageLeft.human = {
    "nl": "",
    "fr": "",
    "en": "",
};

ContactFormTwoCol_ImageLeft.preview = () => { return <ContactFormTwoCol_ImageLeft_Preview /> };

export default ContactFormTwoCol_ImageLeft;
