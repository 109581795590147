import React, {useContext, useEffect, useState} from 'react';
import Jello from "react-reveal/Jello";
import DataContext from "../../../store/DataContext";
import {indexOf} from "lodash";

const VehicleFavoriteBtn = ({reference, render = null}) => {
    const [isFavorite, setIsFavorite] = useState(false);
    const {favorites, updateFavorites} = useContext(DataContext);

    useEffect(() => {
        const isFavorite = indexOf(favorites, reference) >= 0
        setIsFavorite(isFavorite)
    }, [favorites])

    const handleClick = (e) => {
        e.stopPropagation();
        updateFavorites(reference);
        setIsFavorite(!isFavorite);
    };

    return (
        <Jello spy={isFavorite}>
            {(render) ? render(handleClick, isFavorite) :
                <div className="flex items-center justify-center absolute bg-white rounded-full hover:bg-gray-200 cursor-pointer" style={{width: 30, height: 30}} onClick={handleClick}>
                    <i className={`${isFavorite ? "fas fa-heart" : "far fa-heart"} text-primary`} style={{marginTop: 4, marginLeft: 1}}/>
                </div>}
        </Jello>
    )
};

export default VehicleFavoriteBtn;
