import React from "react"
import {map} from 'lodash';

const TopBar = ({filters, sorter, onRemove, onRemoveSort}) => {
    return (
        <div className="flex flex-row w-full overflow-x-scroll">
            {map(filters, (filter, index) => {
                return (
                    <div key={`filter.type${index}`} className="flex flex-row border border-gray-400 p-2 mr-2">
                        <div className="flex flex-row">
                            <span className="capitalize">{filter.label}</span>
                            <div className="cursor-pointer ml-2 md:ml-4 hover:text-primary hover:spin-animation" onClick={() => onRemove(filter)}>
                                <i className="fad fa-times"/>
                            </div>
                        </div>
                    </div>
                )
            })}

            {sorter &&
            <div key={`sorter`} className="flex flex-row border border-gray-400 p-2 mr-2">
                <div className="flex flex-row">
                    <span className="capitalize">
                        <span className="mr-2">
                            <i className={`${(sorter.ascending) ? 'fad fa-sort-amount-up' : 'fad fa-sort-amount-down'}`}/>
                        </span>
                        {sorter.label}
                    </span>
                    <div className="cursor-pointer ml-2 md:ml-4 hover:text-primary hover:spin-animation" onClick={onRemoveSort}>
                        <i className="fad fa-times"/>
                    </div>
                </div>
            </div>}
        </div>
    )
};

export default TopBar
