import React from 'react';
import WizardBlock from "../../../../core/components/vehicle/vehicle-reservation/WizardBlock";
import VehicleBlock from "../../../../core/components/vehicle/vehicle-reservation/VehicleBlock";
import InputField from "../../../../core/components/input/Input";
import ButtonWithIcon from "../../../../core/components/input/ButtonWithIcon";
import {DefaultPaymentLogos, preview_context, preview_vehicle} from "../../../../core/constants";

const step = 1

const VehicleReservation_Preview = () => {
    return (
        <div className="flex flex-col overflow-x-hidden min-h-screen relative w-full bg-white">
            <WizardBlock step={step} classnames="mt-2 mb-10"/>

            <div className="flex flex-col items-center justify-center">
                <div className="flex justify-center w-full">
                    <div className="container text-gray-900 text-3xl font-bold px-5 md:px-0 mb-0 md:mb-5">Reserveren</div>
                </div>

                <div className="flex flex-col-reverse md:flex-row container px-5 md:px-0">

                    <div className="relative w-full md:w-2/3 -mr-5 md:mr-0 bg-gray-100 p-5 mr-5 border border-gray-100 shadow">


                        <div className="flex flex-col flex-1 relative h-full justify-between">
                            <div className="flex flex-col">
                                <div className="text-gray-800 text-2xl mb-4">Uw gegevens</div>
                                <div className="text-gray-800 text-base mb-8">Vertel iets meer over jezelf. We hebben deze info nodig om de wagen voor u opzij te kunnen zetten.</div>

                                <div className="flex flex-col mb-5">
                                    <div className="flex flex-col md:flex-row mb-6">
                                        <InputField containerClass="w-full md:w-1/2 px-0 mr-0 md:mr-4 mb-6 md:mb-0"
                                                    label="Voornaam"
                                                    placeholder="John"
                                                    value=""/>
                                        <InputField containerClass="w-full md:w-1/2 px-0"
                                                    label="Naam"
                                                    placeholder="Doe"
                                                    value=""/>
                                    </div>
                                    <div className="flex flex-col md:flex-row mb-6">
                                        <InputField containerClass="w-full md:w-1/2 px-0 mr-0 md:mr-4 mb-6 md:mb-0"
                                                    type="email"
                                                    label="Emailadres"
                                                    placeholder="john.doe@gmail.com"
                                                    value=""/>
                                        <InputField containerClass="w-full md:w-1/2 px-0 "
                                                    label="Telefoonnummer"
                                                    placeholder="0486 32 12 45"
                                                    value=""/>
                                    </div>

                                    <div className="flex w-full text-gray-700 tracking-wide mb-6 ">
                                        <div className="flex w-full items-center">

                                            <input type="checkbox" value={false}
                                                   checked={false}/>
                                            <div className="text-gray-700 mr-1 ml-2 text-sm">Ja, je kunt me berichten sturen over promoties, aanbiedingen en klantonderzoeken.</div>
                                        </div>
                                    </div>

                                    <div className="text-gray-700 text-sm tracking-wide mb-6">
                                        <div className="mb-2 flex items-center">
                                            <input type="checkbox" name="trade-vehicle-chb" value={false}
                                                   checked={false}/>
                                            <div className="mr-1 ml-2 text-sm">Ik heb een wagen die ik eventueel wens te ruilen.</div>
                                        </div>
                                        <div style={{marginLeft: 22}}>
                                            Wij nemen alle wagens over. <br/> Het overnamebedrag zal in mindering gebracht worden.<br/>
                                            <span className="underline cursor-pointer">De inruilwaarde vrijblijvend laten berekenen.</span>
                                        </div>
                                    </div>


                                    <div className="flex justify-end w-full px-0 md:px-3 mt-8">
                                        <ButtonWithIcon text="Volgende"
                                                        icon="fad fa-chevron-right"
                                                        iconPosition="right"
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="w-full md:w-1/3 border border-gray-100 shadow p-5 ml-0 md:ml-5 mt-5 md:mt-0 mb-5 md:mb-0 flex justify-between flex-col overflow-hidden">
                        <VehicleBlock vehicle={preview_vehicle}
                                      vehicleDetailsUrl=""
                                      loading={false}
                                      step={step}
                                      contactMail="inof@autralis.com"
                                      reservation_days={7}
                                      deposit={200}
                                      context={preview_context}
                                      paymentLogos={DefaultPaymentLogos}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VehicleReservation_Preview;
