import React from 'react';
import {map} from "lodash";


const SegmentsTopBar = ({segments, currentSegment,  onClick}) => {
    if (segments && segments.length > 0) {

        return (
            <div className="grid grid-cols-4 gap-4">
                {map(segments, (segment) => {
                    if (segment.count > 0) {
                        return (
                            <div onClick={() => onClick(segment)} className={`${(currentSegment && currentSegment.key === segment.key) ? 'bg-blue-200' : ''}`}>
                                {segment.name} : {segment.count}
                            </div>
                        )
                    }
                })}
            </div>
        );
    }
    return null;
};

export default SegmentsTopBar;
