import React from 'react';

const ContactMap = ({street, number, postal_code, place}) => {
    return (
        <div className="mapouter z-40 relative" style={{height: 400}}>
            <div className="gmap_canvas" style={{height: 400}}>
                <iframe width="100%"
                        height="400"
                        title="Adres"
                        id="gmap_canvas"
                        src={`https://maps.google.com/maps?q=${street}%20${number}%20${postal_code}%20${place}&t=&z=16&ie=UTF8&iwloc=&output=embed`}
                        frameBorder="0"
                        scrolling="no"
                        marginHeight="0"
                        marginWidth="0" />
            </div>
        </div>
    );
};

ContactMap.defaultProps = {
    street: 'Hundelgemsesteenweg',
    number: "575",
    postal_code: '9620',
    place: 'Merelbeke'
};

ContactMap.human = {
    "nl": "",
    "fr": "",
    "en": "",
};

ContactMap.preview = ContactMap;

export default ContactMap;
