import React from "react";
import {DefaultPrimaryAction, DefaultSecondaryAction, DefaultText, DefaultTitle} from "../../../core/constants";
import {MarkupText} from "../../../core";
import {navigateAction} from "../../../core/utils";

const Banner_Black = ({title, text, primary_action, secondary_action, context}) => {
    return (
        <div className="flex items-center justify-center py-5 md:py-12 bg-black">
            <div className="container relative z-10">
                <div className="flex items-center justify-center">
                    <div className="">
                        <div className="text-center items-center justify-center flex flex-col">
                            <div className="space-y-4">
                                <h2 className="text-2xl md:text-4xl font-bold uppercase text-white uppercase">{title}</h2>
                                <div className="text-sm md:text-lg md:text-2xl"><MarkupText text={text} context={context}/></div>
                            </div>

                            {(primary_action.id || secondary_action.id) &&
                            <div className={`flex self-center items-center mt-10 ${(primary_action.id && secondary_action.id) ? "justify-between" : "justify-center"} `}>

                                {primary_action.id &&
                                <div className="flex flex-row bg-white text-black hover:text-gray-800 rounded-none text-center text-sm font-bold cursor-pointer outline-none"
                                     onClick={() => navigateAction(context, primary_action)}>
                                    <div className="flex flex-row justify-between w-full">
                                        <div className='py-3 px-4'> {primary_action.title}</div>
                                    </div>
                                </div>}

                                {secondary_action.id &&
                                <div className="flex flex-row bg-white text-black hover:text-gray-800 rounded-none text-center text-sm font-bold ml-3 cursor-pointer outline-none"
                                     onClick={() => navigateAction(context, secondary_action)}>
                                    <div className="flex flex-row justify-between w-full">
                                        <div className='py-3 px-4'>{secondary_action.title}</div>
                                    </div>
                                </div>}
                            </div>}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

Banner_Black.defaultProps = {
    title: DefaultTitle,
    text: DefaultText,
    primary_action: DefaultPrimaryAction,
    secondary_action: DefaultSecondaryAction,
};

Banner_Black.human = {
    "nl": "Banner black",
    "fr": "",
    "en": "",
};

Banner_Black.preview = Banner_Black;

export default Banner_Black;
