import {useState, useEffect, useContext} from 'react';
import {map, orderBy, values} from 'lodash';
import fetch from 'cross-fetch'
import {saveField} from "../../utils";
import {setLastAction} from "../../data/actions";
import cogoToast from "cogo-toast";
import {DispatchContext, StateContext} from "../../App";
import {useMutation} from "graphql-hooks";
import {PublishMutation, SiteUpdateMutation} from "../../data/graphql";


export function UseBuilderActions(language) {
    const state = useContext(StateContext);
    const dispatch = useContext(DispatchContext);
    const currentState = state.history[state.history.length - 1];
    const [saveMutation, {loading: saveLoading}] = useMutation(SiteUpdateMutation);
    const [publishMutation, {loading: publishLoading}] = useMutation(PublishMutation);

    const updateWebsite = () => {
        const pagesData = map(values(currentState.pages), (page) => {
            return {
                uuid: page.id,
                url: page.url,
                meta: map(page.meta, (meta, index) => {
                    return {
                        uuid: meta.id,
                        language: meta.language,
                        title: meta.title,
                        description: meta.description,
                    }
                }),
                blocks: map(page.blocks, (block, index) => {
                    return {
                        uuid: block.id,
                        contentType: block.contentType,
                        variant: block.componentName,
                        order: index,
                        content: map(block.content, (content) => {
                            return {
                                uuid: content.id,
                                culture: content.culture,
                                fields: map(content.fields, (field) => {
                                    return {
                                        name: field.name,
                                        value: saveField(field),
                                    }
                                })
                            }
                        })
                    }
                })
            }
        });

        saveMutation({
            variables: {
                data: {
                    pages: pagesData
                }
            }
        }).then(res => {
            const {ok} = res.data.siteUpdate;
            if (ok) {
                setLastAction(dispatch);
                cogoToast.success('Layout saved!');
            }
        }).catch(error => {
            cogoToast.error('Error saving layout!');
        });
    };

    const publishWebsite = () => {
        publishMutation().then((result) => {
            const {publish} = result.data;
            if (publish.ok) {
                setLastAction(dispatch);
                cogoToast.success('Website published!');
            } else {
                cogoToast.error('Error publishing website!');
            }
        })
    };

    return {
        updateWebsite,
        publishWebsite,
        loading: saveLoading || publishLoading,
    };
}


export default UseBuilderActions;
