import React from 'react';
import {map} from "lodash";

const locations = [
    {title: "Showroom"},
    {title: "Workplace"},
]

const ContactLocationOpeningHours_Preview = () => {
    return (
        <div className="flex flex-col md:flex-row bg-primary relative">
            {map(locations, (item, i) => {
                return (
                    <div key={i} className={`w-full md:w-1/2 flex items-center justify-center text-center py-10 md:py-30 px-5 md:px-16 border-r border-gray-100`}>
                        <div className="text-primary" style={{width: 500}}>
                            <i className="far fa-city text-6xl text-white"/>
                            <div className="mt-8 mb-4 text-center text-2xl capitalize text-white">{item.title}</div>
                            <div className="flex flex-row items-center justify-start text-md mb-2 text-white">
                                <div className="text-left" style={{width: 70}}>ma-vr</div>
                                <div className="text-left">9u00-12u00 en 13u30-18u30</div>
                            </div>
                            <div className="flex flex-row items-center justify-start text-md mb-2 text-white">
                                <div className="text-left" style={{width: 70}}>za</div>
                                <div className="text-left">9u00-12u00 en 13u30-17u00</div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    );
};

export default ContactLocationOpeningHours_Preview;
