import React from 'react';
import {VehicleListAndFilters} from "../../../core";
import {navigatePage} from "../../../core/utils";
import Vehicles_Preview from "./components/Vehicles_Preview";

const Vehicles = ({custom_card_image, custom_card_action, context}) => {
    return (
        <VehicleListAndFilters context={context}
                               custom_card_image={custom_card_image}
                               custom_card_action={custom_card_action}
                               onVehicleClick={(vehicle) => {
                                   navigatePage(context, context.urls.vehicleDetail, `reference=${vehicle.reference}`);
                               }}
        />
    );
};

Vehicles.human = {
    "nl": "De lijst met alle voertuigen.",
    "fr": "",
    "en": "The list with all vehicles.",
};

Vehicles.preview = () => {
    return <Vehicles_Preview />
};

export default Vehicles;
