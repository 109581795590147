import React, {useContext} from 'react';
import {DispatchContext} from "../../../App";
import {copyContentHandler, languageActiveChangeHandler} from "../../../data/actions";
import {languagesTranslate} from "../../../data/constants";
import Toggle from "react-toggle";


const Languages = ({data}) => {
    const dispatch = useContext(DispatchContext);

    const handleChangeLanguage = (code, active) => {
        // modifyChanges(code);

        if (active) {
            // copy all content from default to new language
            copyContentHandler(dispatch, "nl", code)
        }
        languageActiveChangeHandler(dispatch, code, active)
    };

    if(!data) {
        return null;
    }

    return <div className="builder-w-full">
        {data.map((language) => {
            return (
                <div key={language.code} className="builder-flex builder-h-8">
                    <div className="builder-flex builder-flex-col builder-flex-1 builder-justify-center">
                        {(languagesTranslate[language.code]) ? languagesTranslate[language.code] : language.code}
                    </div>
                    <div className="builder-flex builder-flex-col  builder-flex-1 builder-justify-center builder-items-end">
                        <Toggle
                            defaultChecked={language.available}
                            aria-label='No label tag'
                            onChange={() => handleChangeLanguage(language.code, !language.available)}/>
                    </div>
                </div>
            )
        })}
    </div>

};

export default Languages;
