import React, {useState, useEffect} from "react";
import Sticky from "react-stickynode";
import {Fade} from "react-reveal";
import {ContactTypes, DefaultContactType, DefaultImage, DefaultLinks, DefaultOpeningHoursRef, DefaultPrimaryAction, DefaultText} from "../../../core/constants";
import {Image, MarkupText} from "../../../core";
import {getIcon, navigateAction, navigatePage} from "../../../core/utils";
import ContactOpeningHoursLabelV2 from "../../../core/components/common/ContactOpeningHoursLabelV2";
import HeaderThreeCol_Preview from "./components/HeaderThreeCol_Preview";

const HeaderThreeColV2 = ({logo, links, primary_action, opening_hours, contact_types, closed_label, context}) => {
    const [hamburgerOpen, setHamburgerOpen] = useState(false);
    const [currentUrl, setCurrentUrl] = useState("/");
    const [activeLink, setActiveLink] = useState(null);

    useEffect(() => {
        setCurrentUrl(window.location.pathname);
    }, []);

    const renderRootLink = (link, subLinks) => {
        return (
            <div className="px-1 md:px-3 flex hover:underline text-md relative mb-3 md:mb-0" onMouseEnter={() => setActiveLink(link.id)} onMouseLeave={() => setActiveLink(null)}>
                <div className="cursor-pointer" onClick={() => navigateAction(context, link)}>{link.title}</div>

                {subLinks.length > 0 && activeLink === link.id &&
                <div className="absolute border border-black px-6 pt-6 pb-5 z-40 text-white hidden md:flex flex-col"
                     style={{top: 20, minWidth: 200, width: "max-content", backgroundColor: "rgba(0, 0, 0, 0.8"}}>
                    {subLinks.map((subLink, i) => {
                        return (
                            <div className="mb-2 hover:text-gray-400 cursor-pointer" onClick={() => navigateAction(context, subLink)}>
                                {subLink.title}
                            </div>
                        )
                    })}
                </div>}
            </div>
        )
    };

    const renderRootLinks = (subs = true) => {
        if (!links || links === "") return;
        const rootLinks = links.filter(link => link.level === 0);
        return rootLinks.map(rootLink => {
            const subLinks = (subs) ? links.filter(link => link.level === 1 && link.parent === rootLink.id) : []
            return renderRootLink(rootLink, subLinks)
        })
    };

    const isHome = currentUrl === '/';

    const renderReviews = () => {
        return <div className="flex flex-1 relative" style={{height: 30}}>
            <a href="https://www.google.com/search?ei=OefMXs1wh5WwB4P7utgN&q=garage%20de%20mey%20maldegem&oq=garage+de+mey+maldegem&gs_lcp=CgZwc3ktYWIQAzICCAAyAggAMgIIADICCAAyAggAMgIIADIGCAAQFhAeMgYIABAWEB4yBggAEBYQHlD1kgFYm5kBYPyaAWgAcAB4AIABTYgBmgSSAQE5mAEAoAEBqgEHZ3dzLXdpeg&sclient=psy-ab&ved=2ahUKEwj7_bKjodHpAhUI26QKHdamC-0QvS4wAHoECAsQIg&uact=5&npsic=0&rflfq=1&rlha=0&rllag=51199876,3455033,531&tbm=lcl&rldimm=3736415914806796926&lqi=ChZnYXJhZ2UgZGUgbWV5IG1hbGRlZ2VtWicKDWdhcmFnZSBkZSBtZXkiFmdhcmFnZSBkZSBtZXkgbWFsZGVnZW0&phdesc=hK1XgutMu6g&rldoc=1&tbs=lrf:!1m4!1u3!2m2!3m1!1e1!1m4!1u2!2m2!2m1!1e1!2m1!1e2!2m1!1e3!3sIAE,lf:1,lf_ui:10&rlst=f#rlfi=hd:;si:3736415914806796926,l,ChZnYXJhZ2UgZGUgbWV5IG1hbGRlZ2VtWicKDWdhcmFnZSBkZSBtZXkiFmdhcmFnZSBkZSBtZXkgbWFsZGVnZW0,y,hK1XgutMu6g;mv:[[51.2051952,3.4560524999999997],[51.1945573,3.4540148999999998]];tbs:lrf:!1m4!1u3!2m2!3m1!1e1!1m4!1u2!2m2!2m1!1e1!2m1!1e2!2m1!1e3!3sIAE,lf:1,lf_ui:10" target="_blank" id="google-review" className="flex flex-1 self-start justify-center md:justify-start text-white text-xs absolute left-0 right-0" style={{top: 8}}>
                <div className="flex mr-1">
                    <i className="fas fa-star"/>
                    <i className="fas fa-star"/>
                    <i className="fas fa-star"/>
                    <i className="fas fa-star"/>
                    <i className="fas fa-star"/>
                </div>
                <span className="font-bold">4.7/5</span>
                <div className="flex text-gray-300 ml-2"> Google <span className='ml-1 hidden md:block'> reviews</span></div>
            </a>
            <a href="https://www.autoscout24.be/nl/verkopers/garage-de-mey-vw-audi/reviews" target="_blank" id="as-review" className="flex flex-1 self-start justify-center md:justify-start text-white text-xs absolute left-0 right-0" style={{top: 30}}>
                <div className="flex mr-1">
                    <i className="fas fa-star"/>
                    <i className="fas fa-star"/>
                    <i className="fas fa-star"/>
                    <i className="fas fa-star"/>
                    <i className="fas fa-star"/>
                </div>
                <span className="font-bold">4.8/5</span>
                <div className="flex text-gray-300 ml-2"> Autoscout24 <span className='ml-1 hidden md:block'> reviews</span></div>
            </a>
        </div>
    }

    const phone = contact_types.find(ct => ct.type === ContactTypes.Telephone);


    // const bgColor = (isHome && isMobile) ? "rgba(0, 0, 0, 1)" : isHome ? "rgba(0, 0, 0, 0.6)" : ""
    const bgColor =  'black'

    return (
        <Sticky top={0} activeClass="w-full" innerZ={100}>

            <div className="flex flex-col md:px-0 sticky-header sticky" style={{zIndex: 45, height: 120}}>
                <div className="header-banner flex items-center justify-center bg-gray-900 border border-gray-900 overflow-hidden" >
                    <div className="flex flex-1 flex-col md:flex-row justify-center md:justify-between container text-white ">
                        <div className="flex flex-1 px-0" style={{height: 30}}>{renderReviews()}</div>

                        <div className={`flex items-center ${phone !== undefined ? "justify-center" : "justify-end"} px-0 flex-1 z-20`} style={{height: 30}}>
                            {/*<OpeningsHours />*/}
                            <ContactOpeningHoursLabelV2 data={opening_hours.data} action={opening_hours.action} context={context} />
                        </div>

                        {phone !== undefined && <div className="hidden md:flex flex-1 text-xs md:mt-2 justify-end">
                            <i className={`${getIcon(phone.type)} mr-1`} style={{fontSize: "0.7rem", marginTop: 2}}/>
                            <strong className="text-white"> <MarkupText text={phone.value} itemProp="telephone" context={context}/></strong>
                        </div>}
                    </div>
                </div>
                <div className={`flex items-center justify-center second-header ${!isHome ? "bg-black" : "shadow-none"}`} style={{backgroundColor: bgColor}}>
                    <div className="flex flex-row container justify-between ">
                        <div className="flex self-start pl-5 md:pl-0 cursor-pointer" onClick={() => navigatePage(context, context.urls.home)}>
                            <Image url={(logo) ? logo : undefined} style={{minHeight: 60, maxHeight: 60}} itemProp="logo" />
                        </div>
                        <div className="hidden sm:flex sm:flex-1 sm:justify-between">
                            <div className="flex flex-1 items-center justify-center mx-4 text-white">
                                {links && links.length > 0 && renderRootLinks()}
                            </div>
                            <div className="flex flex-col items-center justify-center">
                                {primary_action.type !== "" &&
                                <div className="flex flex-row bg-primary text-white hover:text-gray-300 rounded-none text-center text-sm font-bold border-2 border-gray-800 cursor-pointer outline-none"
                                     onClick={() => navigateAction(context, primary_action)}>
                                    <div className="flex flex-row justify-between w-full">
                                        <div className='py-3 px-4'> {primary_action.title}</div>
                                        <div className="p-3 icon-wrapper bg-gray-800">
                                            <div className="w-4 h-4">
                                                <i className="fad fa-arrow-right"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>}

                                {/*<AuthButton context={context} />*/}
                            </div>
                        </div>
                        <div className="flex sm:hidden self-center h-full justify-center items-center relative pr-5 md:pr-0">
                            <div id="nav-icon" className={hamburgerOpen ? "open" : ""} onClick={() => setHamburgerOpen(!hamburgerOpen)}>
                                <span/>
                                <span/>
                                <span/>
                            </div>
                            <Fade right when={hamburgerOpen} duration={500}>
                                <div className={`flex flex-col p-6 absolute right-0 z-50 text-white ${!hamburgerOpen && "hidden"}`}
                                     style={{top: 50, minWidth: 280, backgroundColor: "rgba(0,0,0,0.9)"}}>
                                    <React.Fragment>
                                        {renderRootLinks(false)}

                                        {primary_action.type !== "" &&
                                        <div className="mt-10 mb-5">
                                            <div className="flex flex-row bg-primary text-white hover:text-gray-300 rounded-none text-center text-sm font-bold border-2 border-gray-800 cursor-pointer outline-none"
                                                 onClick={() => navigateAction(context, primary_action)}>
                                                <div className="flex flex-row justify-between w-full">
                                                    <div className='py-3 px-4'> {primary_action.title}</div>
                                                    <div className="p-3 icon-wrapper bg-gray-800">
                                                        <div className="w-4 h-4">
                                                            <i className="fad fa-arrow-right"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}

                                        {contact_types.map((contact_type, index) => {
                                            return (
                                                <div key={`${contact_type.type}_${index}`} className={`flex ${(index > 0) ? 'mt-3' : ''}`}>
                                                    <i className={`${getIcon(contact_type.type)} mr-2`}/>
                                                    <strong className="text-center text-white"> <MarkupText text={contact_type.value} context={context}/></strong>
                                                </div>)
                                        })}
                                    </React.Fragment>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        </Sticky>
    )
};

HeaderThreeColV2.defaultProps = {
    logo: DefaultImage,
    links: DefaultLinks,
    primary_action: DefaultPrimaryAction,
    closed_label: DefaultText,
    opening_hours: DefaultOpeningHoursRef,
    contact_types: [
        {...DefaultContactType, type: ContactTypes.Mail},
        {...DefaultContactType, type: ContactTypes.Telephone},
    ],
};

HeaderThreeColV2.human = {
    "nl": "",
    "fr": "",
    "en": "",
};

HeaderThreeColV2.preview = () => { return <HeaderThreeCol_Preview /> };

export default HeaderThreeColV2;
