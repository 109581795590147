import React, {useEffect, useState} from 'react';
import {omit, isEmpty} from "lodash";
import InputField from "../../input/Input";
import ButtonWithIcon from "../../input/ButtonWithIcon";
import {getFromStorage, isPhoneValid, isValidEmail, navigatePage} from "../../../utils";
import LoadingIndicator from "../../indicators/LoadingIndicator";
import {ErrorIndicator} from "../../indicators";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {INITIALIZE_RESERVATION_MUTATION, MUTATION, QUERY, UPDATE_RESERVATION_MUTATION} from "./cms";
import {VEHICLE_QUERY} from "../../../hooks/useVehicleDetails";
import {useTranslation} from "react-i18next";

const User = ({reservationData, nextStep, terms, vehicle, context, reference, vehicleTakeOverUrl, handleTakeOver, paymentLogos, renderUser}) => {
    const [sending, setSending] = useState(false);
    const [error, setError] = useState(undefined);
    const [stepOneData, setStepOneData] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const {executeRecaptcha} = useGoogleReCaptcha();
    const storageData = getFromStorage('reservation');
    const {t} = useTranslation();

    const getReference = () => {
        let ref = reference !== null && reference !== undefined ? reference : null
        if (storageData !== null) {
            ref = storageData.reservationReference
        }
        return ref;
    }

    const reservationReference = getReference();

    useEffect(() => {
        let newData = {};
        if (reservationData.stepOne !== undefined) {
            newData = reservationData.stepOne
        } else {

            if (storageData !== null) {
                if (storageData.vehicleReference === vehicle.reference) {
                    newData = storageData.data.stepOne
                }
            }
        }

        setStepOneData(newData);

    }, []);

    const isValid = () => {
        let isValid = true;
        let formErrors = {};
        setFormErrors({});

        if (stepOneData.firstName === undefined || stepOneData.firstName === "") {
            formErrors['firstName'] = t("Please enter your first name.");
            isValid = false;
        }

        if (stepOneData.lastName === undefined || stepOneData.lastName === "") {
            formErrors['lastName'] = t("Please enter your last name.");
            isValid = false;
        }

        if (stepOneData.email === undefined || stepOneData.email === "") {
            formErrors['email'] = t("Please enter your email address.");
            isValid = false;
        } else {
            if (!isValidEmail(stepOneData.email)) {
                formErrors['email'] = t("Please enter a valid email address.");
                isValid = false;
            }
        }

        if (stepOneData.phone === undefined || stepOneData.phone === "") {
            formErrors['phone'] = t("Please enter your phone number.");
            isValid = false;
        } else {
            if (!isPhoneValid(stepOneData.phone)) {
                formErrors['phone'] = t("Please enter a valid phone number.");
                isValid = false;
            }
        }

        setFormErrors(formErrors);
        return isValid
    };

    const getQueryData = () => {
        return {
            vehicleReference: vehicle.reference,
            firstName: stepOneData.firstName,
            lastName: stepOneData.lastName,
            email: stepOneData.email,
            telephone: stepOneData.phone,
            mailings: stepOneData.sendPromotions ? stepOneData.sendPromotions : false,
            tradeVehicle: stepOneData.tradeVehicle ? stepOneData.tradeVehicle : false
        };
    }

    const initializeReservation = (token) => {
        const queryData = getQueryData();
        const headers = {
            'Captcha': token
        }

        MUTATION(INITIALIZE_RESERVATION_MUTATION, {data: queryData}, (res) => {
            if (res.data.initializeReservation.ok) {
                setSending(false);
                nextStep(stepOneData, res.data.initializeReservation.reservation.reference);
            } else {
                const msg = res.data.updateReservation.reservation === null ? t('Recaptcha error.') : t('The reservation could not be initialized.');
                setError(msg);
                setSending(false);
            }
        }, (error) => {
            setSending(false);
            setError(t('The reservation could not be initialized.'))
        }, headers)
    }

    const updateReservation = (token) => {
        const queryData = getQueryData();

        const headers = {
            'Captcha': token
        }

        MUTATION(UPDATE_RESERVATION_MUTATION, {data: queryData, reference: reservationReference}, (res) => {
            if (res.data.updateReservation.ok) {
                setSending(false);
                nextStep(stepOneData, reservationReference);
            } else {
                const msg = res.data.updateReservation.reservation === null ? t('Recaptcha error.') : t('The reservation could not be updated.');
                setError(msg);
                setSending(false);
            }
        }, (error) => {
            setSending(false);
            setError(t('The reservation could not be updated.'))
        }, headers)
    }

    const onSubmit = async e => {
        e.preventDefault();
        // Go to next step
        if (isValid()) {

            if (!executeRecaptcha) {
                return;
            }
            setSending(true);
            const token = await executeRecaptcha("initializing_reservation");


            QUERY(VEHICLE_QUERY, {reference: vehicle.reference}, (res) => {
                    if (res.data.vehicle.reserved) {
                        setError(t('This vehicles has already been reserved.'));
                        setSending(false);
                    } else {
                        if (reservationReference !== null && reservationReference !== undefined && reservationReference !== "") {
                            // UPDATE
                            updateReservation(token)
                        } else {
                            // INITIALIZE
                            initializeReservation(token)
                        }
                    }
                }, (error) => {
                    setSending(false);
                    setError(t('It could not be checked whether the car has already been reserved.'));
                },
                {
                    'Accept-Language': context.culture,
                })
        }
    };

    const updateData = (data) => {
        setStepOneData(data)
    }

    return renderUser && typeof renderUser === 'function'
        ? renderUser(onSubmit, stepOneData, updateData, sending, error, handleTakeOver)
        : <div className="flex flex-col flex-1 relative h-full justify-between">
            <div className="flex flex-col">
                <div className="text-gray-800 text-2xl mb-4">Uw gegevens</div>
                <div className="text-gray-800 text-base mb-8">Vertel iets meer over jezelf. We hebben deze info nodig om de wagen voor u opzij te kunnen zetten.</div>

                {error && <div className="-mt-3 mb-5"><ErrorIndicator error={error}/></div>}


                <form action="" onSubmit={e => onSubmit(e)} className="flex flex-col mb-5">
                    <div className="flex flex-col md:flex-row mb-6">
                        <InputField containerClass="w-full md:w-1/2 px-0 mr-0 md:mr-4 mb-6 md:mb-0"
                                    label="Voornaam"
                                    placeholder="John"
                                    value={stepOneData.firstName !== undefined ? stepOneData.firstName : ""}
                                    onChange={value => {
                                        if (value) {
                                            setFormErrors(omit(formErrors, ['firstName']));
                                        }
                                        setStepOneData({...stepOneData, firstName: value})
                                    }}
                                    errorText={formErrors['firstName']}/>
                        <InputField containerClass="w-full md:w-1/2 px-0"
                                    label="Naam"
                                    placeholder="Doe"
                                    value={stepOneData.lastName !== undefined ? stepOneData.lastName : ""}
                                    onChange={value => {
                                        if (value) {
                                            setFormErrors(omit(formErrors, ['lastName']));
                                        }
                                        setStepOneData({...stepOneData, lastName: value})
                                    }}
                                    errorText={formErrors['lastName']}/>
                    </div>
                    <div className="flex flex-col md:flex-row mb-6">
                        <InputField containerClass="w-full md:w-1/2 px-0 mr-0 md:mr-4 mb-6 md:mb-0"
                                    type="email"
                                    label="Emailadres"
                                    placeholder="john.doe@gmail.com"
                                    value={stepOneData.email !== undefined ? stepOneData.email : ""}
                                    onChange={value => {
                                        if (value) {
                                            setFormErrors(omit(formErrors, ['email']));
                                        }
                                        setStepOneData({...stepOneData, email: value})
                                    }}
                                    errorText={formErrors['email']}/>
                        <InputField containerClass="w-full md:w-1/2 px-0 "
                                    label="Telefoonnummer"
                                    placeholder="0486 32 12 45"
                                    value={stepOneData.phone !== undefined ? stepOneData.phone : ""}
                                    onChange={value => {
                                        if (value) {
                                            setFormErrors(omit(formErrors, ['phone']));
                                        }
                                        setStepOneData({...stepOneData, phone: value})
                                    }}
                                    errorText={formErrors['phone']}/>
                    </div>

                    <div className="flex w-full text-gray-700 tracking-wide mb-6 ">
                        <div className="flex w-full items-center">

                            <input type="checkbox" value={stepOneData.sendPromotions !== undefined ? stepOneData.sendPromotions : false}
                                   onChange={e => setStepOneData({...stepOneData, sendPromotions: !stepOneData.sendPromotions})}
                                   checked={stepOneData.sendPromotions !== undefined ? stepOneData.sendPromotions : false}/>
                            <div className="text-gray-700 mr-1 ml-2 text-sm">Ja, je kunt me berichten sturen over promoties, aanbiedingen en klantonderzoeken.</div>
                        </div>
                    </div>

                    <div className="text-gray-700 text-sm tracking-wide mb-6">
                        <div className="mb-2 flex items-center">
                            <input type="checkbox" name="trade-vehicle-chb" value={stepOneData.tradeVehicle !== undefined ? stepOneData.tradeVehicle : false}
                                   onChange={e => setStepOneData({...stepOneData, tradeVehicle: e.target.checked})}
                                   checked={stepOneData.tradeVehicle !== undefined ? stepOneData.tradeVehicle : false}/>
                            <div className="mr-1 ml-2 text-sm">Ik heb een wagen die ik eventueel wens te ruilen.</div>
                        </div>
                        <div style={{marginLeft: 22}}>
                            Wij nemen alle wagens over. <br/> Het overnamebedrag zal in mindering gebracht worden.<br/>
                            {/*<span className="underline cursor-pointer" onClick={() => navigatePage(context, vehicleTakeOverUrl, null, null, true)}>De inruilwaarde vrijblijvend laten berekenen.</span>*/}
                            {/*<span className="underline cursor-pointer" onClick={handleTakeOver}>De inruilwaarde vrijblijvend laten berekenen.</span>*/}
                            <div>{handleTakeOver()}</div>
                        </div>
                    </div>


                    <div className="flex justify-end w-full px-0 md:px-3 mt-8">
                        <ButtonWithIcon onClick={(e) => onSubmit(e)}
                                        text={sending ? <div className="flex items-center"><LoadingIndicator extraSmall/> <span className="ml-2">Bezig...</span></div> : "Volgende"}
                                        icon={sending ? null : "fad fa-chevron-right"}
                                        iconPosition="right"
                        />

                    </div>
                </form>
            </div>


            <div className="text-gray-700 text-xs mt-10 md:mt-5" style={{bottom: 0}}>{terms}</div>

            {/*{paymentLogos.length > 0 && <div className="bg-white -ml-5 -mr-5 -mb-5 mt-10 p-5">*/}
            {/*    <div className="text-gray-800 text-2xl mb-5">Betalingsmogelijkheden</div>*/}
            {/*    <div className="flex">*/}
            {/*        {paymentLogos.map((paymentLogo, i) => {*/}
            {/*            return <img key={i} src={paymentLogo} alt="Payment" style={{maxHeight: 45}} className="mr-2"/>*/}
            {/*        })}*/}
            {/*    </div>*/}
            {/*</div>}*/}

        </div>;
};

export default User;
