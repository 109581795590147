import React, {useEffect, useState} from 'react';
import {DefaultThumbnailWidth} from "../../../constants";
import {currentDateTime, getLastActivity, updateLastActivity, getUrlParams, removeFromStorage, getUrlParamValueFromWindow, navigateAction, navigatePage} from "../../../utils";
import {useVehicleDetails} from "../../../hooks/useVehicleDetails";
import ReservationPage from "./ReservationPage";
import NoResultsIndicator from "../../indicators/NoResultsIndicator";
import LoadingIndicator from "../../indicators/LoadingIndicator";
import ErrorIndicator from "../../indicators/ErrorIndicator";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {GET_RESERVATION_SETTINGS_QUERY, QUERY} from "./cms";
import {useTranslation} from "react-i18next";


const VehicleReservationWizard = ({context, vehicleDetailsUrl, take_over_action, payment_logos, opening_hours_action, vehicleTakeOverUrl, contactMail, vehicleReference: vehicleReferenceFromPops = undefined, reservationReference: reservationReferenceFromPops = undefined, renderHeader, renderUser, renderVehicle, renderPayment, renderConfirmation, renderContent, wrapperClassName}) => {
    const [gettingSettings, setGettingSettings] = useState(false);
    const [gettingSettingsError, setGettingSettingsError] = useState(null);
    const [deposit, setDeposit] = useState(null);
    const [reservationDays, setReservationDays] = useState(null);
    const reservationReference = (reservationReferenceFromPops) ? reservationReferenceFromPops : (context && !context.builder) ? getUrlParamValueFromWindow('reference') : "";
    const vehicleReference = (vehicleReferenceFromPops) ? vehicleReferenceFromPops : (context && !context.builder) ? getUrlParamValueFromWindow('vehicle') : "";
    const {vehicle, loading, error} = useVehicleDetails(vehicleReference, context.culture);
    const {executeRecaptcha} = useGoogleReCaptcha();
    const {t} = useTranslation();

    const saveActivity = () => {
        let lastActivity = getLastActivity();
        if (lastActivity !== undefined) {
            lastActivity.type = 'Reservation form';
            lastActivity.stop = currentDateTime();
            lastActivity.data = {
                vehicle: vehicle,
                financeSlidersChanged: false
            };
            updateLastActivity(lastActivity)
        }
    };


    const fetchReservationSettings = () => {
        setGettingSettings(true);

        fetch('/graphql', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                query: GET_RESERVATION_SETTINGS_QUERY,
            })
        }).then(resp => resp.json()).then(result => {
            const {reservationAmount, reservationDays} = result.data.reservationSettings;
            setDeposit(reservationAmount);
            setReservationDays(reservationDays);

            setGettingSettings(false);
            setGettingSettingsError(null);

        }).catch(err => {
            setGettingSettings(false);
            setGettingSettingsError(t('The settings could not be fetched.'));
        });

    };

    useEffect(() => {
        fetchReservationSettings();
        // Save activity
        if (vehicleReference !== "") {

            saveActivity();
        }
    }, []);


    if (vehicleReference === "")
        return <div className="my-12"><NoResultsIndicator message={t('No result. No reference is provided.')}/></div>;


    const isLoading = loading;
    const hasError = error !== null || gettingSettingsError !== null;
    let errorMessage = t('Something went wrong');
    if (error !== null) errorMessage = t('The vehicle could not be fetched.');
    if (gettingSettingsError !== null) errorMessage = t('The reservation settings could not be fetched.');



    return (
        <div className="flex justify-center w-full min-h-screen">
            {isLoading && <div className="flex w-full justify-center"><LoadingIndicator/></div>}
            {!isLoading && hasError && <div className="container my-12"><ErrorIndicator error={errorMessage}/></div>}
            {!isLoading && !hasError && <div className="flex w-full justify-center">
                <ReservationPage vehicle={vehicle}
                                 reservationReference={reservationReference}
                                 wrapperClassName={wrapperClassName}
                                 context={context}
                                 vehicleDetailsUrl={vehicleDetailsUrl}
                                 handleTakeOver={() => {
                                     if(take_over_action){
                                         return <div className="underline cursor-pointer" onClick={() => navigateAction(context, take_over_action)}>
                                             {take_over_action.title}
                                         </div>

                                     }else{
                                         const vehicleTakeOverUrl = context.urls.vehicleTakeOver;
                                         if(vehicleTakeOverUrl){
                                             return <span className="underline cursor-pointer"
                                                          onClick={() => navigatePage(context, vehicleTakeOverUrl, null, null, true)}>
                                                 De inruilwaarde vrijblijvend laten berekenen.
                                             </span>
                                         }
                                     }
                                 }}
                                 deposit={deposit}
                                 reservation_days={reservationDays}
                                 payment_logos={payment_logos}
                                 contactMail={contactMail}
                                 opening_hours_action={opening_hours_action}
                                 renderHeader={renderHeader}
                                 renderUser={renderUser}
                                 renderPayment={renderPayment}
                                 renderConfirmation={renderConfirmation}
                                 renderVehicle={renderVehicle}
                                 renderContent={renderContent}
                />

            </div>}
        </div>
    );
};

VehicleReservationWizard.defaultProps = {};

VehicleReservationWizard.thumbnail = {
    width: DefaultThumbnailWidth,
    height: 400,
};

export default VehicleReservationWizard;
