import React from "react"

const LoadingIndicator = ({color, small, extraSmall}) => {
    let classes = small ? "small-loader" : "loader";
    classes = extraSmall ? "extra-small" : classes;
    return (
        <div className={classes}>
            <div className={`inner one ${color === null ? "border-primary" : ""}`} style={{borderColor: color !== null ? color : ""}}/>
            <div className={`inner two ${color === null ? "border-primary" : ""}`} style={{borderColor: color !== null ? color : ""}}/>
            <div className={`inner three ${color === null ? "border-primary" : ""}`} style={{borderColor: color !== null ? color : ""}}/>
        </div>
    )
};

LoadingIndicator.defaultProps = {
    color: null,
    small: false,
    extraSmall: false
};

export default LoadingIndicator
