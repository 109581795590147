import React from 'react'

const MIN_DRAG = 20

const ActionTypes = {
    SET_TURNTABLE_IMAGES: 'SET_TURNTABLE_IMAGES',
    SET_ACTIVE_IMAGE_INDEX: 'SET_ACTIVE_IMAGE_INDEX',
    SET_DRAG_START_POSITION: 'SET_DRAG_START_POSITION',
    SET_IS_DRAGGING: 'SET_IS_DRAGGING',
    SET_DISTANCE_DRAGGED: 'SET_DISTANCE_DRAGGED',
    UNSUPPORTED_TYPE: 'UNSUPPORTED_TYPE',
}

const initialState = {
    activeImageIndex: 0,
    turntableImages: [],
    dragStartPosition: 0,
    isDragging: false,
    distanceDragged: 0,
    started: false
}

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.SET_TURNTABLE_IMAGES:
            return {...state, turntableImages: action.payload}
        case ActionTypes.SET_ACTIVE_IMAGE_INDEX:
            return {
                ...state,
                activeImageIndex: action.payload,
            }
        case ActionTypes.SET_DRAG_START_POSITION:
            return {
                ...state,
                dragStartPosition: action.payload,
                started: true,
            }
        case ActionTypes.SET_IS_DRAGGING:
            return {
                ...state,
                isDragging: action.payload,
                started: true,
            }
        case ActionTypes.SET_DISTANCE_DRAGGED:
            return {
                ...state,
                distanceDragged: action.payload,
                started: true,
            }
        default:
            throw new Error(ActionTypes.UNSUPPORTED_TYPE)
    }
}

const useTurntable = (images) => {
    const [
        {
            turntableImages,
            activeImageIndex,
            dragStartPosition,
            isDragging,
            distanceDragged,
            started,
        },
        dispatch,
    ] = React.useReducer(reducer, initialState);

    React.useEffect(() => {
        dispatch({type: ActionTypes.SET_TURNTABLE_IMAGES, payload: images})
        dispatch({type: ActionTypes.SET_ACTIVE_IMAGE_INDEX, payload: 0})
    }, [])

    const handleRightSwipe = () => {
        if (activeImageIndex === turntableImages.length - 1) {
            dispatch({type: ActionTypes.SET_ACTIVE_IMAGE_INDEX, payload: 0})
        } else {
            dispatch({
                type: ActionTypes.SET_ACTIVE_IMAGE_INDEX,
                payload: activeImageIndex + 1,
            })
        }
    }

    const handleLeftSwipe = () => {
        if (activeImageIndex === 0) {
            dispatch({
                type: ActionTypes.SET_ACTIVE_IMAGE_INDEX,
                payload: images.length - 1,
            })
        } else {
            dispatch({
                type: ActionTypes.SET_ACTIVE_IMAGE_INDEX,
                payload: activeImageIndex - 1,
            })
        }
    }

    const updateImage = (dir) => {
        const isLeftSwipe = dir < 0

        if (isLeftSwipe) {
            handleLeftSwipe()
        } else {
            handleRightSwipe()
        }
    }

    const handleTouchStart = (event) => {
        const startOfTouchMove = Math.round(event.touches[0].clientX)
        dispatch({
            type: ActionTypes.SET_DRAG_START_POSITION,
            payload: startOfTouchMove,
        })
    }

    const resetDragMeasurement = (event) => {
        dispatch({
            type: ActionTypes.SET_IS_DRAGGING,
            payload: true,
        })

        if ('clientX' in event) {
            dispatch({
                type: ActionTypes.SET_DRAG_START_POSITION,
                payload: event.clientX,
            })
        } else {
            dispatch({
                type: ActionTypes.SET_DRAG_START_POSITION,
                payload: event.touches[0].clientX,
            })
        }
    }

    const handleTouchMove = (event) => {
        dispatch({
            type: ActionTypes.SET_IS_DRAGGING,
            payload: true,
        })
        dispatch({
            type: ActionTypes.SET_DISTANCE_DRAGGED,
            payload: dragStartPosition - Math.round(event.touches[0].clientX),
        })

        if (distanceDragged > MIN_DRAG) {
            resetDragMeasurement(event)
            updateImage(1)
        } else if (distanceDragged < -MIN_DRAG) {
            resetDragMeasurement(event)
            updateImage(-1)
        }
    }

    const handleTouchEnd = () =>
        dispatch({
            type: ActionTypes.SET_IS_DRAGGING,
            payload: false,
        })

    const handleStarted = () =>
        dispatch({
            type: ActionTypes.SET_STARTED,
            payload: true,
        })

    const handleMouseDown = (event) => {
        dispatch({
            type: ActionTypes.SET_IS_DRAGGING,
            payload: true,
        })
        dispatch({
            type: ActionTypes.SET_DRAG_START_POSITION,
            payload: event.clientX,
        })
    }

    const handleMouseMove = (event) => {
        if (isDragging) {
            dispatch({
                type: ActionTypes.SET_DISTANCE_DRAGGED,
                payload: dragStartPosition - Math.round(event.clientX),
            })

            if (distanceDragged > MIN_DRAG) {
                resetDragMeasurement(event)
                updateImage(1)
            } else if (distanceDragged < -MIN_DRAG) {
                resetDragMeasurement(event)
                updateImage(-1)
            }
        }
    }

    const eventHandlers = {
        onTouchEnd: handleTouchEnd,
        onTouchStart: handleTouchStart,
        onTouchMove: handleTouchMove,
        onMouseUp: handleTouchEnd,
        onMouseMove: handleMouseMove,
        onMouseDown: handleMouseDown,
        onDragStart: (event) => {
            event.preventDefault();
        },
    }

    return {
        eventHandlers,
        activeImageIndex,
        started,
    }
}

export {useTurntable}
