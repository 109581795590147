import React from 'react';
import VehicleSearchBgVideoCore from "./components/VehicleSearchBgVideoCore";

const VehicleSearchBgMultiVideo = ({logo, title, videos, context}) => {
    return <VehicleSearchBgVideoCore logo={logo}
                                     title={title}
                                     videos={videos}
                                     context={context}/>
};

VehicleSearchBgMultiVideo.human = {
    "nl": "",
    "fr": "",
    "en": "",
};

VehicleSearchBgMultiVideo.preview = VehicleSearchBgMultiVideo;

export default VehicleSearchBgMultiVideo;
