import React from 'react';
import {DefaultSocialMedia, SocialMediaTypes} from "../../../core/constants";
import {map} from 'lodash';
import {getSocialIcon} from "../../../core/utils";


const ContactSocial = ({media}) => {
    const components = [];
    map(media, (m, i) => {
        components.push(
            <a key={`${m.type}_${i}`} href={m.url} target="_blank" rel="noopener noreferrer" className={`${(i>(media.length -1)) ? 'mr-2' : null}`} style={{minWidth: 48, minHeight: 48}}>
                <i className={`${getSocialIcon(m.type)} text-white text-3xl ml-4`} />
            </a>)
    });

    return (
        <div className="flex items-center justify-center bg-primary relative z-50">
            <div className="container text-center py-4">
                <React.Fragment>
                    {components.map((c, i) => {
                        return c
                    })}
                </React.Fragment>
            </div>
        </div>
    );
};

ContactSocial.defaultProps = {
    media: [
        {...DefaultSocialMedia, type: SocialMediaTypes.Facebook},
        {...DefaultSocialMedia, type: SocialMediaTypes.Twitter},
        {...DefaultSocialMedia, type: SocialMediaTypes.Instagram},
        {...DefaultSocialMedia, type: SocialMediaTypes.LinkedIn},
    ]
};

ContactSocial.human = {
    "nl": "",
    "fr": "",
    "en": "",
};

ContactSocial.preview = ContactSocial;

export default ContactSocial;
