import React from "react";
import {BannerTwoColTextImageDefaultProps} from "../../../core/constants";
import {MarkupText, Image} from "../../../core";
import {navigateAction} from "../../../core/utils";
import BannerTwoColTextImage_ImageRight_Preview from "./components/BannerTwoColTextImage_ImageRight_Preview";

const BannerTwoColTextImage_ImageRight = ({title, text, image, primary_action, secondary_action, context}) => {
    return <div className="flex items-center justify-center pb-8">
        <div className="flex flex-col md:flex-row container">

            <div className="flex md:hidden w-full md:w-1/2">
                <Image url={(image) ? image : undefined} style={{width: '100%', height: '100%', objectFit: 'contain'}}/>
            </div>

            <div className="flex flex-col w-full md:w-1/2">
                <div className="flex items-start justify-center flex-col h-full p-8">
                    <h2 className="second-banner-title text-left text-3xl font-bold mb-2">{title}</h2>

                    <div className="text-sm line-height-base text-left second-banner-description list-none" style={{minHeight: 160}}>
                        <MarkupText text={text} context={context}/>
                    </div>


                    <div className={`mt-10 flex self-center items-center ${(primary_action.type !== "" && secondary_action.type !== "") ? "justify-between" : "justify-center"} `}>
                        {primary_action.type !== "" &&
                        <div className="flex flex-row bg-primary  text-white hover:text-gray-300 rounded-none text-center text-sm font-bold cursor-pointer outline-none"
                             onClick={() => navigateAction(context, primary_action)}>
                            <div className="flex flex-row justify-between w-full">
                                <div className='py-3 px-4'> {primary_action.title}</div>
                            </div>
                        </div>}

                        {secondary_action.type !== "" &&
                        <div className="flex flex-row bg-primary  text-white hover:text-gray-300 rounded-none text-center text-sm font-bold ml-3 cursor-pointer outline-none"
                             onClick={() => navigateAction(context, secondary_action)}>
                            <div className="flex flex-row justify-between w-full">
                                <div className='py-3 px-4'>{secondary_action.title}</div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
            <div className="hidden md:flex w-full md:w-1/2">
                <div className="-mx-5 md:mx-0 h-full">
                    <Image url={(image) ? image : undefined} style={{width: '100%', height: '100%', objectFit: 'contain'}}/>
                </div>
            </div>

        </div>
    </div>
};

BannerTwoColTextImage_ImageRight.defaultProps = BannerTwoColTextImageDefaultProps;

BannerTwoColTextImage_ImageRight.human = {
    "nl": "Banner 2 kolommen, afbeelding rechts",
    "fr": "",
    "en": "",
};

BannerTwoColTextImage_ImageRight.preview = () => { return <BannerTwoColTextImage_ImageRight_Preview /> };

export default BannerTwoColTextImage_ImageRight;
