import React, {useContext} from 'react';
import {Route, Redirect} from "react-router-dom";
import {routes} from "../../data/constants";
import {StateContext} from "../../App";
import {LoadingIndicator} from "./index";


const PrivateRoute = ({children, ...rest}) => {
    const state = useContext(StateContext);

    if (state.session === undefined) {
        return (<LoadingIndicator />)
    }

    return (
        <Route {...rest}
               render={({location}) =>
                   (state.session.authenticated) ? (
                       children
                   ) : (
                       <Redirect
                           to={{
                               pathname: routes.LOGIN,
                               state: {from: location}
                           }}
                       />
                   )
               }
        />
    );
};

export default PrivateRoute;

