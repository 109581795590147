import React from 'react';
import {Image} from "../../../../core";

const BannerTwoColTextImage_ImageLeft_Preview = () => {
    return (
        <div className="flex items-center justify-center pb-8 overflow-x-hidden">
            <div className="container flex flex-col md:flex-row">
                <div className="flex flex-col w-full md:w-1/2">
                    <div className="-mx-5 md:mx-0 h-full">
                        <Image url={require('../../../../core/assets/previews/snow-tire.jpg')} style={{width: '100%', height: '100%', objectFit: 'contain'}}/>
                    </div>
                </div>
                <div className="flex flex-col w-full md:w-1/2">
                    <div className="flex items-start justify-center flex-col h-full p-8">
                        <h2 className="second-banner-title text-left text-3xl font-bold mb-2">Lorem ipsum</h2>

                        <div className="text-sm line-height-base text-left second-banner-description list-none" style={{minHeight: 160}}>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        </div>

                        <div className={`mt-10 flex self-center items-center justify-between`}>
                            <div className="flex flex-row bg-primary  text-white hover:text-gray-300 rounded-none text-center text-sm font-bold cursor-pointer outline-none">
                                <div className="flex flex-row justify-between w-full">
                                    <div className='py-3 px-4'>Button</div>
                                </div>
                            </div>

                            <div className="flex flex-row bg-primary  text-white hover:text-gray-300 rounded-none text-center text-sm font-bold ml-3 cursor-pointer outline-none">
                                <div className="flex flex-row justify-between w-full">
                                    <div className='py-3 px-4'>Button</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default BannerTwoColTextImage_ImageLeft_Preview;
