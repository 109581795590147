import React from 'react';
import {useLegalContent} from "../../hooks/useLegalContent";
import {ErrorIndicator, LoadingIndicator} from "../indicators";
import {b64DecodeUnicode} from "../../utils";

const LegalContent = ({context, renderContent}) => {
    const culture = (context && context.culture) ? context.culture : 'nl';
    const {content, loading, error} = useLegalContent(culture);


    try {
        return renderContent && typeof renderContent === 'function'
            ? renderContent(loading, content)
            : <div className="flex items-center justify-center py-5 md:py-10">
                <div className="container px-5 xl:px-0">
                    {loading && <div className="flex w-full items-center justify-center"><LoadingIndicator/></div>}
                    {!loading && content === null && <div className="flex w-full items-center justify-center"><ErrorIndicator error="Something went wrong."/></div>}
                    {!loading && content !== null && <div dangerouslySetInnerHTML={{__html: b64DecodeUnicode(content)}}/>}
                </div>
            </div>;
    } catch (e) {
        return null;
    }
};

export default LegalContent;
