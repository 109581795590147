import React, {useState} from 'react';
import {DefaultText, DefaultTitle} from "../../../core/constants";
import Modal from 'react-modal';
import {MarkupText} from "../../../core";

const customStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        zIndex: 500,
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        // minWidth: (window.innerWidth > 800) ? 600 : 0,
        minWidth: 600,
        maxWidth: 400,
        maxHeight: 400

    }
};

const ModalPopup = ({title, text, context}) => {
    const [modalVisible, setModalVisible] = useState(true);
    // for mobile filter popup

    if (!context.builder) {
        Modal.setAppElement('#___gatsby');
    }

    if (context.builder) {
        return (
            <div style={{minHeight: 50}}>
                Dit is een modal die opent by het laden van de pagina !
            </div>
        )
    }

    return (
        <Modal
            isOpen={modalVisible}
            style={customStyles}
        >
            <div className="bg-white flex-col" style={{minWidth: 300}} >
                <div className="flex justify-end">
                    <div className="flex bg-red-400 sticky top-0 rounded-full flex items-center justify-center z-50"
                         onClick={() => setModalVisible(false)}
                         style={{width: 40, height: 40}}><i
                        className="far fa-times"/></div>
                </div>
                <div className="font-bold text-xl mb-8">{title}</div>
                <MarkupText text={text} context={context}/>
            </div>
        </Modal>
    );
};

ModalPopup.defaultProps = {
    title: DefaultTitle,
    text: DefaultText
};

ModalPopup.human = {
    "nl": "",
    "fr": "",
    "en": "",
};

ModalPopup.preview = ModalPopup;

export default ModalPopup;
