import React, {useContext} from 'react';
import {useMutation} from "graphql-hooks";
import {resetSessionToDefault} from "../../data/session";
import {DispatchContext} from "../../App";
import {setSession} from "../../data/actions";

const SIGNOUT = `
    mutation Logout {
        logout {
           ok          
        }
    }`;


const SignOutButton = () => {
    const [signoutMutation] = useMutation(SIGNOUT);
    const dispatch = useContext(DispatchContext);

    const handleSignOut = () => {

        signoutMutation().then((result) => {
            setSession(dispatch, resetSessionToDefault())
        })
    };

    return (
        <div className="builder-flex builder-px-4 builder-py-2 builder-ml-2 builder-text-gray-700 builder-border builder-border-transparent builder-text-sm builder-items-center builder-cursor-pointer hover:builder-text-gray-900 builder-text-gray-600 builder-font-thin"
             onClick={handleSignOut}>
            <div >Logout</div>
            <i className="fal fa-sign-out builder-ml-2  builder-text-xs"/>
        </div>
    );
};

export default SignOutButton;
