import {ACTIONS} from "./reducers";
import {v4} from "uuid";

export const pageCreationHandler = (dispatch, url, copyId) => {
    dispatch({
        type: ACTIONS.PAGE_ADD,
        payload: {
            id: v4(),
            url,
            copyId,
        }
    });
};

export const pageDeleteHandler = (dispatch, id) => {
    dispatch({
        type: ACTIONS.PAGE_DELETE,
        payload: {
            id,
        }
    });
};

export const pageMetaChange = (dispatch, pageId, culture, meta) => {
    dispatch({
        type: ACTIONS.PAGE_META_CHANGE,
        payload: {
            pageId,
            culture,
            meta,
        }
    });
};

export const dataSetHandler = (dispatch, data) => {
    dispatch({
        type: ACTIONS.DATA_SET,
        payload: {
            pages: data.pages,
            languages: data.languages,
            contentTypeDefinitions: data.contentTypeDefinitions,
            dealerships: data.dealerships,
            dealershipLocationTypes: data.dealershipLocationTypes,

        }
    });
};

export const pageBlockCreationHandler = (dispatch, pageId, index, component) => {
    dispatch({
        type: ACTIONS.PAGE_BLOCK_ADD,
        payload: {
            pageId,
            blockId: v4(),
            component,
            index,
        }
    });
};


export const pageBlockOrderChangedHandler = (dispatch, pageId, removedIndex, addedIndex, block) => {
    dispatch({
        type: ACTIONS.PAGE_BLOCK_ORDER,
        payload: {
            pageId,
            removedIndex,
            addedIndex,
            block,
        }
    });
};

export const pageBlockComponentChangedHandler = (dispatch, pageId, blockId, componentName) => {
    dispatch({
        type: ACTIONS.PAGE_BLOCK_COMPONENT_CHANGE,
        payload: {
            pageId,
            blockId,
            componentName
        }
    });
};

export const pageBlockContentChange = (dispatch, pageId, blockId, field, value, culture) => {
    dispatch({
        type: ACTIONS.PAGE_BLOCK_CONTENT_CHANGE,
        payload: {
            pageId,
            blockId,
            field,
            value,
            culture,
        }
    });
};

export const pageBlockContentCopy = (dispatch, pageId, blockId, copyBlock, culture) => {
    dispatch({
        type: ACTIONS.PAGE_BLOCK_CONTENT_COPY,
        payload: {
            pageId,
            blockId,
            copyBlock,
            culture,
        }
    });
};

export const pageBlockDeleteHandler = (dispatch, pageId, blockId) => {
    dispatch({
        type: ACTIONS.PAGE_BLOCK_DELETE,
        payload: {
            pageId,
            blockId,
        }
    });
};

export const undoHandler = (dispatch) => {
    dispatch({
        type: ACTIONS.UNDO,
        payload: {}
    });
};

export const pageSelectHandler = (dispatch, id) => {
    dispatch({
        type: ACTIONS.PAGE_SELECT,
        payload: {
            id
        }
    });
};

export const blockSelectHandler = (dispatch, id) => {
    dispatch({
        type: ACTIONS.BLOCK_SELECT,
        payload: {
            id
        }
    });
};

export const languageActiveChangeHandler = (dispatch, languageCode, available) => {
    dispatch({
        type: ACTIONS.LANGUAGES_CHANGE,
        payload: {
            languageCode,
            available,
        }
    });
};

export const copyContentHandler = (dispatch, from, to) => {
    dispatch({
        type: ACTIONS.CONTENT_COPY,
        payload: {
            from,
            to,
        }
    });
}

export const setSession = (dispatch, session) => {
    dispatch({
        type: ACTIONS.SESSION_SET,
        payload: {
            session,
        }
    });
}

export const setLastAction = (dispatch) => {
    dispatch({
        type: ACTIONS.LAST_ACTION_SET,
        payload: {}
    });
}
