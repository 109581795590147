import React, {useEffect, useState} from "react"
import {useIsMobile} from "../hooks/useIsMobile";
import {useSwipeable} from "react-swipeable";
import useWindowDimensions from "../hooks/useWindowDimensions";
import {LoadingIndicator} from "../index";
import {useTranslation} from "react-i18next";
import * as url from "url";

// Component to show images with (custom) arrows, NOTHING ELSE !!
// images in array like [{"uri": images.uri}]
const Carousel = ({images, reference, onImageLoad, fetchingImages, renderArrows = null, style, onAfterChange = null, onMouseEnter, onVehicleClick, swipeEnabled, defaultImage, showArrowsAlways, imagesFetched}) => {
    const [index, setIndex] = useState(0);
    const [imgWidth, setImgWidth] = useState(0);
    const {t} = useTranslation();
    const {isMobile} = useIsMobile();
    const {width} = useWindowDimensions();
    const defaultVehicleImage = defaultImage ? defaultImage : require("../assets/vehicle-default.jpg");
    const isTabletOrMobile = width <= 1024;

    const handlePrevClick = (e) => {
        e.stopPropagation();
        const newIndex = index > 0 ? index - 1 : images.length - 1;
        setIndex(newIndex);
    };

    const handleNextClick = (e) => {
        e.stopPropagation();
        const newIndex = index + 1 < images.length ? index + 1 : 0;
        setIndex(newIndex);
    };

    useEffect(() => {
        if (onAfterChange) {
            onAfterChange(index)
        }
    }, [index])

    useEffect(() => {
        if(swipeEnabled){
            if (isMobile && fetchingImages !== undefined) {
                if (!fetchingImages && images.length > 1) {
                    setIndex(index + 1);
                }
            }
        }
    }, [fetchingImages, images])

    const handlersOptions = swipeEnabled ? {
        onSwipedLeft: () => {
            onMouseEnter && typeof onMouseEnter === "function" && onMouseEnter();
            if(index < images.length - 1){
                const newIndex = index + 1 < images.length ? index + 1 : 0;
                setIndex(newIndex);
            }
        },
        onSwipedRight: () => {
            onMouseEnter && typeof onMouseEnter === "function" && onMouseEnter();
            if(index > 0){
                const newIndex = index > 0 ? index - 1 : images.length - 1;
                setIndex(newIndex);
            }
        }
    } : {};


    const handlers = useSwipeable({
        ...handlersOptions,
        onTap: () => onVehicleClick(),
        preventDefaultTouchmoveEvent: true,
        trackMouse: false
    });

    // const onTouch = () => {
    //     onMouseEnter && typeof onMouseEnter === "function" && onMouseEnter();
    // }

    const onLoad = (e) => {
        setImgWidth(e.target.clientWidth);
        if(onImageLoad && typeof onImageLoad === 'function'){
            onImageLoad();
        }
    }

    const renderImagesSlider = () => {
        let left = index * imgWidth;
        return <>
            {images && images.length < 1 && <img src={defaultVehicleImage}
                                                 alt={`vehicle_${reference}`}
                                                 style={{maxWidth: "100%", width: 300, pointerEvents: 'none', objectFit: 'cover', height: "100%"}}
                                                 onLoad={onImageLoad}
            />}

            {images && images.length >= 1 && <div className="images-container flex flex-nowrap relative items-start h-full" style={{left: -left}}>
                {images.filter(img => img).map(img => {
                    return <img src={img.uri}
                                key={img.uri}
                                alt={`vehicle_${reference}`}
                                style={{maxWidth: "100%", width: 300, pointerEvents: 'none', objectFit: 'cover', height: "100%"}}
                                onLoad={onLoad}
                    />
                })}
            </div>}
        </>
    }

    const renderMobile = () => {
        return <div className="relative">
            {fetchingImages && <div className="flex flex-col justify-center items-center absolute top-0 right-0 left-0 bottom-0 z-10" style={{background: "rgba(0, 0, 0, 0.6)"}}>
                <div style={{height: 75}}><LoadingIndicator color="white"/></div>
                <div className="paragraph-small mt-2 text-white">{t('Loading images')}</div>
            </div>}
            <div style={{...style, height: 260}} className="carousel-image relative" {...handlers}>
                {renderImagesSlider()}

                {/*<img src={images ? (images[index] ? images[index].uri : defaultVehicleImage) : defaultVehicleImage}*/}
                {/*     alt={`vehicle_${reference}`}*/}
                {/*     style={{maxWidth: "100%", width: 300, pointerEvents: 'none'}}*/}
                {/*     onLoad={onImageLoad}*/}
                {/*/>*/}
            </div>
            {(images.length > 1 || showArrowsAlways) &&
            <React.Fragment>
                {(renderArrows) ? renderArrows(handlePrevClick, handleNextClick) :
                    <div className="flex absolute bottom-0 right-0 mr-1 mb-1 text-white">
                        <div className="flex items-center justify-center cursor-pointer z-20 mr-1" style={{width: 30, height: 30, background: 'rgba(0,0,0,0.6)'}} onClick={handlePrevClick}>
                            <i className="fad fa-chevron-left text-xs "/>
                        </div>

                        <div className="flex items-center justify-center cursor-pointer z-20" style={{width: 30, height: 30, background: 'rgba(0,0,0,0.6)'}} onClick={handleNextClick}>
                            <i className="fad fa-chevron-right text-xs "/>
                        </div>
                    </div>
                }

            </React.Fragment>}
        </div>
    }

    if(isMobile){
        return renderMobile();
    }

    return (
        <div className="relative" onMouseEnter={onMouseEnter}>

            <div className="relative">
                <div style={{...style, height: 260}} className="carousel-image">
                    {/*<img src={images ? (images[index] ? images[index].uri : defaultVehicleImage) : defaultVehicleImage}*/}
                    {/*     alt={`vehicle_${reference}`}*/}
                    {/*     style={{maxWidth: "100%", width: 300}}*/}
                    {/*     onLoad={onImageLoad}*/}
                    {/*/>*/}

                    {renderImagesSlider()}
                </div>

                {images.length > 1 &&
                <React.Fragment>
                    {(renderArrows) ? renderArrows(handlePrevClick, handleNextClick) :
                        <div className="flex absolute bottom-0 right-0 mr-1 mb-1 text-white">
                            <div className="flex items-center justify-center cursor-pointer z-20 mr-1" style={{width: 30, height: 30, background: 'rgba(0,0,0,0.6)'}} onClick={handlePrevClick}>
                                <i className="fad fa-chevron-left text-xs "/>
                            </div>

                            <div className="flex items-center justify-center cursor-pointer z-20" style={{width: 30, height: 30, background: 'rgba(0,0,0,0.6)'}} onClick={handleNextClick}>
                                <i className="fad fa-chevron-right text-xs "/>
                            </div>
                        </div>
                    }

                </React.Fragment>}

            </div>
        </div>
    )
};

Carousel.defaultProps = {
    fetchingImages: undefined,  // bugfix not al pictures are lazy loaded; otherwise useffect for index is not corrct
    images: [],
    swipeEnabled: true,
    style: {minHeight: 240, overflow: "hidden"},
    defaultImage: undefined,
    showArrowsAlways: false,
    imagesFetched: false
};

export default Carousel
