import React from 'react';
import {navigateAction} from "../../../utils";

const Confirmation = ({terms, deposit, reservationDays, context, openingHoursAction, renderConfirmation}) => {
    return renderConfirmation && typeof renderConfirmation === 'function'
        ? renderConfirmation(openingHoursAction, deposit, reservationDays)
        : <div className="flex flex-1 w-full h-full flex-col items-center mb-5 text-center">
            <div className="text-4xl text-center text-gray-800 mb-8">Reservatie voltooid.</div>
            <div className="flex items-center justify-center border-2 border-green-200 rounded-full p-8 mb-8 self-center"><i className="fad fa-check text-4xl text-green-600"/></div>

            <div className="text-lg text-center text-gray-800 tracking-wide mb-5">Controleer je mailbox, een bevestigingsemail is verzonden.</div>
            <div className="text-md text-gray-700 mb-2 tracking-wide text-center">Wij hebben je betaling goed ontvangen. De komende {reservationDays} dagen staat de wagen opzij voor u! We ontmoeten je graag terug in onze showroom.</div>


            {openingHoursAction.id !== undefined && <div className="flex w-full items-center justify-center mt-5">
                <div
                    className="w-full md:w-1/3 flex justify-center bg-white border-2 border-primary text-primary hover:bg-primary hover:border-2 hover:border-primary hover:text-white rounded-none w-full text-center text-md font-medium py-3 px-3 rounded-none cursor-pointer button-primary"
                    onClick={() => navigateAction(context, openingHoursAction)}>
                    Naar de openingsuren
                </div>
            </div>}


            <div className="text-gray-700 text-xs mt-10 md:mt-12 tracking-wide" style={{bottom: 0}}>{terms}</div>
        </div>
};


export default Confirmation;
