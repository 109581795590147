import React, {useEffect, useState} from 'react';
import User from "./User";
import Payment from "./Payment";
import Confirmation from "./Confirmation";
import Fade from 'react-reveal/Fade';
import {getFromStorage, getVehicleDetailUrl, removeFromStorage, saveToStorage} from "../../../utils";
import LoadingIndicator from "../../indicators/LoadingIndicator";

import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {CHECK_RESERVATION_QUERY, COMPLETE_PAYMENT_MUTATION, MUTATION, QUERY} from "./cms";
import VehicleBlock from "./VehicleBlock";
import WizardBlock from "./WizardBlock";
import {useTranslation} from "react-i18next";

const terms = "*Wij verkopen uw persoonlijke gegevens niet aan derden. U kunt zich met 1 klik afmelden. Lees meer over hoe we uw gegevens verzamelen, gebruiken en delen in ons beleid voor gegevensgebruik. Ontdek alles over cookies en andere vergelijkbare technologieën in ons cookiebeleid.";


const ReservationPage = ({vehicle, reservationReference, context, vehicleDetailsUrl, deposit, reservation_days, payment_logos, opening_hours_action, handleTakeOver, vehicleTakeOverUrl, contactMail, renderHeader, renderUser, renderVehicle, renderPayment, renderConfirmation, renderContent, wrapperClassName}) => {
    const [step, setStep] = useState(1);
    const [error, setError] = useState(undefined)
    const [reservationData, setReservationData] = useState({});
    const [checkingOrder, setCheckingOrder] = useState(false);
    const {executeRecaptcha} = useGoogleReCaptcha();
    const {t} = useTranslation();


    const setReservation = (newReservationData = null) => {
        let newData = {}
        let newStep = 1;
        const storageData = getFromStorage('reservation');
        if (newReservationData !== null) {
            if (newReservationData.vehicleReference === vehicle.reference) {
                newStep = storageData !== null ? storageData.step : 1;
                newData = {
                    stepOne: {
                        email: newReservationData.email,
                        firstName: newReservationData.firstName,
                        lastName: newReservationData.lastName,
                        phone: newReservationData.telephone,
                        sendPromotions: newReservationData.mailings
                    }
                }
            } else {
                removeFromStorage('reservation')
            }
        } else {
            if (storageData !== null) {
                if (storageData.vehicleReference === vehicle.reference) {
                    newStep = storageData.step;
                    newData = storageData.data;
                } else {
                    removeFromStorage('reservation')
                }

            }
        }

        setReservationData(newData);
        setStep(newStep);
    }

    const completeReservation = async () => {
        if (!executeRecaptcha) {
            return;
        }
        setCheckingOrder(true);
        const token = await executeRecaptcha("completing_payment");

        const headers = {
            'Captcha': token
        }

        MUTATION(COMPLETE_PAYMENT_MUTATION, {reference: reservationReference, vehicleUrl: getVehicleDetailUrl(vehicle, context)}, (res) => {
            if (res.data.completeReservationPayment.ok && (res.data.completeReservationPayment.reservation.status === 'completed' || res.data.completeReservationPayment.reservation.status === 'pending') && res.data.completeReservationPayment.reservation.paymentStatus === 'paid') {
                setStep(3);
                removeFromStorage('reservation');
            } else {
                const msg = res.data.completeReservationPayment.reservation === null ? t('Recaptcha error.') : t('The reservation has not been completed successfully.');
                setError(msg);
                setReservation();
            }

            setCheckingOrder(false);
        }, (error) => {
            setError(t('The reservation has not been completed successfully.'));
            setReservation();
        }, headers)
    }

    const checkReservation = async () => {
        if (!executeRecaptcha) {
            return;
        }
        setCheckingOrder(true);
        const token = await executeRecaptcha("checking_reservation");

        const headers = {
            'Captcha': token
        }

        QUERY(CHECK_RESERVATION_QUERY, {reference: reservationReference}, (res) => {
            if (res.data.reservation === null) {
                setError(t('Recaptcha error.'));
                setReservation(res.data.reservation);
                setCheckingOrder(false);
            } else {
                if (res.data.reservation.paymentStatus === undefined || res.data.reservation.paymentStatus === null) {
                    setError(t('The Mollie is not available.'));
                    setReservation(res.data.reservation);
                    setCheckingOrder(false);

                } else {
                    if (res.data.reservation.paymentStatus !== 'paid') {
                        setError(t('Payment has failed.'));
                        setReservation(res.data.reservation);
                        setCheckingOrder(false);
                    } else if (res.data.reservation.status === 'pending' && res.data.reservation.paymentStatus === 'paid') {
                        completeReservation();
                    } else if (res.data.reservation.status === 'completed' && res.data.reservation.paymentStatus === 'paid') {
                        setStep(3);
                        removeFromStorage('reservation');
                        setCheckingOrder(false);
                    } else {
                        setError(undefined);
                        setReservation(res.data.reservation);
                        setCheckingOrder(false);
                    }
                }
            }
        }, (error) => {
            setError(t('The reservation could not be checked.'))
            setCheckingOrder(false);
        }, headers)
    }

    useEffect(() => {
        if (reservationReference !== null && reservationReference !== undefined) {
            checkReservation();
        } else {
            setReservation()
        }
    }, []);

    const onNextStep = (stepOneData, reservationReference) => {
        setError(undefined);
        setStep(step + 1);
        const data = {...reservationData, stepOne: {...stepOneData}};
        setReservationData(data);
        saveToStorage('reservation', {data: data, step: step + 1, reservationReference: reservationReference, vehicleReference: vehicle.reference});
    }

    const onBack = () => {
        let storageData = getFromStorage('reservation');
        setStep(1);
        saveToStorage('reservation', {...storageData, step: 1});
    }

    return (
        <div className={`flex flex-col overflow-x-hidden min-h-screen relative w-full ${wrapperClassName}`}>
            <WizardBlock step={step} renderHeader={renderHeader}/>

            {renderContent && typeof renderContent === 'function'
                ? renderContent(vehicle, renderUser, renderVehicle, renderPayment, renderConfirmation, step, checkingOrder, onNextStep, onBack, reservationData, error, deposit, reservation_days, handleTakeOver)
                : <div className="flex flex-col items-center justify-center">

                    <div className="flex justify-center w-full">
                        <div className="container text-gray-900 text-3xl font-bold px-5 md:px-0 mb-0 md:mb-5">Reserveren</div>
                    </div>

                    <div className="flex flex-col-reverse md:flex-row container px-5 md:px-0">

                        <div className="relative w-full md:w-2/3 -mr-5 md:mr-0 bg-gray-100 p-5 mr-5 border border-gray-100 shadow">
                            {checkingOrder && <LoadingIndicator/>}
                            {!checkingOrder && <Fade spy={step} duration={10}>
                                <div className="flex h-full">
                                    {step === 1 && <User reservationData={reservationData}
                                                         vehicle={vehicle}
                                                         context={context}
                                                         reference={reservationReference}
                                                         /*vehicleTakeOverUrl={vehicleTakeOverUrl}*/
                                                         handleTakeOver={handleTakeOver}
                                                         terms={terms}
                                                         renderUser={renderUser}
                                                         paymentLogos={payment_logos}
                                                         nextStep={(stepOneData, reservationReference) => onNextStep(stepOneData, reservationReference)}/>
                                    }
                                    {step === 2 && <Payment reservationData={reservationData}
                                                            terms={terms}
                                                            context={context}
                                                            error={error}
                                                            reservationDays={reservation_days}
                                                            paymentLogos={payment_logos}
                                                            renderPayment={renderPayment}
                                                            onBack={() => onBack()}
                                                            vehicle={vehicle}
                                    />
                                    }
                                    {step === 3 && <Confirmation terms={terms}
                                                                 reservationDays={reservation_days}
                                                                 deposit={deposit}
                                                                 context={context}
                                                                 contactMail={contactMail}
                                                                 renderConfirmation={renderConfirmation}
                                                                 openingHoursAction={opening_hours_action}/>}
                                </div>
                            </Fade>}

                        </div>

                        {renderVehicle && typeof renderVehicle === 'function'
                            ? renderVehicle(vehicle, step, checkingOrder, deposit, reservation_days)
                            : <div className="w-full md:w-1/3 border border-gray-100 shadow p-5 ml-0 md:ml-5 mt-5 md:mt-0 mb-5 md:mb-0 flex justify-between flex-col overflow-hidden">
                                <VehicleBlock vehicle={vehicle}
                                              vehicleDetailsUrl={vehicleDetailsUrl}
                                              loading={checkingOrder}
                                              step={step}
                                              contactMail={contactMail}
                                              reservation_days={reservation_days}
                                              reservationReference={reservationReference}
                                              deposit={deposit}
                                              context={context}
                                              paymentLogos={payment_logos}
                                              reservation={reservationData}

                                />
                            </div>}


                    </div>
                </div>}
        </div>
    );
};

ReservationPage.defaultProps = {
    wrapperClassName: 'bg-white'
}

export default ReservationPage;
