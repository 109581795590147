import {useState, useEffect} from 'react';

export function useIsMobile() {
    const [isMobile, setIsMobile] = useState(false);
    const [isTablet, setIsTablet] = useState(false);

    const resize = () => {
        setIsMobile(window.innerWidth <= 768)
        setIsTablet(window.innerWidth > 768 && window.innerWidth <= 1199);
    }

    useEffect(() => {
        setIsMobile(window.innerWidth <= 768);
        setIsTablet(window.innerWidth > 768 && window.innerWidth <= 1199);
        window.addEventListener("resize", resize);

        return () => {
            window.removeEventListener("resize", resize);
        }
    });


    return {
        isMobile,
        isTablet
    };
}
