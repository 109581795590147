import React, {useState} from 'react';
import {keys, last, map, orderBy} from "lodash";
import {v4} from "uuid";
import DaysWithDescription from "../components/DaysWithDescription";
import TimePickersV2 from "../components/TimePickersV2";
import {DAYS} from "../../../dealer-theme/core/constants";
import {getDayTranslation} from "../../../utils";

const tabs = {
    hours: 'hours',
    closingDays: "closingDays"
}

const daysOptions = orderBy(map(keys(DAYS), (key, i) => {
    return {
        key: key,
        value: getDayTranslation (key),
        order: i
    }
}), 'order');


const HoursAndDays = ({type, days, onAddDays, onRemoveDays, onChangeDays, hours, onChangeHours, onAddHours, onRemoveHour}) => {
    const [activeTab, setActiveTab] = useState(tabs.hours);

    const handleRemoveHour = (id) => {
        onRemoveHour(hours.filter(hour => hour.uuid !== id))
    }

    const onHoursChanged = (start, stop, day, id) => {
        const selectedHour = hours.find(hour => hour.uuid === id);
        let newHours;
        if (selectedHour) {
            newHours = [
                ...hours.filter(hour => hour.uuid !== id),
                {
                    ...selectedHour,
                    start,
                    stop
                }
            ]
        } else {
            newHours = [
                ...hours,
                {
                    uuid: id,
                    start,
                    stop,
                    day,
                    type
                }]
        }

        onChangeHours(newHours)
    }

    const handleAddHours = day => {
        const newHours = [
            ...hours,
            {
                uuid: v4(),
                start: '',
                stop: '',
                day,
                type
            }]
        onAddHours(newHours)
    }

    const renderHours = () => {
        return <div className="builder-flex builder-flex-1 builder-w-full builder-justify-between">
            {daysOptions.map((day, i) => {
                const selectedHours = hours.filter(hour => hour.day === day.key)
                const orderedHours = [...orderBy(selectedHours.filter(hour => hour.start && hour.stop), ['start']), ...selectedHours.filter(hour => !hour.start && !hour.stop)]

                return <div key={`${day.value}-${i}`} className={`builder-flex builder-flex-col builder-w-full ${i === daysOptions.length - 1 ? "" : "builder-mr-4"}`}>
                    <div className="builder-p-2 builder-bg-gray-200">{day.value}</div>
                    <div className='builder-mt-2'>
                        {orderedHours.length > 0 && orderedHours.map((item, i) => {
                            return <div key={i} className={`builder-mb-4 ${i > 0 ? "builder-border-t builder-border-gray-300 builder-pt-4" : ""}`}>
                                {i > 0 && <div className="builder-flex builder-justify-center builder-w-full builder-text-xs builder-text-red-500 builder-mb-1 builder-cursor-pointer hover:text-red-800" onClick={() => handleRemoveHour(item.uuid)}>Remove</div>}
                                <TimePickersV2 onChange={(start, stop) => onHoursChanged(start, stop, item.day, item.uuid)}
                                               dayKey={item.day}
                                               start={item.start}
                                               stop={item.stop}/>
                            </div>
                        })}
                        {selectedHours.length < 1 && <div className="builder-mb-4">
                            <TimePickersV2 onChange={(start, stop) => onHoursChanged(start, stop, day.key, v4())}
                                           dayKey={day.key}
                                           start=""
                                           stop=""/>

                        </div>}

                        {selectedHours.length > 0 && <div className="builder-flex builder-items-center builder-w-full builder-justify-center">
                            <div className='builder-flex builder-items-center builder-justify-center builder-bg-white builder-border builder-border-gray-300 builder-bg-gray-200 builder-w-6 builder-h-6 builder-cursor-pointer hover:builder-bg-gray-300'
                                 onClick={() => handleAddHours(day.key)}>
                                <i className="fal fa-plus text-gray-600"/>
                            </div>
                        </div>}

                    </div>
                </div>
            })}
        </div>
    }

    const onDaysChanged = (start, stop, description, id) => {
        const selectedDay = days.find(day => day.uuid === id);
        let newDays;
        if (selectedDay) {
            newDays = [
                ...days.filter(day => day.uuid !== id),
                {
                    ...selectedDay,
                    start,
                    stop,
                    description
                }
            ]
        } else {
            newDays = [
                ...days,
                {
                    uuid: id,
                    start,
                    stop,
                    description,
                    type
                }]
        }

        onChangeDays(newDays)
    }

    const handleAddDays = () => {
        const newDays = [
            ...days,
            {
                uuid: v4(),
                start: '',
                stop: '',
                type
            }]
        onAddDays(newDays)
    }

    const handleRemoveDays = id => {
        onRemoveDays(days.filter(day => day.uuid !== id))
    }

    const renderDays = () => {
        const lastAddedDay = last(days)
        return <div className="builder-flex builder-flex-col builder-w-full">
            <div className="builder-flex builder-flex-1 builder-ml-2 builder-w-full">
                <div className="builder-flex builder-flex-wrap">
                    {days.length > 0 && days.map((item, i) => {
                        return <div key={i} className={`builder-mr-4 builder-py-2 ${i > 0 ? "builder-border-l builder-border-gray-300 builder-pl-4" : ""}`}>
                            <DaysWithDescription item={item} onChanged={(start, stop, description) => onDaysChanged(start, stop, description, item.uuid)} />
                            {i > 0 && <div className="builder-flex builder-justify-center builder-w-full builder-text-xs builder-text-red-500 builder-mt-1 builder-cursor-pointer hover:text-red-800" onClick={() => handleRemoveDays(item.uuid)}>Remove</div>}
                        </div>
                    })}
                    {days.length < 1 && <DaysWithDescription onChanged={(start, stop, description) => onDaysChanged(start, stop, description, v4())} />}
                </div>

                {lastAddedDay && lastAddedDay.start && lastAddedDay.stop && lastAddedDay.description && <div className="builder-flex builder-flex-col builder-items-center builder-justify-center">
                    {days.length > 0 && <div className="builder-flex builder-items-center">
                        <div className='builder-flex builder-items-center builder-justify-center builder-bg-white builder-border builder-border-gray-300 builder-bg-gray-200 builder-w-6 builder-h-6 builder-cursor-pointer hover:builder-bg-gray-300'
                             onClick={() => handleAddDays()}>
                            <i className="fal fa-plus text-gray-600"/>
                        </div>
                    </div>}
                    {days.length === 1 && <div className='builder-flex builder-mt-2 builder-items-center builder-justify-center builder-bg-white builder-border builder-border-gray-300 builder-bg-gray-200 builder-w-6 builder-h-6 builder-cursor-pointer hover:builder-bg-gray-300'
                                               onClick={() => handleRemoveDays(days[0].uuid)}>
                        <i className="fal fa-eraser text-gray-600"/>
                    </div>}
                </div>}



            </div>
        </div>
    }

    const activeTabClass = "builder-text-blue-600 builder-border-blue-300"
    return <div className="builder-flex builder-flex-col builder-w-full">
        <div className="builder-flex builder-w-full builder-py-4">
            <div className="builder-flex builder-flex-col builder-flex-1 builder-w-full builder-justify-between">
                <ul className="builder-flex builder-border-b builder-mb-4">
                    <li className="-mb-px builder-mr-1">
                        <div
                            className={`builder-bg-white builder-cursor-pointer builder-border-l builder-border-t builder-border-r builder-rounded-t builder-inline-block builder-py-2 builder-px-4 ${activeTab === tabs.hours ? activeTabClass : "builder-border-transparent builder-text-gray-600 hover:builder-text-blue-600"}`}
                            onClick={() => setActiveTab(tabs.hours)}>Openings hours
                        </div>
                    </li>
                    <li>
                        <div
                            className={`builder-bg-white builder-cursor-pointer builder-border-l builder-border-t builder-border-r builder-rounded-t builder-inline-block builder-py-2 builder-px-4 ${activeTab === tabs.closingDays ? activeTabClass : "builder-border-transparent builder-text-gray-600 builder-hover:text-blue-600"}`}
                            onClick={() => setActiveTab(tabs.closingDays)}>Closing days
                        </div>
                    </li>
                </ul>
                <div>
                    {activeTab === tabs.hours && renderHours()}
                    {activeTab === tabs.closingDays && renderDays()}
                </div>
            </div>
        </div>
    </div>
};

export default HoursAndDays;
