import React from 'react';
import DateTimePicker from "react-datetime-picker";

const DateTimePickerComp = ({onChange, value, label, error}) => {
    return (
        <div className={`builder-flex builder-items-center builder-justify-center ${error && 'picker-error'}`}>
            {label && <span className="builder-text-gray-700 builder-text-xs builder-mr-2">{label}</span>}
            <DateTimePicker
                clearIcon={null}
                calendarIcon={null}
                // clockIcon={null}
                // className={["builder-w-full"]}
                // format="H:m"
                format="y-MM-dd H:m"
                // locale='nl-NL'
                // showLeadingZeros={true}
                // renderSecondHand={false}
                onChange={onChange}
                value={value ? new Date(value) : undefined}
            />
        </div>
    );
};

// H, HH, h, hh, m, mm, s, ss, a

DateTimePickerComp.defaultProps = {
    value: undefined,
    label: undefined
}

export default DateTimePickerComp;
