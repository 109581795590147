import React from 'react';
import {VehicleReservationWizard} from "../../../core";
import {DefaultDeposit, DefaultPaymentLogos, DefaultPrimaryAction, DefaultReservationDays} from "../../../core/constants";
import VehicleReservation_Preview from "./components/VehicleReservation_Preview";

const VehicleReservation = ({context, reservation_days, deposit, payment_logos, opening_hours_action, vehicleReference = undefined, reservationReference = undefined, contact_mail}) => {
    return (
        <VehicleReservationWizard context={context}
                                  vehicleDetailsUrl={context.urls.vehicleDetail}
                                  vehicleTakeOverUrl={context.urls.vehicleTakeOver}
                                  deposit={deposit}
                                  reservation_days={reservation_days}
                                  payment_logos={payment_logos}
                                  contactMail={contact_mail}
                                  opening_hours_action={opening_hours_action}
                                  vehicleReference={vehicleReference}
                                  reservationReference={reservationReference}/>
    );
};

VehicleReservation.defaultProps = {
    reservation_days: DefaultReservationDays,
    payment_logos: DefaultPaymentLogos,
    deposit: DefaultDeposit,
    opening_hours_action: DefaultPrimaryAction,
    contact_mail: "",
};


VehicleReservation.human = {
    "nl": "De reservation pagina",
    "fr": "",
    "en": "Vehicle reservation page",
};

VehicleReservation.preview = ()  => { return <VehicleReservation_Preview />};

export default VehicleReservation;
