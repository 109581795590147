import React from 'react';
import TextInput from "../../builder/editors/components/TextInput";
import TextArea from "../../builder/editors/components/TextArea";

const Seo = ({settings, isSuperUser, onChange}) => {
    return <div className="builder-bg-white builder-border builder-border-gray-200 builder-ring-1 builder-ring-black builder-ring-opacity-5 builder-mb-4">
        <div className="builder-py-4 builder-border-b builder-border-gray-200 builder-bg-gray-100">
            <div className="builder-text-lg builder-leading-6 builder-font-medium builder-text-gray-900 builder-px-4">SEO information</div>
        </div>

        <div className="builder-flex builder-p-4">
            <div className="builder-flex builder-w-full builder-flex-col">
                <div className="builder-flex builder-flex-col builder-w-1/2 builder-mb-4">
                    <label className="builder-text-sm builder-text-gray-500 builder-mb-1">META title</label>
                    <TextInput value={settings.META_TITLE}
                               placeHolder='META title'
                               onChanged={value => onChange({...settings, META_TITLE: value})}/>
                </div>
                <div className="builder-flex builder-flex-col builder-w-1/2 builder-mb-4">
                    <label className="builder-text-sm builder-text-gray-500 builder-mb-1 builder-ml-2">META description</label>
                    <TextArea value={settings.META_DESCRIPTION}
                              placeHolder='META description'
                              onChanged={value => onChange({...settings, META_DESCRIPTION: value})}/>
                </div>
                {isSuperUser && <div className="builder-flex builder-flex-col builder-w-1/2">
                    <label className="builder-text-sm builder-text-gray-500 builder-mb-1">Sitemap output</label>
                    <TextInput value={settings.SITEMAP_OUTPUT}
                               placeHolder='Sitemap output'
                               onChanged={value => onChange({...settings, SITEMAP_OUTPUT: value})}/>
                </div>}

            </div>
        </div>
    </div>;
};

export default Seo;
