import React, { useEffect, useState} from 'react';
import Fade from "react-reveal/Fade";
import {filter, includes, map} from "lodash";
import VehicleCard from "../VehicleCard";
import AdvertiseCard from "../AdvertiseCard";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import {getNumberOfColumns} from "../../../utils";


const List = ({vehicles, custom_card_image, custom_card_action, context, onVehicleClick, renderVehicle, totalInvisibleCards, cardClass, renderCarSearchAgentBanner}) => {
    const [customCardIndexes, setCustomCardIndexes] = useState([]);
    const [carSearchAgentBannerIndex, setCarSearchAgentBannerIndex] = useState(null);
    const {width} = useWindowDimensions();
    const carSearchBannerExists = renderCarSearchAgentBanner && typeof renderCarSearchAgentBanner === "function";


    useEffect(() => {
        if (custom_card_image && custom_card_image !== "") {
            if (vehicles.length > 0 && vehicles.length > 10) {
                // const randoms = generateRandoms(Math.round(vehicles.length / 20), 7, vehicles.length);
                const amount = Math.round(vehicles.length / 20) - 1;
                const multiplier = Math.round((vehicles.length - 7) / amount);
                const randoms = [7];
                let i;
                for (i = 0; i < amount; i++) {
                    randoms.push((randoms[randoms.length - 1] + multiplier));
                }
                setCustomCardIndexes(randoms);
            } else {
                setCustomCardIndexes([vehicles.length]);
            }
        }

        if(carSearchBannerExists){
            let nrPerRow = getNumberOfColumns(width) ? getNumberOfColumns(width) : 0;

            let index;
            if(vehicles.length > 12){
                index = vehicles.length - 3*nrPerRow //
            }
            let customCardsBefore = filter(customCardIndexes, ci => ci <= index);
            const totalIndex = index + customCardsBefore.length;
            const nr = nrPerRow - (totalIndex % nrPerRow);
            setCarSearchAgentBannerIndex(totalIndex + nr + 1)
        }

    }, [vehicles]);


    const renderEmptyContainers = () => {
        const vehiclesLength = customCardIndexes.length > 0 ? vehicles.length + customCardIndexes.length : vehicles.length;
        const totalEmptyContainers = totalInvisibleCards - (vehiclesLength % totalInvisibleCards);
        let emptyContainers = [];
        for (let i = 0; i < totalEmptyContainers; i++) {
            emptyContainers.push(i)
        }
        //
        return emptyContainers;
    };

    const getListClassName = () => {
        const numberOfColumns = getNumberOfColumns(width);
        let grid = 'sm:grid-cols-2'
        if(numberOfColumns === 1) grid = 'sm:grid-cols-1 gap-0'
        if(numberOfColumns === 2) grid = 'sm:grid-cols-2 gap-4'
        if(numberOfColumns === 3) grid = 'sm:grid-cols-3 gap-4'
        if(numberOfColumns === 4) grid = 'sm:grid-cols-4 gap-4'
        if(numberOfColumns === 5) grid = 'sm:grid-cols-5 gap-4'
        if(numberOfColumns === 6) grid = 'sm:grid-cols-6 gap-4'
        const gridClassName = context.builder ? "sm:grid-cols-3 gap-4" : grid;
        return `flex flex-row flex-wrap justify-between sm:grid ${gridClassName}`;
    }

    const getAgentBannerClassName = () => {
        const numberOfColumns = getNumberOfColumns(width);
        let agentClass = "sm:col-span-2"
        if(numberOfColumns === 1) agentClass = 'sm:col-span-1'
        if(numberOfColumns === 2) agentClass = 'sm:col-span-2'
        if(numberOfColumns === 3) agentClass = 'sm:col-span-3'
        if(numberOfColumns === 4) agentClass = 'sm:col-span-4'
        if(numberOfColumns === 5) agentClass = 'sm:col-span-5'
        if(numberOfColumns === 6) agentClass = 'sm:col-span-6'
        return context.builder ? 'sm:col-span-3' : agentClass;
    }

    return (
        <div className="w-full px-5 md:px-0">
            {vehicles.length > 0 && <Fade spy={vehicles.length} appear={true} duration={200} big className="w-full">
                <div className={`vehicles-list ${getListClassName()}`}>
                    {map(vehicles, (vehicle, index) => {
                        let customCard = undefined;
                        if (custom_card_image && custom_card_image !== "") {
                            if (includes(customCardIndexes, index + 1)) {
                                customCard = (
                                    <AdvertiseCard action={custom_card_action}
                                                   key={index}
                                                   context={context}
                                                   classNames={`${cardClass} shadow-xl overflow-hidden hover:opacity relative cursor-pointer`}
                                                   styles={{marginBottom: "2%"}}
                                                   img_src={custom_card_image}
                                    />
                                )
                            }
                        }

                        let carSearchAgentBanner = undefined
                        let customIndex = index + 1 + filter(customCardIndexes, cci => index + 1 >= cci).length
                        if(carSearchAgentBannerIndex && carSearchAgentBannerIndex === customIndex){
                            carSearchAgentBanner = renderCarSearchAgentBanner;
                        }

                        return (
                            <React.Fragment>
                                {carSearchAgentBanner && carSearchAgentBanner(getAgentBannerClassName())}
                                {(renderVehicle) ? renderVehicle(vehicle , onVehicleClick) :
                                    <VehicleCard key={vehicle.reference}
                                                 vehicle={vehicle}
                                                 context={context}
                                                 onClick={(vehicle) => {
                                                     onVehicleClick(vehicle)
                                                 }}
                                    />}
                                {customCard && customCard}
                            </React.Fragment>
                        )
                    })}

                    {renderEmptyContainers().map(ec => {
                        return <div key={ec} className={`${cardClass} opacity-0 hidden md:block`}/>
                    })}
                </div>
            </Fade>}
            {vehicles.length < 12 && carSearchBannerExists && renderCarSearchAgentBanner()}
        </div>
    );
};

List.defaultProps = {
    totalInvisibleCards: 4,
    cardClass: 'vehicle-card'
}

export default List;
