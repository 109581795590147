import React from 'react';
import {BeatLoader} from "react-spinners";

const LoadingIndicator = ({classNames}) => {
    return (
        <div className={`builder-w-full builder-flex builder-flex-1 builder-justify-center builder-items-center ${classNames}`}>
            <BeatLoader
                size={30}
                color={'lightgrey'}
                loading={true}
            />
        </div>
    );
};

LoadingIndicator.defaultProps = {
    classNames: "builder-h-screen builder-bg-gray-100"
}

export default LoadingIndicator;
