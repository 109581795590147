import React, {useContext, useEffect, useRef, useState} from 'react';
import {DefaultThumbnailWidth} from "../../../constants";
import {formatPrice, getStickiesOffset} from '../../../utils';
import DataContext from "../../../store/DataContext";
import {filter, head, includes, map, orderBy} from 'lodash';
import {LoadingIndicator, NoResultsIndicator} from "../../indicators";
import Carousel from "../../Carousel";
import {VehicleFavoriteBtn} from "../../../index";
import Sticky from 'react-stickynode'
import {useIsMobile} from "../../../hooks/useIsMobile";
import {useTranslation} from "react-i18next";

const VehiclesCompareQuery = `query Vehicles($filter: VehicleFilterInputType) {
  vehicles(filter: $filter) {
        id
        reference     
        makeKey
        make        
        model        
        modelFamily
        version
        firstRegistrationMonth
        firstRegistrationYear
        mileage
        doors
        seats
        kw
        hp
        engineCc
        co2
        euroNorm
        
        category        
        body        
        fuel        
        gearbox
        
        images  
        imagesCount
        equipments
        comments
        price
        
        price
        brutoPrice
        monthlyPrice
        sold
        carpass
        warranty
        vatRegime
        
        dealership
        dealershipKey
  } 
}`;


const getImageForVehicle = (imagesString) => {
    if (imagesString && imagesString !== "") {
        const parsed = JSON.parse(imagesString);
        if (parsed && parsed.length > 0) {
            return parsed[0].uri
        }
    }
    return ""
};

const vehicleHasFeature = (feature, vehicle) => {
    const parsed = JSON.parse(vehicle.equipments);
    return filter(parsed, (eq) => eq.key === feature).length > 0;
};

const getFeatureComponent = (hasFeature) => {
    return (hasFeature) ? <i className="fal fa-check"/> : <i className="fal fa-times"/>
};


const VehicleCompare = ({context, references, handleToVehiclesClick, renderCarouselArrows = null, renderHeader, renderDesktopContent, renderMobileContent, wrapperClassName}) => {
    const {vehicleCompareReferences, removeVehicleFromCompare, setVehicleCompareReferences} = useContext(DataContext);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [vehicleHeaderHeight, setVehicleHeaderHeight] = useState(0);
    const [marginLeft, setMarginLeft] = useState(0);
    const [containerWidth, setContainerWidth] = useState(0);
    const {isMobile} = useIsMobile();
    const bottomBox = useRef();
    const {t} = useTranslation();

    const compareConfig = [
        {
            label: t('Price'),
            value: (vehicle) => formatPrice(vehicle.price),
            position: 1
        },
        {
            label: t('First registration'),
            value: (vehicle) => `${vehicle.firstRegistrationMonth} / ${vehicle.firstRegistrationYear}`,
            position: 2
        },
        {
            label: t('Mileage'),
            value: (vehicle) => `${vehicle.mileage} km`,
            position: 3

        },
        {
            label: t('Fuel'),
            value: (vehicle) => vehicle.fuel,
            position: 4
        },
        {
            label: t('Cylinder capacity'),
            value: (vehicle) => `${vehicle.engineCc} cc`,
            position: 5
        },
        {
            label: t('kw'),
            value: (vehicle) => vehicle.kw,
            position: 6
        },
        {
            label: t('CO² emissions'),
            value: (vehicle) => `${vehicle.co2} g/km`,
            position: 7
        },
        {
            label: t('Euronorm'),
            value: (vehicle) => vehicle.euroNorm,
            position: 8
        },
        {
            label: t('Transmission'),
            value: (vehicle) => vehicle.gearbox,
            position: 9
        },
        {
            label: t('Doors'),
            value: (vehicle) => vehicle.doors,
            position: 10
        },
        {
            label: t('Seats'),
            value: (vehicle) => vehicle.seats,
            position: 11
        },
        {
            label: t('ABS'),
            value: (vehicle) => getFeatureComponent(vehicleHasFeature("ABS", vehicle)),
            position: 12
        },
        {
            label: t('Airco'),
            value: (vehicle) => getFeatureComponent(vehicleHasFeature("AIR CONDITIONING", vehicle)),
            position: 13
        },
        {
            label: t('Navigation'),
            value: (vehicle) => getFeatureComponent(vehicleHasFeature("NAVIGATION SYSTEM", vehicle)),
            position: 14
        },
        {
            label: t('Automatic climate control'),
            value: (vehicle) => getFeatureComponent(vehicleHasFeature("AUTOMATIC CLIMATE CONTROL", vehicle)),
            position: 15
        },
        {
            label: t('Seat heating'),
            value: (vehicle) => getFeatureComponent(vehicleHasFeature("SEAT HEATING", vehicle)),
            position: 16
        },
        {
            label: t('Cruise Control'),
            value: (vehicle) => getFeatureComponent(vehicleHasFeature("CRUISE CONTROL", vehicle)),
            position: 17
        },
        {
            label: t('Adaptive Cruise Control'),
            value: (vehicle) => getFeatureComponent(vehicleHasFeature("ADAPTIVE CRUISE CONTROL", vehicle)),
            position: 18
        },
        {
            label: t('Open roof'),
            value: (vehicle) => getFeatureComponent(vehicleHasFeature("SUNROOF", vehicle)),
            position: 19
        },
        {
            label: t('Panoramic roof'),
            value: (vehicle) => getFeatureComponent(vehicleHasFeature("PANORAMA ROOF", vehicle)),
            position: 20
        },
        {
            label: t('Parking assistance front'),
            value: (vehicle) => getFeatureComponent(vehicleHasFeature("PARKING ASSIST SYSTEM SENSORS FRONT", vehicle)),
            position: 21
        },
        {
            label: t('Parking assistance back'),
            value: (vehicle) => getFeatureComponent(vehicleHasFeature("PARKING ASSIST SYSTEM SENSORS REAR", vehicle)),
            position: 22
        },
        {
            label: t('Towbar'),
            value: (vehicle) => getFeatureComponent(vehicleHasFeature("TRAILER HITCH", vehicle)),
            position: 23
        },
    ];



    const vehicleHeader = useRef(null);
    const vehiclesContainer = useRef(null);
    const compareTitle = useRef(null);

    const vehicleColWidth = isMobile ? 150 : 300;
    const colMarginLeft = isMobile ? 12 : 16;


    useEffect(() => {
        if (!loading) {
            const element = head(document.getElementsByClassName('vehicles-compare-parent'));
            if (element) element.classList.add('hidden')

            return () => {
                if (element) element.classList.remove('hidden')
            }
        }

    }, [loading])

    useEffect(() => {
        setLoading(true);
        fetch(
            '/graphql', {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 'Accept-Language': context.culture},
                body: JSON.stringify({query: VehiclesCompareQuery, variables: {filter: {references: references}}}),
            })
            .then(res => res.json())
            .then(res => {
                setData(res.data.vehicles);
                setLoading(false);
            })
            .catch(error => {
                setError("ERROR");
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (!loading) {
            if (vehicleHeader.current && vehicleHeader.current.offsetHeight) {
                setVehicleHeaderHeight(vehicleHeader.current.offsetHeight)
            }

            if (vehiclesContainer.current && vehiclesContainer.current.offsetWidth) {
                setContainerWidth(vehiclesContainer.current.offsetWidth)
            }
        }
    }, [containerWidth, vehicleHeaderHeight, loading])


    useEffect(() => {
        if (references && references.length > 0) {
            setVehicleCompareReferences(references)
        }
    }, [references]);

    const removeFromCompare = (reference) => {
        removeVehicleFromCompare(reference)
    };

    const getContentWidth = () => {
        const totalCols = vehiclesData.length;
        return (totalCols * vehicleColWidth) + ((totalCols - 1) * colMarginLeft);
    }

    const vehiclesData = filter(data, (veh) => includes(vehicleCompareReferences, veh.reference));

    const onNextClick = () => {
        const contentWidth = getContentWidth();
        const margin = contentWidth - containerWidth < vehicleColWidth ? contentWidth - containerWidth : vehicleColWidth + colMarginLeft;

        if (containerWidth + (marginLeft * -1) <= contentWidth) {
            let currentMargin = marginLeft - margin;
            const newMargin = (currentMargin * -1) > contentWidth - containerWidth ? contentWidth - containerWidth : (currentMargin * -1)
            setMarginLeft(newMargin * -1)
        }
    }

    const onPrevClick = () => {
        if (marginLeft < 0) {
            let currentMargin = marginLeft + vehicleColWidth + colMarginLeft;
            const newMargin = (currentMargin * -1) < vehicleColWidth ? 0 : currentMargin
            setMarginLeft(newMargin);
        }
    }

    const renderVehicleHeader = (vehicle) => {
        return <div ref={vehicleHeader} className="relative bg-white" style={{maxWidth: vehicleColWidth, minWidth: vehicleColWidth}}>
            <div className="flex items-center justify-end py-1 bg-white">
                <i className=" fal fa-times text-2xl cursor-pointer text-primary" onClick={() => removeFromCompare(vehicle.reference)}/>
            </div>

            <div className="relative">

                <Carousel images={JSON.parse(vehicle.images)}
                          reference={vehicle.reference}
                          renderArrows={renderCarouselArrows ? (handlePrevClick, handleNextClick) => renderCarouselArrows(handlePrevClick, handleNextClick) : null}
                          style={{minHeight: isMobile ? "auto" : 220}}
                          fetchingImages={(vehicle.imagesLoaded === undefined) ? undefined : !vehicle.imagesLoaded}
                />

                <div>
                    {context.favorites &&
                    <div className="absolute" style={{bottom: 15, right: 55}}>
                        <VehicleFavoriteBtn context={context} reference={vehicle.reference}/>
                    </div>}
                </div>
            </div>

            <div className="flex flex-col px-2 py-5 bg-color_four">
                <div className="text-left font-medium text-primary capitalize mb-1">{vehicle.make}</div>
                <div className="text-left font-bold text-primary capitalize truncate text-lg">{vehicle.model} {vehicle.version}</div>
            </div>
        </div>
    }

    const renderLabel = (label, classNames = null) => {
        return <div className={`py-3 ${classNames}`}>
            {label}
        </div>
    }

    const prevArrow = () => {
        const isVisible = marginLeft < 0
        return <i className={`fal fa-chevron-left mr-2 text-primary font-bold ${isVisible ? "opacity-100 cursor-pointer" : "opacity-20"}`}
                  onClick={marginLeft === 0 ? () => {
                  } : () => onPrevClick(vehiclesData.length)}/>
    }

    const nextArrow = () => {
        const contentWidth = getContentWidth();
        const isVisible = containerWidth + (marginLeft * -1) < contentWidth;

        return <i className={`fal fa-chevron-right ml-2 text-primary font-bold ${isVisible ? "opacity-100 cursor-pointer" : "opacity-20"}`}
                  onClick={() => onNextClick()}/>
    }

    const arrowsVisible = () => {
        return getContentWidth() > containerWidth;
    }

    const options = {
        marginLeft: marginLeft,
        vehicleHeaderHeight: vehicleHeaderHeight,
        arrowsVisible: arrowsVisible(),
        prevArrow: prevArrow(),
        nextArrow: nextArrow(),
        colMarginLeft: colMarginLeft,
        vehicleColWidth: vehicleColWidth
    }

    const refs = {
        vehicleHeaderRef: vehicleHeader,
        vehiclesContainerRef: vehiclesContainer
    }


    const renderOnDesktop = () => {
        return renderDesktopContent && typeof renderDesktopContent === 'function'
            ? renderDesktopContent(compareConfig, vehiclesData, refs, options, removeFromCompare)
            : <div className="flex flex-col w-full">
                <div className="flex">
                    <div className="flex flex-col bg-white" style={{minWidth: 280, maxWidth: 280, zIndex: 26}}>
                        <Sticky enabled={true} top={getStickiesOffset()} bottomBoundary='.bottom' innerZ={25}>
                            {arrowsVisible() && <div className="bg-white" style={{height: 16}}/>}
                            <div className="flex items-center justify-center bg-white" style={{height: vehicleHeaderHeight}}>
                                <div className="w-20 h-20 rounded-full flex items-center justify-center bg-color_three text-white cursor-pointer opacity-90 hover:opacity-100 " onClick={() => handleToVehiclesClick()}>
                                    <i className="fas fa-plus text-2xl"/>
                                </div>
                            </div>
                            {arrowsVisible() && <div className="bg-white" style={{height: 16}}/>}
                        </Sticky>
                        <div className="flex flex-col">
                            {map(orderBy(compareConfig, ['position']), item => renderLabel(item.label))}
                        </div>
                    </div>

                    <div ref={vehiclesContainer} className="vehicles-container flex flex-col w-full overflow-hidden">

                        <Sticky enabled={true} top={getStickiesOffset()} bottomBoundary='.bottom' innerZ={25}>
                            {arrowsVisible() && <div className="flex items-center justify-center bg-gray-100" style={{height: 32}}>
                                {prevArrow()}
                                {nextArrow()}
                            </div>}
                            <div className="vehicle-header flex" style={{marginLeft: marginLeft}}>
                                {map(vehiclesData, (vehicle, i) => {
                                    const isFirst = i === 0;
                                    return <div key={vehicle.reference} style={{marginLeft: isFirst ? 0 : colMarginLeft}}>
                                        <div style={{maxWidth: vehicleColWidth, minWidth: vehicleColWidth}}>{renderVehicleHeader(vehicle)}</div>
                                    </div>
                                })}
                            </div>
                        </Sticky>

                        <div className="flex vehicle-specs" style={{marginLeft: marginLeft}}>
                            {map(vehiclesData, (vehicle, i) => {
                                const isFirst = i === 0;
                                return <div key={vehicle.reference} style={{marginLeft: isFirst ? 0 : colMarginLeft}}>
                                    <div style={{maxWidth: vehicleColWidth, minWidth: vehicleColWidth}}>{map(orderBy(compareConfig, ['position']), item => renderLabel(item.value(vehicle), 'border-b border-color_three'))}</div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </div>
    }


    const renderOnMobile = () => {
        let style = {};
        return renderMobileContent && typeof renderMobileContent === 'function'
            ? renderMobileContent(compareConfig, vehiclesData, refs, options, removeFromCompare)
            : <div className="boris-container flex w-full overflow-x-scroll">
                <div className="flex">
                    {map(vehiclesData, (vehicle, i) => {
                        const isFirst = i === 0;
                        return <div key={vehicle.reference} style={{marginLeft: isFirst ? 0 : colMarginLeft}}>
                            <div className="" style={{maxWidth: vehicleColWidth, minWidth: vehicleColWidth, ...style}}>
                                {renderVehicleHeader(vehicle)}
                            </div>
                            <div className="relative" style={{maxWidth: vehicleColWidth, minWidth: vehicleColWidth}}>
                                {map(orderBy(compareConfig, ['position']), item => {
                                    return <div className="flex flex-col border-b border-color_three py-2 text-primary">
                                        <span>{item.label}</span>
                                        <span className="font-bold">{item.value(vehicle)}</span>
                                    </div>
                                })}
                            </div>
                        </div>
                    })}
                </div>
            </div>
    }
    return (
        <div className={`container mx-auto w-full vehicle-compare overflow-x-hidden ${wrapperClassName}`}>
            {loading && <LoadingIndicator />}
            {!loading && vehiclesData.length === 0 && <div className="w-full"><NoResultsIndicator message={t('You have no any vehicles to compare.')}/></div>}

            {!loading && vehiclesData !== undefined && vehiclesData.length > 0 && <div>
                {renderHeader && typeof renderHeader === 'function'
                    ? renderHeader(compareTitle)
                    : <div ref={compareTitle} className="flex flex-col-reverse md:flex-col">
                        <h2 className="text-2xl md:text-4xl font-bold text-primary uppercase mb-4 md:mb-0">Vergelijken ({vehiclesData.length})</h2>
                        <div className="hover:underline cursor-pointer text-primary mb-2 md:mb-4" onClick={handleToVehiclesClick}>
                            <i className="fal fa-chevron-left mr-2" style={{fontSize: "0.6rem"}}/> Terug naar het overzicht
                        </div>
                    </div>}


                {!isMobile ? renderOnDesktop() : renderOnMobile()}
            </div>}

            <div ref={bottomBox} id="bottom-box" className="bottom"/>
        </div>
    )
};

VehicleCompare.defaultProps = {
    wrapperClassName: "px-5 xl:px-0"
};

VehicleCompare.thumbnail = {
    width: DefaultThumbnailWidth,
    height: 400,
};


export default VehicleCompare;

