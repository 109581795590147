import React from 'react';
import {navigateAction} from "../../core/utils";

const AdvertCard = ({custom_card_action, custom_card_image, context}) => {
    return (
        <div className="shadow overflow-hidden hover:opacity cursor-pointer rounded-10"
             onClick={() => navigateAction(context, custom_card_action)}
             style={{background: `url(${custom_card_image}) no-repeat center bottom`, backgroundSize: "cover", height: "100%"}}>
        </div>
    );
};

export default AdvertCard;
