import React from 'react';
import {DefaultImage, DefaultLinks, DefaultPrimaryAction, DefaultText, DefaultTitle} from "../../../core/constants";
import {MarkupText, Image} from "../../../core";
import {map} from 'lodash';
import {navigatePage, navigateAction} from '../../../core/utils'
import FooterTwoCom_Preview from "./components/FooterTwoCol_Preview";

export const renderFooterReviews = () => {
    return <div className="flex">
        <div className="flex flex-col mr-2" style={{minWidth: 130}}>
            <a href="https://www.google.com/search?ei=OefMXs1wh5WwB4P7utgN&q=garage%20de%20mey%20maldegem&oq=garage+de+mey+maldegem&gs_lcp=CgZwc3ktYWIQAzICCAAyAggAMgIIADICCAAyAggAMgIIADIGCAAQFhAeMgYIABAWEB4yBggAEBYQHlD1kgFYm5kBYPyaAWgAcAB4AIABTYgBmgSSAQE5mAEAoAEBqgEHZ3dzLXdpeg&sclient=psy-ab&ved=2ahUKEwj7_bKjodHpAhUI26QKHdamC-0QvS4wAHoECAsQIg&uact=5&npsic=0&rflfq=1&rlha=0&rllag=51199876,3455033,531&tbm=lcl&rldimm=3736415914806796926&lqi=ChZnYXJhZ2UgZGUgbWV5IG1hbGRlZ2VtWicKDWdhcmFnZSBkZSBtZXkiFmdhcmFnZSBkZSBtZXkgbWFsZGVnZW0&phdesc=hK1XgutMu6g&rldoc=1&tbs=lrf:!1m4!1u3!2m2!3m1!1e1!1m4!1u2!2m2!2m1!1e1!2m1!1e2!2m1!1e3!3sIAE,lf:1,lf_ui:10&rlst=f#rlfi=hd:;si:3736415914806796926,l,ChZnYXJhZ2UgZGUgbWV5IG1hbGRlZ2VtWicKDWdhcmFnZSBkZSBtZXkiFmdhcmFnZSBkZSBtZXkgbWFsZGVnZW0,y,hK1XgutMu6g;mv:[[51.2051952,3.4560524999999997],[51.1945573,3.4540148999999998]];tbs:lrf:!1m4!1u3!2m2!3m1!1e1!1m4!1u2!2m2!2m1!1e1!2m1!1e2!2m1!1e3!3sIAE,lf:1,lf_ui:10"
               target="_blank">
                <img src={require('../../../core/assets/google-reviews.png')} alt="Google ratings" style={{maxWidth: 99, height: 40}}/>
            </a>
            <div className="mt-1 flex text-xs">
                <span className="mr-1">4.7</span>
                <div className="flex">
                    <i className="fas fa-star text-yellow-400"/>
                    <i className="fas fa-star text-yellow-400"/>
                    <i className="fas fa-star text-yellow-400"/>
                    <i className="fas fa-star text-yellow-400"/>
                    <i className="fas fa-star text-yellow-400"/>
                </div>
            </div>
        </div>

        <div className="flex flex-col items-center">
            <a href="https://www.autoscout24.be/nl/verkopers/garage-de-mey-vw-audi/reviews" target="_blank">
                <img src={require('../../../core/assets/as-new-logo.png')} alt="AS ratings" style={{maxWidth: 63, maxHeight: 40}}/>
            </a>
            <div className="mt-1 flex text-xs">
                <span className="mr-1">4.8</span>
                <div className="flex">
                    <i className="fas fa-star text-yellow-400"/>
                    <i className="fas fa-star text-yellow-400"/>
                    <i className="fas fa-star text-yellow-400"/>
                    <i className="fas fa-star text-yellow-400"/>
                    <i className="fas fa-star text-yellow-400"/>
                </div>
            </div>
        </div>
    </div>
}

const FooterTwoCol = ({logo, title, text, primary_action, links, context}) => {
    const vehicleLinks = links.filter(link => link.url === context.urls.vehicles);
    const otherLinks = links.filter(link => link.url !== context.urls.vehicles);
    return (
        <div id="footer-main" className="bg-primary items-center justify-center flex flex-col pt-10 md:pt-15 pt-20 px-5 md:px-0 z-50 relative">
            <div className="flex flex-col md:flex-row container">
                <div className="w-full md:w-1/2 border-r-0 md:border-r border-gray-100 pr-8">
                    <div className="flex mb-8 justify-center md:justify-start cursor-pointer"
                         onClick={() => navigatePage(context, context.urls.home)}>
                        <Image url={(logo) ? logo : undefined} style={{height: 60}} itemProp="logo"/>
                    </div>

                    <div className="footer-description">
                        <div className="mb-5 text-2xl text-white">{title}</div>
                        <div className="text-xs mb-10 text-white"><MarkupText text={text} itemProp="address" context={context}/></div>
                        <div className="flex justify-center md:justify-start">
                            {primary_action.type !== "" &&
                            <div className="flex flex-row bg-primary  text-white hover:text-gray-300 rounded-none text-center text-sm font-bold border-2 border-gray-800 cursor-pointer outline-none"
                                 onClick={() => navigateAction(context, primary_action)}>
                                <div className="flex flex-row justify-between w-full">
                                    <div className='py-3 px-4'>{primary_action.title}</div>
                                    <div className="p-3 icon-wrapper bg-gray-800">
                                        <div className="w-4 h-4">
                                            <i className='fad fa-arrow-right'/>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>

                <div className="flex flex-col w-full md:w-1/2 pl-8 pt-24 md:pt-12 text-white justify-between">
                    <div className="flex">
                        <div className="mr-2" style={{minWidth: 130}}>
                            {map(vehicleLinks, (link, i) => {
                                const param = (link.title.toLowerCase() !== 'alle') ? `make=${link.title.toLowerCase()}` : '';
                                return (
                                    <h4 key={i} className="flex mb-2 hover:underline cursor-pointer"
                                        itemProp="brand">
                                        {/*// onClick={() => navigatePage(context, context.urls.vehicles, param)}>*/}
                                        <a href={`/${context.urls.vehicles}?${param}`} target="_self">{link.title}</a>
                                    </h4>
                                )
                            })}
                        </div>
                        <div className="">
                            {map(otherLinks, (link, i) => {
                                return (
                                    <div key={i} className="flex mb-2 hover:underline cursor-pointer">
                                         {/*onClick={() => navigateAction(context, link)}>*/}
                                        <a href={`/${link.url}`} target="_self">{link.title}</a>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    {renderFooterReviews()}
                </div>
            </div>

        </div>
    );
};

FooterTwoCol.defaultProps = {
    logo: DefaultImage,
    title: DefaultTitle,
    text: DefaultText,
    primary_action: DefaultPrimaryAction,
    links: DefaultLinks,
};

FooterTwoCol.human = {
    "nl": "",
    "fr": "",
    "en": "",
};

FooterTwoCol.preview = () => { return <FooterTwoCom_Preview /> };

export default FooterTwoCol;
