import { useState, useEffect } from 'react';
import fetch from 'cross-fetch'

const VEHICLE_COUNT_QUERY = `query VehiclesCount  {
  vehiclesCount
}`;

export function useVehiclesCount(language) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [vehiclesCount, setVehiclesCount] = useState(null);

    useEffect(() => {

        fetch('/graphql', {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Accept-Language': language},
            body: JSON.stringify({query: VEHICLE_COUNT_QUERY})
        })
            .then(resp => resp.json())
            .then((result) => {
                const {vehiclesCount} = result.data;
                setVehiclesCount(vehiclesCount);
                setLoading(false);
            })
            .catch(err => {
                setError("ERROR");
                setLoading(false);
            })

    }, []);

    return {
        vehiclesCount,
        loading: loading,
        error
    };
}
