import React from 'react';
import TextArea from "../editors/components/TextArea";
import TextInput from "../editors/components/TextInput";
import {v4} from 'uuid';
import themeConfig from '../../../dealer-theme/theme/config';

const PageMetaData = ({page, meta, onChange}) => {

    const handleChangeMeta = (name, value) => {
        onChange({
            ...meta,
            id: (meta) ? meta.id : v4(),
            [name]: value,
        })
    };

    const isVehicleDetailPage = page === themeConfig.urls.vehicleDetail;

    return (
        <div className="builder-p-4">
            <h3 className='builder-mb-3'>Page SEO</h3>
            <label className="builder-block builder-text-gray-700 builder-text-md builder-font-bold builder-mb-2 ">
                Title *
            </label>

            <TextInput
                placeholder='title'
                value={(meta) ? meta.title : ""}
                onChanged={(value) => handleChangeMeta('title', value)}
            />
            {isVehicleDetailPage &&
            <span className="builder-text-xs">* Merk, model, versie worden automatisch toegevoegd</span>}

            {!isVehicleDetailPage &&
            <label className="builder-block builder-text-gray-700 builder-text-md builder-font-bold builder-mb-2 builder-mt-2 ">
                Description
            </label>}


            {!isVehicleDetailPage &&
            <TextArea
                placeholder='description'
                value={(meta) ? meta.description : ""}
                rows={10}
                onChanged={(value) => handleChangeMeta('description', value)}
            />}

        </div>
    );
};

export default PageMetaData;
