import React, {useState} from "react";
import {filter, map} from "lodash";
import {Container, Draggable} from "react-smooth-dnd";
import {getComponentsForCategory, getHumanText, getPreviewComponent} from "../../../utils";

const ComponentCategories = [
    {id: 'general', description: 'General'},
    {id: 'content', description: 'Content'},
    {id: 'vehicle', description: 'Vehicle'},
    {id: 'features', description: 'Features'},
    {id: 'contact', description: 'Contact'},
    {id: 'seo', description: 'SEO'},
];


export const Menu = ({context}) => {
    const [category, setCategory] = useState(undefined);
    const [dragStarted, setDragStarted] = useState(false);

    const categoryComponents = getComponentsForCategory(category);
    const componentsWithPreview = filter(map(categoryComponents, (comp) => ({
        ...comp,
        preview: getPreviewComponent(comp.name),
    })), (comp) => comp.preview !== undefined)

    return (
        <div className="builder-h-full" onMouseLeave={() => {
            if (!dragStarted) {
                setCategory(undefined)
            }
        }}>
            <div className="builder-bg-white builder-cursor-pointer builder-m-2 builder-rounded" style={{width: 120}}>

                {map(ComponentCategories, (cat) => {
                    return (
                        <MenuItem id={cat.id}
                                  key={`menu${cat.id}`}
                                  text={cat.description}
                                  onMouseEnter={() => setCategory(cat.id)}
                                  onClick={() => setCategory(cat.id)}
                        />
                    )
                })}
            </div>

            {(category || dragStarted) &&
            <div
                className={`builder-absolute builder-bg-white builder-rounded builder-f-full builder-max-h-full builder-overflow-scroll  ${(!dragStarted) ? 'builder-p-2' : 'builder-overflow-hidden'}`}
                style={{top: 8, left: 130, width: (!dragStarted) ? 800 : 0, zIndex: 999}}>

                <div className="builder-mb-4">
                    <span>Select component and drag it to the canvas</span>
                </div>

                <div className="builder-p-2 ">
                    <Container groupName="1"
                               behaviour="copy"
                               style={{flex: 1, width: '100%'}}
                               onDragStart={() => setDragStarted(true)}
                               onDragEnd={() => {
                                   setCategory(undefined);
                                   setDragStarted(false);
                               }}
                               getChildPayload={i => componentsWithPreview[i]}>
                        {
                            componentsWithPreview.map((comp, i) => {
                                const Component = comp.preview

                                return (
                                    <Draggable key={`${comp.name}_${i}`} className="mt-2">
                                        <div className="builder-flex builder-flex-1 builder-w-full builder-border-2 builder-border-white hover:builder-border-blue-600" style={{zIndex: 999}}>
                                            <div className='builder-w-full builder-flex builder-flex-1 builder-cursor-pointer builder-border builder-border-gray-300  builder-flex-col'>
                                                <div className="builder-text-center builder-p-2">{getHumanText(comp.name, 'nl')}</div>

                                                <div className="relative">
                                                    <div className='absolute' style={{width: '100%', height: '100%', zIndex: 900}}></div>
                                                    <Component context={context}/>
                                                </div>
                                            </div>
                                        </div>
                                    </Draggable>

                                );
                            })
                        }
                    </Container>
                </div>
            </div>}
        </div>
    )
};


const MenuItem = ({id, text, onMouseEnter, onClick}) => {
    return (
        <div className="h-10 p-2 flex items-center"
             key={`menu_item_${id}`}
             onMouseEnter={onMouseEnter}
             onClick={onClick}
        >
            <span>{text}</span>
        </div>
    )
};


