import React from 'react';
import {useQuery} from "graphql-hooks";
import {DealershipsQuery} from "../../../data/graphql";
import {orderBy} from "lodash";
import {Link} from "react-router-dom";
import {routes} from "../../../data/constants";
import LoadingIndicator from "../../common/LoadingIndicator";
import ErrorIndicator from "../../common/ErrorIndicator";

const Overview = ({props}) => {
    const {loading, error, data} = useQuery(DealershipsQuery);

    if(loading){
        return <div><LoadingIndicator /></div>
    }

    if(!loading && error){
        return <div><ErrorIndicator error="Overview cannot be fetched." /> </div>
    }

    if(!loading && !error){
        return <div className="builder-flex builder-flex-wrap builder-mt-4">
            {orderBy(data.dealerships, ['id']).map((dealership, i) => {
                return (
                    <div key={dealership.id} className={`sm:w-1/4 builder-mt-4 ${i % 4 === 0 ? "" : "builder-pl-4"}`}>
                        <div className="builder-bg-white builder-border builder-border-gray-200 builder-text-center">
                            <div style={{height: 220}} className={`builder-flex builder-items-center builder-justify-center builder-border-b builder-border-gray-200 ${dealership.image ? "" : "builder-bg-gray-100"}`}>
                                {dealership.image ?  <img className="builder-w-auto builder-mx-auto" src={dealership.image} alt={dealership.name} /> : <i className="fal fa-image builder-text-gray-200" style={{fontSize: 80}}/> }
                            </div>
                            <div className="builder-p-6 builder-flex builder-flex-col">
                                <h2 className="builder-text-xl builder-font-medium builder-text-gray-700 builder-mb-1">{dealership.name}</h2>
                                <span className="builder-text-gray-600 builder-text-sm">{dealership.address} <br />{dealership.postal} {dealership.city}</span>
                                <Link className="builder-px-4 builder-py-2 builder-bg-blue-500 builder-text-white builder-mt-6 builder-cursor-pointer hover:bg-blue-400" to={{
                                    pathname: `${routes.SETTINGS_DEALERSHIP}/${dealership.id}`,
                                    state: {dealership}
                                }}>Details</Link>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    }
};

export default Overview;
