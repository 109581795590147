import React, {useState, useEffect} from 'react';
import {map, upperFirst, values} from "lodash";
import CopyContentFrom from "./CopyContentFrom";
import {definitionsForContentType, getContentForField, getHumanText} from "../../../utils";
import FieldEditor from "./FieldEditor";

const BlockEditor = ({block, pages, currentPage, currentLanguage, contentTypeDefinitions, onCopyBlock, onContentChange}) => {
    const [currentField, setCurrentField] = useState(undefined);

    useEffect(() => {
        setCurrentField(undefined)
    }, [block.componentName]);

    const definitions = definitionsForContentType(contentTypeDefinitions, block.contentType);
    // if(definitions.length === 1 && currentField === undefined) {
    //     setCurrentField(definitions[0])
    // }

    return (
        <React.Fragment>

            {!currentField &&
            <div className="builder-p-4">
                <div className="builder-flex builder-flex-col builder-mb-4">
                    <div className="builder-flex builder-flex-col  builder-mb-4">
                        <span className="builder-font-bold builder-text-lg"> {getHumanText(block.componentName, 'nl')}</span>

                        <div className="flex items-center">
                            <span className="builder-text-sm">ref: b{block.id.split('-')[0]}</span>
                            <CopyContentFrom contentType={block.contentType}
                                             allPages={pages}
                                             currentPage={currentPage}
                                             language={currentLanguage}
                                             onCopy={(copyBlock) => onCopyBlock(block.id, copyBlock, currentLanguage)}
                            />
                        </div>

                    </div>


                </div>
                <React.Fragment>
                    {map(definitions,  (definition, i) => {
                        return (
                            <div key={`${definition.name}_${i}`} className="builder-mb-6 builder-cursor-pointer builder-p-2 builder-border-b hover:builder-bg-gray-100"
                                 onClick={() => setCurrentField(definition)}>
                                <label className="builder-block builder-text-gray-700 builder-text-md builder-font-bold builder-mb-2 ">
                                    {upperFirst(definition.label)}
                                </label>
                                <div className="builder-italic builder-text-sm">
                                    {definition.description}
                                </div>

                            </div>
                        )
                    })}
                </React.Fragment>
            </div>}

            {currentField &&
            <React.Fragment>
                <FieldEditor definition={currentField}
                             value={getContentForField(block, currentField.name, currentLanguage)}
                             onChange={(field, value) => onContentChange(block.id, field, value, currentLanguage)}
                             context={{pages: values(pages)}}
                             breadcrumbs={[getHumanText(block.componentName, 'nl'), upperFirst(currentField.label)]}
                             onBack={(e) => {
                                 setCurrentField(undefined);
                             }}
                />
            </React.Fragment>}
        </React.Fragment>
    );
};

export default BlockEditor;
