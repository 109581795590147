import React, {useState, useEffect, useRef} from 'react';
import InputField from "../../input/Input";
import ButtonWithIcon from "../../input/ButtonWithIcon";
import DatePickerField from "../../input/DatePicker";
import {ErrorIndicator, SuccessIndicator} from "../../indicators";
import {getWindowPathLocation, hasValue, isValidEmail, scrollTo} from "../../../utils";
import {useDealerContact} from "../../../hooks/useDealerContact";
import {keys} from "lodash";


const TestDriveFormFields = {
    firstName: 'firstName',
    lastName: 'lastName',
    email: 'email',
    phone: 'phone',
    date: 'date',
    privacy: 'privacy',
    origin: 'origin',
}

const initialData = {
    [TestDriveFormFields.firstName]: "",
    [TestDriveFormFields.lastName]: "",
    [TestDriveFormFields.email]: "",
    [TestDriveFormFields.phone]: "",
    [TestDriveFormFields.date]: new Date(),
    [TestDriveFormFields.privacy]: false,
    [TestDriveFormFields.origin]: ""
};

const TestDrive = ({onClose, vehicle, context, handlePrivacy, onLoad = null}) => {
    const [data, setData] = useState(initialData);
    const {submit, loading, dealerContactTypes} = useDealerContact();
    const [success, setSuccess] = useState(false);
    const [errors, setErrors] = useState({});
    const blockRef = useRef(null);


    useEffect(() => {
        if (onLoad !== null) {
            onLoad(blockRef.current)
        }
    }, [])


    const validate = () => {
        let errors = {};

        if (!hasValue(data.firstName)) {
            errors[TestDriveFormFields.firstName] = "Vul aub uw naam in.";
        }

        if (!hasValue(data.lastName)) {
            errors[TestDriveFormFields.lastName] = "Vul aub uw familienaam in.";
        }

        if (!hasValue(data.email)) {
            errors[TestDriveFormFields.email] = "Ongeldig e-mailadres";
        } else if (!isValidEmail(data.email)) {
            errors[TestDriveFormFields.email] = "Ongeldig e-mailadres";
        }

        if (!hasValue(data.phone)) {
            errors[TestDriveFormFields.phone] = "Vul aub uw telefoonnummer in.";
        }

        if (data.privacy === false) {
            errors[TestDriveFormFields.privacy] = "";
        }

        setErrors(errors);

        return keys(errors).length <= 0;
    };


    const setValue = (field, value) => {
        setData({
            ...data,
            [field]: value
        });
    };

    const hasError = (field) => {
        return (errors && errors[field] !== undefined)
    };

    useEffect(() => {
        const testDriveForm = document.getElementById("test-drive-form");
        if(testDriveForm){
            scrollTo(testDriveForm)
        }

        if(success){
            setTimeout(() => {
                onClose();
            }, 2000)
        }


    }, [success, errors])

    const onSubmit = e => {
        e.preventDefault();
        setSuccess(false);


        if (validate()) {
            setSuccess(false);

            const jsonData = JSON.stringify({
                first_name: data.firstName,
                last_name: data.lastName,
                email: data.email,
                phone: data.phone,
                date: data.date.toLocaleDateString(),
                privacy: data.privacy,
                // form_type: dealerContactTypes.vehicleTestDrive,
                vehicle: {
                    id: vehicle.id,
                    reference: vehicle.reference,
                    identification: vehicle.identification,
                },
                origin: getWindowPathLocation(context),
            });

            submit(dealerContactTypes.vehicleTestDrive, jsonData)
                .then(result => {
                    if (result) {
                        setSuccess(true);
                        setErrors({});
                        setData(initialData);
                    } else {
                        setErrors({
                            result: 'Probleem met het verzenden van uw aanvraag.'
                        })
                    }
                })
        }
    };

    return (
        <form id="test-drive-form" ref={blockRef} action="" onSubmit={e => onSubmit(e)} className=" bg-gray-100 p-3 relative">
            <div className="flex flex-col mb-10">
                <div className="flex justify-between items-center w-full text-gray-800 mb-2">
                    <div className="text-lg text-gray-800 text-1xl ">Aanvraag proefrit</div>
                    <i className="hover:spin-animation fad fa-times text-xl cursor-pointer" onClick={onClose}/>
                </div>
                <div className="w-full text-md text-gray-600 ">Geef aan wanneer u een proefrit wenst te maken met dit voertuig. Wij bevestigen vervolgens uw boeking.</div>
            </div>

            {errors && errors['result'] &&
            <div className="mb-4 px-5 md:px-0" style={{minHeight: 32}}><ErrorIndicator error={errors["result"]}/></div>}

            {success &&
            <div className="px-5 md:px-0 absolute" style={{top: 40, right: "0.75rem", left: "0.75rem"}}><SuccessIndicator success="Uw aanvraag is verzonden!"/></div>}

            <div className="flex flex-col justify-between">
                <div className="flex flex-col">
                    <div className="flex mb-5">
                        <InputField containerClass="w-full md:w-1/2 md:mb-0 mr-1"
                                    label="Voornaam"
                                    placeholder="John"
                                    value={data.firstName}
                                    onChange={(value) => setValue(TestDriveFormFields.firstName, value)}
                                    errorText={errors[TestDriveFormFields.firstName]}
                                    required={true}/>

                        <InputField containerClass="w-full md:w-1/2 md:mb-0 ml-1"
                                    label="Naam"
                                    placeholder="Doe"
                                    value={data.lastName}
                                    onChange={(value) => setValue(TestDriveFormFields.lastName, value)}
                                    errorText={errors[TestDriveFormFields.lastName]}
                                    required={true}/>
                    </div>

                    <div className=" mb-5">
                        <InputField containerClass="w-full mb-2 md:mb-0"
                                    label="Email"
                                    placeholder="john.doe@gmail.com"
                                    value={data.email}
                                    onChange={(value) => setValue(TestDriveFormFields.email, value)}
                                    errorText={errors[TestDriveFormFields.email]}
                                    required={true}/>
                    </div>
                    <div className="mb-5">
                        <InputField containerClass="w-full mb-2 md:mb-0"
                                    label="Telefoonnummer"
                                    placeholder="0486 12 34 56"
                                    value={data.phone}
                                    onChange={(value) => setValue(TestDriveFormFields.phone, value)}
                                    errorText={errors[TestDriveFormFields.phone]}
                                    required={true}/>
                    </div>
                    <div className="mb-2 test-drive">
                        <DatePickerField containerClass="w-full px-0 mb-2 md:mb-0"
                                         label="Date"
                                         value={data.date}
                                         calendarIcon="fad fa-calendar-check"
                                         noPast={true}
                                         onChange={(date) => {
                                             setValue(TestDriveFormFields.date, date)
                                         }}
                        />
                    </div>
                </div>


                <div className="flex mb-3">
                    <div className={`text-primary flex  ${(hasError('privacy') ? "text-red-400" : "")}`}>
                        <input type="checkbox" checked={data.privacy} onChange={e => setValue(TestDriveFormFields.privacy, e.target.checked)}/>
                        <span className={`mr-1 ml-2 ${(hasError('privacy') ? "text-red-400" : "")}`}>Ik ga akkoord met het </span>
                        <span className={`underline cursor-pointer ${(hasError('privacy') ? "text-red-400" : "")}`}
                              onClick={() => (handlePrivacy) ? handlePrivacy() : null}>privacybeleid</span>.
                    </div>
                </div>

                <div className="text-2xs text-gray-600 mb-6">Let op: Dit is een VERZOEK van afspraak. Als we niet kunnen voldoen aan uw verzoek, zullen wij contact met u opnemen via de telefoon. Om een dringende afspraak te maken, gelieve contact met ons op te nemen via
                    telefoon.
                </div>


                <div className="flex justify-end">
                    <ButtonWithIcon disabled={loading}
                                    onClick={onSubmit}
                                    loading={loading}
                                    loadingText="Aan het sturen..."
                                    text="Bevestig"
                                    icon="fad fa-paper-plane"
                                    iconPosition="right"
                    />
                </div>
            </div>
        </form>
    );
};

TestDrive.defaultProps = {

};

export default TestDrive;
