import fetch from "cross-fetch";

export const CHECK_RESERVATION_QUERY = `query Reservation ($reference: String!) {
  reservation (reference: $reference)  {
    number
    amount
    firstName
    lastName
    email
    telephone
    mailings
    status 
    paymentStatus
    vehicleReference
  }
}`;

export const COMPLETE_PAYMENT_MUTATION = `mutation Complete ($reference: String!, $vehicleUrl: String!) {
  completeReservationPayment (reference: $reference, vehicleUrl: $vehicleUrl) {
        ok
        reservation {
            number
            amount
            firstName
            lastName
            email
            telephone
            mailings
            status 
            paymentStatus
            vehicleReference
        }
  }
}`;

export const INITIALIZE_RESERVATION_MUTATION = `mutation Initialize ($data: ReservationInputType!) {
  initializeReservation (data: $data) {
        ok
        reservation {
            reference
        }
  }
}`;

export const UPDATE_RESERVATION_MUTATION = `mutation Update ($data: ReservationInputType!, $reference: String!) {
  updateReservation (data: $data, reference: $reference) {
        ok
        reservation {
            reference
        }
  }
}`;

export const INITIALIZE_PAYMENT_MUTATION = `mutation Initialize ($reference: String!, $redirectUrl: String!) {
  initializeReservationPayment (reference: $reference, redirectUrl: $redirectUrl) {
        ok
        paymentUrl
  }
}`;


export const GET_RESERVATION_SETTINGS_QUERY = `query ReservationSettings {
  reservationSettings  {
    reservationDays
    reservationAmount
  }
}`;

const request = (query, variables= {}, success_cb, error_cb, headers) => {
    fetch(
        '/graphql', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...headers
            },
            body: JSON.stringify({query: query, variables: variables})
        })
        .then(res => res.json())
        .then(res => {
            success_cb(res)
        })
        .catch(error => {
            error_cb(error)
        });
}

export const QUERY = (query, variables, success_cb, error_cb, headers={}) => {
    request(query, variables, success_cb, error_cb, headers)
}

export const MUTATION = (mutation, variables, success_cb, error_cb, headers={}) => {
    request(mutation, variables, success_cb, error_cb, headers)
}
