import {useEffect, useState} from "react";
import {head} from "lodash";

export const useHeight = (classNames) => {
    const [totalHeight, setTotalHeight] = useState(0);

    useEffect(() => {
        let height = 0;
        classNames.map(className => {
            const selector = document.getElementsByClassName(className);
            if(selector.length > 0){
                const elHeight = head(selector).offsetHeight;
                height = height + elHeight
            }
        })

        setTotalHeight(height)
    }, []);

    return totalHeight
}
