import React from 'react';
import Header from "../Header";
import Menu from "./components/Menu";
import Overview from "./dealership/Overview";
import {useHistory} from "react-router-dom";
import {routes} from "../../data/constants";
import HeaderMenu from "./components/HeaderMenu";

const Dealerships = () => {
    let history = useHistory();

    const handleBack = () => {
        history.push(routes.HOME)
    };


    return (
        <div className="builder-flex builder-flex-1 builder-flex-col builder-h-screen builder-font-body builder-text-sm">
            <Header renderMenu={() => <HeaderMenu/>}/>

            <Menu title="Dealerships overview" onBack={handleBack}/>

            <div className="builder-flex builder-items-center builder-justify-center builder-bg-white">
                <div className="builder-container">
                    <Overview/>
                </div>
            </div>

        </div>
    );
};

export default Dealerships;
