import {keys, find, mapValues, keyBy, filter, map, capitalize, includes, values, pickBy} from "lodash";
import * as components from "./dealer-theme";
import React from "react";
import {DAYS} from "autralis-dealer-themes/src/core/constants";
const imagesExtensions = ['jpeg', 'jpg', 'png', 'svg'];
const videoExtensions = ['mov', 'mp4'];


export const applyDrag = (arr, dragResult) => {
    const {removedIndex, addedIndex, payload} = dragResult;
    if (removedIndex === null && addedIndex === null) return arr;

    const result = [...arr];
    let itemToAdd = payload;

    if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0];
    }

    if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
    }

    return result;
};


export const objectNotEmpty = (object) => {
    return keys(object).length > 0;
};

export const definitionsForContentType = (definitions, contentType) => {

    const definition = find(definitions, (def) => def.name === contentType);

    if (definition) {
        return definition.fields
    } else return []
};

export const getFieldsForContent = (block, culture) => {
    const filtered = filter(block.content, (content) => content.culture === culture);
    if (filtered.length >= 1) {
        return filtered[0].fields;
    }
    return null;
};

export const getKeyValueContentFieldsForBlock = (block, culture) => {
    const content = getFieldsForContent(block, culture);
    return mapValues(keyBy(content, 'name'), 'value');
};

export const getFieldByName = (fields, name) => {
    const filtered = filter(fields, (field) => field.name === name);
    if (filtered.length >= 1) {
        return filtered[0];
    }
    return null;
};

export const getContentForField = (block, fieldName, culture) => {
    const content = getKeyValueContentFieldsForBlock(block, culture);
    return (content[fieldName]) ? content[fieldName] : undefined
};

export const parseFields = (fields) => {
    const parsed = map(fields, (field) => ({
        ...field,
        value: parseField(field)
    }));
    return parsed;
};

export const saveField = (content) => {
    if (content.type && content.type.startsWith("Json")) {
        return JSON.stringify(content.value)
    } else if (content.type && content.type === "BooleanField") {
        return capitalize(content.value)
    } else {
        return content.value;
    }
};

export const parseField = (field) => {
    if (field.type && field.type.startsWith("Json")) {
        return field.value !== "" ? JSON.parse(field.value) : field.value;
    } else {
        return field.value;
    }
};


export const copyBlocks = (blocksToCopy) => {
    return

};

export const getExtension = (url) => {
    if(url && url !== "") {
        return url.split('.').pop();
    }
    return "";
};

export const isExtensionAllowed = (ext) => {
    if (includes(imagesExtensions, ext) || includes(videoExtensions, ext)) {
        return true;
    }
    return false;
};

export const isImage = (url) => {
    const ext = getExtension(url);
    return includes(imagesExtensions, ext)
};

export const isVideo = (url) => {
    const ext = getExtension(url);
    return includes(videoExtensions, ext)
};


export const getComponentDetails = (components) =>  {
    if(components["componentDetails"]) {
        return components["componentDetails"];
    }
    else {
        return {}
    }
};

export const getComponentsForCategory = (category) => {
    return values(pickBy(getComponentDetails(components), (value, key) => value.category === category));
};

export const getComponentsForContentType = (contentType) => {
    return values(pickBy(getComponentDetails(components), (value, key) => value.contentType === contentType));
};

export const getPreviewComponent = (name) => {

    // Try to resolve preview component
    try {
        const preview_name = `${name}`;
        const comp = components[preview_name];
        return (comp && comp.default.preview) ? comp.default.preview :  undefined;
    } catch (ex) {
        return  <div>No component preview implementation</div>
    }
};


export const getHumanText = (name, language) => {
    // Try to resolve human name
    try {
        const preview_name = `${name}`;
        const comp = components[preview_name];

        const human = (comp && comp.default.human) ? comp.default.human : null;
        return (human && human[language]) ? human[language] : name

    } catch (ex) {
        return name;
    }
};


export const getDayTranslation = (day) => {
    switch (day) {
        case DAYS.mon:
            return "Maandag";
        case DAYS.tue:
            return "Dinsdag";
        case DAYS.wed:
            return "Woensdag";
        case DAYS.thu:
            return "Donderdag";
        case DAYS.fri:
            return "Vrijdag";
        case DAYS.sat:
            return "Zaterdag";
        case DAYS.sun:
            return "Zondag";
        default:
            return ""
    }
}
