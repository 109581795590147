import React from 'react';
import './scrolldown.css';

const ScrollDownIndicator = () => {
    return (
        <div className="scroll-down-wrapper">
            <div className="scroll-down-container">
                <div className="chevron"></div>
                <div className="chevron"></div>
                <div className="chevron"></div>
            </div>
        </div>
    );
};

export default ScrollDownIndicator;
