import React from 'react';
import {DefaultImage, DefaultPrimaryAction, DefaultText, DefaultTitle} from "../../../core/constants";
import {MarkupText, Image} from "../../../core";
import {navigateAction} from "../../../core/utils";
import FeatureThreeColWithHeader_Preview from "./components/FeatureThreeColWithHeader_Preview";

const FeatureThreeColWithHeader = ({title, text, image1, title1, description1, primary_action1, image2, title2, description2, primary_action2, image3, title3, description3, primary_action3, context}) => {

    const handleClick = (e, action) => {
        e.preventDefault();
        navigateAction(context, action);
    };

    return (
        <div className="flex item-center justify-center bg-primary pt-10 pb-10 md:pt-30 ">
            <div className="container flex flex-col px-5 md:px-0">
                <h2 className="uppercase text-left mb-5 text-3xl md:text-5xl font-bold text-center text-white">{title}</h2>
                <div className="text-center mb-5 md:mb-16 list-none text-white text-base"><MarkupText text={text} context={context}/></div>

                <div className="flex flex-col md:flex-row justify-between mb-10">
                    <div className="flex flex-col items-center justify-center hover:text-gray-700 mr-3 md:mr-3 w-full md:w-1/3 cursor-pointer " onClick={(e) => handleClick(e, primary_action1)}>
                        <Image url={(image1) ? image1 : ''} style={{width: '100%', height: '100%'}}/>
                        <div className="text-white my-2 card-title text-center text-lg md:text-2xl">{title1}</div>
                        <div className="text-white text-center text-sm md:text-md"><MarkupText text={description1} context={context}/></div>
                    </div>
                    <div className="flex flex-col items-center justify-center hover:text-gray-700 mr-3 md:mr-3 w-full md:w-1/3 cursor-pointer " onClick={(e) => handleClick(e, primary_action2)}>
                        <Image url={(image2) ? image2 : ''} style={{width: '100%', height: '100%'}}/>
                        <div className="text-white my-2 card-title text-center text-lg md:text-2xl">{title2}</div>
                        <div className="text-white text-center text-sm md:text-md"><MarkupText text={description2} context={context}/></div>
                    </div>
                    <div className="flex flex-col items-center justify-center hover:text-gray-700 mr-3 md:mr-3 w-full md:w-1/3 cursor-pointer " onClick={(e) => handleClick(e, primary_action3)}>
                        <Image url={(image3) ? image3 : ''} style={{width: '100%', height: '100%'}}/>
                        <div className="text-white my-2 card-title text-center text-lg md:text-2xl">{title3}</div>
                        <div className="text-white text-center text-sm md:text-md"><MarkupText text={description3} context={context}/></div>
                    </div>
                </div>
            </div>
        </div>
    );
};


FeatureThreeColWithHeader.defaultProps = {
    title: DefaultTitle,
    text: DefaultText,
    image1: DefaultImage,
    title1: DefaultTitle,
    description1: DefaultText,
    primary_action1: DefaultPrimaryAction,
    image2: DefaultImage,
    title2: DefaultTitle,
    description2: DefaultText,
    primary_action2: DefaultPrimaryAction,
    image3: DefaultImage,
    title3: DefaultTitle,
    description3: DefaultText,
    primary_action3: DefaultPrimaryAction,
};

FeatureThreeColWithHeader.human = {
    "nl": "",
    "fr": "",
    "en": "",
};

FeatureThreeColWithHeader.preview = () => { return <FeatureThreeColWithHeader_Preview /> };

export default FeatureThreeColWithHeader;
