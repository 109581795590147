import React, {useState} from "react"
import Fade from 'react-reveal/Fade';
import {orderBy, map} from "lodash"
import {FilterTypes, SortByFilterLabelsTranslation} from "../../../../constants";


const sortByFilters = [
    {value: FilterTypes.price, label: SortByFilterLabelsTranslation.price["nl"], action: (vehicles, sortType) => orderBy(vehicles, [v => parseInt(v.price)], [sortType])},
    {value: FilterTypes.mileage, label: SortByFilterLabelsTranslation.mileage["nl"], action: (vehicles, sortType) => orderBy(vehicles, [v => parseInt(v.mileage)], [sortType])},
    {value: FilterTypes.year, label: SortByFilterLabelsTranslation.firstRegistrationYear["nl"], action: (vehicles, sortType) => orderBy(vehicles, [v => parseInt(v.registration_year)], [sortType])},
];

const Sort = ({onChange}) => {
    const [open, setOpen] = useState(false);
    const [ascending, setAscending] = useState(false);
    const [activeFilter, setActiveFilter] = useState({});

    const setSortBy = (data) => {
        const sortByFilter = {
            type: FilterTypes.sortBy,
            value: data.value,
            label: data.label,
            ascending: !ascending, action: (vehicles, sortType) => data.action(vehicles, sortType)
        };

        setActiveFilter(sortByFilter);
        setAscending(!ascending);
        onChange(sortByFilter);
        setOpen(false);
    };

    return (
        <div>
            <div className="relative">
                <div className="cursor-pointer hover:text-primary" onClick={() => setOpen(!open)}>Sorteren <i className="fad fa-angle-down ml-2"/></div>
                <div className={`absolute right-0 mt-2 ${!open && "hidden"}`}>
                    <Fade spy={open} duration={200}>
                        <div className="flex flex-col bg-white p-3 shadow z-50">
                            {map(sortByFilters, (sb_filter) => {
                                return (
                                    <div key={sb_filter.value} className="flex flex-row items-center p-2 cursor-pointer hover:text-primary" onClick={() => setSortBy(sb_filter)}>
                                        <div style={{minWidth: 25}}>
                                            {activeFilter.value === sb_filter.value && ascending ? <i className="fad fa-sort-amount-up"/> : <i className="fad fa-sort-amount-down"/>}
                                        </div>
                                        <span className="mr-2">{sb_filter.label}</span>
                                    </div>
                                )
                            })}
                        </div>
                    </Fade>
                </div>
            </div>
        </div>
    )
};

export default Sort
