import React from 'react';
import ButtonWithIndicator from "../../common/ButtonWithIndicator";

const Menu = ({title, onBack, onSave, onCancel, loading, saveDisabled}) => {
    return <div className="builder-flex builder-bg-gray-100 builder-p-4 builder-items-center builder-justify-center builder-sticky z-50" style={{top: 0, height: 76, minHeight: 76}}>
        <div className="builder-flex builder-container builder-justify-between">
            <div className="builder-flex builder-flex-col builder-items-center builder-justify-center">

                <div className="builder-flex builder-items-center builder-justify-center builder-self-start builder-text-xl">
                    <div className="builder-font-bold">{title}</div>
                </div>

                <div className="builder-flex builder-self-start builder-mt-2 builder-text-xs builder-text-gray-700 hover:builder-text-gray-900 builder-mt-2 builder-cursor-pointer"
                     onClick={onBack}>
                    <i className="fal fa-chevron-left builder-mr-2 builder-text-gray-700 builder-cursor-pointer hover:text-gray-800"/>Back
                </div>

            </div>
        </div>

        <div className="builder-flex">

            {onCancel && <div className='builder-bg-white builder-border builder-border-gray-300 builder-px-4 builder-py-2 builder-cursor-pointer hover:builder-bg-gray-100' onClick={onCancel}>Cancel</div>}

            {onSave &&
            <ButtonWithIndicator loading={loading}
                                 onClick={onSave}
                                 icon="fal fa-save"
                                 text="Save"
                                 disabled={saveDisabled}
                                 colorClass="builder-bg-primary builder-text-white"
                                 borderClass="builder-border builder-border-bg-primary"
            />}
        </div>
    </div>
};

Menu.defaultProps = {
    loading: false,
    saveDisabled: false
}

export default Menu;
