import React, {useState} from 'react';
import Dropdown from "../../common/Dropdown";
import {map, values, toLower, orderBy} from 'lodash';


const PageSelector = ({value, pages, onSelectPage, onDeletePage, onCreatePage}) => {
    const [creating, setCreating] = useState(false);
    const [copyFrom, setCopyFrom] = useState(undefined);

    const dropDownOptions = orderBy(map(values(pages), (page) => {
        return {
            value: page.url,
            key: page.id,
            priority: (page.url === 'index') ? 1 : 0
        }
    }), ['priority', 'value'], ['desc', 'asc']);

    const renderItem = (item, index) => {
        return (
            <div
                className="builder-flex builder-justify-between builder-px-2 builder-py-3 builder-border-b builder-border-gray-300 builder-ml-2 builder-mr-2 builder-text-sm hover:builder-bg-gray-100"
                onClick={() => onSelectPage(item)}>

                <div className="builder-cursor-pointer">
                    {`${item.value}.html`}
                </div>
                <div className="builder-flex">
                    <React.Fragment>
                        <div onClick={(e) => {
                            e.stopPropagation();
                            setCopyFrom(item.key);
                            setCreating(true);
                        }}>
                            <i className="fas fa-copy builder-cursor-pointer builder-text-gray-800 builder"/>
                        </div>

                        {(index > 0) &&
                        <div onClick={(e) => {
                            e.stopPropagation();
                            onDeletePage(item);
                        }} className="ml-4">
                            <i className="fas fa-trash-alt builder-cursor-pointer builder-text-red-800"/>
                        </div>}

                    </React.Fragment>
                </div>
            </div>
        )
    };

    const reset = () => {
        setCreating(false)
        setCopyFrom(undefined)
    };

    const renderFooter = (onClose) => {
        return (
            <DropDownAddPageFooter creating={creating}
                                   onCancel={() => reset()}
                                   onCreate={() => setCreating(true)}
                                   onSave={(url) => {
                                       onCreatePage(url, copyFrom);
                                       reset();
                                   }}
            />
        )
    }

    return (
        <Dropdown centerText={true}
                  options={dropDownOptions}
                  formatValue={(value) => `${value}.html`}
                  value={(value) ? value : undefined}
                  renderItem={renderItem}
                  renderFooter={renderFooter}
        />
    )
};

const DropDownAddPageFooter = ({creating, onCancel, onCreate, onSave}) => {
    const [input, setInput] = useState("");

    const handleSave = (e) => {
        const emptySpaces = input.split(" ").join("");
        const value = toLower(emptySpaces);
        if (value !== "") {
            onSave(value);
        } else {
            e.stopPropagation();
        }
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            handleSave(e);

        } else if (e.keyCode === 27) {
            onCancel()
        }
    };

    return (
        <div>
            {!creating &&
            <div className="builder-flex builder-ml-2 builder-mr-2 builder-px-2 builder-py-3 builder-justify-center builder-text-sm hover:builder-text-blue-600">
            <span onClick={(e) => {
                e.stopPropagation();
                onCreate();
            }}> <i className="fal fa-plus builder-mr-1"/> <span className="builder-font-medium builder-cursor-pointer">Add a page </span> </span>
            </div>}

            {creating &&
            <div className="builder-flex builder-flex-1 builder-flex-col">
                <div className="builder-flex builder-bg-gray-200 builder-ml-2 builder-mr-2 builder-border-b builder-border-gray-300" style={{minHeight: 30}}>

                    <div className="builder-w-2/3">
                        <input value={input}
                               onChange={(e) => setInput(e.target.value)}
                               className="builder-bg-gray-200 builder-appearance-none builder-w-full builder-py-2 builder-px-2 builder-text-gray-700 builder-leading-tight focus:builder-outline-none"
                               type="text" placeholder="name" autoFocus={true}
                               onKeyDown={handleKeyDown}
                               onClick={(e) => e.stopPropagation()}>
                        </input>
                    </div>
                    <div className="builder-w-1/3 builder-flex builder-items-center builder-justify-end builder-pr-2">
                        <div className="builder-text-blue-600 builder-font-medium hover:builder-text-blue-800 builder-cursor-pointer" onClick={handleSave}>Save</div>
                    </div>
                </div>

                <div className="builder-flex builder-ml-2 builder-mr-2 builder-px-2 builder-py-3 builder-justify-center builder-text-sm ">
                    <div className="builder-text-sm builder-cursor-pointer" onClick={(e) => {
                        e.stopPropagation();
                        onCancel()
                    }}> Cancel
                    </div>
                </div>

            </div>}

        </div>
    )
};

export default PageSelector;
