import React from "react";
import {MarkupText} from "../../../core";

const Paragraph = ({text, context}) => {
    return (
        <div className="flex p-8 bg-white" style={{minHeight: 80}}>
            <MarkupText text={text} context={context} />
        </div>
    )
};

Paragraph.human = {
    "nl": "",
    "fr": "",
    "en": "",
};

Paragraph.preview = Paragraph;

export default Paragraph;
