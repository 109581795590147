import {useContext} from 'react';
import {filter, findIndex, map} from "lodash";
import DataContext from "../store/DataContext";
import {getFilteredVehicles} from "../components/vehicle/vehicles-list/filter/functions";

export function useNextPrevContextVehicle() {
    const contextData = useContext(DataContext);

    const findVehicleRef = (id, increment = 1) => {
        if (!contextData || !contextData.vehicles) return undefined;

        const vehicles = getFilteredVehicles(contextData.filters, contextData.sorter, contextData.vehicles);
        const availableVehicles = filter(vehicles, (veh) => !veh.sold);
        const index = findIndex(availableVehicles, (vehicle) => vehicle.reference === id);

        if (index >= 0 && availableVehicles[index + increment] !== undefined) {
            return availableVehicles[index + increment].reference;
        } else {
            return undefined;
        }
    };


    return {
        getNextVehicleRef: (ref) => findVehicleRef(ref, 1),
        getPrefVehicleRef: (ref) => findVehicleRef(ref, -1),
    }
}
