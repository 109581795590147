import React, {useState, useEffect} from 'react';
import {useDropzone} from 'react-dropzone';
import {head} from 'lodash';
import LoadingIndicator from "../../../indicators/LoadingIndicator";
import {base64ToFile} from "../../../../utils";
import {useDealerFileUpload} from "../../../../hooks/useDealerFileUpload";

const ImageContainer = ({className, name, type, overlay, showIconOverlay, iconOverlay, onUpload, required, error, setIsUploading, img, helpImage, isUploading, setCurrentActive, currentActive}) => {
    const [image, setImage] = useState(null);
    const [uploadingFailed, setUploadingFailed] = useState(false);
    const [showDescription, setShowDescription] = useState(null);
    const {submit, loading: fileUploading, error: fileUploadError} = useDealerFileUpload();

    const onUploadingStarted = () => {
        // setUploading(true);
        setCurrentActive !== undefined && setCurrentActive(type);
        setIsUploading !== undefined && setIsUploading(true);
        setUploadingFailed(false);
    };

    const onUploadingFinished = () => {
        // setUploading(false);
        setCurrentActive !== undefined && setCurrentActive(type);
        setIsUploading !== undefined && setIsUploading(false);
        setUploadingFailed(false);
    };

    const onUploadingFailed = () => {
        // setUploading(false);
        setCurrentActive !== undefined && setCurrentActive(type);
        setIsUploading !== undefined && setIsUploading(false);
        setUploadingFailed(true);
    };

    const resize = (item) => {
        //define the width to resize e.g 1024px
        let resize_width = 1024;//without px
        //create a FileReader
        let reader = new FileReader();

        //image turned to base64-encoded Data URI.
        reader.readAsDataURL(item);
        reader.name = item.name;//get the image's name
        reader.size = item.size; //get the image's size
        reader.onload = function (event) {
            let img = new Image();//create a image
            img.src = event.target.result;//result is base64-encoded Data URI
            img.name = event.target.name;//set name (optional)
            img.size = event.target.size;//set size (optional)
            img.onload = function (el) {
                const uploaded_img_width = el.target.width;
                const uploaded_img_height = el.target.height;
                const uploaded_img_name = el.target.name;

                let elem = document.createElement('canvas');//create a canvas

                let scaleFactor = resize_width / uploaded_img_width;
                // If the uploaded image width is smaller than resize_width, take than uploaded_img_width
                if (uploaded_img_width < resize_width) {
                    resize_width = uploaded_img_width
                    scaleFactor = 1
                }

                //scale the image to 1024 (width) and keep aspect ratio
                elem.width = resize_width;
                elem.height = uploaded_img_height * scaleFactor;

                //draw in canvas
                let ctx = elem.getContext('2d');
                ctx.drawImage(el.target, 0, 0, elem.width, elem.height);

                //get the base64-encoded Data URI from the resize image
                let srcEncoded = ctx.canvas.toDataURL('image/jpeg', 0.8);
                //get the file from base64-encoded Data URI
                let resizedFile = base64ToFile(srcEncoded, uploaded_img_name)
                uploadPhoto(resizedFile)

                /*Now you can send "srcEncoded" to the server and
                convert it to a png o jpg. Also can send
                "el.target.name" that is the file's name.*/

            }
        }
    }

    const uploadPhoto = async (file) => {
        submit(file, (url) => {
            onUploadingFinished();
            const newImage = Object.assign({}, {full_url: url, imageType: type});
            setImage(newImage);
            onUpload(newImage);
        }, (error) => {
            onUploadingFailed();
        })
    }


    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
        multiple: false,
        accept: 'image/jpeg, image/png, image/jpg',
        onDrop: async acceptedFiles => {
            onUploadingStarted();
            const uploadedFile = head(acceptedFiles);
            const fileSize = uploadedFile.size / 1024 / 1024; // in MB
            if (fileSize > 2) {
                resize(uploadedFile);
            } else {
                uploadPhoto(uploadedFile)
            }
        }
    });

    useEffect(() => {
        setImage(img)
    }, [img]);


    const pictureOverlay = () => {
        if (showIconOverlay) {
            return <div className="flex flex-col items-center justify-center text-gray-600">
                <i className={`${iconOverlay} text-3xl mb-2`}/>
                <div>Voeg een foto toe</div>
            </div>
        } else {
            return <img src={require(`../images/overlays/${overlay}.svg`)} alt="" style={{maxHeight: 140}}/>

        }
    };

    const thumb = () => {
        if (uploadingFailed) {
            return <div className="w-full h-full flex items-center justify-center text-red-400">De foto is niet geuploaded!</div>
        } else {
            return image !== null ? <img src={image.full_url} alt="" style={{height: 140}}/> : pictureOverlay();
        }

    };

    const uploadingOverlay = () => {
        return <div className="flex flex-col items-center justify-center w-full h-full">
            <div className="w-full relative" style={{height: "70%"}}><LoadingIndicator/></div>
            <div className="w-full flex justify-center text-gray-600" style={{height: "30%"}}>Aan het uploaden...</div>
        </div>
    };

    const hasError = (error !== undefined && image === null) || uploadingFailed;

    return (
        <div className={`image-container relative ${className} ${isUploading && currentActive !== type ? "opacity-50" : "opacity-100"}`}>
            <div className={`border ${hasError ? "border-red-400" : "border-gray-200"} ${!isUploading ? "cursor-pointer" : "cursor-default"} flex flex-col justify-between items-center overflow-hidden`} style={{height: 140}}>
                <div {...getRootProps({className: 'dropzone'})} className="flex-1 w-full flex items-center justify-center outline-none relative">
                    {!isUploading && <input {...getInputProps()} />}
                    {isUploading && currentActive === type ? uploadingOverlay() : thumb()}

                    {/*Show on bigger screens*/}
                    {helpImage !== null && helpImage !== undefined && <div className="absolute right-0 top-0 p-1 z-10 hidden md:flex" onMouseEnter={() => setShowDescription(true)} onMouseLeave={() => setShowDescription(false)}>
                        <i className="fal fa-info-circle text-lg text-gray-600 m-1"/>
                    </div>}

                    {/*Show on small screens*/}
                    {helpImage !== null && helpImage !== undefined && <div className="absolute right-0 top-0 p-1 z-10 flex md:hidden" onClick={(e) => {
                        e.stopPropagation();
                        setShowDescription(!showDescription);
                    }}>
                        <i className="fal fa-info-circle text-4xl text-gray-400 m-2"/>
                    </div>}
                </div>
            </div>
            {name !== undefined && <div className={`px-2 py-1 border-r border-l border-b ${hasError ? "bg-red-100 border-red-400 text-red-700" : "bg-gray-100 border-gray-200 text-gray-700"}  w-full flex items-center justify-center text-sm `}>
                {name} {required && "*"}
            </div>}
            {showDescription && helpImage !== null && helpImage !== undefined && <div className="absolute right-0 border border-gray-400 shadow-2xl bg-white p-1 rounded z-50 opa" style={{bottom: "100%", width: 300}}>
                <img src={require(`../images/takeover-help/${helpImage}`)} alt="" style={{maxWidth: "100%"}} className="rounded"/>
            </div>}

        </div>
    );
};

ImageContainer.defaultProps = {
    required: false,
    showIconOverlay: false,
    iconOverlay: "fal fa-camera-retro",
    helpImage: null
};


export default ImageContainer;
