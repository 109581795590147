import { useState, useEffect } from 'react';
import fetch from 'cross-fetch'
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";

export const DealerContactMutation = `mutation DealerContact ($data: String!) {
  dealerContact (data: $data) {
        ok
        error
  }
}`;

const DealerContactTypes = {
    general: 'general',
    vehicleInformation: 'vehicle_information',
    vehicleTestDrive: 'vehicle_test_drive',
    vehicleOffer: 'vehicle_offer',
    vehicleRequest: 'vehicle_request'
};

export function useDealerContact() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { executeRecaptcha } = useGoogleReCaptcha();

    const submit = async (type, data) => {
        if (!executeRecaptcha) {
            return;
        }
        setLoading(true);

        const token = await executeRecaptcha(`dealer_contact_${type}`);


        const tmpData = JSON.parse(data);
        if(!tmpData.form_type) {
            tmpData.form_type = type
        }
        const parsedData = JSON.stringify(tmpData);

        return fetch('/graphql', {
            method: 'POST',
            headers: {'Content-Type': 'application/json', "Captcha" : token},
            body: JSON.stringify({query: DealerContactMutation, variables: {data: parsedData}})
        })
            .then(resp => resp.json())
            .then((result) => {
                const {dealerContact} = result.data;
                setLoading(false);
                if(dealerContact.ok) {
                    return true;
                } else {
                    setError(dealerContact.error)
                    return false;
                }

            })
            .catch(err => {
                setError("ERROR");
                setLoading(false);
                return false;
            })
    };

    return {
        submit,
        dealerContactTypes: DealerContactTypes,
        loading,
        error
    };
}
