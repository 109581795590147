import React from 'react';

const Spacing_Gray = () => {
    return (
        <div className="hidden md:flex py-6 bg-gray-200">
            <div className="opacity-0">.</div>
        </div>
    );
};

Spacing_Gray.human = {
    "nl": "",
    "fr": "",
    "en": "",
};

Spacing_Gray.preview = Spacing_Gray;

export default Spacing_Gray;
