import React, {useContext} from 'react';
import {InputField, TextAreaField, ButtonWithIcon} from "../../../input";
import {ACTIONS, DispatchContext, StateContext, TakeOverFormFields} from "../Takeover";
import {hasValue, isValidEmail} from "../../../../utils";

const StepFive = ({onConfirm, sending, handlePrivacy, sendSuccess, context}) => {
    const dispatch = useContext(DispatchContext);
    const {user, errors} = useContext(StateContext);

    const isValid = () => {
        let isValid = true;


        if (!user.first_name) {
            dispatch({type: ACTIONS.SET_ERROR, field: TakeOverFormFields.first_name, payload: 'Vul aub uw naam in.'})
            isValid = false;
        }

        if (!user.last_name) {
            dispatch({type: ACTIONS.SET_ERROR, field: TakeOverFormFields.last_name, payload: 'Vul aub uw familienaam in.'})
            isValid = false;
        }

        if (!hasValue(user.email) || !isValidEmail(user.email)) {
            dispatch({type: ACTIONS.SET_ERROR, field: TakeOverFormFields.email, payload: 'Vul aub uw emailadres in.'})
            isValid = false;
        }

        if (!user.phone) {
            dispatch({type: ACTIONS.SET_ERROR, field: TakeOverFormFields.phone, payload: 'Vul aub uw telefoonnummer in.'})
            isValid = false;
        }

        if (!user.privacy) {
            dispatch({type: ACTIONS.SET_ERROR, field: TakeOverFormFields.privacy, payload: ''})
            isValid = false;
        }


        return isValid
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (isValid()) {
            onConfirm()
        }
    };

    const onBack = (e) => {
        e.preventDefault();
        dispatch({type: ACTIONS.PREV_STEP})
    };

    const hasError = (field) => {
        return (errors && errors[field] !== undefined)
    };

    const handleChange = (field, value) => {
        dispatch({type: ACTIONS.SET_USER, field, payload: value});
        dispatch({type: ACTIONS.REMOVE_ERROR, field})
    }

    return (
        <form action="" onSubmit={e => onSubmit(e)} className="bg-white flex flex-col">
            <div className="-mx-3 md:flex mb-2">

                <InputField containerClass="w-full md:w-1/2 px-3 mb-2 md:mb-0"
                            label="Naam"
                            placeholder="John"
                            value={user[TakeOverFormFields.first_name]}
                            onChange={(value) => handleChange(TakeOverFormFields.first_name, value)}
                            errorText={errors[TakeOverFormFields.first_name]}
                            required={true}/>

                <InputField containerClass="w-full md:w-1/2 px-3 mb-2 md:mb-0"
                            label="Familienaam"
                            placeholder="Doe"
                            value={user[TakeOverFormFields.last_name]}
                            onChange={(value) => handleChange(TakeOverFormFields.last_name, value)}
                            errorText={errors[TakeOverFormFields.last_name]}
                            required={true}/>


            </div>
            <div className="-mx-3 md:flex mb-2">
                <InputField containerClass="w-full md:w-1/2 px-3 mb-6 md:mb-0"
                            label="Email"
                            placeholder="john.doe@gmail.com"
                            value={user[TakeOverFormFields.email]}
                            onChange={(value) => handleChange(TakeOverFormFields.email, value)}
                            errorText={errors[TakeOverFormFields.email]}
                            required={true}/>

                <InputField containerClass="w-full md:w-1/2 px-3 mb-6 md:mb-0"
                            label="Telefoonnummer"
                            placeholder="0486 12 34 56"
                            value={user[TakeOverFormFields.phone]}
                            onChange={(value) => handleChange(TakeOverFormFields.phone, value)}
                            errorText={errors[TakeOverFormFields.phone]}
                            required={true}/>


            </div>
            <div className="-mx-3 md:flex mb-2">
                <TextAreaField containerClass="md:w-full px-3 mb-6 md:mb-0"
                               label="Commentaar"
                               placeholder=""
                               value={user[TakeOverFormFields.message]}
                               onChange={value => handleChange(TakeOverFormFields.message, value)}/>

            </div>

            <div className="flex">
                <div className={`text-primary flex  ${(hasError(TakeOverFormFields.privacy) ? "text-red-400" : "")}`}>
                    <input type="checkbox" onChange={e => handleChange(TakeOverFormFields.privacy, e.target.checked)} checked={user[TakeOverFormFields.privacy]}/>
                    <span className={`mr-1 ml-2 ${(hasError('privacy') ? "text-red-400" : "")}`}>Ik ga akkoord met het </span>
                    <span className={`underline cursor-pointer ${(hasError('privacy') ? "text-red-400" : "")}`} onClick={() => handlePrivacy()}>privacybeleid</span>.
                </div>
            </div>


            <div className="flex justify-between w-full mt-6">
                <ButtonWithIcon disabled={sending || sendSuccess}
                                onClick={(e) => onBack(e)}
                                text="Terug"
                                icon="fad fa-chevron-left"
                                iconPosition="left"
                />

                <ButtonWithIcon disabled={sending || sendSuccess}
                                onClick={(e) => onSubmit(e)}
                                loading={sending}
                                loadingText="Aan het sturen..."
                                text="Bevestig"
                                icon="fad fa-check"
                                iconPosition="right"
                />
            </div>
        </form>
    );
};

export default StepFive;
