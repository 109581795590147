import React from 'react';
import SignOutButton from "./authentication/SignOutButton";
import {routes} from "../data/constants";
import {useHistory} from "react-router-dom";
import History from "./History";

const Header = ({renderMenu}) => {
    let history = useHistory();

    return (
        <div className="builder-flex builder-flex-row builder-h-16 builder-bg-white builder-border-b builder-border-gray-400  builder-top-0 builder-left-0 builder-right-0" style={{height: 65, minHeight: 65}}>
            <div className="builder-flex builder-items-center builder-ml-4 builder-justify-center builder-text-blue-800" style={{width: 300}}>
                <div className="builder-flex builder-items-center">
                    <img className="builder-cursor-pointer" src={require("../assets/img/logo.png").default} onClick={() => history.push(routes.HOME)}/>
                </div>
            </div>

            <div className="builder-flex-1 builder-flex builder-items-center builder-justify-end builder-pr-2">
                <History/>
            </div>

            <div className="builder-flex-1 builder-flex builder-items-center builder-justify-end builder-pr-2 builder-border-r builder-border-gray-400" style={{zIndex: 999}}>
                {renderMenu && renderMenu()}
            </div>

            <div className="builder-flex builder-items-center builder-justify-end builder-pr-2">
                <SignOutButton/>
            </div>
        </div>
    );
};

Header.defaultProps = {
    renderMenu: null
}

export default Header;
