import React from 'react';
import {map} from "lodash";
import Member from "./Member";
import {preview_context, preview_members} from "../../../../core/constants";

const ContactTeamMember_Preview = () => {
    return (
        <div className="bg-gray-100 p-5">
            <div className="flex flex-1 items-center justify-center text-center mb-10 bg-gray-100">
                <h1 className="h2">
                    <div className="text-md mt-5">Lorem Ipsum is simply dummy text of the printing and</div>
                </h1>
            </div>

            <div className="flex items-center justify-center relative">
                <div className="flex flex-1 justify-between pr-10">
                    {map(preview_members, member => <Member context={preview_context} member={member}/>)}
                </div>
            </div>
        </div>
    );
};

export default ContactTeamMember_Preview;
