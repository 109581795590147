import React from 'react';
import {BasicContactForm, Image} from "../../../../core";
import {preview_context} from "../../../../core/constants";

const ContactFormTwoCol_ImageLeft_Preview = () => {

    return (
        <div className="flex items-center justify-center w-full bg-black relative py-0 sm:py-10 md:py-0 border-b" style={{borderColor: "rgb(255,255,255, 0.6)"}}>
            <div className="flex items-center justify-center w-full relative">
                <div className="container flex flex-row items-center justify-center">
                    <div className="flex items-center justify-center w-6/12 absolute left-0 pr-8">
                        <Image url={require('../../../../core/assets/previews/call-center.jpg')} style={{maxWidth: '100%'}}/>

                    </div>
                    <div className="lg:w-6/12 opacity-0"/>
                    <div className="flex w-full flex-col lg:w-6/12 items-center lg:items-start justify-center px-5 lg:px-0 py-5">
                        <div className="w-full">
                            <div className="">
                                <div className="flex flex-1 flex-col">
                                    <div className="mb-1 lg:mb-3 text-2xl lg-text-4xl text-white">Lorem ipsum</div>
                                    <div className="mb-8 lg:mb-8 lg:px-0 text-white">Lorem Ipsum is simply dummy text of the printing and</div>

                                </div>

                                <BasicContactForm context={preview_context}
                                                  lightForm={true}
                                                  handlePrivacy={() => {}}/>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactFormTwoCol_ImageLeft_Preview;
