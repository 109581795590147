import React, {useEffect, useRef, useState} from "react";

const TextInput = ({value, placeHolder, onChanged, className}) => {
    const inputRef = useRef(null);
    const [text, setText] = useState(value);

    useEffect(() => {
        setText(value)
    }, [value]);

    return (
        <input
            className={`builder-appearance-none builder-border-b builder-w-full builder-py-2 builder-px-3 builder-text-gray-700 builder-leading-tight  focus:builder-outline-none ${className}`}
            type="text"
            ref={inputRef}
            placeholder={(placeHolder && placeHolder !== "") ? placeHolder : "Add your text here"}
            value={text}
            //onChange={(e) => setText(e.target.value)}
            onChange={(e) => onChanged(e.target.value)}
            // onBlur={() => {
            //     onChanged(text)
            // }}
            onFocus={() => {
                inputRef.current.focus();
                inputRef.current.select()
            }}
        />
    )
};

export default TextInput;
