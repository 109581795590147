import React from "react"
import CarouselDEPRICATED from "../Carousel-DEPRICATED"
import {formatNumber, formatPrice} from "../../utils";
import {includes} from "lodash";

const visibleCategories = ['new', 'demo'];
const newCategory = 'new';
const overlayTypes = {
    sold: 'sold',
    reserved: 'reserved'
}


const VehicleCard = ({vehicle, cardClass, styles, onImageLoad, context, onClick }) => {
    const financingEnabled = context.financing;
    const isNew = vehicle.categoryKey === newCategory;
    const isSold = vehicle.sold;
    const isReserved = vehicle.reserved;
    const hasMonthlyPrice = vehicle.monthlyPrice > 0
    const hasBrutoPrice = (vehicle.brutoPrice && vehicle.brutoPrice) > 0;


    const handleClick = (e) => {
        if(!isSold && !isReserved) onClick(vehicle)
    }

    const renderOverlay = type => {
        let icon = require('../../../core/assets/sold_white.svg')
        if (type === overlayTypes.reserved) icon = require('../../../core/assets/reserved_white.svg');
        return <div className="flex items-center justify-center absolute right-0 left-0 top-0 bottom-0 z-20" style={{background: "rgba(0, 0, 0, 0.5)"}}>
            <div className="flex items-center justify-center flex-col w-38 h-38 bg-primary rounded-full opacity-75" style={{height: 160, width: 160}}>
                <img src={icon} alt="Niet beschikbaar" style={{height: 80}}/>
            </div>
        </div>
    }

    const renderDefaultCard = () => {
        return <div className="flex flex-col">
            <CarouselDEPRICATED images={vehicle.images}
                                category={(includes(visibleCategories, vehicle.categoryKey)) ? vehicle.category : undefined}
                                reference={vehicle.reference}
                                label={vehicle.label}
                                context={context}
                                onImageLoad={onImageLoad}
            />

            <div className="flex flex-col">
                <div className="flex flex-col px-2 py-4 border-b " style={{borderColor: "#b3b3b3"}}>
                    <div className="text-left font-bold mb-2 text-gray-700 capitalize" style={{minHeight: 18}}>{vehicle.make}</div>
                    <div className={`text-left truncate text-primary text-lg capitalize`}>{vehicle.model} {vehicle.version}</div>
                </div>

                <div className="flex flex-row flex-wrap bg-gray-50 px-2 py-4" style={{minHeight: 176, backgroundColor: "#f8f8f8"}}>
                    {!isNew && <>
                        <div className="flex justify-start items-center py-2 text-gray-700" style={{flex: "0 50%"}}>
                            <div className="text-left" style={{width: 30}}><i className="fad fa-calendar-day"/></div>
                            {vehicle.firstRegistrationMonth} / {vehicle.firstRegistrationYear}
                        </div>
                        <div className="flex items-center pl-2 py-2 text-gray-700" style={{flex: "0 50%"}}>
                            <div className="text-left" style={{width: 30}}><i className="fad fa-tachometer-average mr-3"/></div>
                            {formatNumber(vehicle.mileage)} km
                        </div>
                    </>}
                    <div className="flex flex-row items-center py-2 text-gray-700 capitalize truncate" style={{flex: "0 50%"}}>
                        <div className="text-left" style={{width: 30}}><i className="fad fa-gas-pump mr-3"/></div>
                        {vehicle.fuel}
                    </div>
                    <div className="flex items-center pl-2 py-2 text-gray-700" style={{flex: "0 50%"}}>
                        <div className="text-left" style={{width: 30}}><i className="fad fa-door-closed mr-3"/></div>
                        {vehicle.doors} deuren
                    </div>
                    <div className="flex items-center py-2 text-gray-700 capitalize" style={{flex: "0 50%"}}>
                        <div className="text-left" style={{width: 30}}><i className="far fa-joystick mr-3"/></div>
                        {vehicle.gearbox}
                    </div>
                    <div className="flex items-center pl-2 py-2 text-gray-700" style={{flex: "0 50%"}}>
                        <div className="text-left" style={{width: 30}}><i className="fad fa-flower-tulip mr-3"/></div>
                        <div className="text-left">
                            {vehicle.euroNorm.includes('geen') ? `${vehicle.co2}g CO2` : <div className="flex flex-col">
                                <div>{vehicle.euroNorm}</div>
                                <div>{vehicle.co2} g CO2</div>
                            </div>}
                        </div>
                    </div>
                    {isNew && <div className="flex justify-start items-center py-2 text-gray-700" style={{flex: "0 100%"}}>

                    </div>}
                </div>
            </div>

            <div className="flex flex-row border-t bg-primary" style={{borderColor: "#b3b3b3", minHeight: 75}}>

                {!financingEnabled && !isSold && !isReserved &&
                <div className={`flex flex-col px-2 py-2 text-white text-center bg-primary justify-center`} style={{flex: "0 100%"}}>
                    <div className="flex justify-center items-center">
                        <span className={`font-thin mb-1 ${(hasBrutoPrice) ? 'line-through text-xl' : 'text-2xl'}`}>{(hasBrutoPrice) ? formatPrice(vehicle.brutoPrice) : formatPrice(vehicle.price)}</span>
                        {hasBrutoPrice &&
                        <span className="text-2xl font-extralight mb-1 ml-3">{formatPrice(vehicle.price)}</span>}
                    </div>
                    <span className="text-xs font-extralight">incl BTW.</span>
                </div>}


                {financingEnabled && !isSold && !isReserved &&
                <div className="flex w-full">
                    {hasMonthlyPrice &&  <div className="flex flex-col text-primary px-3 py-4 text-center bg-gray-200" style={{flex: "0 50%"}}>
                        <span className="text-2xl font-extralight mb-1">{formatPrice(vehicle.monthlyPrice)}</span>
                        <span className="text-xs font-extralight">{`per maand incl BTW.`}</span>
                    </div>}

                    <div className={`flex flex-col px-3 py-4 text-gray-800 text-center bg-white `} style={{flex: hasMonthlyPrice ? "0 50%" : "0 100%"}}>
                        <div className="flex justify-center items-center">
                            <span className={`font-thin mb-1 ${(hasBrutoPrice) ? 'line-through text-sm' : 'text-2xl'}`}>{(hasBrutoPrice) ? formatPrice(vehicle.brutoPrice) : formatPrice(vehicle.price)}</span>
                            {hasBrutoPrice &&
                            <span className="text-2xl font-extralight mb-1 ml-3">{formatPrice(vehicle.price)}</span>}
                        </div>
                        <span className="text-xs font-extralight">incl BTW.</span>
                    </div>
                </div>}
            </div>
        </div>
    }

    return (
        <div className={`${cardClass} shadow overflow-hidden relative ${(!isSold && !isReserved) ? 'cursor-pointer' : 'cursor-default'}`} style={{...styles, outline: 'none !important'}} onClick={handleClick}>

            {isSold && renderOverlay(overlayTypes.sold)}
            {isReserved && renderOverlay(overlayTypes.reserved)}

            {renderDefaultCard()}

        </div>
    )
};

VehicleCard.defaultProps = {
    cardClass: "vehicle-card",
    styles: {marginBottom: "2%"}
};

export default VehicleCard
