import React from 'react';
import {navigateAction} from "../../utils";

const AdvertiseCard = ({action, classNames, styles, img_src, context}) => {
    return (
        <div className={`${classNames}`}
             style={styles}
             onClick={() => navigateAction(context, action)}>
            <img src={img_src} alt="Advertise Banner" title="advertise" style={{width: "100%", height: "100%"}}/>
        </div>
    )
};

AdvertiseCard.defaultProps = {
    style: {},
    classNames: ""
};

export default AdvertiseCard;
