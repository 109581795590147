import React, {useReducer} from "react"
import TakeoverWizard from "./components/TakeoverWizard";
import {omit} from "lodash";

export const ACTIONS = {
    NEXT_STEP: 'NEXT_STEP',
    PREV_STEP: 'PREV_STEP',
    SET_VEHICLE: 'SET_VEHICLE',
    SET_IMAGES: 'SET_IMAGES',
    SET_DAMAGES: 'SET_DAMAGES',
    SET_USER: 'SET_USER',
    SET_ERROR: 'SET_ERROR',
    REMOVE_ERROR: 'REMOVE_ERROR',
    RESET_ALL: 'RESET_ALL'
}

export const TakeOverFormFields = {
    make: 'make',
    model: 'model',
    fuel: 'fuel',
    firstRegistration: 'firstRegistration',
    licencePlate: 'licencePlate',
    mileage: 'mileage',
    exteriorQuality: 'exteriorQuality',
    interiorQuality: 'interiorQuality',
    interiorMaterial: 'interiorMaterial',
    interiorColor: 'interiorColor',
    exteriorColor: 'exteriorColor',
    images: 'images',
    damages: 'damages',
    first_name: 'first_name',
    last_name: 'last_name',
    email: 'email',
    phone: 'phone',
    privacy: 'privacy',
    message: 'message'
}

const initialState = {
    step: 1,
    vehicle: {
        [TakeOverFormFields.make]: '',
        [TakeOverFormFields.model]: '',
        [TakeOverFormFields.fuel]: '',
        [TakeOverFormFields.firstRegistration]: new Date(),
        [TakeOverFormFields.licencePlate]: '',
        [TakeOverFormFields.mileage]: '',
        [TakeOverFormFields.exteriorQuality]: '',
        [TakeOverFormFields.interiorQuality]: '',
        [TakeOverFormFields.interiorMaterial]: '',
        [TakeOverFormFields.interiorColor]: '',
        [TakeOverFormFields.exteriorColor]: '',
        [TakeOverFormFields.images]: [],
        [TakeOverFormFields.damages]: []
    },
    user: {
        [TakeOverFormFields.first_name]: '',
        [TakeOverFormFields.last_name]: '',
        [TakeOverFormFields.email]: '',
        [TakeOverFormFields.phone]: '',
        [TakeOverFormFields.privacy]: false,
        [TakeOverFormFields.message]: '',
    },
    errors: {},
}

function takeoverReducer(state, action) {
    switch (action.type) {
        case ACTIONS.NEXT_STEP:
            return {
                ...state,
                step: state.step + 1
            };
        case ACTIONS.PREV_STEP:
            return {
                ...state,
                step: state.step - 1
            }
        case ACTIONS.SET_VEHICLE:
            return {
                ...state,
                vehicle: {...state.vehicle, [action.field]: action.payload}
            }
        case ACTIONS.SET_IMAGES:
            return {
                ...state,
                vehicle: {...state.vehicle, [TakeOverFormFields.images]: [...state.vehicle.images.filter(img => img.imageType !== action.payload.imageType), action.payload]}
            }
        case ACTIONS.SET_DAMAGES:
            return {
                ...state,
                vehicle: {...state.vehicle, [TakeOverFormFields.damages]: [...state.vehicle.damages.filter(img => img.imageType !== action.payload.imageType), action.payload]}
            }

        case ACTIONS.SET_USER:
            return {
                ...state,
                user: {...state.user, [action.field]: action.payload}
            }
        case ACTIONS.SET_ERROR:
            return {
                ...state,
                errors: {...state.errors, [action.field]: action.payload}
            }
        case ACTIONS.REMOVE_ERROR:
            return {
                ...state,
                errors: omit(state.errors, [action.field])
            }
        case ACTIONS.RESET_ALL:
            return {
                ...state,
                ...initialState
            }
        default:
            throw new Error();
    }
}

export const StateContext = React.createContext();
export const DispatchContext = React.createContext();

const Takeover = ({handlePrivacy, context}) => {
    const [state, dispatch] = useReducer(takeoverReducer, initialState);
    return (
        <DispatchContext.Provider value={dispatch}>
            <StateContext.Provider value={state}>
                <TakeoverWizard context={context} handlePrivacy={handlePrivacy}/>
            </StateContext.Provider>
        </DispatchContext.Provider>
    )
};

Takeover.defaultProps = {
};

export default Takeover;
