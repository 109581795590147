import {cloneDeep, filter, findKey, forEach, groupBy, indexOf, invert, isEmpty, keys, last, map, orderBy, replace, startCase} from "lodash";
import {ActionTypes, ContactTypes, DAYS, SocialMediaTypes, vehicleSliderFilterTypes} from "./constants";
import fetch from "cross-fetch";
import Cookies from 'js-cookie'


const validateUrl = (context, type, url, searchParam = undefined, block = undefined) => {
        let result = '';
        const culture = (context && context.culture) ? context.culture : 'nl';


        if (type === ActionTypes.Page) {
            let translatedUrl = undefined;
            try {
                if (context && context.urlsMultiLanguage && context.urlsMultiLanguage[url]) {
                    translatedUrl = (context.urlsMultiLanguage[url][culture]) ? context.urlsMultiLanguage[url][culture] : url;

                    if (url === "/" && !context.multiLanguage) {
                        translatedUrl = url;
                    }

                    if (translatedUrl === "") {
                        translatedUrl = url;
                    }
                } else {
                    translatedUrl = url;
                }
            } catch (ex) {
                translatedUrl = url;
            }

            if (translatedUrl === 'index' || translatedUrl === '/') {
                result = (context.multiLanguage) ? `/${culture}` : '/';
            } else if (context.multiLanguage) {
                result = `/${culture}/${translatedUrl}`
            } else {
                result = `/${translatedUrl}`
            }

            if (searchParam && searchParam.startsWith('?')) {
                result = `${result}${searchParam}`;
            } else if (searchParam) {
                result = `${result}?${searchParam}`
            } else if (block) {
                result = `${result}#b${block}`
            }
        } else {
            result = url;

            if (searchParam) {
                result = `${result}?${searchParam}`
            }
        }
        return result;
    }
;

const navigate = (context, url, type, searchParam = undefined, block = undefined, newWindow = false) => {
    if (context && !context.builder) {
        const gatsby = require('gatsby-link');
        const validatedUrl = validateUrl(context, type, url, searchParam, block);

        if (type === ActionTypes.Page && !newWindow) {
            gatsby.navigate(validatedUrl);
        } else if (type === ActionTypes.Page && newWindow) {
            window.open(`${window.location.origin}${validatedUrl}`);
        } else {
            window.open(url);
        }
    }
};

export const navigateOtherLanguage = (context, lng, origin, search) => {
    if (context && !context.builder) {
        const gatsby = require('gatsby-link');

        let pageKey = origin;
        if(pageKey !== 'home') {
            const currentLanguageFromUrl = origin.substr(1, 2)
            const currentPageName = origin.substr(4, origin.length)
            pageKey = findKey(context.urlsMultiLanguage, {[currentLanguageFromUrl]: currentPageName}) || currentPageName;
        }

        let tmpContext = cloneDeep(context)
        tmpContext.culture = lng
        const url = validateUrl(tmpContext, ActionTypes.Page, pageKey, search)

        // gatsby.navigate(url, {replace: true});
        gatsby.navigate(url, {replace: true});
    }
}

export const getUrlFromAction = (context, action) => {
    return validateUrl(context, action.type, action.url, action.search_param, action.block)
};

export const navigateAction = (context, action) => {
    navigate(context, action.url, action.type, action.search_param, action.block);
};

export const navigatePage = (context, url, searchParam = undefined, block = undefined, newWindow = false) => {
    navigate(context, url, ActionTypes.Page, searchParam, block, newWindow);
};

export const getStickiesOffset = (className = 'sticky-header') => {
    try {
        let offset = 0;
        const stickies = document.getElementsByClassName(className);
        for (let key in stickies) {
            const element = stickies[key];
            if (element && element.offsetHeight && element.offsetHeight > 0) {
                offset += element.offsetHeight
            }
        }
        return offset
    } catch (ex) {
        return 0;
    }
};

export const scrollTo = el => {
    const yOffset = getStickiesOffset();
    const y = (el.getBoundingClientRect().top + window.pageYOffset) - yOffset;

    window.scrollTo({top: y, behavior: 'smooth'});
}


export const getBodyIcon = (key) => {
    switch (key) {
        case 'off-road/pick-up':
            return 'icon-off_road';
        case 'compact':
            return 'icon-compact';
        case 'station_wagon':
            return 'icon-station_wagon';
        case 'sedans':
            return 'icon-sedan';
        case 'coupe':
            return 'icon-coupe';
        case 'convertible':
            return 'icon-convertible';
        case 'van':
        case 'transporter':
            return 'icon-bus_van';
        case 'other':
            return "icon-compact"
        default:
            return ""
    }
};

export const formatNumber = number => {
    let nr = parseFloat(number);
    return nr.toLocaleString('nl-NL', {maximumFractionDigits: 2})
};

export const formatPrice = (price, currency = "€") => {
    return `${currency}${formatNumber(price)}`
};

export const hasWindow = () => {
    let hasWindow = false;
    try {
        if (window) {
            hasWindow = true;
        }
    } catch (ex) {
        hasWindow = false;
    }

    return hasWindow
};

export const getUrlParamValueFromWindow = (field) => {
    let hasWindow = false;
    try {
        if (window) {
            hasWindow = true;
        }
    } catch (ex) {
        hasWindow = false;
    }


    if (hasWindow) {
        return getUrlParams(window.location.search)[field]
    } else {
        return ""
    }

};


export const getUrlParams = (params_string) => {
    const vars = {};
    params_string.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
        vars[key] = value;
    });
    return vars;
};

export const b64DecodeUnicode = str => {
    return decodeURIComponent(atob(str).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
};

export const updateLastActivity = (updatedActivity) => {
    const activities = getFromStorage('activities') !== null ? getFromStorage('activities') : [];
    if (activities.length > 0) {
        activities.pop();
        const updatedActivities = [...activities, updatedActivity];
        saveToStorage('activities', updatedActivities);
    }
};

export const getLastActivity = () => {
    const activities = getFromStorage('activities') !== null ? getFromStorage('activities') : [];
    return last(activities);
};

export const currentDateTime = () => {
    const currentDate = new Date();
    return currentDate.getDate() + "/"
        + (currentDate.getMonth() + 1) + "/"
        + currentDate.getFullYear() + " @ "
        + currentDate.getHours() + ":"
        + currentDate.getMinutes() + ":"
        + currentDate.getSeconds();
};

// export const postData = async (token, url, formData) => {
//     return fetch(`${url}`, {
//         method: 'POST',
//         headers: {
//             "Authorization": `Token ${token}`
//         },
//         body: formData
//     })
// };

export const postRequestWithAuth = (token, url, formData, successCb = null, errorCb = null, headers = {}) => {

    fetch(`${url}`, {
        method: 'POST',
        headers: {
            "Authorization": `Token ${token}`,
            ...headers
        },
        body: formData
    })
        .then((response) => response.json())
        .then((data) => {
            if (data.result === 'Ok') {
                successCb !== null && successCb(data);
            }
        })
        .catch((error) => {
            errorCb !== null && errorCb(error);
        });
};

export const getRequestWithAuth = (token, url, successCb = null, errorCb = null) => {
    fetch(`${url}`, {
        method: 'GET',
        headers: {
            "Authorization": `Token ${token}`
        },
    })
        .then((response) => response.json())
        .then((data) => {
            if (data.result === 'Ok') {
                successCb !== null && successCb(data);
            }
        })
        .catch((error) => {
            errorCb !== null && errorCb(error);
        });
};

export const saveToStorage = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data));
};

export const removeFromStorage = (key) => {
    localStorage.removeItem(key)
};

export const getFromStorage = (key) => {
    return JSON.parse(localStorage.getItem(key));
};

export const saveToSessionStorage = (key, data) => {
    sessionStorage.setItem(key, JSON.stringify(data))
};

export const getFromSessionStorage = (key) => {
    return JSON.parse(sessionStorage.getItem(key))
};

export const getWindowPathLocation = (context) => {
    if (context.builder) {
        return ""
    } else {
        return window.location.pathname.replace('/', '');
    }
};

export const getCookie = (name) => {
    return Cookies.get(name);
};

export const hasGdprCookiesSet = () => {
    const cookie = Cookies.get('GDPR_ANALYTICS');
    return cookie !== undefined;
};

export const hasValue = value => (value && value !== '') ? true : false;


export const isValidEmail = value => {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(value)
}

export const isPhoneValid = value => {
    return /^[- +()]*[0-9][- +()0-9]*$/.test(value)
}

export const getIcon = (type) => {
    let icon = "far fa-ellipsis-v";
    if (type === ContactTypes.Address) icon = "fal fa-map-marker-alt";
    if (type === ContactTypes.Mail) icon = "fal fa-envelope";
    if (type === ContactTypes.Telephone) icon = "fal fa-phone";
    if (type === ContactTypes.Hours) icon = "fal fal fa-clock";
    return icon;
};

export const addParameterToString = (string, param) => {
    if (string.length === 0) {
        return `${param}`
    } else {
        return `${string}&${param}`
    }
};

export const getSocialIcon = (type) => {
    let icon = "";
    if (type === SocialMediaTypes.Facebook) icon = "fab fa-facebook-square";
    if (type === SocialMediaTypes.Twitter) icon = "fab fa-twitter-square";
    if (type === SocialMediaTypes.Instagram) icon = "fab fa-instagram-square";
    if (type === SocialMediaTypes.LinkedIn) icon = "fab fa-linkedin";
    return icon;
};

export const base64ToFile = (dataUrl, fileName) => {
    let arr = dataUrl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, {type: mime});
};

export const fileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export const getEmptyContainers = (elements_per_row, total_elements) => {
    const totalEmptyContainers = elements_per_row - (total_elements % elements_per_row);
    let emptyContainers = [];
    for (let i = 0; i < totalEmptyContainers; i++) {
        emptyContainers.push(i)
    }
    return emptyContainers;

};

export const getTranslationFromJson = (jsonString, culture) => {
    const parsed = JSON.parse(jsonString);
    return (parsed[culture]) ? parsed[culture] : parsed['en']
};

export const mapFiltersArrayToObject = (filters, context) => {
    const result = {};
    forEach(filter(filters, (f) => f.type !== ""), (filter) => {
        if (filter.type === vehicleSliderFilterTypes.reference) {
            if (context && context.reference) {
                result[filter.type] = context.reference
            } else {
                result[filter.type] = ""
            }
        } else if (filter.value !== "") {
            const value = (filter.value.key) ? filter.value.key : filter.value;
            result[filter.type] = value
        }
    });
    return result;
};


export const mapArrayToString = (arr, separator = ',') => {
    let result = ""
    forEach(arr, (item) => {
        if (result === "") {
            result = item
        } else {
            result = `${result}${separator}${item}`
        }
    })
    return result;
}

export function formatString() {
    if (arguments.length === 0) {
        throw "No arguments";
    }
    const string = arguments[0];
    const lst = string.split("{}");
    if (lst.length !== arguments.length) {
        throw "Placeholder format mismatched";
    }
    let string2 = "";
    let off = 1;
    for (let i = 0; i < lst.length; i++) {
        if (off < arguments.length) {
            string2 += lst[i] + arguments[off++]
        } else {
            string2 += lst[i]
        }
    }
    return string2;
}


const mapHoursToString = (hours) => {
    let result = ""
    const sortedHours = orderBy(hours, 'start')

    forEach(sortedHours, (item) => {
        const str = `${item.start}-${item.stop}`

        if (result !== "")
            result = `${result}_${str}`
        else
            result = str
    })

    return result;
}

const findLastEqualOpeningsHoursOfDay = (day, hours, openingsHours) => {
    const otherDays = filter(keys(DAYS), (d) => d !== day)
    const hoursString = mapHoursToString(hours);

    let lastEqualDay = day
    forEach(otherDays, (d) => {
        const hoursForDay = openingsHours[d]
        const hoursForDayString = mapHoursToString(hoursForDay)

        if (hoursString === hoursForDayString) {
            lastEqualDay = d
        }
    })

    return lastEqualDay;
}

export const mapOpeningHours = (t, openingsHours, short=false) => {
    if (isEmpty(openingsHours)) {
        return []
    }

    let currentIndex = 0;
    const result = []
    const workingDayKeys = keys(DAYS);

    const groupedByDay = groupBy(openingsHours, 'day');
    forEach(workingDayKeys, (workingDay) => {
        if (currentIndex === indexOf(workingDayKeys, workingDay)) {
            const hoursForDay = groupedByDay[workingDay]
            const lastEqualDayWithOpeningsHours = findLastEqualOpeningsHoursOfDay(workingDay, hoursForDay, groupedByDay)

            if (hoursForDay && hoursForDay.length > 0) {
                if (workingDay !== lastEqualDayWithOpeningsHours) {
                    currentIndex = indexOf(workingDayKeys, lastEqualDayWithOpeningsHours) + 1
                    let label = formatString(t('{} to {}'), startCase(getDayTranslation(t,workingDay)), startCase(getDayTranslation(t,lastEqualDayWithOpeningsHours)));
                    if(short){
                        label = formatString(t('{} - {}'), startCase(getDayTranslationShort(t,workingDay)), startCase(getDayTranslationShort(t,lastEqualDayWithOpeningsHours)))
                    }

                    result.push({
                        label:  label,
                        hours: hoursForDay,
                        closed: false,
                    })
                } else {
                    currentIndex = currentIndex + 1;
                    let label = startCase(getDayTranslation(t, workingDay));
                    if(short){
                        label = startCase(getDayTranslationShort(t, workingDay))
                    }
                    result.push({
                        label: label,
                        hours: hoursForDay,
                        closed: false
                    })
                }
            } else {
                currentIndex = currentIndex + 1;
                let label = startCase(getDayTranslation(t, workingDay));
                if(short){
                    label = startCase(getDayTranslationShort(t, workingDay))
                }
                result.push({
                    label: label,
                    hours: hoursForDay,
                    closed: true,
                })
            }
        }
    })

    return result;
}

export const mapMarkers = (dealerships) => {
    return map(filter(dealerships, (d) => d.latitude && d.longitude), (d) => ({
        'lat': d.latitude,
        'lng': d.longitude,
    }));
}

const contentFormatFields = {
    'vehicle_count': 'vehicle_count',
}

const getValueForField = (field, data) => {
    switch (field) {
        case contentFormatFields.vehicle_count:
            return (data.vehicle_count) ? data.vehicle_count : "";
        default:
            return ""
    }
};

export const replaceAll = (source, search, replacement) => {
    if (source === undefined) {
        return source
    }
    return replace(source, new RegExp(search, 'g'), replacement);
};

export const parseHtml = (html, data) => {
    if (data === undefined)
        return html;

    const availableFields = keys(contentFormatFields)
    let result = html;
    forEach(availableFields, (field) => {
        result = replaceAll(result, `{{${field}}}`, getValueForField(field, data));
    });

    return result;
};

export const getDayTranslation = (t, day) => {
    switch (day) {
        case DAYS.mon:
            return t('Monday');
        case DAYS.tue:
            return t('Tuesday');
        case DAYS.wed:
            return t('Wednesday');
        case DAYS.thu:
            return t('Thursday');
        case DAYS.fri:
            return t('Friday');
        case DAYS.sat:
            return t('Saturday');
        case DAYS.sun:
            return t('Sunday');
        default:
            return ""
    }
}

export const getDayTranslationShort = (t, day) => {
    switch (day) {
        case DAYS.mon:
            return t('Mon');
        case DAYS.tue:
            return t('Tue');
        case DAYS.wed:
            return t('Wed');
        case DAYS.thu:
            return t('Thu');
        case DAYS.fri:
            return t('Fri');
        case DAYS.sat:
            return t('Sat');
        case DAYS.sun:
            return t('Sun');
        default:
            return ""
    }
}

export const getVehicleDetailUrl = (vehicle, context) => {
    const origin = window.location.origin;
    let vehicleUrl = ""
    if(context.urlsMultiLanguage) {
        vehicleUrl = `${origin}/${context.urlsMultiLanguage[context.urls.vehicleDetail][context.culture]}?reference=${vehicle.reference}`
    } else {
        vehicleUrl = `${origin}/${context.urls.vehicleDetail}?reference=${vehicle.reference}`
    }
    return vehicleUrl
}

export const getNumberOfColumns = (width) => {
    let columns = 2
    if(width > 768) columns = 1
    if(width > 940) columns = 2
    if(width > 1250) columns = 3
    if(width > 1670) columns = 4
    if(width > 2000) columns = 5
    if(width > 2500) columns = 6
    return columns;
}



