import React from 'react';
import {MarkupText} from "../../../core";

const ParagraphBlack = ({text, context}) => {
    return (
        <div className="flex justify-center items-center bg-primary text-white text-3xl px-5 md:px-0 py-10 md:py-16 " style={{minHeight: 80,  overflow: 'hidden'}}>
            <MarkupText text={text} context={context} />
        </div>
    );
};

ParagraphBlack.human = {
    "nl": "",
    "fr": "",
    "en": "",
};

ParagraphBlack.preview = ParagraphBlack;

export default ParagraphBlack;
