import React from 'react';

const ButtonWithIcon = ({disabled, className, onClick, loading, loadingText, text, icon, iconPosition}) => {
    let iconContent = "";
    if(icon){
        iconContent = iconPosition === 'right' ? <i className={`${icon} ml-3`} /> : <i className={`${icon} mr-3`}/>
    }
    const buttonContent = iconPosition === 'right' ? <React.Fragment>{text} {iconContent}</React.Fragment> : <React.Fragment>{iconContent} {text}</React.Fragment>;
    const loadingContent = loadingText;

    return (
        <button type="button" className={`${disabled ? "cursor-default opacity-50" : "cursor-pointer hover:text-gray-400"} bg-primary text-white outline-none py-2 px-4 rounded-none text-center ${className}`} disabled={disabled} onClick={onClick}>
            {loading ? loadingContent : buttonContent}
        </button>
    );
};

ButtonWithIcon.defaultProps = {
    disabled: false,
    loading: false,
    loadingText: "Bezig...",
    iconPosition: 'right',
    className: ""
};

export default ButtonWithIcon;
