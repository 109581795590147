import React, {useState} from 'react';
import {getFromStorage} from "../../../utils";
import ButtonWithIcon from "../../input/ButtonWithIcon";
import LoadingIndicator from "../../indicators/LoadingIndicator";
import {VEHICLE_QUERY} from "../../../hooks/useVehicleDetails";
import {ErrorIndicator} from "../../indicators";
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {INITIALIZE_PAYMENT_MUTATION, MUTATION, QUERY} from "./cms";
import {useTranslation} from "react-i18next";


const Payment = ({error, reservationData, onBack, terms, vehicle, reservationDays, context, paymentLogos, renderPayment}) => {
    const [paymentError, setPaymentError] = useState(error);
    const [sending, setSending] = useState(false);
    const [checkingAvailability, setCheckingAvailability] = useState(false);
    const {executeRecaptcha} = useGoogleReCaptcha();
    const {t} = useTranslation();

    const handlePayment = async (vehicle, reservationReference) => {
        if (!executeRecaptcha) {
            return;
        }
        setSending(true);
        const token = await executeRecaptcha("handling_payment");
        const origin = window.location.origin;

        let redirectUrl = ""
        if(context.urlsMultiLanguage) {
            redirectUrl = `${origin}/${context.urlsMultiLanguage[context.urls.vehicleReservation
                ][context.culture]}?vehicle=${vehicle.reference}&reference=${reservationReference}`
        } else {
            redirectUrl = `${origin}/${context.urls.vehicleReservation}?vehicle=${vehicle.reference}&reference=${reservationReference}`
        }


        const headers = {
            'Captcha': token,
            'Origin': origin
        }

        MUTATION(INITIALIZE_PAYMENT_MUTATION, {reference: reservationReference, redirectUrl: redirectUrl}, (res) => {
            if (res.data.initializeReservationPayment.ok) {
                window.location.href = res.data.initializeReservationPayment.paymentUrl;
            } else {
                const msg = res.data.initializeReservationPayment.paymentUrl === null ? t('Recaptcha error.') : t('Payment has failed.');
                setPaymentError(msg)
                setSending(false);
            }
        }, error => {
            setPaymentError(t('The payment could not be initialized.'))
            setSending(false);
        }, headers)
    }

    const onPayClick = e => {
        e.preventDefault();
        const storageData = getFromStorage('reservation');
        const reservationReference = storageData !== null ? storageData.reservationReference : null;
        if (reservationReference === null) {
            setPaymentError(t('Reservation reference could not be found.'));
        } else {
            // CHECK IF IT IS RESERVED
            setCheckingAvailability(true);

            QUERY(VEHICLE_QUERY, {reference: vehicle.reference}, (res) => {
                    if (res.data.vehicle.reserved) {
                        setCheckingAvailability(false);
                        setPaymentError(t('This vehicle has already been reserved.'));
                    } else {
                        setCheckingAvailability(false);
                        handlePayment(vehicle, reservationReference);
                    }
                }, (error) => {
                    setCheckingAvailability(false);
                    setPaymentError(t('It could not be checked whether the car has already been reserved.'));
                },
                {'Accept-Language': context.culture})
        }
    };

    const isLoading = checkingAvailability || sending;
    return renderPayment && typeof renderPayment === 'function'
        ? renderPayment(onBack, reservationData.stepOne, isLoading, paymentError, onPayClick, reservationDays)
        : <div className="flex flex-col">
            <div className="flex flex-col flex-1 relative h-full">
                <div className="flex flex-col mb-10 items-start">
                    <button className="text-gray-600 hover:text-blue-800 mb-5" onClick={() => onBack()}><i className="fad fa-long-arrow-left mr-2"/> Ga terug</button>
                    <div className="text-gray-800 text-2xl">Betaling</div>
                </div>

                {paymentError && <div className="-mt-5 mb-5"><ErrorIndicator error={paymentError}/></div>}

                <div className="flex flex-col mb-5 text-gray-500">
                    <div className="text-xl mb-3">Uw gegevens</div>
                    <div className="flex mb-2  items-center">
                        <i className="fal fa-user-tag mr-2 text-sm w-6"/>
                        <div className="text-md">{reservationData.stepOne !== undefined ? reservationData.stepOne.firstName : ""} {reservationData.stepOne !== undefined ? reservationData.stepOne.lastName : ""}</div>
                    </div>
                    <div className="flex items-center mb-2 ">
                        <i className="fal fa-envelope text-sm mr-2 w-6"/>
                        <div className="text-md">{reservationData.stepOne !== undefined ? reservationData.stepOne.email : ""}</div>
                    </div>
                    <div className="flex items-center mb-2 ">
                        <i className="fal fa-phone text-sm mr-2 w-6"/>
                        <div className="text-md">{reservationData.stepOne !== undefined ? reservationData.stepOne.phone : ""}</div>
                    </div>
                </div>

                <div className="text-gray-700 text-sm mb-2 tracking-wide">De reservatiekost zal in mindering gebracht worden bij aankoop van de wagen of bij de financiering.</div>
                <div className="text-gray-700 text-sm mb-10 tracking-wide">*De reservatiekost zal <strong>niet worden terugbetaald</strong> wanneer u afziet van de aankoop en/of de aankoop na de {reservationDays} reservatiedagen doet.</div>


                <div className="flex justify-end w-full px-3 mt-12">
                    <ButtonWithIcon onClick={(e) => onPayClick(e)}
                                    text={isLoading ? <div className="flex items-center"><LoadingIndicator extraSmall/> <span className="ml-2">Bezig...</span></div> : "Betalen"}
                                    icon={isLoading ? null : "fad fa-check"}
                                    iconPosition="right"
                    />

                </div>

                <div className="text-gray-700 text-xs relative mt-12" style={{bottom: 10}}>{terms}</div>
            </div>
            {/*{paymentLogos.length > 0 && <div className="bg-white -ml-5 -mr-5 -mb-5 mt-10 p-5">*/}
            {/*    <div className="text-gray-800 text-2xl mb-5">Betalingsmogelijkheden</div>*/}
            {/*    <div className="flex">*/}
            {/*        {paymentLogos.map((paymentLogo, i) => {*/}
            {/*            return <img key={i} src={paymentLogo} alt="Payment" style={{maxHeight: 45}} className="mr-2"/>*/}
            {/*        })}*/}
            {/*    </div>*/}
            {/*</div>}*/}

        </div>
};

export default Payment;
