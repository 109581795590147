import {useState, useEffect} from 'react';
import {map, orderBy} from 'lodash';
import fetch from 'cross-fetch'

const MAKES_QUERY = `query Makes {
  makes {
    id
    name
  } 
}`;

export function useMakes() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [makes, setMakes] = useState(null);

    useEffect(() => {
        fetch('/graphql', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({query: MAKES_QUERY})
        })
            .then(resp => resp.json())
            .then((result) => {
                const {makes} = result.data;
                const sortedMakes =  orderBy(makes, ['name'], ['asc']);
                setMakes(sortedMakes);
                setLoading(false);
            })
            .catch(err => {
                setError("ERROR");
                setLoading(false);
            })
    }, []);

    return {
        makes,
        makeKeys: map(makes, m => m.name.toLowerCase()),
        loading: loading,
        error
    };
}
