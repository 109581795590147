import React, {useContext, useState} from 'react';
import {SelectField, ButtonWithIcon} from "../../../input";
import {ACTIONS, DispatchContext, StateContext, TakeOverFormFields} from "../Takeover";
import {interior, exteriorColors, interiorColors} from "../../../../constants";
import {scrollTo} from '../../../../utils';


const StepTwo = ({context}) => {
    const [activeTab, setActiveTab] = useState(1);
    const dispatch = useContext(DispatchContext);
    const {vehicle, errors} = useContext(StateContext);


    const isValid = () => {
        let isValid = true;
        if (!vehicle[TakeOverFormFields.exteriorQuality]) {
            dispatch({type: ACTIONS.SET_ERROR, field: TakeOverFormFields.exteriorQuality, payload: 'Dit veld is verplicht.'})
            isValid = false;
        }

        if (!vehicle[TakeOverFormFields.interiorQuality]) {
            dispatch({type: ACTIONS.SET_ERROR, field: TakeOverFormFields.interiorQuality, payload: 'Dit veld is verplicht.'})
            isValid = false;
        }

        if (!vehicle[TakeOverFormFields.interiorMaterial]) {
            dispatch({type: ACTIONS.SET_ERROR, field: TakeOverFormFields.interiorMaterial, payload: 'Dit veld is verplicht.'})
            isValid = false;
        }

        if (!vehicle[TakeOverFormFields.interiorColor]) {
            dispatch({type: ACTIONS.SET_ERROR, field: TakeOverFormFields.interiorColor, payload: 'Kies interieur kleur aub.'})
            isValid = false;
        }

        if (!vehicle[TakeOverFormFields.exteriorColor]) {
            dispatch({type: ACTIONS.SET_ERROR, field: TakeOverFormFields.exteriorColor, payload: 'Kies exterieur kleur aub.'})
            isValid = false;
        }

        return isValid
    };


    const onHandleChange = (field, value) => {
        dispatch({type: ACTIONS.SET_VEHICLE, field, payload: value})
        dispatch({type: ACTIONS.REMOVE_ERROR, field})
    }

    const onSubmit = e => {
        e.preventDefault();
        if(isValid()){
            dispatch({type: ACTIONS.NEXT_STEP})
        }else{
            // Scroll to tabs
            const elem = document.getElementById("container-tabs");
            if(elem){
                scrollTo(elem);
            }
        }
    };

    const onBack = () => {
        dispatch({type: ACTIONS.PREV_STEP})
    };

    const renderColorSelector = (colors, fieldName) => {
        return colors.map((color, i) => {
            const isSelected = vehicle[fieldName] && color.name === vehicle[fieldName];

            let bgColor = isSelected ? "border-primary" : "border-gray-200";
            bgColor = errors[fieldName] ? "border-red-400" : bgColor;
            return <div key={i} className={`rounded p-1 border ${bgColor} mr-2`} style={{padding: "0.2rem", width: 30, height: 30}} onClick={() => onHandleChange(fieldName, color.name)}>
                <div className="flex items-center justify-center w-full h-full rounded" style={{backgroundColor: color.code}}>
                    {isSelected && <i className="fad fa-check text-xs" />}
                </div>
            </div>
        })
    }

    const renderInteriorSelect = () => {
        let options = [];
        interior.map(item => options.push({id: item, text: item}));
        return <SelectField label="Bekleding interieur"
                            text="Bekleding interieur"
                            containerClass="w-full px-0 mt-6"
                            options={options}
                            selectedOption={vehicle[TakeOverFormFields.interiorMaterial] ? vehicle[TakeOverFormFields.interiorMaterial] : null}
                            onChange={e => onHandleChange(TakeOverFormFields.interiorMaterial, e.target.value)}
                            errorText={errors[TakeOverFormFields.interiorMaterial]}/>
    }

    return <form action="" onSubmit={e => onSubmit(e)} className=" flex flex-col  items-center">

        <div id="container-tabs" className="w-full bg-gray-50 border-r-2 border-l-2 border-t-2 border-gray-100 rounded flex flex-row mb-6">
            <div className={`flex w-1/2 items-center justify-center border-b-2 cursor-pointer mr-1 ${activeTab === 1 ? "border-primary" : "border-gray-100"} ${(errors[TakeOverFormFields.exteriorQuality] || errors[TakeOverFormFields.exteriorColor]) && "bg-red-100 text-red-600 border-red-600"} p-4 text-gray-800 text-base`} onClick={() => setActiveTab(1)}>Kwaliteit exterieur</div>
            <div className={`flex w-1/2 items-center justify-center border-b-2 cursor-pointer ${activeTab === 2 ? "border-primary" : "border-gray-100"} ${(errors[TakeOverFormFields.interiorQuality] || errors[TakeOverFormFields.interiorMaterial] || errors[TakeOverFormFields.interiorColor]) && "bg-red-100 text-red-600 border-red-600"} p-4 text-gray-800 text-base`} onClick={() => setActiveTab(2)}>Kwaliteit interieur</div>
        </div>

        {activeTab === 1 && <div className={`w-full flex flex-col px-0 mb-2 md:mb-0 ${errors[TakeOverFormFields.exteriorQuality] ? "text-red-400" : "text-gray-800"}`}>
            <div className="flex flex-col justify-between items-center">
                <div className={`flex w-full text-base ${errors[TakeOverFormFields.exteriorQuality] ? "text-red-400" : "text-gray-800"} items-center mb-3`}>Kwaliteit exterieur</div>
                <div className="flex flex-col w-full">
                    <div className={`mb-2 p-2 border-2 ${vehicle[TakeOverFormFields.exteriorQuality] === 1 ? "border-primary" : "border-gray-200"} ${errors[TakeOverFormFields.exteriorQuality] && "border-red-200"} rounded flex flex-row justify-center`}>
                        <div className="w-3/4 pr-2 text-gray-700 text-sm line-height-base">Mijn auto heeft zijn beste tijd gehad. Hij heeft vier wielen en we hebben samen veel goede herinneringen maar daar houdt het op. De auto vraagt wat verf, onderdelen en veel liefde. </div>
                        <div className="w-1/4 pl-2 flex items-center">
                            <div className={`${errors[TakeOverFormFields.exteriorQuality] ? "border-2 border-red-200" : "bg-outline hover:bg-primary hover:text-white"} py-1 px-2 rounded-none text-center w-full cursor-pointer`} onClick={() => onHandleChange(TakeOverFormFields.exteriorQuality, 1)}>Lekker ruig</div>
                        </div>
                    </div>

                    <div className={`mb-2 p-2 border-2 ${vehicle[TakeOverFormFields.exteriorQuality] === 2 ? "border-primary" : "border-gray-200"} ${errors[TakeOverFormFields.exteriorQuality] && "border-red-200"} rounded flex flex-row justify-center`}>
                        <div className="w-3/4 pr-2 text-gray-700 text-sm line-height-base">Mijn auto heeft al betere dagen gekend. Hij heeft zijn deel aan tegenslagen in het leven wel gehad en dat is te zien aan zijn look. Aan de buitenkant enkele blauwe plekken en kneuzingen. Langs binnen een beetje afgeleefd. Mijn vrienden vragen mij soms of ik al op zoek ben naar een nieuwe wagen en het begint zo stilaan gênant te worden. </div>
                        <div className="w-1/4 pl-2 flex items-center">
                            <div className={`${errors[TakeOverFormFields.exteriorQuality] ? "border-2 border-red-200" : "bg-outline hover:bg-primary hover:text-white"} py-1 px-2 rounded-none text-center w-full cursor-pointer`} onClick={() => onHandleChange(TakeOverFormFields.exteriorQuality, 2)}>Best ok</div>
                        </div>
                    </div>

                    <div className={`mb-2 p-2 border-2 ${vehicle[TakeOverFormFields.exteriorQuality] === 3 ? "border-primary" : "border-gray-200"} ${errors[TakeOverFormFields.exteriorQuality] && "border-red-200"} rounded flex flex-row justify-center`}>
                        <div className="w-3/4 pr-2 text-gray-700 text-sm line-height-base">De eerste liefdesperikelen tussen mij en mijn auto beginnen de kop op te steken. Hij ziet er nog prima uit maar die eerste vlek en schram zijn een doorn in het oog. </div>
                        <div className="w-1/4 pl-2 flex items-center">
                            <div className={`${errors[TakeOverFormFields.exteriorQuality] ? "border-2 border-red-200" : "bg-outline hover:bg-primary hover:text-white"} py-1 px-2 rounded-none text-center w-full cursor-pointer`} onClick={() => onHandleChange(TakeOverFormFields.exteriorQuality, 3)}>Goed</div>
                        </div>
                    </div>

                    <div className={`p-2 border-2 ${vehicle[TakeOverFormFields.exteriorQuality] === 4 ? "border-primary" : "border-gray-200"} ${errors[TakeOverFormFields.exteriorQuality] && "border-red-200"} rounded flex flex-row justify-center`}>
                        <div className="w-3/4 pr-2 text-gray-700 text-sm line-height-base">Liefde op het eerste gezicht. De wagen ziet er goed uit en rijdt vlot. Als ik op zondag pistolets ga halen bij de bakker krijg ik geregeld een compliment. </div>
                        <div className="w-1/4 pl-2 flex items-center">
                            <div className={`${errors[TakeOverFormFields.exteriorQuality] ? "border-2 border-red-200" : "bg-outline hover:bg-primary hover:text-white"} py-1 px-2 rounded-none text-center w-full cursor-pointer`} onClick={() => onHandleChange(TakeOverFormFields.exteriorQuality, 4)}>Uitstekend</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>}


        {activeTab === 2 && <div className={`w-full flex flex-col px-0 mb-2 md:mb-0 ${errors[TakeOverFormFields.interiorQuality] ? "text-red-400" : "text-gray-800"}`}>
            <div className="flex flex-col justify-between items-center">
                <div className={`flex w-full text-base ${errors[TakeOverFormFields.interiorQuality] ? "text-red-400" : "text-gray-800"} items-center mb-3`}>Kwaliteit interieur</div>
                <div className="flex flex-col w-full">
                    <div className={`mb-2 p-2 border-2 ${vehicle[TakeOverFormFields.interiorQuality] === 1 ? "border-primary" : "border-gray-200"} ${errors[TakeOverFormFields.interiorQuality] && "border-red-200"} rounded flex flex-row justify-center`}>
                        <div className="w-3/4 pr-2 text-gray-700 text-sm line-height-base">De zetels zijn herleid tot de puurste vorm van hun functie. Ik gebruik soms met rode wangen de term 'comfort'. De radio werkt als russian roulette, met een beetje geluk geraak je er wel. </div>
                        <div className="w-1/4 pl-2 flex items-center">
                            <div className={`${errors[TakeOverFormFields.interiorQuality] ? "border-2 border-red-200" : "bg-outline hover:bg-primary hover:text-white"} py-1 px-2 rounded-none text-center w-full cursor-pointer`} onClick={() => onHandleChange(TakeOverFormFields.interiorQuality, 1)}>Slecht</div>
                        </div>
                    </div>

                    <div className={`mb-2 p-2 border-2 ${vehicle[TakeOverFormFields.interiorQuality] === 2 ? "border-primary" : "border-gray-200"} ${errors[TakeOverFormFields.interiorQuality] && "border-red-200"} rounded flex flex-row justify-center`}>
                        <div className="w-3/4 pr-2 text-gray-700 text-sm line-height-base">We hebben de auto nu al zo lang dat we perfect weten welke vlek bij welke reis hoort. Elektronica moet nagekeken worden want dezelfde fouten blijven terugkomen. Met een tik van Assepoesters' staf kan de wagen nog een aantal jaren mee.  </div>
                        <div className="w-1/4 pl-2 flex items-center">
                            <div className={`${errors[TakeOverFormFields.interiorQuality] ? "border-2 border-red-200" : "bg-outline hover:bg-primary hover:text-white"} py-1 px-2 rounded-none text-center w-full cursor-pointer`} onClick={() => onHandleChange(TakeOverFormFields.interiorQuality, 2)}>Matig</div>
                        </div>
                    </div>

                    <div className={`mb-2 p-2 border-2 ${vehicle[TakeOverFormFields.interiorQuality] === 3 ? "border-primary" : "border-gray-200"} ${errors[TakeOverFormFields.interiorQuality] && "border-red-200"} rounded flex flex-row justify-center`}>
                        <div className="w-3/4 pr-2 text-gray-700 text-sm line-height-base">Een eerste vlek of veeg verraden de leeftijd van de wagen. De enige meerwaarde aan die eerste kras op het dashboard is de herinnering die eraan vast hangt.</div>
                        <div className="w-1/4 pl-2 flex items-center">
                            <div className={`${errors[TakeOverFormFields.interiorQuality] ? "border-2 border-red-200" : "bg-outline hover:bg-primary hover:text-white"} py-1 px-2 rounded-none text-center w-full cursor-pointer`} onClick={() => onHandleChange(TakeOverFormFields.interiorQuality, 3)}>Goed</div>
                        </div>
                    </div>

                    <div className={`p-2 border-2 ${vehicle[TakeOverFormFields.interiorQuality] === 4 ? "border-primary" : "border-gray-200"} ${errors[TakeOverFormFields.interiorQuality] && "border-red-200"} rounded flex flex-row justify-center`}>
                        <div className="w-3/4 pr-2 text-gray-700 text-sm line-height-base">De wagen ziet er als nieuw uit, ruikt fris en alle elektronica werkt.</div>
                        <div className="w-1/4 pl-2 flex items-center">
                            <div className={`${errors[TakeOverFormFields.interiorQuality] ? "border-2 border-red-200" : "bg-outline hover:bg-primary hover:text-white"} py-1 px-2 rounded-none text-center w-full cursor-pointer`} onClick={() => onHandleChange(TakeOverFormFields.interiorQuality, 4)}>Uitstekend</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>}
        {activeTab === 2 && renderInteriorSelect()}

        {activeTab === 1 && <div className="w-full px-0 mb-2 md:mb-0 mt-6">
            <label className={`block tracking-wide text-base font-bold mb-2 ${errors[TakeOverFormFields.exteriorColor] ? "text-red-400" : "text-gray-800"} `}> Kleur exterieur </label>
            <div className="flex flex-row relative flex-wrap ">
                {renderColorSelector(exteriorColors, TakeOverFormFields.exteriorColor)}
            </div>
        </div>}

        {activeTab === 2 && <div className="w-full px-0 mb-2 md:mb-0 mt-6">
            <label className={`block tracking-wide text-base font-bold mb-2 ${errors[TakeOverFormFields.interiorColor] ? "text-red-400" : "text-gray-800"} `}> Kleur interieur </label>
            <div className="flex flex-row relative">
                {renderColorSelector(interiorColors, TakeOverFormFields.interiorColor)}
            </div>
        </div>}

        <div className="flex justify-between w-full px-0 mt-10">
            <ButtonWithIcon onClick={(e) => onBack(e)}
                            text="Terug"
                            icon="fad fa-chevron-left"
                            iconPosition="left"
            />


            <ButtonWithIcon onClick={(e) => onSubmit(e)}
                            text="Volgende"
                            icon="fad fa-chevron-right"
                            iconPosition="right"
            />

        </div>
    </form>
};

export default StepTwo;
