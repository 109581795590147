import React, {useContext, useState, useEffect} from 'react';
import Fade from 'react-reveal/Fade';
import {b64DecodeUnicode} from '../../utils';
import {useLegalContent} from "../../hooks/useLegalContent";
import {useHeight} from "../../hooks/useHeight";
import DataContext from "../../store/DataContext";
import Cookies from 'js-cookie'
import {useIsMobile} from "../../hooks/useIsMobile";

const FinanceBanner = ({visible, location, context, renderContent}) => {
    const [isVisible, setVisible] = useState(false);
    const {content, loading, error} = useLegalContent(context.culture, visible);
    const {cookiesAccepted} = useContext(DataContext);
    const cookiesBannerHeight = useHeight(['cookies-banner'])
    const {isMobile} = useIsMobile();

    useEffect(() => {
        setVisible(visible);
    }, [visible])

    if (content === undefined || content === null || !context.financingText) {
        return null;
    }


    const bottomSpace = cookiesAccepted ? 0 : cookiesBannerHeight;
    let extraStyle;
    if (location === 'top') {
        extraStyle = {top: 0}
    } else {
        extraStyle = {bottom: isMobile ? 0 : bottomSpace}
    }

    const inAnHour = 1 / 24;
    const onAccept = () => {
        setVisible(false);
        // saveToStorage('legal', new Date())
        Cookies.set('LEGAL_ACCEPTED', true, {
            expires: inAnHour
        });
    }

    try {
        return <Fade bottom when={isVisible} innerZ={15} duration={500} collapse>

            {renderContent && typeof renderContent === 'function'
                ? renderContent(onAccept, content)
                : <div className={`flex flex-col finance-popup items-center justify-center text-white py-10 px-5 md:px-0 fixed left-0 right-0`} style={{zIndex: 99999, backgroundColor: "rgba(0, 0, 0, 0.9)", ...extraStyle}}>
                    <div className={`container px-5`} dangerouslySetInnerHTML={{__html: b64DecodeUnicode(content)}}/>
                    <div className="container flex items-end justify-end px-5">
                        <button
                            className="flex relative justify-center items-center h-10 bg-black text-white text-md font-medium py-2 px-4 rounded-none text-center hover:text-gray-300 black-and-white w-48 mt-5"
                            id="finance-popup-button"
                            onClick={() => onAccept()}>
                            <div className="flex items-center justify-center mr-2"><i className="fad fa-check text-white mr-2"/>Aanvaarden</div>
                        </button>
                    </div>
                </div>}

        </Fade>
    } catch (e) {
        return null;
    }
};

FinanceBanner.defaultProps = {
    language: 'nl',
};

export default FinanceBanner;
