import {useState, useEffect} from 'react';
import fetch from 'cross-fetch'

const AVAILABLE_MAKES_QUERY = `query AvailableMakes {
  availableMakes 
}`;

export function useAvailableMakes(culture) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [makes, setMakes] = useState(null);

    useEffect(() => {
        fetch('/graphql', {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Accept-Language': culture},
            body: JSON.stringify({query: AVAILABLE_MAKES_QUERY})
        })
            .then(resp => resp.json())
            .then((result) => {
                const {availableMakes} = result.data;
                setMakes(availableMakes);
                setLoading(false);
            })
            .catch(err => {
                setError("ERROR");
                setLoading(false);
            })
    }, []);

    return {
        makes,
        loading: loading,
        error
    };
}
