import React from 'react';

const WizardBlock = ({step, classnames, renderHeader}) => {
    const stepOneDone = step > 1;
    const stepTwoDone = step > 2;
    const stepThreeDone = step === 3;

    return renderHeader && typeof renderHeader === 'function'
        ? renderHeader(step)
        : <div className={`container mx-auto px-5 md:px-0 ${classnames}`}>
            <div className="h-18 flex justify-center flex-1 justify-between">
                <div className="w-30 flex flex-col items-center justify-between">
                    <div className={`w-10 h-10 bg-white border-2 ${stepOneDone ? "border-green-300" : "border-gray-500"} mx-auto rounded-full text-lg text-white flex items-center mb-2`}>
                                <span className={`${stepOneDone ? "text-green-500" : "text-gray-600"} text-center w-full`}>
                                    <i className="fal fa-user-edit w-full fill-current"/>
                                </span>
                    </div>
                    <div className={`${stepOneDone ? "text-green-500" : "text-gray-600"} hidden md:flex items-baseline `}>Stel jezelf voor</div>
                </div>
                <div className="h-10 w-1/6 md:w-1/3 align-center items-center align-middle content-center flex -mx-6">
                    <div className="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                        <div className={`${stepOneDone ? "bg-green-200" : "bg-gray-200"} text-xs leading-none py-1 text-center text-gray-900 rounded`} style={{width: "100%"}}/>
                    </div>
                </div>
                <div className="w-30 flex flex-col items-center justify-between">
                    <div className={`w-10 h-10 border-2 ${stepTwoDone ? "border-green-300" : "border-gray-500"} mx-auto rounded-full text-lg text-white flex items-center`}>
                                <span className={`${stepTwoDone ? "text-green-500" : "text-gray-600"} text-center w-full`}>
                                    <i className="fal fa-money-check-edit w-full fill-current"/>
                                </span>
                    </div>
                    <div className={`${stepTwoDone ? "text-green-500" : "text-gray-600"} hidden md:flex items-baseline`}>Betaling</div>
                </div>
                <div className="h-10 w-1/6 md:w-1/3 align-center items-center align-middle content-center flex -mx-6">
                    <div className="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                        <div className={`${stepTwoDone ? "bg-green-200" : "bg-gray-200"} text-xs leading-none py-1 text-center text-gray-900 rounded`} style={{width: "100%"}}/>
                    </div>
                </div>
                <div className="w-30 flex flex-col items-center justify-between">
                    <div className={`w-10 h-10 border-2 ${stepThreeDone ? "border-green-300" : "border-gray-500"} mx-auto rounded-full text-lg text-white flex items-center`}>
                                <span className={`${stepThreeDone ? "text-green-500" : "text-gray-600"} text-center w-full`}>
                                    <i className="fal fa-check w-full fill-current"/> </span>
                    </div>
                    <div className={`${stepTwoDone ? "text-green-500" : "text-gray-600"} hidden md:flex items-baseline`}>Bevestiging</div>
                </div>
            </div>
        </div>;
};

WizardBlock.defaultProps = {
    classnames: "my-10 md:my-20"
}

export default WizardBlock;
