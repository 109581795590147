import React, {useState} from "react";
import {settingsMenuItems} from "../../../data/constants";
import {Link} from "react-router-dom";

export const SettingsDropDown = () => {
    const [open, setOpen] = useState(false);

    return (
        <div className=" builder-cursor-pointer builder-relative z-50" onMouseLeave={() => setOpen(false)} style={{zIndex: 999999999}}>
            <div className='builder-bg-white builder-border builder-border-gray-300 builder-px-4 builder-py-2 builder-cursor-pointer' onClick={(() => setOpen(!open))}>
                <i className="fal fa-cog"/> Settings
            </div>

            {open && <div className="builder-absolute builder-bg-white builder-border builder-border-gray-300 builder-border-t-0 builder-bg-white builder-border-gray-200 builder-border builder-shadow-lg builder-p-4" style={{width: 200, left: -100, zIndex: 999999999}}>
                {settingsMenuItems.map((settingMenu, i) => {
                    return <Link key={i} to={settingMenu.url} className="builder-flex builder-flex-1 builder-items-center builder-capitalize hover:builder-text-gray-900 builder-text-gray-800 builder-h-8">
                        <i className={`mr-2 ${settingMenu.icon}`}/> {settingMenu.label}
                    </Link>
                })}
            </div>}

        </div>
    )
};

export default SettingsDropDown;
