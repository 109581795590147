import {useState, useEffect} from 'react';
import fetch from 'cross-fetch'
import {orderBy} from "lodash";

const MODELS_QUERY = `query Models ($makeId: Int!) {
  models (makeId: $makeId) {
    id
    name
  }
}`;

export function useModels(make_id=undefined) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [models, setModels] = useState(null);

    useEffect(() => {
        if(make_id !== undefined && make_id !== ''){
            setLoading(true);
            fetch('/graphql', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({query: MODELS_QUERY, variables: {makeId: make_id}})
            })
                .then(resp => resp.json())
                .then((result) => {
                    const {models} = result.data;
                    const sortedModels =  orderBy(models, ['name'], ['asc']);
                    setModels(sortedModels);
                    setLoading(false);
                })
                .catch(err => {
                    setError("ERROR");
                    setLoading(false);
                })
        }
    }, [make_id]);

    return {
        models,
        loading: loading,
        error
    };
}
