import React from 'react';
import {VehicleRequestForm} from '../../../core';
import {DefaultText, DefaultTitle} from "../../../core/constants";
import {MarkupText} from "../../../core";
import {navigatePage} from '../../../core/utils'

const VehicleRequest = ({title, text, form_footer, context}) => {
    return (
        <div className="flex items-center justify-center py-10 md:py-15">
            <div className="container flex flex-col items-center justify-center relative">
                <div className="flex flex-col mb-16">
                    <div className="px-5 md:px-0 mb-6 text-2xl text-primary">{title}</div>
                    <div className="px-5 md:px-0 text-md"><MarkupText text={text} context={context}/></div>
                </div>

                <VehicleRequestForm context={context}
                                    form_footer={form_footer}
                                    handleCalculateVehicleValue={() => navigatePage(context, context.urls.vehicleTakeOver)}
                                    handlePrivacy={() => navigatePage(context, context.urls.privacy, null, null, true)}/>
            </div>
        </div>)
};

VehicleRequest.defaultProps = {
    title: DefaultTitle,
    text: DefaultText,
    form_footer: DefaultText,
};

VehicleRequest.human = {
    "nl": "",
    "fr": "",
    "en": "",
};

VehicleRequest.preview = VehicleRequest;

export default VehicleRequest;
