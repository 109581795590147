import React, {useContext} from 'react';
import ButtonWithIndicator from "../../common/ButtonWithIndicator";
import {undoHandler} from "../../../data/actions";
import SettingsDropDown from "../../settings/components/SettingsDropDown";
import useBuilderActions from "../useBuilderActions";

const HeaderMenu = () => {
    const {publishWebsite, updateWebsite, loading} = useBuilderActions();


    const handleUndo = () => {
        undoHandler(dispatch)
    };


    return (
        <div className="builder-flex builder-flex-1 builder-items-center builder-justify-end builder-p-2 builder-cursor-pointer">
            <div className='builder-bg-white builder-border builder-border-gray-300 builder-px-4 builder-py-2 builder-mr-2' onClick={handleUndo}>
                <i className="fal fa-undo"/>
            </div>

            <SettingsDropDown/>

            <ButtonWithIndicator loading={loading}
                                 onClick={() => publishWebsite()}
                                 icon="fal fa-cloud-upload"
                                 text="Publish"
            />

            <ButtonWithIndicator loading={loading}
                                 onClick={() => updateWebsite()}
                                 icon="fal fa-save"
                                 text="Save"
                                 colorClass="builder-bg-primary builder-text-white"
                                 borderClass="builder-border builder-border-bg-blue-500"
            />
        </div>
    );
};

export default HeaderMenu;
