import {useState, useEffect, useContext} from 'react';
import fetch from 'cross-fetch'
import {filter, map, find, includes} from 'lodash';
import DataContext from "../store/DataContext";


const ALL_VEHICLES_QUERY_V2 = `query Vehicles($vehiclesFilter: VehicleFilterInputType, $filtersFilter: VehiclesFilterParamType) {
  vehicles(filter: $vehiclesFilter) {
        id
        reference     
        makeKey
        make
        modelKey
        model
        modelFamilyKey
        modelFamily
        version
        firstRegistrationMonth
        firstRegistrationYear
        mileage
        doors
        seats
        categoryKey
        category
        bodyKey
        body
        fuelKey
        fuel
        gearboxKey
        gearbox
        images  
        price
        brutoPrice
        monthlyPrice
        sold
        co2
        euroNorm       
        reserved
        dealership
        dealershipKey
        labels
        engineCc
  }
  
  vehiclesFilter(params: $filtersFilter) {
    dealerships {
      key
      description
      count
    }
    
    labels {
      key
      description
      count
    }
    
    makes {
      key
      description
      count
    }
    models {
      key
      makeKey
      description
      count
    }
    fuelTypes {
      key
      description
      description
      count
    }
    transmissionTypes {
      key
      description
      count
    }
    bodyTypes {
      key
      description
      count
    }
    categories {
      key
      description
      count
    }
    doors {
      key
      value
      count
    }
    seats {
      key
      value
      count
    }
    price {
      key
      minValue
      maxValue
    }
    monthlyPrice {
      key
      minValue
      maxValue
    }
    mileage {
      key
      minValue
      maxValue
    }
    year {
      key
      minValue
      maxValue
    }
  }
}`;


const ALL_VEHICLES_QUERY = `query Vehicles($vehiclesFilter: VehicleFilterInputType, $filtersFilter: FiltersFilterInputType) {
  vehicles(filter: $vehiclesFilter) {
        id
        reference     
        makeKey
        make
        modelKey
        model
        modelFamilyKey
        modelFamily
        version
        firstRegistrationMonth
        firstRegistrationYear
        mileage
        doors
        seats
        categoryKey
        category
        bodyKey
        body
        fuelKey
        fuel
        gearboxKey
        gearbox
        images  
        price
        brutoPrice
        monthlyPrice
        sold
        co2
        euroNorm       
        reserved
        dealership
        dealershipKey
  }
  
  filters(filter: $filtersFilter) {
        type
        key
        text
        value   
        custom
  }    
}`;

const getImages = (vehicle) => {

    if (vehicle.images) {
        return JSON.parse(vehicle.images)
    } else if (vehicle.image) {
        const image = JSON.parse(vehicle.image)
        return [image]
    }
    return []
}

export const map_vehicles = (vehicles) => {
    const mapped = map(vehicles, (vehicle) => {
        return {
            ...vehicle,
            images: getImages(vehicle)
        }
    });
    return mapped;
};

const mapRanges = (data, type) => {
    let min = 0;
    let max = 0;
    const item = find(data, (filter) => filter.type === type);

    try {
        const parts = item.value.split('-');
        min = parseFloat(parts[0]);
        max = parseFloat(parts[1]);
    } catch (ex) {

    }

    return {
        min,
        max,
    }
};

const map_filters = (filters) => {
    const makes = filter(filters, (filter) => filter.type === 'make');
    const models = filter(filters, (filter) => filter.type === 'model');
    const fuels = filter(filters, (filter) => filter.type === 'fuel');
    const gearboxes = filter(filters, (filter) => filter.type === 'gearbox');
    const bodies = filter(filters, (filter) => filter.type === 'body');
    const dealerships = filter(filters, (filter) => filter.type === 'dealership');
    const categories = filter(filters, (filter) => filter.type === 'category');
    const doors = filter(filters, (filter) => filter.type === 'doors');
    const seats = filter(filters, (filter) => filter.type === 'seats');

    const prices = mapRanges(filters, 'price');
    const monthlyPrices = mapRanges(filters, 'monthly');
    const mileages = mapRanges(filters, 'mileage');
    const years = mapRanges(filters, 'year');

    return {
        makes: makes,
        models: models,
        fuels: fuels,
        gearboxes: gearboxes,
        bodies: bodies,
        dealerships: dealerships,
        categories: categories,
        doors: doors,
        seats: seats,
        prices: prices,
        monthlyPrices: monthlyPrices,
        mileages: mileages,
        years: years,
    }
};

const map_v2_item = (field, item, custom_field = undefined) => {
    return {
        key: item.key,
        text: item.description,
        type: field,
        value: item.count,
        custom: (custom_field) ? item[custom_field] : ""
    }
};

const map_v2_range_item = (item) => {
    let min = 0;
    let max = 0;

    if (item && item.minValue !== undefined && item.maxValue !== undefined) {
        min = item.minValue
        max = item.maxValue
    }

    return {
        min,
        max,
    }
};

const map_v2_number_item = (field, item) => {
    return {
        key: item.key,
        text: item.value,
        type: field,
        value: item.count,
        custom: ""
    }
}


export const map_filters_v2 = (filters, dataSettings) => {
    const makes = map(filters.makes, (item) => map_v2_item('make', item));
    const models = map(filters.models, (item) => map_v2_item('model', item, 'makeKey'));
    const fuels = map(filters.fuelTypes, (item) => map_v2_item('fuel', item));
    const gearboxes = map(filters.transmissionTypes, (item) => map_v2_item('gearbox', item));
    const bodies = map(filters.bodyTypes, (item) => map_v2_item('body', item));
    const labels = map(filters.labels, (item) => map_v2_item('label', item));
    const dealerships = map(filters.dealerships, (item) => map_v2_item('dealership', item));
    const categories = map(filters.categories, (item) => map_v2_item('category', item));
    const doors = map(filters.doors, (item) => map_v2_number_item('doors', item));
    const seats = map(filters.seats, (item) => map_v2_number_item('seats', item));

    const prices = map_v2_range_item(filters.price);
    const monthlyPrices = map_v2_range_item(filters.monthlyPrice);
    const mileages = map_v2_range_item(filters.mileage);
    const years = map_v2_range_item(filters.year);

    const segments = map(filters.segments, (item) => map_v2_item('segment', item));

    const features = (filters.features) ? map(filters.features, (item) => map_v2_item('feature', item)) : [];
    const exterior_colors = (filters.exteriorColors) ? map(filters.exteriorColors, (item) => map_v2_item('exterior_color', item, 'hex')) : [];
    const interior_colors = (filters.interiorColors) ? map(filters.interiorColors, (item) => map_v2_item('interior_color', item, 'hex')) : [];
    const interior_upholsteries = (filters.interiorUpholsteries) ? map(filters.interiorUpholsteries, (item) => map_v2_item('interior_upholstery', item)) : [];
    const euro_norms = (filters.euroNorms) ? map(filters.euroNorms, (item) => map_v2_item('euronorm', item)) : [];
    const co2 = (filters.co2) ? map(filters.co2, (item) => map_v2_number_item('co2', item)) : [];

    // exterior_colors = graphene.List(VehiclesFilterColorItemType)
    // interior_colors = graphene.List(VehiclesFilterColorItemType)
    // interior_upholsteries = graphene.List(VehiclesFilterItemType)
    // euro_norms = graphene.List(VehiclesFilterItemType)

    return {
        makes: makes,
        models: models,
        fuels: fuels,
        gearboxes: gearboxes,
        bodies: bodies,
        dealerships: dealerships,
        labels: labels,
        categories: categories,
        doors: doors,
        seats: seats,
        prices: prices,
        monthlyPrices: monthlyPrices,
        mileages: mileages,
        years: years,
        features: features,
        exterior_colors: exterior_colors,
        interior_colors: interior_colors,
        interior_upholsteries: interior_upholsteries,
        euro_norms: euro_norms,
        co2: co2,
        segments: segments,
        dataSettings: dataSettings,

    }
};

export function useVehiclesData(culture, query, vehiclesFilter = null) {
    const contextData = useContext(DataContext);
    const [loading, setLoading] = useState(contextData.vehicles === undefined);
    const [dataFetchStarted, setDataFetchStarted] = useState(false);
    const [error, setError] = useState(null);


    useEffect(() => {
        if (contextData.vehicles === undefined && !dataFetchStarted || ((contextData.vehiclesCulture === undefined || contextData.vehiclesCulture !== culture) && !dataFetchStarted)) {
            setDataFetchStarted(true);
            setLoading(true);
            const qry = (query) ? query : ALL_VEHICLES_QUERY_V2
            fetch(
                '/graphql', {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json', 'Accept-Language': culture},
                    body: JSON.stringify({query: qry, variables: {vehiclesFilter: vehiclesFilter, filtersFilter: vehiclesFilter}}),
                })
                .then(res => res.json())
                .then(res => {
                    // contextData.setVehiclesData(map_vehicles(res.data.vehicles), map_filters(res.data.filters)); V1
                    contextData.setVehiclesData(map_vehicles(res.data.vehicles), map_filters_v2(res.data.vehiclesFilter, res.data.dataSettings), culture);
                    setLoading(false);
                    setDataFetchStarted(false);
                })
                .catch(error => {
                    setError("ERROR");
                    setLoading(false);
                    setDataFetchStarted(false);
                });
        }

    }, [contextData, culture]);

    return {
        vehicles: (contextData.vehicles) ? map(contextData.vehicles, (veh) => ({...veh, isFavorite: includes(contextData.favorites, veh.reference)})) : [],
        filterData: (contextData.filterData) ? contextData.filterData : undefined,
        loading: loading,
        error
    };
}
