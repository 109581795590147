import React from 'react';
import {Image} from "../../../../core";

const FeatureThreeCol_Preview = () => {
    return (
        <div className="flex item-center justify-center bg-white pb-10">
            <div className="container flex flex-col md:flex-row">
                <div className="flex flex-col items-center justify-center pr-5 md:pr-16 w-full md:w-1/3 pl-5 md:pl-0">
                    <Image url={require('../../../../core/assets/previews/snow-tire.jpg')} style={{height: 90, width: 90}}/>
                    <div className="flex items-center justify-center my-2 card-title text-center text-lg md:text-2xl" style={{height: 54, minHeight: 54}}>Lorem ipsum</div>
                    <div className="text-center text-sm md:text-md">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                </div>
                <div className="flex flex-col items-center justify-center pr-5 md:pr-16 w-full md:w-1/3 pl-5 md:pl-0">
                    <Image url={require('../../../../core/assets/previews/snow-tire.jpg')} style={{height: 90, width: 90}}/>
                    <div className="flex items-center justify-center my-2 card-title text-center text-lg md:text-2xl" style={{height: 54, minHeight: 54}}>Lorem ipsum</div>
                    <div className="text-center text-sm md:text-md">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                </div>
                <div className="flex flex-col items-center justify-center pr-5 md:pr-16 w-full md:w-1/3 pl-5 md:pl-0">
                    <Image url={require('../../../../core/assets/previews/snow-tire.jpg')} style={{height: 90, width: 90}}/>
                    <div className="flex items-center justify-center my-2 card-title text-center text-lg md:text-2xl" style={{height: 54, minHeight: 54}}>Lorem ipsum</div>
                    <div className="text-center text-sm md:text-md">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                </div>
            </div>
        </div>
    );
};

export default FeatureThreeCol_Preview;
