import React, {useEffect, useRef, useState} from 'react';
import './VehiclesSliderV2.css'
import {useVehiclesSliderData} from "../../../hooks/useVehiclesSliderData";
import {ErrorIndicator, LoadingIndicator} from "../../indicators";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import VehicleCard from "../VehicleCard";
import {useTranslation} from "react-i18next";
import {useSwipeable} from "react-swipeable";
import {useIsMobile} from "../../../hooks/useIsMobile";
import {mapFiltersArrayToObject} from "../../../utils";
import {defaultSliderFilters} from "../../../constants";


const VehiclesSliderV2 = ({context, className, style, renderLoading, renderError, filter, renderHeader, renderFooter, renderLegalText, onVehicleClick, renderVehicle, renderAdvertCard, renderPrevArrow, renderNextArrow}) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [sliderTotalWidth, setSliderTotalWidth] = useState(0);
    const [contentWidth, setContentWidth] = useState(0);

    const sliderFilter = filter ? filter : mapFiltersArrayToObject(defaultSliderFilters);

    const {width} = useWindowDimensions();
    const {isMobile} = useIsMobile();
    const {t} = useTranslation();
    const culture = (context && context.culture) ? context.culture : 'nl';
    const {vehicles, loading, error} = useVehiclesSliderData(culture, sliderFilter);

    const sliderContainerRef = useRef(null);
    const contentRef = useRef(null);
    const cardWidth = isMobile ? 300 : 330;
    const margin = 10;

    useEffect(() => {
        if (!loading && !error) {
            if (sliderContainerRef && sliderContainerRef.current) {
                setSliderTotalWidth(sliderContainerRef.current.clientWidth)
            }
            if (contentRef && contentRef.current) {
                setContentWidth(contentRef.current.clientWidth)
            }
        }
    }, [loading, error])

    const renderLoadingScreen = () => {
        return <div style={{minHeight: 200}}>
            {renderLoading && typeof renderLoading === 'function'
                ? renderLoading()
                : <LoadingIndicator/>
            }
        </div>
    };

    const renderErrorScreen = () => {
        return <div style={{minHeight: 200}}>
            {renderError && typeof renderError === 'function'
                ? renderError()
                : <ErrorIndicator error={t('Error! The vehicles could not be fetched.')}/>
            }
        </div>
    };

    const renderNoResults = () => {
        return <div className="flex items-center justify-center">
            <div className="w-full md:w-1/2 lg:w-1/3 bg-yellow-50 border-l-4 border-yellow-400 p-4">
                <div className="flex">
                    <div className="flex-shrink-0">
                        <i className="far fa-exclamation-triangle text-yellow-700"/>
                    </div>
                    <div className="ml-3">
                        <p className="text-sm text-yellow-700">
                            {t('There are no vehicles to show')}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    }

    const getMaxIndex = () => {
        // If we have advert card, increase index for 1
        return renderAdvertCard && typeof renderAdvertCard === 'function' ? vehicles.length : vehicles.length - 1;
    }

    const onPrev = () => {
        if (currentIndex > 0) {
            const newIndex = currentIndex > 0 ? currentIndex - 1 : getMaxIndex();
            setCurrentIndex(newIndex);
        }
    }

    const getOptions = () => {
        const scrollValue = cardWidth + margin;
        const currentLeft = currentIndex * scrollValue;
        const maxLeft = sliderTotalWidth - contentWidth < 1 ? 0 : sliderTotalWidth - contentWidth;
        return {
            currentLeft,
            maxLeft
        }
    }

    const onNext = () => {
        const {currentLeft, maxLeft} = getOptions();

        if (currentIndex < getMaxIndex() && currentLeft < maxLeft) {
            const newIndex = currentIndex < getMaxIndex() ? currentIndex + 1 : 0;
            setCurrentIndex(newIndex);
        }
    }

    const prevArrow = () => {
        if (renderPrevArrow && typeof renderPrevArrow === 'function') {
            return renderPrevArrow(() => onPrev())
        }
        return <div className="flex justify-center items-center h-full absolute z-20 top-0" style={{left: -49}}>
            <div className="flex justify-center items-center bg-black opacity-75 hover:opacity-100 cursor-pointer p-5 rounded-l-full"
                 style={{width: 50, height: 60}}
                 onClick={() => onPrev()}>
                <i className="fa fa-chevron-left text-2xl text-white"/>
            </div>
        </div>
    }

    const nextArrow = () => {
        if (renderNextArrow && typeof renderNextArrow === 'function') {
            return renderNextArrow(() => onNext())
        }
        return <div className="flex justify-center items-center h-full absolute z-20 top-0" style={{right: -50}}>
            <div className="flex justify-center items-center bg-black opacity-75 hover:opacity-100 cursor-pointer p-5 rounded-r-full"
                 style={{width: 50, height: 60}}
                 onClick={() => onNext()}>
                <i className="fa fa-chevron-right text-2xl text-white"/>
            </div>
        </div>
    }

    const handlers = useSwipeable({
        onSwipedLeft: () => onNext(),
        onSwipedRight: () => onPrev(),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });


    const renderSlider = (vehicles) => {
        const {currentLeft, maxLeft} = getOptions();
        const left = currentLeft >= maxLeft ? maxLeft : currentLeft;

        return <div className="relative" {...handlers}>
            {width > 1024 && prevArrow()}
            <div className="overflow-x-hidden w-full">
                <div ref={sliderContainerRef} className="slider-images-container flex flex-nowrap relative" style={{left: -left, width: 'fit-content'}}>
                    {vehicles.map((vehicle, i) => {
                        const isLast = vehicles.length - 1 === i;
                        return <div key={`slider-vehicle-${i}`} className="" style={{minWidth: cardWidth, maxWidth: cardWidth, marginRight: isLast ? 0 : margin}}>
                            {vehicle.custom !== undefined
                                ? renderAdvertCard && typeof renderAdvertCard === 'function' && renderAdvertCard()
                                : (renderVehicle && typeof renderVehicle === "function")
                                    ? renderVehicle(vehicle, onVehicleClick)
                                    : <VehicleCard vehicle={vehicle}
                                                   context={context}
                                                   onClick={() => onVehicleClick(vehicle)}
                                    />
                            }
                        </div>
                    })}
                </div>
            </div>
            {width > 1024 && nextArrow()}
        </div>
    }

    const renderContent = () => {
        let vehiclesToRender = [...vehicles];
        if (renderAdvertCard && typeof renderAdvertCard === 'function') {
            const random = Math.ceil(vehiclesToRender.length / 2);
            vehiclesToRender.splice(random, 0, {custom: true});
        }

        return <div className="relative">
            {/*{width > 1024 && prevArrow()}*/}
            <div ref={contentRef} className="flex flex-col w-full">
                {renderHeader && typeof renderHeader === 'function' && renderHeader(() => onPrev(), () => onNext())}
                {renderSlider(vehiclesToRender)}
                {renderFooter && typeof renderFooter === 'function' && renderFooter()}
                {renderLegalText && typeof renderLegalText === 'function' && renderLegalText()}
            </div>
            {/*{width > 1024 && nextArrow()}*/}
        </div>
    }


    return (
        <div className={`flex flex-col w-full relative ${className}`}>
            <div className="relative">
                <div className="flex flex-col w-full">
                    <div className="" style={{maxWidth: "100%", textAlign: "-webkit-center", width: context.builder ? width - 720 : "100%", ...style}}>
                        {loading && renderLoadingScreen()}
                        {!loading && error !== null && renderErrorScreen()}
                        {!loading && error === null && vehicles.length < 1 && renderNoResults()}
                        {!loading && error === null && vehicles.length > 0 && renderContent()}
                    </div>
                </div>
            </div>
        </div>
    );
};

VehiclesSliderV2.defaultProps = {
    style: {},
    className: 'z-20'
}

export default VehiclesSliderV2;
