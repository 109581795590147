import React, { useEffect, useState } from "react"
import {Range} from 'rc-slider';
import {find} from 'lodash';
import {formatPrice, formatNumber} from "../../../../utils";
import {FilterTypes} from "../../../../constants";



const RangeSlider = ({min, max, onChange, type, step, filters, disabled}) => {

    const [values, setValues] = useState([min, max]);

    useEffect(() => {
        if(find(filters, fil => fil.type === type) === undefined){
            setValues([min, max])
        }
    }, [filters]);

    const onSlide = value => {
        setValues(value)
    };

    let label = `${values[0]} - ${values[1]}`;
    if (type === FilterTypes.price || type === FilterTypes.monthlyPrice) label = `${formatPrice(values[0])} - ${formatPrice(values[1])}`;
    if (type === FilterTypes.mileage) label = `${formatNumber(values[0])} km - ${formatNumber(values[1])} km`;



    return (
        <div className="flex flex-col">
            <div className="flex flex-row text-gray-900 mb-3" style={{marginLeft: "-0.75rem"}}>
                <div className="mr-3">{label}</div>
            </div>
            <div>
                <Range allowCross={false}
                       defaultValue={[min, max]}
                       min={min}
                       max={max}
                       step={step !== undefined ? step : 1}
                       value={values}
                       disabled={disabled}
                       onChange={onSlide}
                       onAfterChange={() => onChange({type, values})}
                />
            </div>
        </div>
    )
}

RangeSlider.defaultProps = {
    disabled: false
}

export default RangeSlider
