import React from 'react';
import {Image} from "../../../../core";

const FeatureThreeColWithHeader_Preview = () => {
    return (
        <div className="flex item-center justify-center bg-primary">
            <div className="container flex flex-col p-5">
                <h2 className="uppercase text-left mb-5 text-3xl md:text-5xl font-bold text-center text-white">Block title</h2>
                <div className="text-center mb-5 md:mb-16 list-none text-white text-base">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>

                <div className="flex flex-col md:flex-row justify-between mb-10">
                    <div className="flex flex-col items-center justify-center hover:text-gray-700 mr-3 md:mr-3 w-full md:w-1/3 cursor-pointer ">
                        <Image url={require('../../../../core/assets/previews/snow-tire.jpg')} style={{width: '100%', height: '100%'}}/>
                        <div className="text-white my-2 card-title text-center text-lg md:text-2xl">Lorem ipsum</div>
                        <div className="text-white text-center text-sm md:text-md">Lorem Ipsum is simply dummy text of the printing</div>
                    </div>
                    <div className="flex flex-col items-center justify-center hover:text-gray-700 mr-3 md:mr-3 w-full md:w-1/3 cursor-pointer ">
                        <Image url={require('../../../../core/assets/previews/snow-tire.jpg')} style={{width: '100%', height: '100%'}}/>
                        <div className="text-white my-2 card-title text-center text-lg md:text-2xl">Lorem ipsum</div>
                        <div className="text-white text-center text-sm md:text-md"> Lorem Ipsum is simply dummy text of the printing</div>
                    </div>
                    <div className="flex flex-col items-center justify-center hover:text-gray-700 mr-3 md:mr-3 w-full md:w-1/3 cursor-pointer ">
                        <Image url={require('../../../../core/assets/previews/snow-tire.jpg')} style={{width: '100%', height: '100%'}}/>
                        <div className="text-white my-2 card-title text-center text-lg md:text-2xl">Lorem ipsum</div>
                        <div className="text-white text-center text-sm md:text-md"> Lorem Ipsum is simply dummy text of the printing</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FeatureThreeColWithHeader_Preview;
