import React, {useState, useEffect, useRef} from 'react';
import InputField from "../../input/Input";
import TextArea from "../../input/TextAreaField";
import ButtonWithIcon from "../../input/ButtonWithIcon";
import {ErrorIndicator, SuccessIndicator} from "../../indicators";
import {hasValue, isValidEmail, getWindowPathLocation, scrollTo} from "../../../utils";

import {useDealerContact} from "../../../hooks/useDealerContact";
import {keys} from "lodash";

const OfferFormFormFields = {
    firstName: 'firstName',
    lastName: 'lastName',
    email: 'email',
    phone: 'phone',
    message: 'message',
    privacy: 'privacy',
    origin: 'origin',
};

const initialData = {
    [OfferFormFormFields.firstName]: "",
    [OfferFormFormFields.lastName]: "",
    [OfferFormFormFields.email]: "",
    [OfferFormFormFields.phone]: "",
    [OfferFormFormFields.message]: "",
    [OfferFormFormFields.privacy]: false,
    [OfferFormFormFields.origin]: ""
};

const Offer = ({onClose, vehicle, context, onLoad = null, handlePrivacy, financialData}) => {
    const [data, setData] = useState(initialData);
    const {submit, loading, dealerContactTypes} = useDealerContact();
    const [success, setSuccess] = useState(false);
    const [errors, setErrors] = useState({});
    const blockRef = useRef(null);

    useEffect(() => {
        if (onLoad !== null) {
            onLoad(blockRef.current)
        }
    }, [])

    useEffect(() => {
        const offerForm = document.getElementById("offer-form");
        if(offerForm){
            scrollTo(offerForm)
        }

        if(success){
            setTimeout(() => {
                onClose();
            }, 2000)
        }


    }, [success, errors])

    const validate = () => {
        let errors = {};

        if (!hasValue(data.firstName)) {
            errors[OfferFormFormFields.firstName] = "Vul aub uw naam in.";
        }

        if (!hasValue(data.lastName)) {
            errors[OfferFormFormFields.lastName] = "Vul aub uw familienaam in.";
        }

        if (!hasValue(data.email)) {
            errors[OfferFormFormFields.email] = "Ongeldig e-mailadres";
        } else if (!isValidEmail(data.email)) {
            errors[OfferFormFormFields.email] = "Ongeldig e-mailadres";
        }

        if (!hasValue(data.phone)) {
            errors[OfferFormFormFields.phone] = "Vul aub uw telefoonnummer in.";
        }

        if (!hasValue(data.message)) {
            errors[OfferFormFormFields.message] = "Vul aub uw bericht in.";
        }

        if (data.privacy === false) {
            errors[OfferFormFormFields.privacy] = "";
        }

        setErrors(errors);

        return keys(errors).length <= 0;
    };

    const setValue = (field, value) => {
        setData({
            ...data,
            [field]: value
        });
    };

    const hasError = (field) => {
        return (errors && errors[field] !== undefined)
    };

    const onSubmit = e => {
        e.preventDefault();

        if (validate()) {
            setSuccess(false);

            const jsonData = JSON.stringify({
                first_name: data.firstName,
                last_name: data.lastName,
                email: data.email,
                phone: data.phone,
                message: data.message,
                privacy: data.privacy,
                //form_type: dealerContactTypes.vehicleInformation,
                vehicle: {
                    id: vehicle.id,
                    reference: vehicle.reference,
                    identification: vehicle.identification,
                },
                financial: (financialData) ? {
                    price: financialData.price,
                    deposit: financialData.deposit,
                    months: financialData.months,
                    financePrice: financialData.financePrice,
                } : {},
                origin: getWindowPathLocation(context),
            });

            submit(dealerContactTypes.vehicleOffer, jsonData)
                .then(result => {
                    if (result) {
                        setSuccess(true);
                        setErrors({});
                        setData(initialData);
                    } else {
                        setErrors({
                            result: 'Probleem met het verzenden van uw aanvraag.'
                        })
                    }
                })
        }
    };


    return (
        <form id="offer-form" ref={blockRef} action="" onSubmit={e => onSubmit(e)} className="bg-gray-100 p-3 relative">
            <div className="flex justify-between items-center w-full text-gray-800 mb-2">
                <div className="text-lg text-gray-800 text-1xl ">Offerte</div>
                <i className="hover:spin-animation fad fa-times text-xl cursor-pointer" onClick={onClose}/>
            </div>
            <div className="w-full text-md text-gray-600 mb-10">Bezorg ons uw gegevens en wij maken u een mooi voorstel!</div>


            {errors && errors['result'] &&
            <div className="mb-4 px-5 md:px-0" style={{minHeight: 32}}>
                <ErrorIndicator error={errors["result"]}/>
            </div>}

            {success && <div className="px-5 md:px-0 absolute" style={{top: 40, right: "0.75rem", left: "0.75rem"}}><SuccessIndicator success="Uw aanvraag is verzonden!"/></div>}


            <div className="flex mb-5">
                <InputField containerClass="w-full md:w-1/2 md:mb-0 mr-1"
                            label="Voornaam"
                            placeholder="John"
                            value={data.firstName}
                            onChange={(value) => setValue(OfferFormFormFields.firstName, value)}
                            errorText={errors[OfferFormFormFields.firstName]}
                            required={true}/>

                <InputField containerClass="w-full md:w-1/2 md:mb-0 ml-1"
                            label="Naam"
                            placeholder="Doe"
                            value={data.lastName}
                            onChange={(value) => setValue(OfferFormFormFields.lastName, value)}
                            errorText={errors[OfferFormFormFields.lastName]}
                            required={true}/>
            </div>

            <div className=" mb-5">
                <InputField containerClass="w-full mb-2 md:mb-0"
                            label="Email"
                            placeholder="john.doe@gmail.com"
                            value={data.email}
                            onChange={(value) => setValue(OfferFormFormFields.email, value)}
                            errorText={errors[OfferFormFormFields.email]}
                            required={true}/>
            </div>
            <div className=" mb-5">
                <InputField containerClass="w-full mb-2 md:mb-0"
                            label="Telefoonnummer"
                            placeholder="0486 12 34 56"
                            value={data.phone}
                            onChange={(value) => setValue(OfferFormFormFields.phone, value)}
                            errorText={errors[OfferFormFormFields.phone]}
                            required={true}/>
            </div>
            <div className="mb-6">
                <TextArea containerClass="md:w-full md:mb-0"
                          label="Commentaar"
                          placeholder="Ik ben geïnteresseerd in de aankoop van deze auto en wil graag weten of deze nog beschikbaar is?"
                          value={data.message}
                          heightClass="h-24"
                          onChange={value => setValue(OfferFormFormFields.message, value)}/>
            </div>


            <div className="flex mb-3">
                <div className={`text-primary flex  ${(hasError('privacy') ? "text-red-400" : "")}`}>
                    <input type="checkbox" checked={data.privacy} onChange={e => setValue(OfferFormFormFields.privacy, e.target.checked)}/>
                    <span className={`mr-1 ml-2 ${(hasError('privacy') ? "text-red-400" : "")}`}>Ik ga akkoord met het </span>
                    <span className={`underline cursor-pointer ${(hasError('privacy') ? "text-red-400" : "")}`} onClick={() => (handlePrivacy) ? handlePrivacy() : null}>privacybeleid</span>.
                </div>
            </div>


            <div className="flex justify-end">
                <ButtonWithIcon disabled={loading}
                                onClick={(e) => onSubmit(e)}
                                loading={loading}
                                loadingText="Aan het sturen..."
                                text="Bevestig"
                                icon="fad fa-paper-plane"
                                iconPosition="right"
                />
            </div>
        </form>
    );
};

export default Offer;
