import React from 'react';
import {DefaultLocationOpeningHour, OpeningHourTypes, OpeningHourTypesNL} from "../../../core/constants";
import {map} from "lodash";
import {Image} from "../../../core";
import ContactLocationOpeningHours_Preview from "./components/ContactLocationOpeningHours_Preview";

const ContactLocationOpeningHours = ({opening_hours, context}) => {
    const renderImage = (image, type) => {
        if (image === undefined || image === "") {
            return type === OpeningHourTypes.Showroom ? <i className="far fa-city text-6xl"/> : <i className="far fa-tools text-6xl"/>;
        } else {
            return <Image url={image} style={{width: "100%", height: "100%"}}/>
        }
    };

    return <div className="flex flex-col md:flex-row bg-primary relative">
        {map(opening_hours, (item, i) => {
            return (
                <div key={i} className={`w-full md:w-1/2 flex items-center justify-center text-center py-10 md:py-30 px-5 md:px-16 ${(i===0) ? "border-r border-gray-100" : ''}`}>
                    <div className="text-primary" style={{width: 500}}>
                        {renderImage(item.image, item.type)}
`
                        <div className="mt-8 mb-4 text-center text-2xl capitalize text-white">{OpeningHourTypesNL[item.type]}</div>
                        {map(item.hours, (hour, index) => {
                            return <div key={`hour_${index}`} className="flex flex-row items-center justify-start text-md mb-2 text-white">
                                <div className="text-left" style={{width: 70}}>{hour.key}</div>
                                <div className="text-left">{hour.value}</div>
                            </div>
                        })}
                    </div>
                </div>
            )
        })}
    </div>;
};

ContactLocationOpeningHours.defaultProps = {
    opening_hours: [
        {...DefaultLocationOpeningHour, type: OpeningHourTypes.Showroom},
        {...DefaultLocationOpeningHour, type: OpeningHourTypes.Workplace},
    ],
};

ContactLocationOpeningHours.human = {
    "nl": "Locations met foto en openingsuren version 2.",
    "fr": "",
    "en": "",
};

ContactLocationOpeningHours.preview = () => { return <ContactLocationOpeningHours_Preview /> };

export default ContactLocationOpeningHours;
