import React from "react";

const Image = ({url, style, onLoad, className}) => {
    if(url && url !== "") {
        return (
            <img src={url} style={{...style}} alt="img" onLoad={onLoad} className={className}/>
        )
    }

    return (
        <div className="flex flex-1 justify-center items-center bg-gray-200 text-gray-800" style={style}>
            <div>
                <span>Picture</span>
            </div>
        </div>
    )
};

Image.defaultProps = {
    width: "",
    onLoad: () => {},
    style: {width: 150, height: 150}
};

export default Image;
