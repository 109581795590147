import '../../../dealer-theme/theme/css/index.css'
import React, {useState} from "react";
import {Container, Draggable} from "react-smooth-dnd";
import BlockContainer from "./BlockContainer";
import {DataContextProvider} from "../../../dealer-theme/core/store/DataContext";


export const WebsiteContainer = ({page, onDrop, onEditContent, onChangeBlockVariant, onDelete, onMoveUp, onMoveDown, context}) => {
    const [dragOver, setDragOver] = useState(false);

    const handleDrop = (e) => {
        setDragOver(false);
        onDrop(e);
    };

    if (!page) {
        return null;
    }

    return (
        <div className={`builder-bg-white builder-flex builder-flex-1 builder-relative builder-h-full builder-overflow-scroll ${(dragOver) ? 'builder-border-2 builder-border-blue-600' : 'builder-border2 builder-border-white'}`}>
            <Container groupName="1"
                       lockAxis="y"
                       style={{width: '100%', height: '100%'}}
                       getChildPayload={i => page.blocks[i]}
                       onDrop={e => handleDrop(e)}
                       onDragEnter={() => setDragOver(true)}
                       onDragLeave={() => setDragOver(false)}
                       dropPlaceholder={{
                           animationDuration: 150,
                           showOnTop: true,
                           className: 'cards-drop-preview'
                       }}>
                <DataContextProvider>
                    <React.Fragment>
                        {
                            page.blocks.map((block, i) => {
                                return (
                                    <Draggable key={`${block.id}_${i}`}>
                                        <BlockContainer
                                            block={block}
                                            context={context}
                                            onEditContent={onEditContent}
                                            onDelete={onDelete}
                                            onMoveUp={onMoveUp}
                                            onMoveDown={onMoveDown}
                                            onChangeBlockVariant={onChangeBlockVariant}
                                        />
                                    </Draggable>
                                );
                            })
                        }

                        {page && page.blocks.length === 0 && !dragOver &&
                        <React.Fragment>
                            <div className="builder-flex builder-h-full builder-bg-white builder-flex-col builder-items-center builder-justify-center builder-font-body">

                                <span className="builder-text-3xl builder-font-medium">Start creating a new template by selecting UI components.</span>
                                <span className="builder-text-gray-500 builder-text-lg builder-pt-2">Drag & drop them here. You can change the content by clicking the "Change content" button.</span>
                            </div>
                        </React.Fragment>}
                    </React.Fragment>
                </DataContextProvider>
            </Container>
        </div>
    )


};

export default WebsiteContainer;
