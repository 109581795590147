import React, {useState} from "react";
import cogoToast from "cogo-toast";
import {useMutation} from 'graphql-hooks'
import Modal from "react-modal";
import ReactPlayer from 'react-player'
import {v4} from "uuid";
import FilePicker from "../../builder/editors/FilePicker";
import {isImage, isVideo} from "../../../utils";
import {FileUploadMutation} from "../../../data/graphql";


let fileSelector;
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    },
    overlay: {zIndex: 999}
};

const ImagePicker = ({definition, value, onChange, type, className, breadcrumbs, onBack}) => {
    const [filesModalVisible, setFilesModalVisible] = useState(false);
    const [mutation] = useMutation(FileUploadMutation);

    const showFileSelector = (e) => {
        e.preventDefault();

        fileSelector = document.createElement('input');
        fileSelector.setAttribute('id', v4())
        fileSelector.setAttribute('type', 'file');
        fileSelector.setAttribute('multiple', 'multiple');


        fileSelector.addEventListener('change', (e) => {
            handleChangeImage(e)
        });

        fileSelector.setAttribute('accept', (type === 'image') ? '.png,.jpg,.jpeg' : '.mp4,.mov');
        fileSelector.click();
    };


    const handleChangeImage = (e) => {
        const file = e.target.files[0];

        mutation({variables: {file: file}})
            .then(res => {
                const {ok, url} = res.data.fileUpload;
                if (ok) {
                    onChange(url);
                    cogoToast.success('image uploaded!');
                } else {
                    cogoToast.error('Error uploading image!');
                }
            })
            .catch(error => {
                cogoToast.error('Error uploading image!');
                console.log(error);
            });

    };

    const handleSelectExisting = (file) => {
        setFilesModalVisible(false);
        onChange(file.url);
    };

    const handleDelete = () => {
        onChange("");
    };

    return (
        <React.Fragment>
            <div className={`builder-flex-1 builder-border builder-border-gray-300 builder-p-4 ${className}`} style={{maxWidth: 266}}>
                <div className="builder-flex builder-justify-center builder-items-center" style={{maxWidth: 266, minHeight: 200, maxHeight: 200}}>
                    {(!value || value === "") &&
                    <span>{(type === 'image') ? 'No image selected' : 'No video selected'}</span>}

                    {value && value !== "" && (type === 'image') && isImage(value) &&
                    <img src={value} style={{maxWidth: 266, maxHeight: 200}} alt={value}/>}

                    {value && value !== "" && (type === 'video') && isVideo(value) &&
                    <ReactPlayer url={value} playing={false} width={266} height={200}/>}
                </div>

                <div className="builder-flex builder-justify-between builder-p-2 border-t border-t-gray-300">
                    <div className="cursor-pointer" onClick={showFileSelector}>Add</div>
                    <div className="cursor-pointer" onClick={() => setFilesModalVisible(true)}>Select existing</div>
                    <div className="cursor-pointer" onClick={handleDelete}>Delete</div>
                </div>
            </div>

            <Modal
                isOpen={filesModalVisible}
                onRequestClose={() => setFilesModalVisible(false)}
                style={customStyles}
                contentLabel="Example Modal"

            >
                <div className="builder-flex builder-justify-end builder-mb-4">
                    <div className="builder-cursor-pointer" onClick={() => {
                        setFilesModalVisible(false)
                    }}><i className="fal fa-times builder-text-xl"/></div>
                </div>

                <div style={{width: 700, minHeight: 400, maxHeight: 500}}>
                    <FilePicker onSelect={handleSelectExisting} type={type}/>
                </div>
            </Modal>


        </React.Fragment>
    )
};

ImagePicker.defaultProps = {
    className: 'builder-bg-gray-100'
};

export default ImagePicker;
