import React from 'react';
import {getDayTranslation, navigateAction} from "../../utils";
import {each, filter, forEach, head, orderBy} from "lodash";
import {DAYS_BY_INT} from "../../constants";
import {useTranslation} from "react-i18next";

const now = new Date();
const now_day = now.getDay();

const ContactOpeningHoursLabelV2 = ({data, action, context, classNames}) => {
    const {closing_days, openings_hours} = data;
    const zeroPad = (num, places) => String(num).padStart(places, '0');
    const {t} = useTranslation();

    const compareTime = (start, stop, day) => {
        let startHour = new Date(start).getHours()
        let startMinutes = new Date(start).getMinutes()
        const start_date_time_converted_to_today = new Date()
        start_date_time_converted_to_today.setHours(startHour)
        start_date_time_converted_to_today.setMinutes(startMinutes)

        let stopHour = new Date(stop).getHours()
        let stopMinutes = new Date(stop).getMinutes()
        const stop_date_time_converted_to_today = new Date()
        stop_date_time_converted_to_today.setHours(stopHour)
        stop_date_time_converted_to_today.setMinutes(stopMinutes)

        if(now_day === day){
            return start_date_time_converted_to_today > now || start_date_time_converted_to_today < now  && now < stop_date_time_converted_to_today
        }else{
            return start_date_time_converted_to_today < now || now < stop_date_time_converted_to_today
        }
    }

    const convertToDates = (start, stop) => {
        // convert 08:00 to date
        try {
            const startHour = parseInt(start.split(':')[0]);
            const startMinutes = parseInt(start.split(':')[1]);

            const stopHour = parseInt(stop.split(':')[0]);
            const stopMinutes = parseInt(stop.split(':')[1]);

            const startDate = new Date();
            startDate.setHours(startHour);
            startDate.setMinutes(startMinutes);
            startDate.setSeconds(0);

            const stopDate = new Date();
            stopDate.setHours(stopHour);
            stopDate.setMinutes(stopMinutes);
            stopDate.setSeconds(0);

            return {
                start: startDate,
                stop: stopDate,
            };
        } catch (ex) {
            return undefined;
        }
    };

    const getNextOpen = (day, data, tries = 0, t) => {
        const openingHours = filter(data, (d) => d.day === DAYS_BY_INT[day]);


        if (tries === 7) {
            return undefined
        }
        if (openingHours.length === 0) {
            const nextDay = (day === 6) ? 0 : day + 1;
            return getNextOpen(nextDay, data, tries += 1, t);
        } else {
            const result = [];
            forEach(openingHours, (oh) => {
                const dates = convertToDates(oh.start, oh.stop);
                if (dates) {
                    result.push(dates);
                }
            });

            if (result.length === 0) {
                const nextDay = (day === 6) ? 0 : day + 1;
                return getNextOpen(nextDay, data, tries += 1, t);
            }


            const sorted = orderBy(result, 'start');

            let filteredHours = [];

            forEach(sorted, (sh) => {
                const times = compareTime(sh.start, sh.stop, day);
                if (times){
                    filteredHours.push(sh);
                    return;
                }
            });


            if(filteredHours.length > 0){
                let filteredHour = head(filteredHours)
                if(filteredHour){
                    let text = `${getDayTranslation(t, day)} om ${zeroPad(new Date(filteredHour.start).getHours(), 2)}:${zeroPad(new Date(filteredHour.start).getMinutes(), 2)}`
                    if (now_day === day){
                        text = `vandaag om ${zeroPad(new Date(filteredHour.start).getHours(), 2)}:${zeroPad(new Date(filteredHour.start).getMinutes(), 2)}`
                    }
                    return text
                }else{
                    const nextDay = (day === 6) ? 0 : day + 1;
                    return getNextOpen(nextDay, data, tries += 1, t);
                }

            }else{
                const nextDay = (day === 6) ? 0 : day + 1;
                return getNextOpen(nextDay, data, tries += 1, t);
            }
        }
    };

    const isNowOpen = (data) => {
        const now = new Date();
        const now_day = now.getDay();

        const openingHours = filter(data, (d) => d.day === DAYS_BY_INT[now_day]);
        let result = false;
        forEach(openingHours, (oh) => {
            const dates = convertToDates(oh.start, oh.stop);
            if (dates && dates.start < now && now < dates.stop) {
                result = true;
                return;
            }
        });
        return result;
    };


    const isCurrentlyClosed = (closingDays) => {
        const today = new Date();
        let closed = false;
        let label = "Wij zijn momenteel gesloten."
        each(closingDays, cd => {
            if (new Date(cd.start) <= today && today <= new Date(cd.stop)) {
                closed = true
                label = cd.description
                return false;
            }
        })
        return {
            closed,
            label
        };
    }


    let text = "Openingsuren onvolledig."
    const closingDaysData = isCurrentlyClosed(closing_days);
    if(closing_days && closing_days.length > 0 && closingDaysData && closingDaysData.closed){
        text = closingDaysData.label
    }else{
        if(openings_hours && openings_hours.length > 0){
            const open = isNowOpen(openings_hours);
            if (open) {
                text = "We zijn momenteel open.";
            } else {
                const nextDayString = getNextOpen(now_day, openings_hours, t);
                if (nextDayString)  {
                    text = `We zijn terug open ${nextDayString}`
                }
                else {
                    text = 'onvolledige openingsuren.'
                }
            }
        }
    }
    return (
        <div className="flex items-center justify-center" itemProp="openingHours">
            <div className={`text-center ${action ? "underline cursor-pointer" : ""}  md:items-start ${classNames}`} onClick={() => action ? navigateAction(context, action) : undefined}>
                {text}
            </div>
        </div>
    )
};

ContactOpeningHoursLabelV2.defaultProps = {
    classNames: 'text-white text-xs hover:text-gray-200 '
}

export default ContactOpeningHoursLabelV2;
