import {useState, useEffect} from 'react';
import fetch from 'cross-fetch'

const AVAILABLE_MODELS_QUERY = `query AvailableModels($make: String!) {
  availableModels(make: $make)
}`;

export function useAvailableModels(make=undefined) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [models, setModels] = useState(null);

    useEffect(() => {
        if (make && make !== "") {
            setLoading(true);
            fetch('/graphql', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({query: AVAILABLE_MODELS_QUERY, variables: {make: make}})
            })
                .then(resp => resp.json())
                .then((result) => {
                    const {availableModels} = result.data;
                    setModels(availableModels);
                    setLoading(false);
                })
                .catch(err => {
                    setError("ERROR");
                    setLoading(false);
                })
        }
    }, [make]);

    return {
        models,
        loading: loading,
        error
    };
}
