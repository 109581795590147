import React from 'react';

const Title = ({step}) => {
    switch (step) {
        case 1:
            return <React.Fragment>
                <div className="px-5 md:px-0 mb-2 text-2xl">Wij nemen uw wagen over.</div>
                <div className="px-5 md:px-0 mb-6 text-md">Je hoeft geen nieuwe wagen aan te schaffen. Wij berekenen vrijblijvend en gratis de waarde van je wagen.Bij een nieuwe wagen betaal je simpelweg het verschil.</div>
                <div className="px-5 md:px-0 mb-6 text-md">Bereken hieronder de waarde van je auto geheel vrijblijvend en volledig gratis. Vul model, merk, brandstof en eerste inschrijving in.</div>
                <div className="px-5 md:px-0 mb-6 text-md">Wij vragen je je nummerplaat in te vullen zodat we informatie kunnen oproepen over het type wagen, zoals editie en uitvoering. Dit maakt het voor ons mogelijk een zo nauwkeurig mogelijke prijsofferte aan te bieden.</div>
            </React.Fragment>
        case 2:
            return <React.Fragment>
                <div className="px-5 md:px-0 mb-2 text-2xl">De staat van de wagen.</div>
                <div className="px-5 md:px-0 mb-6 text-md">Vul de staat waarin je wagen zich bevindt in.</div>
                <div className="px-5 md:px-0 mb-6 text-md">Deze factoren bepalen mee de waarde van je auto. Hoe meer informatie je kan verschaffen, hoe nauwkeuriger de waardebepaling.</div>
            </React.Fragment>
        case 3:
            return <React.Fragment>
                <div className="px-5 md:px-0 mb-2 text-2xl">Jouw wagen in beeld.</div>
                <div className="px-5 md:px-0 mb-6 text-md">Upload foto's van je wagen zodat we ons er een goed beeld kunnen van vormen.</div>
                <div className="px-5 md:px-0 text-md">Je kan de foto's best in natuurlijk daglicht op een ruime parking maken. Maak de wagen leeg zodat de zetels en de koffer zichtbaar zijn. Voor
                    elke foto kan je rechtsboven in het kader een voorbeeld vinden. Met duidelijke foto's kunnen we de beste prijsraming doen.
                </div>
            </React.Fragment>
        case 4:
            return <React.Fragment>
                <div className="px-5 md:px-0 mb-2 text-2xl">Upload foto's schades.</div>
                <div className="px-5 md:px-0 mb-6 text-md">Schade aan uw wagen? Upload een foto waarin u de schade aanduidt met uw vinger.</div>
                <div className="px-5 md:px-0 text-md">Om de grootte van de schade duidelijk aan te tonen houdt u er een object naast als referentie. Zo kan u bijvoorbeeld uw autosleutels of documenten
                    naast de schade houden.
                </div>
            </React.Fragment>
        case 5:
            return <React.Fragment>
                <div className="px-5 md:px-0 mb-2 text-2xl">Uw gegevens.</div>
                <div className="px-5 md:px-0 mb-6 text-md">Vul uw gegevens en eventuele opmerkingen in.</div>
            </React.Fragment>
        default:
            return <div/>
    }
};

export default Title;