import React, {useContext, useState} from 'react';
import {find, filter} from 'lodash';
import ImageContainer from "./ImageContainer";
import {ButtonWithIcon} from "../../../input";
import {ACTIONS, DispatchContext, StateContext, TakeOverFormFields} from "../Takeover";

const imageContainers = [
    {name: "Links", type: "left", overlay: "left", className: "w-full md:w-1/5 mb-2 ", required: true, key: "vehicle-left.jpg"},
    {name: "Rechts", type: "right", overlay: "right", className: "w-full md:w-1/5 mb-2 ", required: true, key: "vehicle-right.jpg"},
    {name: "Vooraan", type: "front", overlay: "front", className: "w-full md:w-1/5 mb-2 ", required: true, key: "vehicle-front.jpg"},
    {name: "Deur rechts", type: "door-right", overlay: "interior-door-right", className: "w-full md:w-1/5 mb-2 ", required: true, key: "vehicle-door-right.jpg"},
    {name: "Achteraan", type: "back", overlay: "back", className: "w-full md:w-1/5 mb-2", required: true, key: "vehicle-back.jpg"},
    {name: "Binnen", type: "inside", overlay: "inside-front", className: "w-full md:w-1/5 mb-2 ", required: true, key: "vehicle-inside.jpg"},
    {name: "Dashboard", type: "dashboard", overlay: "dashboard", className: "w-full md:w-1/5 mb-2 ", required: true, key: "vehicle-dashboard.jpg"},
    {name: "Motor", type: "engine", overlay: "engine", className: "w-full md:w-1/5 mb-2 ", required: true, key: "vehicle-engine.jpg"},
    {name: "Koffer", type: "trunk", overlay: "trunk", className: "w-full md:w-1/5 mb-2 ", required: true, key: "vehicle-trunk.jpg"},
    {name: "Documenten", type: "documents", overlay: "documents", className: "w-full md:w-1/5 mb-2 ", required: true, key: "vehicle-documents.jpg"},
];


const StepThree = ({context}) => {
    const dispatch = useContext(DispatchContext);
    const {vehicle, errors} = useContext(StateContext);

    const [isUploading, setIsUploading] = useState(false);
    const [currentActive, setCurrentActive] = useState(null)

    const isValid = () => {
        let isValid = true;
        let errors = {};
        dispatch({type: ACTIONS.SET_ERROR, field: TakeOverFormFields.images, payload: {}})
        const requiredImages = filter(imageContainers, imgContainer => imgContainer.required);
        requiredImages.map(reqImg => {
            const imgAdded = find(vehicle[TakeOverFormFields.images], img => img.imageType === reqImg.type) !== undefined;
            if (!imgAdded) {
                errors[reqImg.type] = "Deze photo is verplicht.";
                isValid = false
            }
        });
        dispatch({type: ACTIONS.SET_ERROR, field: TakeOverFormFields.images, payload: errors})
        return isValid
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (isValid()) {
            dispatch({type: ACTIONS.NEXT_STEP})
        }
    };

    const onBack = () => {
        dispatch({type: ACTIONS.PREV_STEP})
    };

    const onUpload = (uploadedImage) => {
        dispatch({type: ACTIONS.SET_IMAGES, field: TakeOverFormFields.images, payload: uploadedImage})
    };


    return (
        <form action="" onSubmit={(e) => onSubmit(e)} className="images-container flex flex-col">
            <div className="flex flex-col md:flex-row flex-wrap">
                {imageContainers.map(imgContainer => {
                    const oldImage = vehicle[TakeOverFormFields.images]  ? find(vehicle[TakeOverFormFields.images], img => img.imageType === imgContainer.type) : null;
                    const error = errors[TakeOverFormFields.images] !== undefined ? errors[TakeOverFormFields.images][imgContainer.type] : undefined;
                    return <ImageContainer key={imgContainer.type}
                                           className={imgContainer.className}
                                           name={imgContainer.name}
                                           type={imgContainer.type}
                                           overlay={imgContainer.overlay}
                                           onUpload={(file) => onUpload(file)}
                                           error={error}
                                           required={imgContainer.required}
                                           helpImage={imgContainer.key}
                                           setIsUploading={value => setIsUploading(value)}
                                           isUploading={isUploading}
                                           setCurrentActive={type => setCurrentActive(type)}
                                           currentActive={currentActive}
                                           img={oldImage !== undefined ? oldImage : null}
                    />
                })}
            </div>

            <div className="flex justify-between w-full mt-5">
                <ButtonWithIcon disabled={isUploading}
                                onClick={(e) => onBack(e)}
                                text="Terug"
                                icon="fad fa-chevron-left"
                                iconPosition="left"
                />

                <ButtonWithIcon disabled={isUploading}
                                onClick={(e) => onSubmit(e)}
                                text="Volgende"
                                icon="fad fa-chevron-right"
                                iconPosition="right"
                />



            </div>

        </form>
    );
};

export default StepThree;
