import React, {useEffect, useState} from "react"
import {find, keys, omit, orderBy , map} from "lodash";
import {hasValue, isValidEmail, getWindowPathLocation} from "../../../utils";
import SelectField from "../../input/Select";
import InputField from "../../input/Input";
import ButtonWithIcon from "../../input/ButtonWithIcon";
import TextArea from "../../input/TextAreaField";
import {MarkupText} from '../../content';
import {useDealerContact} from "../../../hooks/useDealerContact";
import {ErrorIndicator, SuccessIndicator} from "../../indicators";

const AS_MAKES_QUERY = `query Makes {
  makes {
        id
        name
        priority
  }
}`;


const AS_MODELS_QUERY = `query Models ($makeId: Int!) {
  models (makeId: $makeId) {
    id
    name
  }
}`;


const RequestFormFields = {
    name: 'name',
    phone: 'phone',
    email: 'email',
    budget: 'budget',
    comment: 'comment',
    privacy: 'privacy',
    origin: 'origin',
    make: 'make',
    model: 'model',
    takeOver: 'takeOver',
}

const initialData = {
    [RequestFormFields.name]: "",
    [RequestFormFields.phone]: "",
    [RequestFormFields.email]: "",
    [RequestFormFields.budget]: "",
    [RequestFormFields.comment]: "",
    [RequestFormFields.privacy]: false,
    [RequestFormFields.takeOver]: false,
    [RequestFormFields.model]: undefined,
    [RequestFormFields.make]: undefined,
    [RequestFormFields.origin]: ""
};

const Request = ({context, form_footer, handleCalculateVehicleValue, handlePrivacy}) => {
    const [makes, setMakes] = useState([]);
    const [models, setModels] = useState([]);
    const [makesLoading, setMakesLoading] = useState(false);
    const [modelsLoading, setModelsLoading] = useState(false);

    const [data, setData] = useState(initialData);
    const {submit, loading, dealerContactTypes} = useDealerContact();
    const [success, setSuccess] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        fetchMakes();
    }, []);

    const fetchMakes = () => {
        setMakesLoading(true);

        fetch(
            '/graphql', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({query: AS_MAKES_QUERY})
            })
            .then(res => res.json())
            .then(res => {
                if (res.data && res.data.makes) {
                    const sortedMakes = orderBy(res.data.makes, ['priority', 'name'], ['desc', 'asc']);
                    setMakes(map(sortedMakes, (m) => ({id: m.id, text: m.name})));
                }
                setMakesLoading(false)
            })
            .catch(error => {
                setMakesLoading(false)
            });
    }
    const fetchModels = (makeId) => {
        fetch(
            '/graphql', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({query: AS_MODELS_QUERY, variables: {makeId: makeId}})
            })
            .then(res => res.json())
            .then(res => {
                if (res.data && res.data.models) {
                    const sortedModels = orderBy(res.data.models, ['name'], ['asc']);
                    setModels(map(sortedModels, (m) => ({id: m.id, text: m.name})));
                }
                setModelsLoading(false)
            })
            .catch(error => {
                setModelsLoading(false)
            });
    };

    const handleChangeMake = (e) => {
        const makeId = parseInt(e.target.value);
        setModelsLoading(true);

        const selectedMake = find(makes, make => parseInt(make.id) === makeId);
        setValue(RequestFormFields.make, selectedMake);
        setErrors(omit(errors, [RequestFormFields.make]));

        fetchModels(selectedMake.id);
    };

    const handleChangeModel = (e) => {
        const modelId = parseInt(e.target.value);
        const selectedModel = find(models, model => parseInt(model.id) === modelId);

        setValue(RequestFormFields.model, selectedModel);
        setErrors(omit(errors, [RequestFormFields.model]))
    };


    const validate = () => {
        let errors = {};

        if (!hasValue(data.name)) {
            errors[RequestFormFields.name] = "Vul aub uw naam in.";
        }

        if (!hasValue(data.phone)) {
            errors[RequestFormFields.phone] = "Vul aub uw telefoonnummer in.";
        }

        if (!hasValue(data.email)) {
            errors[RequestFormFields.email] = "Ongeldig e-mailadres";
        } else if (!isValidEmail(data.email)) {
            errors[RequestFormFields.email] = "Ongeldig e-mailadres";
        }

        if (!hasValue(data.budget)) {
            errors[RequestFormFields.budget] = " Vul aub uw budget in.";
        }

        if (!hasValue(data.make)) {
            errors[RequestFormFields.make] = "Selecteer het merk van uw wagen";
        }

        if (!hasValue(data.model)) {
            errors[RequestFormFields.model] = "Selecteer het model van uw wagen";
        }

        if (data.privacy === false) {
            errors[RequestFormFields.privacy] = "";
        }

        setErrors(errors);

        return keys(errors).length <= 0;
    };


    const setValue = (field, value) => {
        setData({
            ...data,
            [field]: value
        });
    };

    const hasError = (field) => {
        return (errors && errors[field] !== undefined)
    };

    const onSubmit = e => {
        e.preventDefault();

        if (validate()) {
            setSuccess(false);
            const jsonData = JSON.stringify({
                name: data.name,
                phone: data.phone,
                budget: data.budget,
                email: data.email,
                comment: data.comment,
                privacy: data.privacy,
                has_take_over_vehicle: data.takeOver,
                takeover: data.takeOver,
                make: data.make.text,
                model: data.model.text,
                origin: getWindowPathLocation(context),
            });

            submit(dealerContactTypes.vehicleRequest, jsonData)
                .then(result => {
                    if (result) {
                        setSuccess(true);
                        setErrors({});
                        setData(initialData);
                    } else {
                        setErrors({
                            result: 'Probleem met het verzenden van uw aanvraag.'
                        })
                    }
                })
        }
    };

    return (
        <React.Fragment>

            {errors && errors['result'] &&
            <div className="mb-4 px-5 md:px-0" style={{minHeight: 32}}>
                <ErrorIndicator error={errors["result"]}/>
            </div>}

            {success &&
            <div className="fadeOutAfter5Seconds w-full mb-10 px-5 md:px-0" style={{top: 40, right: "0.75rem", left: "0.75rem"}}><SuccessIndicator success="Uw aanvraag is verzonden!"/></div>}

            <form action="" onSubmit={(e) => onSubmit(e)} className="flex flex-col items-center w-full">
                <div className="flex flex-col md:flex-row w-3/4">
                    <InputField containerClass="w-full md:w-1/2 px-0 mb-4 md:mb-0 mr-0 md:mr-4"
                                label="Naam"
                                placeholder="John Doe"
                                value={data.name !== undefined ? data.name : ""}
                                onChange={(value) => setValue(RequestFormFields.name, value)}
                                errorText={errors[RequestFormFields.name]}
                    />

                    <InputField containerClass="w-full md:w-1/2 px-0 "
                                label="Telefoon"
                                placeholder="0486 12 36 96"
                                value={data.phone !== undefined ? data.phone : ""}
                                onChange={(value) => setValue(RequestFormFields.phone, value)}
                                errorText={errors[RequestFormFields.phone]}
                    />
                </div>

                <div className="flex flex-col md:flex-row w-3/4 mt-4">

                    <InputField containerClass="w-full md:w-1/2 px-0 mb-4 md:mb-0 mr-0 md:mr-4"
                                type="email"
                                label="Emailadres"
                                placeholder="john.doe@hotmail.com"
                                withIcon={true}
                                icon="far fa-at"
                                value={data.email !== undefined ? data.email : ""}
                                onChange={(value) => setValue(RequestFormFields.email, value)}
                                errorText={errors[RequestFormFields.email]}
                    />

                    <InputField containerClass="w-full md:w-1/2 px-0"
                                type="number"
                                label="Max budget"
                                placeholder="25000"
                                withIcon={true}
                                icon="fad fa-euro-sign"
                                value={data.budget !== undefined ? data.budget : ""}
                                onChange={(value) => setValue(RequestFormFields.budget, value)}
                                errorText={errors[RequestFormFields.budget]}
                    />
                </div>

                <div className="flex flex-col md:flex-row w-3/4 mt-4">
                    <SelectField label="Kies merk"
                                 containerClass="w-full md:w-1/2 px-0 mb-4 md:mb-0 mr-0 md:mr-4"
                                 disabled={makesLoading}
                                 text={makesLoading ? "Loading makes..." : "Kies merk"}
                                 loading={makesLoading}
                                 options={makes}
                                 selectedOption={data.make !== undefined ? data.make.id : null}
                                 onChange={e => handleChangeMake(e)}
                                 errorText={errors[RequestFormFields.make]}
                    />

                    <SelectField label="Kies model"
                                 containerClass="w-full md:w-1/2 px-0 "
                                 disabled={modelsLoading}
                                 text={modelsLoading ? "Loading models..." : "Kies model"}
                                 loading={modelsLoading}
                                 options={models}
                                 selectedOption={data.model !== undefined ? data.model.id : null}
                                 onChange={e => handleChangeModel(e)}
                                 errorText={errors[RequestFormFields.model]}
                    />
                </div>

                <div className="w-3/4 mt-4">
                    <TextArea containerClass="w-full md:mb-0"
                              label="Commentaar"
                              placeholder=""
                              value={data.comment !== undefined ? data.comment : ""}
                              heightClass="h-38"
                              onChange={value => setValue(RequestFormFields.comment, value)}
                    />
                </div>

                <div className="flex w-3/4 text-left mt-2">
                    <div className={`text-primary flex  ${(hasError('privacy') ? "text-red-400" : "")}`}>
                        <input type="checkbox" checked={data.privacy} onChange={e => setValue(RequestFormFields.privacy, e.target.checked)}/>
                        <span className={`mr-1 ml-2 ${(hasError('privacy') ? "text-red-400" : "")}`}>Ik ga akkoord met het </span>
                        <span className={`underline cursor-pointer ${(hasError('privacy') ? "text-red-400" : "")}`}
                              onClick={() => (handlePrivacy) ? handlePrivacy() : null}>privacybeleid</span>.
                    </div>
                </div>


                <div className="flex w-3/4 text-left mt-2">
                    <div className="flex flex-col">
                        <div className="text-primary flex-col">
                            <div>
                                <input type="checkbox" name="takeover" checked={data.takeOver} onChange={e => setValue(RequestFormFields.takeOver, e.target.checked)}/>
                                <span className="mr-1 ml-2">Ik heb een wagen die ik eventueel wens te ruilen.</span>
                            </div>
                            <div style={{marginLeft: 22}}>
                                <span className="underline cursor-pointer"
                                      onClick={() => (handleCalculateVehicleValue) ? handleCalculateVehicleValue() : null}>Bereken de inruilwaarde van mijn wagen.</span>
                            </div>
                        </div>
                        <div className="mt-2">
                            <MarkupText text={form_footer}/>
                        </div>
                    </div>
                </div>


                <div className="flex justify-end w-3/4 px-0 mt-6">
                    <ButtonWithIcon disabled={loading}
                                    onClick={(e) => onSubmit(e)}
                                    loading={loading}
                                    loadingText="Aan het sturen..."
                                    text="Verzenden"
                                    icon="fad fa-paper-plane"
                                    iconPosition="right"
                    />
                </div>

            </form>
        </React.Fragment>
    );
};


export default Request;
