import {useEffect, useState} from "react";
import fetch from "cross-fetch";

export const TRACKING_ID = `query TrackingId {
  trackingId 
}`;

export const TRACE_MUTATION = `mutation ActivityTrace ($data: ActivityTraceInputType!) {
  activityTrace (data: $data) {
        ok       
  }
}`;


export function useTracking() {
    const [trackingId, setTrackingId] = useState(undefined);

    useEffect(() => {
        fetchTrackingId()
    }, []);

    const fetchTrackingId = () => {
        fetch(
            '/graphql', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({query: TRACKING_ID})
            })
            .then(res => res.json())
            .then(res => {
                if (res.data !== null && res.data.trackingId) {
                    setTrackingId(res.data.trackingId)
                }
            })
            .catch(error => {
            });
    };

    const handleTrace = (type, data ) => {
        if (trackingId) {
            fetch('/graphql', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    query: TRACE_MUTATION,
                    variables: {
                        data: {
                            type: type,
                            data: data,
                        }
                    }
                })
            }).then(res => res.json()).then(res => {
            }).catch(error => {
            });
        }
    };

    return {
        trackingId,
        traceUserAction: (refType, refId, data) => handleTrace(refType, refId, data),
    };
}
