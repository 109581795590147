import React from "react"

const NoResultsIndicator = ({message}) => {
    return (
        <div className="flex items-center justify-center w-full">
            <div className="flex self-center bg-orange-100 w-1/2">
                <div className="w-16 bg-orange-200 flex items-center justify-center">
                    <div className="p-4">
                        <i className="fad fa-info" />
                    </div>
                </div>
                <div className="w-auto text-gray-800 text-lg items-center p-4">
                    <p className="leading-tight">
                        {message}
                    </p>
                </div>
            </div>
        </div>
    )
};

export default NoResultsIndicator
