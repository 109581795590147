import React, {useState, useEffect, useContext} from 'react';
import {DefaultImage, DefaultThumbnailWidth, DefaultTitle} from "../../../../core/constants";
import {Image, LoadingIndicator, SelectField, ScrollDownIndicator} from "../../../../core";
import {capitalize, map, head} from 'lodash';
import {Fade} from "react-reveal";
import {useAvailableMakes} from "../../../../core/hooks/useAvailableMakes";
import {useAvailableModels} from "../../../../core/hooks/useAvailableModels";
import {navigatePage} from "../../../../core/utils";
import {useVehiclesCount} from "../../../../core/hooks/useVehiclesCount";


const VehicleSearchBgVideoCore = ({logo, title, videos, context}) => {
    const [selectedMake, setSelectedMake] = useState(undefined);
    const [selectedModel, setSelectedModel] = useState(undefined);
    const [selectedMinPrice, setSelectedMinPrice] = useState(undefined);
    const [selectedMaxPrice, setSelectedMaxPrice] = useState(undefined);
    const [selectedMinMonthlyPrice, setSelectedMinMonthlyPrice] = useState(undefined);
    const [selectedMaxMonthlyPrice, setSelectedMaxMonthlyPrice] = useState(undefined);
    const [showMonthlyPrice, setShowMonthlyPrice] = useState(false);
    const [newVehicle, setNewVehicle] = useState(false);
    const [secondHandVehicle, setSecondHandVehicle] = useState(false);
    const [isHome, setIsHome] = useState(true);
    const [popupOpen, setPopupOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    const {makes, loading: loadingMakes} = useAvailableMakes(context.culture);
    const {vehiclesCount, loading: loadingVehiclesCount} = useVehiclesCount(context.culture);
    const {models, loading: loadingModels} = useAvailableModels(selectedMake);
    const loading = loadingMakes || loadingVehiclesCount;

    useEffect(() => {
        window.location.pathname === '/' ? setIsHome(true) : setIsHome(false);
    }, []);

    const checkScreenSize = () => {
        setIsMobile(window.innerWidth < 768)
    }

    useEffect(() => {
        setIsMobile(window.innerWidth < 768);
        window.addEventListener('resize', checkScreenSize);

        return () => {
            window.removeEventListener('resize', checkScreenSize);
        }
    })

    useEffect(() => {
        if (popupOpen) {
            window.document.body.style.overflowY = "hidden"
        } else {
            window.document.body.style.overflowY = "scroll"
        }
    }, [popupOpen])


    const handleNewVehicle = () => {
        setNewVehicle(true);
        setSecondHandVehicle(false);
    };

    const handleSecondHandVehicle = () => {
        setNewVehicle(false);
        setSecondHandVehicle(true);
    }

    const addParameterToString = (string, param) => {
        if (string.length === 0) {
            return `${param}`
        } else {
            return `${string}&${param}`
        }
    };

    const createUrlParams = () => {
        let url_params = "";
        if (selectedMake) url_params = addParameterToString(url_params, `make=${selectedMake.replace(" ", '_')}`);
        if (selectedModel) url_params = addParameterToString(url_params, `model=${selectedModel.replace(" ", '_')}`);

        if (newVehicle) url_params = addParameterToString(url_params, `categories=new,pre_registered`);
        if (secondHandVehicle) url_params = addParameterToString(url_params, `category=used`);

        if (selectedMinPrice !== undefined || selectedMaxPrice !== undefined) {
            if (selectedMinPrice !== undefined && selectedMaxPrice !== undefined) {
                url_params = addParameterToString(url_params, `priceFrom=${selectedMinPrice}`);
                url_params = addParameterToString(url_params, `priceTo=${selectedMaxPrice}`);
            } else {
                url_params = addParameterToString(url_params, `priceFrom=${selectedMinPrice}`);
                url_params = addParameterToString(url_params, `priceTo=${120000}`);
            }
        }

        if (selectedMinMonthlyPrice !== undefined || selectedMaxMonthlyPrice !== undefined) {
            if (selectedMinMonthlyPrice !== undefined && selectedMaxMonthlyPrice !== undefined) {
                url_params = addParameterToString(url_params, `monthlyFrom=${selectedMinMonthlyPrice}`);
                url_params = addParameterToString(url_params, `monthlyTo=${selectedMaxMonthlyPrice}`);
            } else {
                url_params = addParameterToString(url_params, `monthlyFrom=${selectedMinMonthlyPrice}`);
                url_params = addParameterToString(url_params, `monthlyTo=${120000}`);
            }
        }
        return url_params;
    };

    const handleSubmit = e => {
        e.preventDefault();
        navigatePage(context, context.urls.vehicles, createUrlParams());
    };

    const renderSelectMakes = () => {
        let options = map(makes, (make) => ({
            id: make,
            text: capitalize(make),
        }));

        return <SelectField label=""
                            containerClass="w-full md:w-1/2 px-0 mr-0 md:mr-4 mb-2 md:mb-0"
                            disabled={loadingMakes}
                            text={"Kies merk"}
                            loading={loadingMakes}
                            options={options}
                            selectedOption={selectedMake !== null ? selectedMake : null}
                            onChange={e => setSelectedMake(e.target.value === "" ? null : e.target.value)}
                            error=""/>
    };

    const renderSelectModel = () => {
        let options = map(models, (model) => ({
            id: model,
            text: capitalize(model),
        }));

        return <SelectField label=""
                            containerClass="w-full md:w-1/2  px-0 mr-0 md:mr-4"
                            disabled={selectedMake === null || loadingModels}
                            text={"Kies model"}
                            loading={loadingModels}
                            options={options}
                            selectedOption={selectedModel !== null ? selectedModel : null}
                            onChange={e => setSelectedModel(e.target.value === "" ? null : e.target.value)}
                            error=""/>
    };


    const renderSelectTotalPrices = () => {
        const formattedPrices = [];
        // const min = getMinPrice(prices);
        // const max = getMaxPrice(prices);
        const min = 0;
        const max = 120000;
        let current = min;
        const step = 5000;
        while (current <= max) {
            formattedPrices.push(current);
            current += step
        }
        const minPricesOptions = [];
        formattedPrices.map(price => minPricesOptions.push({id: price, text: `€${price}`}));
        const maxPricesOptions = [];
        if (selectedMinPrice !== undefined) {
            formattedPrices.map(price => price > selectedMinPrice && maxPricesOptions.push({id: price, text: `€${price}`}));
        }


        return <div className="flex flex-col md:flex-row w-full total-prices-select">
            <SelectField label=""
                         containerClass="w-full md:w-1/2 px-0 mr-0 md:mr-4 mb-2 md:mb-0"
                         text="Min prijs"
                         options={minPricesOptions}
                         selectedOption={selectedMinPrice !== null ? selectedMinPrice : null}
                         onChange={e => setSelectedMinPrice(e.target.value === "" ? null : parseInt(e.target.value))}
                         error=""/>
            <SelectField label=""

                         disabled={selectedMinPrice === null}
                         containerClass="w-full md:w-1/2 px-0 mr-0 md:mr-4"
                         text="Max prijs"
                         options={maxPricesOptions}
                         selectedOption={selectedMaxPrice !== null ? selectedMaxPrice : null}
                         onChange={e => setSelectedMaxPrice(e.target.value === "" ? null : parseInt(e.target.value))}
                         error=""/>
        </div>
    };

    const renderSelectMonthlyPrices = () => {
        const formattedPrices = [];
        // const min = getMinPrice(monthlyPrices);
        // const max = getMaxPrice(monthlyPrices);
        const min = 0;
        const max = 3000;
        let current = min;
        const step = 100;
        while (current <= max) {
            formattedPrices.push(current);
            current += step
        }
        const minMonthlyPricesOptions = [];
        formattedPrices.map(price => minMonthlyPricesOptions.push({id: price, text: `€${price}`}));
        const maxMonthlyPricesOptions = [];
        if (selectedMinMonthlyPrice !== undefined) {
            formattedPrices.map(price => price > selectedMinMonthlyPrice && maxMonthlyPricesOptions.push({id: price, text: `€${price}`}));
        }


        return <div className="flex flex-col md:flex-row w-full monthly-prices-select">
            <SelectField label=""
                         containerClass="w-full md:w-1/2 px-0 mr-0 md:mr-4 mb-4 md:mb-0"
                         text="Min prijs"
                         options={minMonthlyPricesOptions}
                         selectedOption={selectedMinMonthlyPrice !== null ? selectedMinMonthlyPrice : null}
                         onChange={e => setSelectedMinMonthlyPrice(e.target.value === "" ? null : parseInt(e.target.value))}
                         error=""/>
            <SelectField label=""

                         disabled={selectedMinMonthlyPrice === null}
                         containerClass="w-full md:w-1/2 px-0 mr-0 md:mr-4"
                         text="Max prijs"
                         options={maxMonthlyPricesOptions}
                         selectedOption={selectedMaxMonthlyPrice !== null ? selectedMaxMonthlyPrice : null}
                         onChange={e => setSelectedMaxMonthlyPrice(e.target.value === "" ? null : parseInt(e.target.value))}
                         error=""/>
        </div>
    };

    const renderSelectPrices = () => {
        return showMonthlyPrice ? renderSelectMonthlyPrices() : renderSelectTotalPrices();
    };

    const bannerClasses = isHome ? "home-banner" : "default-banner";

    const isMultiVideo = videos instanceof Array;
    const mainVideo = isMultiVideo ? head(videos) : videos;
    let popupVideo = ''
    if (isMultiVideo) {
        if (videos.length > 1) {
            popupVideo = videos[1]
        }
    }

    const renderTitleAndLogo = () => {
        return <div className="flex flex-col justify-center mb:0 md:mb-5 mt-16 md:mt-0 absolute md:relative">
            <div className="flex flex-row justify-center">
                {title &&
                <div className="flex">
                    <div className="flex text-white mb-0 font-bold text-xl md:text-3xl">
                        <div className=" mr-1" style={{textShadow: "1px 1px #000"}}>
                            {loading ? <LoadingIndicator small color="white"/> : vehiclesCount}
                        </div>
                        <h1 className="text-xl md:text-3xl" style={{textShadow: "1px 1px #000"}}>{title}</h1>
                    </div>
                </div>}

                {logo !== undefined && <div className="hidden md:flex md:justify-center md:items-center md:mb-5">
                    <div className="extra-images-container rounded-full flex justify-center items-center" style={{paddingTop: 7}}>
                        <Image url={(logo) ? logo : undefined}/>
                    </div>
                </div>}
            </div>
        </div>
    }

    const renderPopupVideo = () => {
        return <div className="flex absolute w-full md:w-3/4 px-1 md:p-0 mt-0" style={{zIndex: 98}}>
            <video width="100%" autoPlay loop webkitPlaysinline="true" playsinline="true" style={{width: "100%"}}>
                <source src={popupVideo} type="video/mp4"/>
            </video>
        </div>
    }

    if (popupOpen) {
        return <div className={`fixed flex items-center justify-center w-full flex-1`} style={{zIndex: 150, width: '100vw', height: '100vh', marginTop: -120, background: 'rgba(0, 0, 0, 0.8)'}}>
            <div className="flex absolute right-0 top-0 mt-6 mr-6" style={{zIndex: 99}}>
                <i className="far fa-times text-4xl text-gray-200 cursor-pointer hover:text-gray-100" onClick={() => setPopupOpen(false)}/>
            </div>
            {renderPopupVideo()}
        </div>
    }


    return (

        <div className={`relative overflow-hidden vehicle-search-bg-container bg-black ${bannerClasses}`}>
            <div className="md:py-40 h-full flex items-center justify-center relative" style={{zIndex: 11}}>
                {/*{popupOpen && !isMobile && renderPopupVideo()}*/}
                {!loading &&
                <div className="container relative z-10">
                    <div className="flex items-center justify-center flex-col relative">


                        {popupVideo && <div className="hidden md:flex items-center justify-center w-full md:w-6/7 mb-4 md:mb-20">
                            <i className="fad fa-play-circle text-4xl md:text-6xl text-gray-200 cursor-pointer hover:text-gray-100" onClick={() => setPopupOpen(true)}/>
                        </div>}

                        {/*Show only on small screens*/}
                        {isMobile && <div className="flex md:hidden w-full bg-black relative items-center justify-center bg-black" style={{minHeight: 210}}>
                            {/*{popupOpen && isMobile && renderPopupVideo()}*/}

                            {popupOpen && isMobile && <div className="flex absolute right-0 top-0 mt-6 md:mt-6 mr-10 md:mr-6" style={{zIndex: 99}}>
                                <i className="far fa-times text-4xl text-gray-200 cursor-pointer hover:text-gray-100" onClick={() => setPopupOpen(false)}/>
                            </div>}
                            {popupVideo && <div className="flex absolute justify-center w-full z-20">
                                <i className="fad fa-play-circle text-4xl md:text-6xl text-gray-200 cursor-pointer hover:text-gray-100" onClick={(e) => {
                                    e.stopPropagation();
                                    setPopupOpen(true);
                                }}/>
                            </div>}

                            {renderTitleAndLogo()}

                            {!popupOpen && <video muted autoPlay loop webkitPlaysinline="true" playsinline="true" style={{width: "100%"}}>
                                <source src={mainVideo} type="video/mp4"/>
                            </video>}
                        </div>}


                        <div className="w-full md:w-6/7">
                            {!isMobile && renderTitleAndLogo()}

                            <form action="vehicles" onSubmit={e => handleSubmit(e)} className="p-4 rounded-none md:rounded flex relative mb-0 "
                                  style={{backgroundColor: isMobile ? "transparent" : "rgba(0, 0, 0, 0.6)"}}>
                                {loading && <div className="w-full flex items-center justify-center"><LoadingIndicator color="white" small/></div>}
                                {!loading && <div className="flex flex-col w-full">

                                    <div className="text-white flex">
                                        <div className="w-1/2 mr-4 md:mr-0">
                                            <div className="flex flex-col md:flex-row ml-4 md:ml-0 text-sm mb-2">
                                                <div className="flex mr-3 cursor-pointer mb-2 md:mb-0" onClick={handleNewVehicle}>
                                                    <div className="flex items-center justify-center w-4 h-4 rounded-full border border-white mr-2" style={{padding: 3}}>
                                                        {newVehicle && <div className="bg-white h-full w-full rounded-full"/>}
                                                    </div>
                                                    <span>Nieuw</span>
                                                </div>

                                                <div className="flex ml-0 md:ml-3 cursor-pointer" onClick={handleSecondHandVehicle}>
                                                    <div className="flex items-center justify-center w-4 h-4 rounded-full border border-white mr-2" style={{padding: 3}}>
                                                        {secondHandVehicle && <div className="bg-white h-full w-full rounded-full"/>}
                                                    </div>
                                                    <span>2e hands</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-col md:flex-row ml-4 md:ml-0 text-sm mb-2 w-1/2">
                                            <div className="flex mr-3 cursor-pointer mb-2 md:mb-0" onClick={() => setShowMonthlyPrice(false)}>
                                                <div className="flex items-center justify-center w-4 h-4 rounded-full border border-white mr-2" style={{padding: 3}}>
                                                    {!showMonthlyPrice && <div className="bg-white h-full w-full rounded-full"/>}
                                                </div>
                                                <span>Totaal bedrag</span>
                                            </div>

                                            <div className="flex ml-0 md:ml-3 cursor-pointer" onClick={() => setShowMonthlyPrice(true)}>
                                                <div className="flex items-center justify-center w-4 h-4 rounded-full border border-white mr-2" style={{padding: 3}}>
                                                    {showMonthlyPrice && <div className="bg-white h-full w-full rounded-full"/>}
                                                </div>
                                                <span>Maandelijks</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <div className="flex flex-col md:flex-row w-1/2 mr-2 md:mr-0">
                                            {renderSelectMakes()}
                                            {renderSelectModel()}
                                        </div>
                                        <div className="flex w-1/2 justify-start md:justify-between">
                                            <Fade spy={showMonthlyPrice} duration={200}>
                                                {renderSelectPrices()}
                                            </Fade>
                                            <button type="submit" className="hidden md:flex flex-col items-center rounded-r text-white py-1 px-2 md:py-3 md:px-6 cursor-pointer z-10"
                                                    style={{backgroundColor: "#1a1b1d"}}>
                                                <div className="text-white">Zoeken</div>
                                            </button>
                                        </div>
                                    </div>
                                    <button type="submit"
                                            className="flex md:hidden self-end flex-col items-center rounded-r text-white py-2 px-2 md:py-3 md:px-6 cursor-pointer z-20 mt-2 w-30 md:w-48 z-20"
                                            style={{backgroundColor: "#1a1b1d"}}>
                                        <div className="text-white">Zoeken</div>
                                    </button>
                                </div>}
                            </form>
                        </div>
                    </div>
                </div>
                }
            </div>

            <div className="flex items-center justify-center flex-1 absolute bottom-0 left-0 right-0 mb-0 md:mb-10 pb-10 md:pb-0 z-10 bg-transparent" style={{height: isMobile ? 60 : 100}}>
                <ScrollDownIndicator/>
            </div>

            {/*show only on big screens*/}
            <div className="hidden md:flex absolute right-0 left-0 top-0 bottom-0 bg-black flex items-center" onClick={() => setPopupOpen(false)}>
                {!popupOpen && <video width="100%" muted autoPlay loop webkitPlaysinline="true" playsinline="true" style={{width: "100%"}}>
                    <source src={mainVideo} type="video/mp4"/>
                </video>}
            </div>

        </div>
    );
};

VehicleSearchBgVideoCore.defaultProps = {
    logo: DefaultImage,
    title: DefaultTitle,
    videos: [],
    vehicles_count: false,
};

export default VehicleSearchBgVideoCore;
