import {filter, find, includes, map} from "lodash";
import {FilterTypes} from "../../../../constants";
import {getFilteredVehicles} from "../filter/functions";

export const mapSegmentsWithPriority = (segments, segmentsConfiguration) => {
    return map(segments, (s) => {
        const segment = find(segmentsConfiguration, (s1) => s1.key === s.key)
        return {
            ...s,
            name: (segment) ? segment.name : "Segment X",
            priority: (segment) ? segment.priority: 0
        }
    })
}

export const countVehiclesForSegment = (segment, filters, vehicles) => {
    const filtersForSegments = filter(filters, (f) => !includes(filtersToClearBySegment, f.type));
    //const filtersForSegments = filters;
    const filteredVehicles = getFilteredVehicles(filtersForSegments, undefined, vehicles);

    if (filtersForSegments.length > 0) {
        const filtered = filter(filteredVehicles, (v) => v.segmentKey === segment.key);
        return filtered.length
    } else {
        return segment.value
    }
}


export const filtersHasSegment = (filters) => {
    const result = filter(filters, (f) => f.type === FilterTypes.segment)
    return result.length > 0
}


export const filtersToClearBySegment = [FilterTypes.segment]
export const filtersToClearSegment = [FilterTypes.make, FilterTypes.model, FilterTypes.modelFamily, FilterTypes.category]
