import React, {useState} from "react";
import {find, map} from "lodash";


const Dropdown = ({options, value, renderItem, renderFooter, minWidth, minHeight, borderClassName, formatValue, placeHolder, centerText, defaultBgColor}) => {

    const [expanded, setExpanded] = useState(false);
    const selected = find(options, (item) => item.key === value);


    return (

        <div className="builder-w-full">
            <div className={`builder-flex builder-relative builder-cursor-pointer ${defaultBgColor} ${borderClassName}`} style={{minWidth: minWidth, maxWidth: 300, minHeight: minHeight}}
                 onClick={() => {
                     setExpanded(!expanded)
                 }}
                 onMouseLeave={() => setExpanded(false)}>

                <div className={`builder-flex builder-flex-1 builder-cursor-pointer ${(expanded) ? 'builder-bg-white' : defaultBgColor}`}>
                    <div className={`builder-flex builder-flex-1 ${(centerText) ? 'builder-items-center builder-justify-center' : ''}`} style={{paddingLeft: (centerText) ? 15 : 0}}>
                        {selected &&
                        <span className="builder-flex builder-items-center builder-pl-2 builder-text-sm">{(formatValue) ? formatValue(selected.value) : selected.value}</span>}

                        {!selected &&
                        <span className="builder-flex builder-items-center builder-pl-2 builder-text-sm builder-text-gray-600">{placeHolder}</span>}
                    </div>

                    <div className="builder-flex builder-items-center builder-justify-center" style={{width: 30}}>
                        <i className="fal fa-chevron-down"></i>
                    </div>
                </div>


                {expanded &&
                <div className="builder-absolute builder-bg-white builder-border builder-border-gray-300 builder-border-t-0" style={{width: '100%', top: 30, zIndex: 999, left: -1, right: 2}}>
                    {map(options, (item, index) => {
                        return (
                            <div key={`dropDownItem${index}`} onClick={(e) => {
                                setExpanded(false)
                            }}>
                                {renderItem(item, index)}
                            </div>
                        )
                    })}


                    {renderFooter && renderFooter(() => setExpanded(false))}


                </div>}
            </div>

        </div>
    )
};

Dropdown.defaultProps = {
    minWidth: 100,
    minHeight: 30,
    borderClassName: "builder-border builder-border-gray-300",
    placeHolder: 'Select item',
    centerText: false,
    defaultBgColor: 'builder-bg-white'
};

export default Dropdown;
