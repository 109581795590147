import React from "react";
import {DefaultPrimaryAction, DefaultSecondaryAction, DefaultText, DefaultTitle} from "../../../core/constants";
import {MarkupText} from "../../../core";
import {navigateAction} from "../../../core/utils";

const Banner_White = ({title, text, primary_action, secondary_action, context}) => {
    return (
        <div className="flex items-center justify-center py-5 md:py-20 bg-white">
            <div className="container relative z-10">
                <div className="flex items-center justify-center">
                    <div className="">
                        <div className="text-center items-center justify-center flex flex-col">
                            <div>
                                <h1 className="text-3xl md:text-5xl font-bold uppercase text-primary uppercase">{title}</h1>
                                <div className="text-sm md:text-lg md:text-2xl mt-5"><MarkupText text={text} context={context}/></div>
                            </div>

                            {(primary_action.type !== "" || secondary_action.type !== "") &&
                            <div className={`flex self-center items-center mt-10 ${(primary_action.type !== "" && secondary_action.type !== "") ? "justify-between" : "justify-center"} `}>

                                {primary_action.type !== "" &&
                                <div className="flex flex-row bg-primary text-white hover:text-gray-300 rounded-none text-center text-sm font-bold cursor-pointer outline-none"
                                     onClick={() => navigateAction(context, primary_action)}>
                                    <div className="flex flex-row justify-between w-full">
                                        <div className='py-3 px-4'> {primary_action.title}</div>
                                    </div>
                                </div>}

                                {secondary_action.type !== "" &&
                                <div className="flex flex-row bg-primary text-white hover:text-gray-300 rounded-none text-center text-sm font-bold ml-3 cursor-pointer outline-none"
                                     onClick={() => navigateAction(context, secondary_action)}>
                                    <div className="flex flex-row justify-between w-full">
                                        <div className='py-3 px-4'>{secondary_action.title}</div>
                                    </div>
                                </div>}
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

Banner_White.defaultProps = {
    title: DefaultTitle,
    text: DefaultText,
    primary_action: DefaultPrimaryAction,
    secondary_action: DefaultSecondaryAction,
};

Banner_White.human = {
    "nl": "",
    "fr": "",
    "en": "",
};

Banner_White.preview = Banner_White;

export default Banner_White;
