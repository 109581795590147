import React from 'react';
import TextInput from "../../builder/editors/components/TextInput";

const Integrations = ({settings, isSuperUSer, onChange}) => {
    return (
        <div>
            <div className="builder-bg-white builder-border builder-border-gray-200 builder-ring-1 builder-ring-black builder-ring-opacity-5 builder-mb-4">
                <div className="builder-flex builder-p-4">
                    <div className="builder-flex builder-w-full builder-flex-col">
                        <div className="builder-flex builder-flex-col builder-w-1/2 builder-mb-4">
                            <label className="builder-text-sm builder-text-gray-500 builder-mb-1">Intercom ID</label>
                            <TextInput value={settings.INTERCOM_ID}
                                       placeHolder='Intercom ID'
                                       onChanged={value => onChange({...settings, INTERCOM_ID: value})}/>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
};

export default Integrations;
