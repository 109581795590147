import {useState, useEffect, useContext} from 'react';
import fetch from 'cross-fetch'
import {filter, map, keys, orderBy, includes} from 'lodash';
import DataContext from "../store/DataContext";
import {vehicleSliderFilterTypes} from "../constants";


const ALL_VEHICLES_QUERY = `query Vehicles($filter: VehicleFilterInputType) {
  vehicles(filter: $filter) {
        id
        reference  
        make        
        model        
        modelFamily
        version
        firstRegistrationMonth
        firstRegistrationYear
        mileage
        doors
        seats        
        category        
        body        
        fuel        
        gearbox
        images  
        imagesCount
        price
        brutoPrice
        monthlyPrice
        sold
        co2
        euroNorm  
        reserved   
        dealership 
        dealershipUrl 
  } 
}`;


const map_vehicles = (vehicles) => {
    const mapped = map(vehicles, (vehicle) => {
        return {
            ...vehicle,
            images: JSON.parse(vehicle.images)
        }
    });
    return mapped;
};

export function useVehiclesSliderData(culture, filter) {
    const [vehicles, setVehicles] = useState([]);
    const [lastFilters, setLastFilters] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const [dataFetchStarted, setDataFetchStarted] = useState(false);
    const [error, setError] = useState(null);

    const checkFilterData = (filter) => {
        if(filter !== undefined && filter === lastFilters) {
            return false;
        }

        if (includes(keys(filter), vehicleSliderFilterTypes.reference)) {
            if(filter.reference === ""){
                return false;
            } else {
                if(lastFilters === undefined) {
                    return true
                } else {
                    if (lastFilters.reference !== filter.reference) {
                        return true
                    }
                }
                return false
            }
        } else {
            return (vehicles.length > 0 || dataFetchStarted) ? false: true
        }
    };

    useEffect(() => {
        if (!dataFetchStarted && checkFilterData(filter) ) {
            setDataFetchStarted(true);
            setLastFilters(filter)
            fetch(
                '/graphql', {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json', 'Accept-Language': culture},
                    body: JSON.stringify({query: ALL_VEHICLES_QUERY, variables: {filter: filter}}),
                })
                .then(res => res.json())
                .then(res => {
                    const vehs = filter !== undefined && (filter.reference !== undefined || filter.sort !== undefined) ? res.data.vehicles : orderBy(res.data.vehicles, ['price'], ['asc']);
                    setVehicles(map_vehicles(vehs));
                    setLoading(false);
                    setDataFetchStarted(false);
                })
                .catch(error => {
                    setError("ERROR");
                    setLoading(false);
                    setDataFetchStarted(false);
                });
        }

    }, [filter]);

    return {
        vehicles: vehicles,
        loading: loading,
        error
    };
}
