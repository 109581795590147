import React from 'react';
import Languages from "./Languages";
import TextInput from "../../builder/editors/components/TextInput";
import {languagesTranslate} from "../../../data/constants";
import Toggle from "react-toggle";

const GeneralSettings = ({settings, isSuperUser, onChange, data}) => {
    return (
        <div>
            {isSuperUser && <div className="builder-bg-white builder-border builder-border-gray-200 builder-ring-1 builder-ring-black builder-ring-opacity-5 builder-mb-4">
                <div className="builder-flex builder-p-4">
                    <div className="builder-flex builder-w-full builder-flex-col">
                        <div className="builder-flex builder-flex-col builder-w-1/2 builder-mb-4">
                            <label className="builder-text-sm builder-text-gray-500 builder-mb-1">Domain</label>
                            <TextInput value={settings.DOMAIN}
                                       placeHolder='Domain'
                                       onChanged={value => onChange({...settings, DOMAIN: value})}/>
                        </div>
                        <div className="builder-flex builder-flex-col builder-w-1/2 builder-mb-4">
                            <label className="builder-text-sm builder-text-gray-500 builder-mb-1 builder-ml-2">Cms user</label>
                            <TextInput value={settings.CMS_USERNAME}
                                       placeHolder='CMS username'
                                       onChanged={value => onChange({...settings, CMS_USERNAME: value})}/>
                        </div>
                        <div className="builder-flex builder-flex-col builder-w-1/2">
                            <label className="builder-text-sm builder-text-gray-500 builder-mb-1 builder-ml-2">Cms password</label>
                            <TextInput value={settings.CMS_PASSWORD}
                                       placeHolder='CMS password'
                                       onChanged={value => onChange({...settings, CMS_PASSWORD: value})}/>
                        </div>
                    </div>
                </div>
            </div>}


            <div className="builder-bg-white builder-border builder-border-gray-200 builder-ring-1 builder-ring-black builder-ring-opacity-5 builder-mb-4">
                <div className="builder-py-4 builder-border-b builder-border-gray-200 builder-bg-gray-100">
                    <div className="builder-text-lg builder-leading-6 builder-font-medium builder-text-gray-900 builder-px-4">Languages</div>
                </div>

                <div className="builder-flex builder-p-4">
                    <Languages data={data.languages} />
                </div>
            </div>


            {isSuperUser && <div className="builder-bg-white builder-border builder-border-gray-200 builder-ring-1 builder-ring-black builder-ring-opacity-5">
                <div className="builder-py-4 builder-border-b builder-border-gray-200 builder-bg-gray-100">
                    <div className="builder-text-lg builder-leading-6 builder-font-medium builder-text-gray-900 builder-px-4">Modules</div>
                </div>

                <div className="builder-flex builder-p-4 builder-flex-col">
                    <div className="builder-flex builder-w-full builder-mb-4">
                        <div className="builder-flex builder-flex-col builder-flex-1 builder-justify-center">Favorites</div>
                        <div className="builder-flex builder-flex-col  builder-flex-1 builder-justify-center builder-items-end">
                            <Toggle defaultChecked={settings.FAVORITES} checked={settings.FAVORITES} aria-label='No label tag' onChange={() => onChange({...settings, FAVORITES: !settings["FAVORITES"]})}/>
                        </div>
                    </div>

                    <div className="builder-flex builder-w-full builder-mb-4">
                        <div className="builder-flex builder-flex-col builder-flex-1 builder-justify-center">Reservation</div>
                        <div className="builder-flex builder-flex-col  builder-flex-1 builder-justify-center builder-items-end">
                            <Toggle defaultChecked={settings.RESERVATION} checked={settings.RESERVATION} aria-label='No label tag' onChange={() => onChange({...settings, RESERVATION: !settings["RESERVATION"]})}/>
                        </div>
                    </div>

                    {isSuperUser && <div className="builder-flex builder-w-full builder-mb-4">
                        <div className="builder-flex builder-flex-col builder-flex-1 builder-justify-center">Vehicle compare</div>
                        <div className="builder-flex builder-flex-col  builder-flex-1 builder-justify-center builder-items-end">
                            <Toggle defaultChecked={settings.VEHICLE_COMPARE} checked={settings.VEHICLE_COMPARE} aria-label='No label tag' onChange={() => onChange({...settings, VEHICLE_COMPARE: !settings["VEHICLE_COMPARE"]})}/>
                        </div>
                    </div>}

                    <div className="builder-flex builder-w-full builder-mb-4">
                        <div className="builder-flex builder-flex-col builder-flex-1 builder-justify-center">Financing</div>
                        <div className="builder-flex builder-flex-col  builder-flex-1 builder-justify-center builder-items-end">
                            <Toggle defaultChecked={settings.FINANCING} checked={settings.FINANCING} aria-label='No label tag' onChange={() => onChange({...settings, FINANCING: !settings["FINANCING"]})}/>
                        </div>
                    </div>

                    {settings.FINANCING && <div className="builder-flex builder-w-full builder-mb-4">
                        <div className="builder-flex builder-flex-col builder-flex-1 builder-justify-center">Financing text</div>
                        <div className="builder-flex builder-flex-col  builder-flex-1 builder-justify-center builder-items-end">
                            <Toggle defaultChecked={settings.FINANCING_TEXT} checked={settings.FINANCING_TEXT} aria-label='No label tag' onChange={() => onChange({...settings, FINANCING_TEXT: !settings["FINANCING_TEXT"]})}/>
                        </div>
                    </div>}

                </div>
            </div>}
        </div>
    );
};

export default GeneralSettings;
