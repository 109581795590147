import React from 'react';
import {navigatePage} from "../../../core/utils";
import {LegalContent} from "../../../core/components/legal";
import VehicleSlider_Preview from "./components/VehicleSlider_Preview";
import VehiclesSliderV2 from "../../../core/components/vehicle/vehicles-slider-v2/VehiclesSliderV2";
import AdvertCard from "../../common/AdvertCard";

const VehicleSliderV2 = ({custom_card_image, custom_card_action, context}) => {
    return (
        <div className="flex flex-col px-10">
            <VehiclesSliderV2 context={context}
                              onVehicleClick={(vehicle) => {
                                  navigatePage(context, context.urls.vehicleDetail, `reference=${vehicle.reference}`);
                              }}
                              renderPrevArrow={(onClick) => {
                                  return <div className=" flex items-center justify-center absolute cursor-pointer text-white z-10 rounded-full"
                                              style={{top: "50%", left: -25, transform: "translateY(-50%)"}}
                                              onClick={() => onClick()}>
                                      <i className="fad fa-chevron-left text-3xl text-primary" />
                                  </div>
                              }}
                              renderNextArrow={(onClick) => {
                                  return <div className=" flex items-center justify-center absolute cursor-pointer text-white z-10 rounded-full"
                                              style={{top: "50%", right: -25, transform: "translateY(-50%)"}}
                                              onClick={() => onClick()}>
                                      <i className="fad fa-chevron-right text-3xl text-primary" />
                                  </div>
                              }}
                              renderAdvertCard={!custom_card_action || !custom_card_image ? null : () => {
                                  return <AdvertCard context={context}
                                                     custom_card_action={custom_card_action}
                                                     custom_card_image={custom_card_image}
                                  />
                              }}
                              renderFooter={() => {
                                  return <div className={`flex flex-row text-center items-center text-xl md:text-3xl justify-center hover:underline mt-5 cursor-pointer ${!context.financing ? 'mb-5' : ''}`}
                                              onClick={() => navigatePage(context, context.urls.vehicles)}>
                                      <i className="far fa-plus mr-3"/>Ontdek onze voorraad
                                  </div>
                              }}
                              renderLegalText={() => {
                                  if (!context.financing) return <div className="hidden"/>
                                  return <div className="mt-2 bg-white text-gray-600 text-left">
                                      <LegalContent context={context}/>
                                  </div>
                              }}
            />
        </div>
    );
};

VehicleSliderV2.human = {
    "nl": "",
    "fr": "",
    "en": "",
};

VehicleSliderV2.preview = () => { return <VehicleSlider_Preview /> };

export default VehicleSliderV2;
