import React from 'react'
import {getKeyValueContentFieldsForBlock} from "../../../utils";
import * as components from '../../../dealer-theme'

const BlockComponent = ({block, context}) => {
    const Component = components[block.componentName];

    if(!Component) {
        return null;
    }

    return (
        <div id={`b${block.id.split('-')[0]}`} className="font-body">
            <Component.default {...getKeyValueContentFieldsForBlock(block,context.culture)} context={context}  />
        </div>
    )

};

export default BlockComponent;
