import React from "react";
import {DefaultTitle} from "../../../core/constants";

const Heading_White = ({text}) => {
    return (
        <div className={`relative overflow-hidden h-full text-bg-container py-5 md:py-20 bg-white text-black`}>
            <div className="flex items-center justify-center h-full">
                <div className="container relative z-10">

                    <div className="flex items-center justify-center">
                        <div className="w-2/3">
                            <div className="text-center items-center justify-center flex flex-col">
                                <h1 className="h2 mb-0 ">
                                    <div className="text-xl md:text-6xl">{text}</div>
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

Heading_White.defaultProps = {
    text: DefaultTitle,
};

Heading_White.human = {
    "nl": "",
    "fr": "",
    "en": "",
};

Heading_White.preview = Heading_White;

export default Heading_White;
