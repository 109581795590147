import React, {useContext, useEffect, useState} from 'react';
import {DefaultAdditionalVehicleImages, DefaultPrimaryAction} from "../../../core/constants";
import {useVehicleDetails} from "../../../core/hooks/useVehicleDetails";
import {ErrorIndicator, LoadingIndicator, VehicleTestDriveForm, PhotoSlider} from "../../../core";
import {formatPrice, b64DecodeUnicode, navigatePage, getUrlParamValueFromWindow} from '../../../core/utils'
import Sticky from "react-stickynode";
import DetailRightSide from "./components/DetailRightSide";
import {useNextPrevContextVehicle} from "../../../core/hooks/useNextPrevContextVehicle";
import {find} from "lodash";
import {Fade} from "react-reveal";
import DataContext from "../../../core/store/DataContext";
import MoreInformation from "../../../core/components/vehicle/forms/MoreInformation";
import {Redirect} from "@reach/router";
import VehicleDetails_Preview from "./components/VehicleDetails_Preview";


const VehicleDetail = ({calculate_value_action, additional_vehicle_images, context, reference: referenceFromPops = undefined, overridePageMetaData}) => {
        const tmpRef = (referenceFromPops) ? referenceFromPops : (context && !context.builder) ? getUrlParamValueFromWindow('reference') : "";
        const [reference, setReference] = useState(tmpRef);
        const {vehicle, loading, error} = useVehicleDetails(reference, context.culture);
        const {getNextVehicleRef, getPrefVehicleRef} = useNextPrevContextVehicle();
        const [financingRate, setFinancingRate] = useState(undefined);
        const contextData = useContext(DataContext);

        const [testDriveFormVisible, setTestDriveFormVisible] = useState(false);
        const [moreInfoVisible, setMoreInfoVisible] = useState(false);
        const financingEnabled = context.financing;

        const nextVehicleRef = getNextVehicleRef(reference);
        const prevVehicleRef = getPrefVehicleRef(reference);

        const reservation = context.reservation;


        useEffect(() => {
            setReference(tmpRef);
            if (contextData && contextData.setReference ) {
                contextData.setReference(tmpRef);
            }
            return () => {
                if (contextData && contextData.setReference) {
                    contextData.setReference('');
                }
            }
        }, [tmpRef]);

        // useEffect(() => {
        //     if (tmpRef === undefined || tmpRef === "") {
        //         navigatePage(context, context.urls.vehicles)
        //     }
        // }, [])


        useEffect(() => {

            if (vehicle && context.financing) {
                const defaultFinanceRate = find(vehicle.financeRates, (fr) => fr.default);

                if (defaultFinanceRate) {
                    setFinancingRate(defaultFinanceRate)
                }
            }

            if (overridePageMetaData && vehicle) {
                overridePageMetaData(`${vehicle.make} ${vehicle.model} ${vehicle.version}`)
            }
        }, [vehicle]);

        const handleNextVehicle = () => {
            if (nextVehicleRef) {
                setReference(nextVehicleRef)
                contextData.setReference(nextVehicleRef);
            }
        };

        const handlePrevVehicle = () => {
            if (prevVehicleRef) {
                setReference(prevVehicleRef)
                contextData.setReference(prevVehicleRef);
            }
        };

        if (reference === undefined) {
            return <Redirect to={`/${context.urls.vehicles}`}/>
        }


        if (loading) {
            return <div className="flex h-screen"><LoadingIndicator/></div>
        }

        if (error) {
            return <div className="container" style={{maxHeight: 40}}><ErrorIndicator error={error}/></div>
        }

        if (!loading && vehicle.sold) {
            return <div className="flex h-screen relative z-30" style={{backgroundColor: "rgba(0, 0, 0, 0.5"}}>
                <div className="absolute left-0 right-0 top-0 bottom-0 flex items-center justify-center">
                    <div className="flex items-center justify-center flex-col w-38 h-38 bg-primary rounded-full opacity-75" style={{height: 160, width: 160}}>
                        <img src={require('../../../core/assets/sold_white.svg')} alt="Verkocht" style={{height: 80}}/>
                    </div>
                </div>
            </div>
        }

        if (!loading && vehicle.reserved) {
            return <div className="flex h-screen relative z-30" style={{backgroundColor: "rgba(0, 0, 0, 0.5"}}>
                <div className="absolute left-0 right-0 top-0 bottom-0 flex items-center justify-center">
                    <div className="flex items-center justify-center flex-col w-38 h-38 bg-primary rounded-full opacity-75" style={{height: 160, width: 160}}>
                        <img src={require('../../../core/assets/reserved_white.svg')} alt="Gereserveerd" style={{height: 80}}/>
                    </div>
                </div>
            </div>
        }

        const hasBrutoPrice = (vehicle.brutoPrice && vehicle.brutoPrice) > 0;
        const pdfEndpoint = `/manager/seller/print/windscreen/0/1/?language=${context.culture}&reference=${reference}`

        const buttonsAndPrice = () => {
            return <div className="flex ">
                <div className="flex flex-col mr-3" style={{width: reservation ? 300 : 200}}>

                    {reservation &&
                    <div className="flex w-full">
                        <div onClick={() => navigatePage(context, context.urls.vehicleReservation, `vehicle=${vehicle.reference}`)}
                             className="bg-primary text-white hover:text-gray-400 text-sm py-2 px-4 rounded-none w-full text-center cursor-pointer outline-none">
                            Reserveren
                        </div>

                    </div>}

                    <div className={`flex ${reservation ? "flex-row  mt-2" : "flex-col"}`}>
                        <button className={`bg-primary w-full text-white hover:text-gray-400 text-sm py-2 px-2 ${reservation ? "mr-2" : "mb-3"} rounded-none outline-none`}
                                onClick={() => {
                                    setMoreInfoVisible(false);
                                    setTestDriveFormVisible(true);
                                }}>
                            Testrit aanvragen
                        </button>

                        <button className="bg-primary w-full text-white hover:text-gray-400 text-sm py-2 px-2 rounded-none cursor-pointer outline-none"
                                onClick={() => {
                                    setTestDriveFormVisible(false);
                                    setMoreInfoVisible(true);
                                }}>
                            Meer informatie
                        </button>
                    </div>

                </div>

                {!vehicle.sold && !vehicle.reserved &&
                <div className="flex flex-col self-center justify-center text-black py-3 border-l border-transparent md:border-0 mr-0 md:mr-2"
                     style={{width: 250, minHeight: 76}}>
                    <div className="flex items-center justify-end">
                        <div className="flex items-center">
                            <span className={`font-thin mr-2  w-full ${(hasBrutoPrice) ? 'text-sm line-through' : 'text-3xl'}`}>{formatPrice(vehicle.price)}</span>
                            {hasBrutoPrice &&
                            <span className="text-3xlfont-extralight mr-2 ">{formatPrice(vehicle.brutoPrice)}</span>}
                        </div>
                        <span className="self-end text-xsfont-extralight " style={{paddingBottom: 3}}>incl. BTW</span>
                    </div>
                    < div className="flex items-center justify-end text-center">
                        <span className="self-end text-smfont-extralight text-black" style={{paddingBottom: 3}}>{(vehicle.vatRegime) ? 'BTW aftrekbaar' : ''}</span>
                    </div>
                </div>}
            </div>
        }




        return (
            <Fade spy={reference} duration={250}>
                <div className="vehicle-details flex flex-col my-5 px-5">

                    <div className="flex mb-5 justify-center md:justify-between" style={{letterSpacing: "0.03rem", fontWeight: 500, fontSize: 14}}>
                        <div className="flex items-center justify-center hover:text-gray-900 mt-1 text-center cursor-pointer outline-none uppercase text-black"
                             onClick={() => navigatePage(context, context.urls.vehicles)}>
                            <i className="fal fa-chevron-left mr-2" style={{fontSize: "0.6rem"}}/> Terug naar het overzicht
                        </div>

                        {(prevVehicleRef || nextVehicleRef) && <div className="hidden md:flex">
                            <div className={`flex items-center justify-center mr-5 uppercase ${prevVehicleRef ? "text-black cursor-pointer" : "text-gray-400"}`} onClick={handlePrevVehicle}>
                                <i className="fal fa-chevron-left mr-2" style={{fontSize: "0.6rem"}}/> Vorige
                            </div>
                            <div className={`flex items-center justify-center uppercase ${nextVehicleRef ? "text-black cursor-pointer" : "text-gray-400"}`} onClick={handleNextVehicle}>
                                Volgende <i className="flex self-center fal fa-chevron-right ml-2" style={{fontSize: "0.6rem"}}/>
                            </div>
                        </div>}

                    </div>
                    <Sticky enabled={true} top=".sticky-header" bottomBoundary='.bottom-container' innerZ={21}>
                        <div id="veh-detail-header" className="sticky-header">
                            {/*big screens*/}
                            <div className="flex justify-center items-center hidden md:block header2">
                                <div className="flex flex-row justify-between items-center w-full pl-0 relative text-black mb-5 border-b border-primary bg-gray-100 pb-2 pt-2">

                                    <i className={`fal fa-chevron-left text-4xl mr-2 ml-2 cursor-pointer ${(prevVehicleRef ? 'text-black' : 'text-gray-200')}`} onClick={handlePrevVehicle}/>
                                    <div className="flex flex-col w-full self-center items-center">
                                        <div className="flex flex-col w-full self-start pl-2 text-2xl capitalize truncate">
                                            <div>{vehicle.make} {vehicle.model}</div>
                                            <div className="text-md truncate">{vehicle.version}</div>
                                        </div>
                                        {vehicle.title && vehicle.title !== "" &&
                                        <div className="flex w-full self-start text-md pl-2 mt-1 text-gray-800  ">
                                            {vehicle.title}
                                        </div>}
                                        <div className="flex w-full self-start text-xs pl-2 mt-1 ">
                                            <span className="text-gray-600 text-xs mr-1">Referentie:</span>{vehicle.identification}
                                        </div>
                                    </div>
                                    <div className="hidden xl:block">{buttonsAndPrice()}</div>
                                    <i className={`fal fa-chevron-right text-4xl ml-2 mr-2 cursor-pointer ${(nextVehicleRef ? 'text-black' : 'text-gray-200')}`} onClick={handleNextVehicle}/>
                                </div>
                                <div className="flex w-full items-center justify-center xl:hidden mb-5 lg-mb-0">{buttonsAndPrice()}</div>
                            </div>


                            {/*On mobile*/}
                            <div className="flex flex-col justify-center items-center md:hidden bg-white">

                                <div className="flex items-center w-full pl-0 relative text-black mb-2 border-b border-primary bg-gray-100 pb-2 pt-2">

                                    <i className={`fal fa-chevron-left text-4xl mr-2 ml-2 cursor-pointer ${(prevVehicleRef ? 'text-black' : 'text-gray-200')}`} onClick={handlePrevVehicle}/>
                                    <div className="flex flex-col flex-1">
                                        <div className="flex flex-col w-full pl-2 ">
                                            <div className="flex w-full pl-2 text-xl capitalize truncate">
                                                {vehicle.make} {vehicle.model} {vehicle.type}
                                            </div>
                                            {vehicle.title && vehicle.title !== "" &&
                                            <div className="flex w-full text-md pl-2 mt-1 text-gray-800  ">
                                                {vehicle.title}
                                            </div>}
                                            <div className="flex w-full text-xs pl-2 mt-1 ">
                                                <span className="text-gray-600 text-xs mr-1">Referentie:</span>
                                                {vehicle.identification} {(vehicle.vatRegime) ? ' | BTW aftrekbaar' : ''}
                                            </div>
                                        </div>

                                        <div className="flex bg-gray-100 pt-2 pl-2">

                                            {!vehicle.sold && !vehicle.reserved &&
                                            <div className="flex items-center">
                                                <span className={`self-end text-xlfont-extralight mr-2 text-black pl-2 ${(hasBrutoPrice) ? 'line-through' : ''}`}>{formatPrice(vehicle.price)}</span>
                                                {hasBrutoPrice &&
                                                <span className="self-end text-2xlfont-extralight ml-2">{formatPrice(vehicle.brutoPrice)}</span>}
                                                <span className="self-end text-xsfont-extralight text-black ml-2" style={{paddingBottom: 3}}>incl. BTW</span>
                                            </div>}

                                        </div>

                                        <div className="flex ">


                                        </div>
                                    </div>
                                    <i className={`fal fa-chevron-right text-4xl ml-2 mr-2 cursor-pointer ${(nextVehicleRef ? 'text-black' : 'text-gray-200')}`} onClick={handleNextVehicle}/>

                                </div>
                            </div>
                        </div>
                    </Sticky>


                    <div className="flex flex-col md:flex-row ">
                        {/*On big screens*/}
                        <div className="hidden md:block w-full md:w-1/2 -mr-5 md:mr-0 pr-0 md:pr-5">
                            <div className="bg-white">
                                <PhotoSlider images={vehicle.images} onePhotoSlider={true} showThumbs={true} photosSorted={true} vehicleId={vehicle.reference}/>
                            </div>

                            {financingRate &&
                            <div className="mt-5 px-5">
                                <div className="text-gray-600 text-center mb-6" dangerouslySetInnerHTML={{__html: b64DecodeUnicode(financingRate.legal)}}/>
                            </div>}
                        </div>

                        {/*On small screens*/}
                        <div className={`${testDriveFormVisible ? 'hidden' : 'block'} md:hidden w-full md:w-1/2 -mr-5 md:mr-0 pr-0 md:pr-5`}>
                            <PhotoSlider images={vehicle.images} onePhotoSlider={true} showThumbs={true} photosSorted={true}/>
                        </div>

                        {testDriveFormVisible && <VehicleTestDriveForm context={context}
                                                                       vehicle={vehicle}
                                                                       handlePrivacy={() => navigatePage(context, context.urls.privacy, null, null, true)}
                                                                       onClose={() => setTestDriveFormVisible(false)}/>}

                        {moreInfoVisible && <MoreInformation context={context}
                                                             vehicle={vehicle}
                                                             handlePrivacy={() => navigatePage(context, context.urls.privacy, null, null, true)}
                                                             onClose={() => setMoreInfoVisible(false)}/>}


                        {!testDriveFormVisible && !moreInfoVisible &&
                        <div className="vehicle-detail-right-side flex flex-1 mt-5 md:mt-0 relative">
                            <DetailRightSide vehicle={vehicle}
                                             reservation={reservation}
                                             financing={financingEnabled}
                                             financingRate={financingRate}
                                             pdfEndpoint={pdfEndpoint}
                                             context={context}
                                             onFinanceRateChanged={(rate) => setFinancingRate(rate)}
                            />
                        </div>}


                    </div>


                </div>
                <div id="bottom-container" className="opacity-0 bottom-container"/>
            </Fade>
        )

    }
;

VehicleDetail.defaultProps = {
    calculate_value_action: DefaultPrimaryAction,
    additional_vehicle_images: DefaultAdditionalVehicleImages,
};

VehicleDetail.human = {
    "nl": "",
    "fr": "",
    "en": "",
};

VehicleDetail.preview = ()  => { return <VehicleDetails_Preview />};


export default VehicleDetail;

