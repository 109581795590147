import React, {useState} from 'react'
import {useTurntable} from './useTurntable'
import {useIsMobile} from "../../../hooks/useIsMobile";

export const Turntable = ({images, onLoad}) => {
    const {activeImageIndex, eventHandlers, started} = useTurntable(images)
    const {isMobile} = useIsMobile()
    const overLaySize = (isMobile) ? 50 : 100;
    const overLayImageWidth = (isMobile) ? 20 : 70

    if (!images || !images.length) {
        return (
            <h1>No images...</h1>
        )
    }

    const handleOnLoad = (e) => {
        (onLoad) ? onLoad(e) : null
    }


    return (
        <div className="relative">

            {/*{overlayVisible &&*/}
            {/*<div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center" style={{cursor: 'grab', height: parentHeight, width: '100%', zIndex: 999}} onDragStart={hideOverlay} onClick={hideOverlay} onMouseDown={hideOverlay}>*/}
            {/*    <div className="flex items-center justify-center rounded-full"*/}
            {/*         style={{width: 60, height: 60, backgroundColor: 'black', opacity: '70%', margin: 'auto'}}>*/}
            {/*        <img src={require("./360-camera.png")} style={{width: 40}}/>*/}
            {/*    </div>*/}
            {/*</div>}*/}

            {!started &&
            <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center drag-block z-40" style={{cursor: 'grab', marginTop: '35%', width: '100%'}}>
                <div className="flex items-center justify-center rounded-full"
                     style={{width: overLaySize, height: overLaySize, backgroundColor: '#344248CC', margin: 'auto'}}>
                    <img className="drag-block" src={require("./360-camera.png")} alt="360" style={{width: overLayImageWidth}}/>
                </div>
            </div>}

            <div {...eventHandlers}
                 style={{position: 'relative'}}>

                {images.map((imageSrc, index) => (
                    <img
                        key={imageSrc}
                        src={imageSrc}
                        style={{
                            position: 'absolute',
                            opacity: index === activeImageIndex ? 1 : 0,
                            left: 0,
                            top: 0,
                            right: 0,
                            bottom: 0,
                            width: '100%',
                            cursor: "grab"
                        }}
                        alt={`turntable image ${index}`}
                        onLoad={handleOnLoad}
                    />
                ))}
            </div>
        </div>

    )
}

export default Turntable;
