import React, {isValidElement, useContext, useEffect, useState} from 'react';
import Header from "../Header";
import Menu from "./components/Menu";
import {useHistory} from "react-router-dom";
import {routes} from "../../data/constants";
import {useMutation, useQuery} from "graphql-hooks";
import {SettingsQuery, SettingsUpdateMutation} from "../../data/graphql";
import {filter, head, isEqual, map, omit, orderBy, reduce} from "lodash";
import cogoToast from "cogo-toast";
import {StateContext} from "../../App";
import HeaderMenu from "./components/HeaderMenu";

import Page404 from "../Page404";
import {LoadingIndicator, ErrorIndicator} from "../common";
import ConfirmBox from "../common/ConfirmBox";
import GeneralSettings from "./general/GeneralSettings";
import Seo from "./general/Seo";
import Analytics from "./general/Analytics";
import Integrations from "./general/Integrations";


const mapSettings = settings => {
    return reduce(settings, (result, value, key) => {
        let newValue = value;
        if (value === 'True' || value === 'true') newValue = true
        if (value === 'False' || value === 'false') newValue = false
        return {...result, [key]: newValue}
    }, {})
}

const unmapSettings = settings => {
    return reduce(settings, (result, value, key) => {
        let newValue = value;
        if (value === true) newValue = 'true'
        if (value === false) newValue = 'false'
        return {...result, [key]: newValue}
    }, {})
}

const DynamicComponent = (props) => {
    if (isValidElement(props.component)) {
        return React.cloneElement(props.component, omit(props, 'component'))
    }

    return <div>The component has not been created yet.</div>;
}

const generalSettings = [
    {
        title: 'General',
        mainComponent: <GeneralSettings/>,
        default: true,
        reference: 'general',
        icon: 'far fa-globe-asia',
        position: 1
    }, {
        title: 'SEO',
        mainComponent: <Seo/>,
        default: false,
        reference: 'seo',
        icon: 'fal fa-globe-europe',
        position: 2
    }, {
        title: 'Analytics',
        mainComponent: <Analytics/>,
        default: false,
        reference: 'analytics',
        icon: 'fal fa-analytics',
        position: 3
    }, {
        title: 'Integration',
        mainComponent: <Integrations/>,
        default: false,
        reference: 'integrations',
        icon: 'fal fa-network-wired',
        position: 4
    }
]


const General = () => {
    const [activeComponent, setActiveComponent] = useState(generalSettings.find(setting => setting.default).reference)
    const [settings, setSettings] = useState({})
    const [initSettings, setInitSettings] = useState({})
    const [showConfirm, setShowConfirm] = useState(false);
    const state = useContext(StateContext);
    const isSuperUser = state.session.user.isSuperUser;
    const currentState = state.history[state.history.length - 1];
    let history = useHistory();
    const [saveMutation, {loading: saving}] = useMutation(SettingsUpdateMutation);
    const {loading, error, data} = useQuery(SettingsQuery);

    useEffect(() => {
        if (!loading) {
            setSettings(mapSettings(data.settings))
            setInitSettings({
                settings: mapSettings(data.settings),
                languages: data.languages
            })
        }
    }, [loading, data])

    const handleGeneralSettingsSave = () => {
        const availableLanguages = filter(currentState.languages, (l) => l.available);
        const languagesData = map(availableLanguages, (l) => l.id);

        saveMutation({
            variables: {
                data: {
                    languages: languagesData,
                    settings: unmapSettings(settings)
                }
            }
        }).then(res => {
            const {ok} = res.data.settingsUpdate;
            if (ok) {
                cogoToast.success('Settings saved!');
                setInitSettings({
                    settings: settings,
                    languages: currentState.languages
                })
            }
        }).catch(error => {
            cogoToast.error('Error saving settings!');
        });
    };

    const hasChanges = () => {
        let settingsHaveChanges = !isEqual(settings, initSettings.settings);
        let languagesHaveChanges = !isEqual(currentState.languages, initSettings.languages);
        return settingsHaveChanges || languagesHaveChanges
    }

    const handleBack = () => {
        if (!hasChanges()) {
            history.push(routes.HOME)
        } else {
            setShowConfirm(true);
        }
    };
    console.log(settings);

    const handleCancel = () => {
        history.push(routes.HOME)
    };

    if (loading && !error) {
        return <LoadingIndicator/>
    }

    if (!loading && error) {
        return <ErrorIndicator error="Settings could not be fetched."/>
    }

    if (!loading && !error) {
        const setting = generalSettings.find(setting => setting.reference === activeComponent);
        if (!setting) {
            return <Page404/>
        }

        if (setting) {
            const {mainComponent} = setting;
            return (
                <div className="builder-flex builder-flex-1 builder-flex-col builder-h-screen builder-font-body builder-text-sm">
                    {showConfirm && <ConfirmBox onCancel={() => setShowConfirm(false)} onConfirm={() => {
                        history.push(routes.HOME)
                    }}/>}
                    <Header renderMenu={() => <HeaderMenu/>}/>

                    <Menu title="General settings" onSave={handleGeneralSettingsSave} onBack={handleBack} onCancel={handleCancel} loading={saving} saveDisabled={!hasChanges()}/>

                    <div className="builder-flex builder-items-center builder-justify-center builder-bg-white">
                        <div className="builder-container">

                            <div className="builder-flex builder-items-center builder-justify-center builder-py-2">
                                <div className="builder-flex builder-container builder-h-full">
                                    <div className="builder-w-1/5 builder-border-r builder-border-gray-200">
                                        {orderBy(generalSettings, ['position'], ['asc']).map((item, i) => {
                                            return <div key={`${item.reference}-${i}`}
                                                        className={`builder-p-3 builder-cursor-pointer hover:bg-gray-200 ${activeComponent === item.reference ? "builder-bg-gray-100" : ""}`}
                                                        onClick={() => setActiveComponent(item.reference)}>
                                                <i className={`mr-2 ${item.icon}`}/> {item.title}
                                            </div>
                                        })}
                                    </div>
                                    <div className="builder-w-4/5 builder-mx-4">
                                        <div className="builder-bg-white ">
                                            <DynamicComponent component={mainComponent}
                                                              data={data}
                                                              settings={settings}
                                                              isSuperUser={isSuperUser}
                                                              onChange={updatedSettings => setSettings(updatedSettings)}/>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                </div>
            );
        }
    }
};

export default General;
