import React, {useEffect, useState} from 'react';
import {cloneDeep, filter, find, includes, indexOf} from "lodash";
import {getFromStorage, saveToStorage} from "../utils";
import {getCookie} from "../utils";
import {initSession} from "./session";
import {useTracking} from "../tracking/useTracking";

const defaultState = {
    vehicles: undefined,
    reference: undefined,
    filterData: undefined,
    globalModalComponent: undefined,
    scrollOffset: 0,
    cookiesAccepted: false,
    setVehiclesData: () => {
    },
    setVehicleImages: () => {
    },
    setFilters: () => {
    },
    setSorter: () => {
    },
    setScrollOffset: () => {
    },
    favorites: [],
    updateFavorites: () => {
    },
    setCookiesAccepted: () => {
    },
    setGlobalModal: () => {
    }
};


const DataContext = React.createContext(defaultState);

const DataContextProvider = ({children}) => {
    const [vehicles, setVehicles] = useState(undefined);
    const [vehiclesCulture, setVehiclesCulture] = useState(undefined)
    const [segments, setSegments] = useState(undefined)
    const [locationTypes, setLocationTypes] = useState(undefined)
    const [reference, setReference] = useState(undefined);
    const [filterData, setFilterData] = useState(undefined);
    const [globalModalComponent, setGlobalModalComponent] = useState(undefined);
    const [favorites, setFavorites] = useState([]);
    const [cookiesAccepted, setCookiesAccepted] = useState(false);
    const [scrollOffset, setScrollOffset] = useState(0);
    const [lastSelectedVehicle, setLastSelectedVehicle] = useState(undefined);
    const [filters, setFilters] = useState([]);
    const [sorter, setSorter] = useState(undefined);
    const [session, setSession] = useState(initSession());
    const [vehicleCompareReferences, setVehicleCompareReferences] = useState([]);
    const {trackingId, traceUserAction} = useTracking();


    useEffect(() => {
        const cookie = getCookie('GDPR_ANALYTICS');
        const accepted = cookie !== undefined;
        setCookiesAccepted(accepted);
    });

    useEffect(() => {
        const storageFavorites = getFromStorage('favorites');
        if (storageFavorites !== null) {
            setFavorites(storageFavorites)
        }
    }, [0]);

    const isFavorite = (reference) => {
        const storageFavorites = getFromStorage('favorites');
        let isFavorite = false
        if (storageFavorites !== null) {
            isFavorite = indexOf(storageFavorites, reference) >= 0
        }

        return isFavorite;
    }

    const updateFavorites = reference => {
        const storageFavorites = getFromStorage('favorites');
        let updatedFavorites;
        if (storageFavorites !== null) {
            if (isFavorite(reference)) {
                updatedFavorites = filter(storageFavorites, favorite => favorite !== reference);
            } else {
                updatedFavorites = [...storageFavorites, reference]
            }
        } else {
            updatedFavorites = [reference]
        }
        setFavorites(updatedFavorites);
        saveToStorage('favorites', updatedFavorites);
    };

    const handleAddVehicleToCompare = (reference) => {
        if (!includes(vehicleCompareReferences, reference)) {
            setVehicleCompareReferences([...vehicleCompareReferences, reference])
        }
    };

    const handleRemoveVehicleFromCompare = (reference) => {
        setVehicleCompareReferences(filter(vehicleCompareReferences, (ref) => ref !== reference))
    };

    return (
        <DataContext.Provider value={{
            session,
            vehicles,
            vehiclesCulture,
            filterData,
            filters,
            sorter,
            reference,
            scrollOffset,
            lastSelectedVehicle,
            cookiesAccepted,
            favorites,
            globalModalComponent,
            setVehiclesData: (vehicles, filters, culture) => {
                setVehicles(vehicles);
                setFilterData(filters)
                setVehiclesCulture(culture)
            },
            setVehicleImages: (reference, images) => {
                const tmpVehicles = cloneDeep(vehicles);
                let vehicle = find(tmpVehicles, (v) => v.reference === reference)
                vehicle.images = images;
                vehicle.imagesLoaded = true
                setVehicles(tmpVehicles)
            },
            setFilters: (filters) => setFilters(filters),
            setSorter: (sorter) => setSorter(sorter),
            setReference: (reference) => setReference(reference),
            setScrollOffset: (offset) => setScrollOffset(offset),
            setLastSelectedVehicle: (reference) => setLastSelectedVehicle(reference),
            setCookiesAccepted: (accepted) => setCookiesAccepted(accepted),
            updateFavorites: reference => updateFavorites(reference),
            setGlobalModalVisible: component => setGlobalModalComponent(component),

            // vehicle compare
            vehicleCompareReferences,
            addVehicleToCompare: (ref) => handleAddVehicleToCompare(ref),
            removeVehicleFromCompare: (ref) => handleRemoveVehicleFromCompare(ref),
            setVehicleCompareReferences: (refs) => (refs && refs.length > 0 && vehicleCompareReferences !== refs) ? setVehicleCompareReferences(refs) : null,


            // user activity tracking
            trackingId: trackingId,
            traceUserAction: (type, data) => traceUserAction(type,  data),


            //segments
            segments: segments,
            setSegments: (segments) => setSegments(segments),

            //location types
            locationTypes: locationTypes,
            setLocationTypes: (types) => setLocationTypes(types)

        }}>
            {children}
        </DataContext.Provider>
    );
};

export default DataContext;

export {DataContextProvider};
