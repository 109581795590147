import React from 'react';
import {DefaultImage, DefaultText, DefaultTitle} from "../../../core/constants";
import {navigatePage} from "../../../core/utils";
import {BasicContactForm} from '../../../core';
import ContactFormTwoCol_Preview from "./components/ContactFormTwoCol_Preview";


const ContactFormTwoCol = ({title, text, image, expert_name, expert_title, context}) => {
    return (
        <div className="flex items-center justify-center w-full pt-10 bg-gray-700 relative" style={{
            background: `url(${image}) no-repeat center bottom`,
            backgroundSize: "cover"
        }}>
            <div id="contact-us" className="flex flex-col md:flex-row container">
                <div className="flex items-center justify-center md:w-6/12 ">


                </div>
                <div className="flex w-full flex-col md:w-6/12 p-5 mb-10 bg-white">
                    <div className="flex flex-1 flex-col">
                        <div className="mb-1 lg:mb-3 text-2xl md-text-4xl px-5 md:px-0">{title}</div>
                        <div className="mb-8 lg:mb-8 px-5 md:px-0">{text}</div>

                    </div>

                    <BasicContactForm context={context}
                                      handlePrivacy={() => navigatePage(context, context.urls.privacy, null, null, true)}/>
                </div>
            </div>
        </div>
    )
};

ContactFormTwoCol.defaultProps = {
    title: DefaultTitle,
    text: DefaultText,
    image: DefaultImage,
    expert_name: "",
    expert_title: "",
};

ContactFormTwoCol.human = {
    "nl": "Banner met achtergrond foto en contact formulier",
    "fr": "",
    "en": "",
};

ContactFormTwoCol.preview = () => { return <ContactFormTwoCol_Preview /> };

export default ContactFormTwoCol;
