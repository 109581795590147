import React, {useEffect, useReducer} from 'react';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import PrivateRoute from "./components/common/PrivateRoute";
import {routes} from "./data/constants";
import {initialState, rootReducer} from "./data/reducers";

import {initSession, updateSession} from "./data/session";
import {setSession} from "./data/actions";
import Login from "./components/authentication/Login";
import Builder from "./components/builder/Builder";
import Page404 from "./components/Page404";
import General from "./components/settings/General";
import Dealership from "./components/settings/Dealership";
import Dealerships from "./components/settings/Dealerships";


export const StateContext = React.createContext();
export const DispatchContext = React.createContext();


function App() {
    const [state, dispatch] = useReducer(rootReducer, initialState);


    useEffect(() => {
        async function intializeSession() {
            const session = await initSession();
            setSession(dispatch, session)
        }

        intializeSession();
    }, []);


    const handleAuthenticated = (user) => {
        const updatedSession = updateSession(state.session, user);
        setSession(dispatch, updatedSession);
    };

    return (
        <DispatchContext.Provider value={dispatch}>
            <StateContext.Provider value={state}>
                <Router>
                    <Switch>
                        <Route path={routes.LOGIN}>
                            <Login onAuthenticated={handleAuthenticated}/>
                        </Route>
                        <PrivateRoute exact path={routes.SETTINGS_GENERAL}>
                            <General/>
                        </PrivateRoute>
                        <PrivateRoute path={`${routes.SETTINGS_DEALERSHIPS}`}>
                            <Dealerships/>
                        </PrivateRoute>
                        <PrivateRoute path={`${routes.SETTINGS_DEALERSHIP}/:id`}>
                            <Dealership/>
                        </PrivateRoute>
                        <PrivateRoute exact path={routes.HOME}>
                            <Builder/>
                        </PrivateRoute>
                        <Route path="*">
                            <Page404/>
                        </Route>
                    </Switch>
                </Router>
            </StateContext.Provider>
        </DispatchContext.Provider>
    )
}

export default App;
