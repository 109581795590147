import React from 'react';
import {BasicContactForm} from "../../../../core";
import {preview_context} from "../../../../core/constants";

const ContactFormTwoCol_Preview = () => {
    return (
        <div className="flex items-center justify-center w-full pt-10 bg-gray-700 relative" style={{
            background: `url(${require('../../../../core/assets/previews/tires-in-snow-2.jpg')}) no-repeat center bottom`,
            backgroundSize: "cover"
        }}>
            <div id="contact-us" className="flex flex-col md:flex-row container">
                <div className="flex items-center justify-center md:w-6/12 ">


                </div>
                <div className="flex w-full flex-col md:w-6/12 p-5 mb-10 bg-white mr-5">
                    <div className="flex flex-1 flex-col">
                        <div className="mb-1 lg:mb-3 text-2xl md-text-4xl px-5 md:px-0">Lorem ipsum</div>
                        <div className="mb-8 lg:mb-8 px-5 md:px-0">Lorem Ipsum is simply dummy text of the printing and</div>

                    </div>

                    <BasicContactForm context={preview_context}
                                      handlePrivacy={() => {}}/>
                </div>
            </div>
        </div>
    );
};

export default ContactFormTwoCol_Preview;
