import { useState, useEffect } from 'react'

const useWindow = () => {
    const [hasWindow, setHasWindow]= useState(false)

    useEffect(() => {
        setHasWindow(() => true)
    }, [])

    return (hasWindow) ? window : undefined
}

export default useWindow
