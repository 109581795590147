import React from 'react';
import {Image} from "../../../../core";

const Member = ({member, context}) => {
    return (
        <div key={`${member.first_name}-${member.last_name}`} className="team-card flex flex-col shadow bg-white p-5">

            {context.builder || member.image &&
            <div className="flex w-full mb-5 justify-center">
                <Image url={member.image} style={{width: 200, height: 200, borderRadius: "50%"}}/>
            </div>}

            <div className="mt-2 text-center text-md text-gray-700">{member.first_name} {member.last_name}</div>
            <div className="text-center text-gray-700 text-sm">{member.title}</div>

            {member.mail && <a href={`mailto:${member.mail}`} className="flex flex-row items-center justify-center text-gray-700 text-md mt-4">
                <i className="fad fa-envelope mr-3"/> {member.mail}
            </a>}

            {member.mobile && <a href={`tel:${member.mobile}`} className="flex flex-row items-center justify-center  text-gray-700 text-md mt-2">
                <i className="fal fa-mobile-android mr-3"/> {member.mobile}
            </a>}

            {member.telephone && <a href={`tel:${member.telephone}`} className="flex flex-row items-center justify-center  text-gray-700 text-md mt-2">
                <i className="fal fa-phone-rotary mr-3"/> {member.telephone}
            </a>}

        </div>
    );
};

export default Member;
