import {v4} from "uuid";
import {includes} from "lodash";

const USER_QUERY = `query {
  user {
    id
    firstName
    lastName   
    email
    roles
  } 
}`;


export const session_default = {
    id: v4(),
    authenticated: false,
    user: {
        id: undefined,
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        roles: [],
    }
};

export async function initSession() {
    const user = await fetchUserInfo();

    if (user !== undefined) {
        return {
            ...session_default,
            authenticated: true,
            user: {
                id: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                roles: user.roles,
                isSuperUser: includes(user.roles, 'super_user')
            }
        }
    } else {
        return session_default;
    }
}

export const resetSessionToDefault = () => {
    return session_default
};

export const isAuthenticatedPending = (session) => {
    return session === undefined;
}

export const isAuthenticated = (session) => {
    return session && session.authenticated;
};

export const updateSession = (user, session) => {
    return {
        ...session,
        authenticated: true,
        user: {
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
        }
    };
}

async function fetchUserInfo() {
    return fetch('/graphql', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({query: USER_QUERY, variables: {}})
    })
        .then(resp => resp.json())
        .then((result) => {
            const {user} = result.data;
            if (user) {
                return {
                    id: user.id,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email,
                    roles: user.roles,
                }
            } else {
                return undefined;
            }
        })
        .catch(err => {
            return undefined;
        })
};

export async function fetchUserData() {
    return fetchUserInfo();
}

