import React, {useContext, useEffect, useState} from 'react';
import Fade from 'react-reveal/Fade';
import {getCookie, navigatePage} from '../../utils'
import Cookies from 'js-cookie'
import './CookieBanner.css';
import Switch from "react-switch";
import DataContext from "../../store/DataContext";


const GDPR_ANALYTICS = 'GDPR_ANALYTICS';

const CookieBanner = ({visible, location, context, renderCookieContent, renderSettingsContent}) => {
    const [isVisible, setVisible] = useState(visible);
    const [settingsVisible, setSettingsVisible] = useState(false);
    const cookieValue = getCookie(GDPR_ANALYTICS);
    const [analyticsEnabled, setAnalyticsEnabled] = useState((cookieValue) ? (cookieValue === "false") ? false : true : true);
    const {setCookiesAccepted} = useContext(DataContext);

    useEffect(() => {
        setVisible(visible);
    }, [visible]);


    const setAnalyticsCookie = (value) => {
        Cookies.set(GDPR_ANALYTICS, value);
    };

    const handleAccept = () => {
        setVisible(false);
        setAnalyticsCookie(true);
        setCookiesAccepted(true);
    };

    const handleSave = () => {
        setVisible(false);
        setAnalyticsCookie(analyticsEnabled);
        setCookiesAccepted(true);
    };

    const handlePrivacyClick = () => {
        navigatePage(context, context.urls.privacy)
    }

    const handleSettings = () => {
        setSettingsVisible(true)
    }

    return (
        <Fade bottom when={isVisible} duration={500} innerZ={14} collapse style={{marginTop: 120}}>

            <div className={`cookies-banner flex flex-col py-5 px-5 fixed left-0 right-0 banner-shadow bg-white ${location}-0`} style={{zIndex: 99999}}>

                {!settingsVisible &&
                <React.Fragment>
                {renderCookieContent && typeof renderCookieContent === "function"
                    ? renderCookieContent(handleAccept, handleSettings, handlePrivacyClick)
                    :
                    <div className="flex flex-1 flex-col md:flex-row ">
                        <div className="flex flex-1 w-full md:w-1/2 lg:w-3/5 items-center">
                            <div className="flex items-center justify-center mr-4 hidden md:block">
                                <i className="fad fa-cookie-bite text-4xl "/>
                            </div>

                            <div className="flex flex-col text-sm font-light cookies-text">
                                <div>Deze website maakt gebruik van cookies om uw ervaring te verbeteren.</div>
                                <div>Klik<span className="underline ml-1 mr-1 cursor-pointer outline-none" onClick={handlePrivacyClick}>hier</span>
                                    <span>om te zien hoe cookies werken en waarom we ze gebruiken.</span>
                                </div>

                            </div>

                        </div>
                        <div className="flex w-full md:w-1/2 lg:w-2/5 flex-col md:flex-row items-end justify-end justify-between mt-4 md:mt-0">
                            <button onClick={handleAccept}
                                    style={{minWidth: 125}}
                                    id="cookies-accept-button"
                                    className="flex w-full relative justify-center items-center h-10 bg-black text-white text-md font-medium py-2 px-4 rounded-none text-center hover:text-gray-300 black-and-white outline-none mb-2 md:mb-0 ">
                                <div className="flex items-center justify-center mr-2">Aanvaarden</div>
                            </button>

                            <button
                                className="flex w-full relative justify-center items-center h-10 bg-black text-white text-md font-medium py-2 px-4 rounded-none text-center hover:text-gray-300 black-and-white  outline-none md:ml-4"
                                style={{minWidth: 125}}
                                id="cookies-settings-button"
                                onClick={handleSettings}>
                                <div className="flex items-center justify-center mr-2">Instellingen</div>
                            </button>
                        </div>
                    </div>
                }
                </React.Fragment>}

                {settingsVisible &&
                <React.Fragment>
                    {renderSettingsContent && typeof renderSettingsContent === "function"
                        ? renderSettingsContent(analyticsEnabled, setAnalyticsEnabled, handleSave)
                        :
                        <div className="flex flex-1 flex-col md:flex-row ">
                            <div className="flex flex-col flex-1 w-full md-4/5">
                                <div className="flex w-4/5 mb-2">
                                    <div className="mr-2">
                                        <Switch disabled={true}
                                                checked={true}
                                                width={40}
                                                height={20}
                                        />
                                    </div>
                                    <div className="cookies-text">
                                        <div className="font-bold text-sm">Noodzakelijke cookies</div>
                                        <div className="text-xs">
                                            Sommige cookies zijn vereist voor basis functionaliteiten. De website zal niet correct werken zonder, en dus zijn deze standaard actief en kunnen niet afgezet
                                            worden.

                                        </div>
                                    </div>
                                </div>

                                <div className="flex w-4/5">
                                    <div className="mr-2">
                                        <Switch onChange={(checked) => setAnalyticsEnabled(checked)}
                                                checked={analyticsEnabled}
                                                width={40}
                                                height={20}
                                        />
                                    </div>
                                    <div className="cookies-text">
                                        <div className="font-bold text-sm">Analytische cookies</div>
                                        <div className="text-xs">
                                            Analytische cookies helpen ons deze website te verbeteren door het verzamelen van informatie en gedrag.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex w-full md:w-1/5 flex-col md:flex-row items-center justify-center mt-2 md:mt-0">
                                <button onClick={handleSave}
                                        style={{minWidth: 125}}
                                        id="cookies-save-button"
                                        className="flex w-full relative justify-center items-center h-10 bg-black text-white text-md font-medium py-2 px-4 rounded-none text-center hover:text-gray-300 black-and-white outline-none mb-2 md:mb-0 ">
                                    <div className="flex items-center justify-center mr-2">Opslaan</div>
                                </button>


                            </div>
                        </div>
                    }
                </React.Fragment>}


            </div>
        </Fade>
    )
};



export default CookieBanner;
