import React from 'react';
import {DefaultImage} from "../../../core/constants";
import {Image} from "../../../core";
import BannerImage_FullWidth_Preview from "./components/BannerImage_FullWidth_Preview";

const BannerImage_FullWidth = ({url}) => {
    return (
        <Image url={url} style={{width: "100%", minHeight: 100}}/>
    )
};

BannerImage_FullWidth.defaultProps = {
    url: DefaultImage,
};

BannerImage_FullWidth.human = {
    "nl": "Banner met afbeelding over het volledige scherm ",
    "fr": "",
    "en": "",
};

BannerImage_FullWidth.preview = () => { return <BannerImage_FullWidth_Preview /> };

export default BannerImage_FullWidth;
