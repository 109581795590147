import React, {useRef, useState} from 'react';
import {LoadingIndicator, ErrorIndicator, NoResultsIndicator} from "../../indicators";
import Slider from "react-slick"
import {take} from 'lodash';
import VehicleCard from "../VehicleCard";
import AdvertiseCard from "../AdvertiseCard";
import {useVehiclesSliderData} from "../../../hooks/useVehiclesSliderData";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import './VehiclesSlider.css';
import {useTranslation} from "react-i18next";

const defaultResponsiveSettings = [
    {
        breakpoint: 2800,
        settings: {
            slidesToShow: 7,

        }
    },
    {
        breakpoint: 2300,
        settings: {
            slidesToShow: 6,

        }
    },
    {
        breakpoint: 1990,
        settings: {
            slidesToShow: 5,

        }
    },
    {
        breakpoint: 1700,
        settings: {
            slidesToShow: 4,

        }
    },
    {
        breakpoint: 1370,
        settings: {
            slidesToShow: 3,

        }
    },
    {
        breakpoint: 1000,
        settings: {
            slidesToShow: 2,

        }
    },
    {
        breakpoint: 850,
        settings: {
            slidesToShow: 2,

        }
    },
    {
        breakpoint: 695,
        settings: {
            slidesToShow: 1,

        }
    }
]

const VehiclesSlider = ({sliderRef, vehicle_count, custom_card_image, custom_card_action, context, filter, renderVehicle, onVehicleClick, renderNext, renderPrev, sliderResponsiveSettings, className='bg-white', loadingColor=null, style}) => {
    const [customCardHeight, setCustomCardHeight] = useState(null);
    const culture = (context && context.culture) ? context.culture : 'nl';
    const {vehicles, loading, error} = useVehiclesSliderData(culture, filter);
    const { width } = useWindowDimensions();
    const {t} = useTranslation();
    const vehicleCard = useRef(null);
    const PrevArrow = ({className, style, onClick}) => {
        if (renderPrev) {
            return renderPrev(onClick)
        }

        return (
            <div className=" flex items-center justify-center absolute cursor-pointer text-white z-10 rounded-full"
                 style={{top: "50%", left: 5, transform: "translateY(-50%)"}}
                 onClick={onClick}>
                <i className="fad fa-chevron-left text-3xl text-primary"/>
            </div>
        )
    };

    const NextArrow = ({className, style, onClick}) => {
        if (renderNext) {
            return renderNext(onClick)
        }

        return (
            <div className=" flex items-center justify-center absolute cursor-pointer text-white rounded-full"
                 style={{top: "50%", right: 5, transform: "translateY(-50%)"}}
                 onClick={onClick}><i className="fad fa-chevron-right text-3xl  text-primary"/></div>

        )
    };

    const builderSettings = [
        {
            breakpoint: 2800,
            settings: {
                slidesToShow: 3,

            }
        },
        {
            breakpoint: 1700,
            settings: {
                slidesToShow: 2,

            }
        },
        {
            breakpoint: 1400,
            settings: {
                slidesToShow: 1,

            }
        }
    ]

    let responsiveSettings = defaultResponsiveSettings;
    if(sliderResponsiveSettings) responsiveSettings = sliderResponsiveSettings;
    if(context.builder) responsiveSettings = builderSettings;

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: vehicle_count,
        slidesToScroll: 1,
        nextArrow: <NextArrow/>,
        prevArrow: <PrevArrow/>,
        responsive: responsiveSettings
    };

    const onImageLoad = () => {
        if (vehicleCard.current && vehicleCard.current.offsetHeight) {
            setCustomCardHeight(vehicleCard.current.offsetHeight);
        }
    };

    const handleVehicleClick = (vehicle) => {
        onVehicleClick(vehicle);
    };

    const renderSlider = (cards) => {
        return <div>
            <Slider ref={sliderRef} {...settings}>
                {
                    take(cards, vehicle_count).map((vehicle, i) => {
                        if (vehicle.custom !== undefined) {
                            return (
                                <AdvertiseCard key={i}
                                               action={custom_card_action}
                                               classNames={`flex vehicle-card shadow overflow-hidden hover:opacity relative cursor-pointer`}
                                               styles={{marginBottom: "2%", width: 330, height: customCardHeight}}
                                               img_src={custom_card_image}
                                               context={context}/>
                            )
                        } else {
                            return (
                                <div key={vehicle.reference} ref={vehicleCard}>
                                    {(renderVehicle) ? renderVehicle(vehicle, onVehicleClick) :
                                        <VehicleCard key={vehicle.reference}
                                                     vehicle={vehicle}
                                                     context={context}
                                                     styles={{width: 330}}
                                                     onClick={() => handleVehicleClick(vehicle)}
                                        />}
                                </div>)
                        }

                    })
                }
            </Slider>
        </div>
    };

    const renderWithoutSlider = (vehicles) => {
        return <div className="flex items-center justify-start">
            {vehicles.map((vehicle, i) => {
                const cardClass = vehicles.length === i ? 'mr-0' : 'mr-5';

                if (vehicle.custom !== undefined) {
                    return (
                        <AdvertiseCard key={i}
                                       action={custom_card_action}
                                       classNames={`flex vehicle-card shadow overflow-hidden hover:opacity relative cursor-pointer`}
                                       styles={{marginBottom: "2%", width: 330, height: customCardHeight}}
                                       img_src={custom_card_image}
                                       context={context}/>
                    )
                } else {
                    return (
                        (renderVehicle) ? <div style={{width: 342}}>{renderVehicle(vehicle, (vehicle) =>  handleVehicleClick(vehicle))}</div> :
                            <VehicleCard key={vehicle.reference}
                                         vehicle={vehicle}
                                         context={context}
                                         cardClass={cardClass}
                                         styles={{width: 330}}
                                         onClick={() => handleVehicleClick(vehicle)}
                            />

                    )
                }
            })}
        </div>
    }

    const renderContent = () => {
        let vehiclesToRender = [...vehicles];
        if (custom_card_image !== undefined) {
            const random = Math.floor(Math.random() * vehiclesToRender.length) + 5;
            vehiclesToRender.splice(random, 0, {custom: true});
        }

        if (vehiclesToRender.length < 6) {
            return renderWithoutSlider(vehiclesToRender)
        } else {
            return renderSlider(vehiclesToRender);
        }


    };

    return (
        // <div className="flex flex-col py-10 md:py-16 w-full bg-white z-40 relative">
        <div className={`flex flex-col w-full relative ${className}`}>
            <div className="relative">
                <div className="flex flex-col w-full">
                    <div className="" style={{maxWidth: "100%", textAlign: "-webkit-center", width: context.builder ? width - 720 : "100%", ...style}}>
                        {loading && <LoadingIndicator color={loadingColor}/>}
                        {!loading && error !== null && <ErrorIndicator error={error}/>}
                        {!loading && error === null && vehicles.length < 1 && <NoResultsIndicator message={t('No vehicles to show')}/>}
                        {!loading && error === null && vehicles.length > 0 && renderContent()}
                    </div>
                </div>
            </div>
        </div>
    );
};

VehiclesSlider.defaultProps = {
    style: {minHeight: 500},
    className: 'z-20'
}

export default VehiclesSlider;
