import React from 'react';
import {MarkupText} from "../../../core";
import {DefaultText} from "../../../core/constants";


const Privacy = ({text, context}) => {
    return (
        <div>
            <div className={`flex items-center justify-center py-5 md:py-20`}>
                <div className="container relative z-10">
                    <div className="flex items-center justify-center">
                        <MarkupText text={text} context={context}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

Privacy.defaultProps = {
    text: DefaultText,
};

Privacy.human = {
    "nl": "",
    "fr": "",
    "en": "",
};

Privacy.preview = () => {return (
    <div>
        <div className={`flex items-center justify-center py-5 md:py-20`}>
            <div className="container relative z-10">
                <div className="flex items-center justify-center">
                    Privacybeleid
                </div>
            </div>
        </div>
    </div>
)} ;

export default Privacy;
