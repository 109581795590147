import React, {useEffect, useState} from 'react';
import Header from "../Header";
import Menu from "./components/Menu";
import Detail from "./dealership/Detail";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {routes} from "../../data/constants";
import {useManualQuery, useMutation} from "graphql-hooks";
import {DealershipQuery, DealershipUpdateMutation} from "../../data/graphql";
import LoadingIndicator from "../common/LoadingIndicator";
import ErrorIndicator from "../common/ErrorIndicator";
import {isEmpty, isEqual, omit} from "lodash";
import cogoToast from "cogo-toast";
import HeaderMenu from "./components/HeaderMenu";

const Dealership = () => {
    const [initDealership, setInitDealership] = useState({});
    const [updatedDealership, setUpdatedDealership] = useState({})
    const [showConfirmBox, setShowConfirmBox] = useState(false);
    let location = useLocation();
    let history = useHistory();
    const {id} = useParams();
    const [fetchDealership, {loading: fetching, error: errorFetchingDealership}] = useManualQuery(DealershipQuery, {variables: {id}});
    const [updateMutation, {loading: updating}] = useMutation(DealershipUpdateMutation);


    useEffect(() => {

        if (location.state) {
            if (location.state.dealership) {
                setInitDealership(location.state.dealership);
            }
        } else {
            const getDealership = async () => {
                const res = await fetchDealership();
                setInitDealership(res.data.dealership);
            }
            getDealership();
        }
        history.replace(); // Clear location state. If
    }, [])

    const onChange = (newDealership) => {
        setUpdatedDealership(newDealership)
    }

    const handleGeneralSettingsSave = () => {
        updateMutation({
            variables: {
                data: updatedDealership
            }
        }).then(res => {
            const {ok, dealership} = res.data.dealershipUpdate;
            if (ok) {
                cogoToast.success('Updated!');
                setInitDealership(dealership);
            }
        }).catch(error => {
            cogoToast.error('Error updating!');
        });
    };

    const hasChanges = () => {
        return !isEqual(initDealership, updatedDealership)
    }

    const handleBack = () => {
        if (hasChanges()) {
            setShowConfirmBox(true);
        } else {
            history.push(routes.SETTINGS_DEALERSHIPS)
        }
    };

    const handleCancel = () => {
        history.push(routes.SETTINGS_DEALERSHIPS)
    };

    const handleBackConfirm = () => {
        history.push(routes.SETTINGS_DEALERSHIPS)
    }

    const handleBackCancel = () => {
        setShowConfirmBox(false);
    }

    const dealershipName = !isEmpty(updatedDealership) ? updatedDealership.name : "Dealership"
    return (
        <div className="builder-flex builder-flex-1 builder-flex-col builder-h-screen builder-font-body builder-text-sm">
            <Header renderMenu={() => <HeaderMenu/>}/>

            <Menu title={dealershipName} onSave={handleGeneralSettingsSave} onBack={handleBack} onCancel={handleCancel} loading={updating} saveDisabled={!hasChanges()}/>

            <div className="builder-flex builder-items-center builder-justify-center builder-bg-white">
                <div className="builder-container">
                    {fetching && !errorFetchingDealership && <LoadingIndicator/>}
                    {!fetching && errorFetchingDealership && <ErrorIndicator error="The dealership could not be fetched."/>}
                    {!fetching && !errorFetchingDealership && <Detail initDealership={initDealership}
                                                                      showConfirmBox={showConfirmBox}
                                                                      onBackConfirm={handleBackConfirm}
                                                                      onBackCancel={handleBackCancel}
                                                                      handleChange={(newDealership) => onChange(newDealership)}/>}
                </div>
            </div>

        </div>
    );
};

export default Dealership;
