import {useState, useEffect, useContext} from 'react';
import {map, orderBy} from 'lodash';
import fetch from 'cross-fetch'
import DataContext from "../../../../store/DataContext";

const SEGMENTS_QUERY = `query Segments {
  segments {      
     key     
     priority
     name
  }
}`;

export function useSegmentsData(context) {
    const contextData = useContext(DataContext);
    const [loading, setLoading] = useState(context.vehicleSegmentation && contextData.segments === undefined);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (context.vehicleSegmentation && contextData.segments === undefined) {
            fetch('/graphql', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({query: SEGMENTS_QUERY})
            })
                .then(resp => resp.json())
                .then((result) => {
                    const {segments} = result.data;
                      contextData.setSegments(segments)

                    setLoading(false);
                })
                .catch(err => {
                    setError("ERROR");
                    setLoading(false);
                })
        }

    }, []);

    return {
        segments: (contextData.segments) ? contextData.segments : [],
        segmentKeys: (contextData.segments) ? map(contextData.segments, s => s.key) : [],
        loading: loading,
        error
    };
}
