import React from "react"

const SuccessIndicator = ({success, icon}) => {
    return (
        <div className="bg-green-200 flex items-center rounded">
            <div className="flex h-full">
                <div className="w-16 bg-green-300 flex items-center justify-center rounded-l" style={{minWidth: "4rem"}}>
                    <i className={`${icon} text-2xl text-green-900`} />
                </div>
                <div className="w-auto text-black opacity-75 items-center px-4 py-4 flex items-center">
                    <div className="leading-tight list-none">{success}</div>
                </div>
            </div>
        </div>
    )
};

SuccessIndicator.defaultProps = {
    success: "Success!",
    icon: "fad fa-check"
};

export default SuccessIndicator
