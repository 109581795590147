import React, {useEffect, useState} from 'react';
import InputField from "../../input/Input";
import ButtonWithIcon from "../../input/ButtonWithIcon";
import {ErrorIndicator, SuccessIndicator} from "../../indicators";
import {hasValue, isValidEmail, getWindowPathLocation, scrollTo} from "../../../utils";
import TextArea from "../../input/TextAreaField";
import {useDealerContact} from "../../../hooks/useDealerContact";
import {keys} from "lodash";


const MoreInformationFormFields = {
    firstName: 'firstName',
    lastName: 'lastName',
    email: 'email',
    phone: 'phone',
    message: 'message',
    privacy: 'privacy',
    origin: 'origin',
};

const initialData = {
    [MoreInformationFormFields.firstName]: "",
    [MoreInformationFormFields.lastName]: "",
    [MoreInformationFormFields.email]: "",
    [MoreInformationFormFields.phone]: "",
    [MoreInformationFormFields.message]: "",
    [MoreInformationFormFields.privacy]: false,
    [MoreInformationFormFields.origin]: ""
};

const MoreInformation = ({onClose, vehicle, context, handlePrivacy}) => {
    const [data, setData] = useState(initialData);
    const {submit, loading, dealerContactTypes} = useDealerContact();
    const [success, setSuccess] = useState(false);
    const [errors, setErrors] = useState({});

    const validate = () => {
        let errors = {};

        if (!hasValue(data.firstName)) {
            errors[MoreInformationFormFields.firstName] = "Vul aub uw naam in.";
        }

        if (!hasValue(data.lastName)) {
            errors[MoreInformationFormFields.lastName] = "Vul aub uw familienaam in.";
        }

        if (!hasValue(data.email)) {
            errors[MoreInformationFormFields.email] = "Ongeldig e-mailadres";
        } else if (!isValidEmail(data.email)) {
            errors[MoreInformationFormFields.email] = "Ongeldig e-mailadres";
        }

        if (!hasValue(data.phone)) {
            errors[MoreInformationFormFields.phone] = "Vul aub uw telefoonnummer in.";
        }

        if (!hasValue(data.message)) {
            errors[MoreInformationFormFields.message] = "Vul aub uw bericht in.";
        }

        if (data.privacy === false) {
            errors[MoreInformationFormFields.privacy] = "";
        }

        setErrors(errors);

        return keys(errors).length <= 0;
    };

    useEffect(() => {
        const moreInfoForm = document.getElementById("more-info-form");
        if(moreInfoForm){
            scrollTo(moreInfoForm)
        }

        if(success){
            setTimeout(() => {
                onClose();
            }, 2000)
        }


    }, [success, errors])

    const setValue = (field, value) => {
        setData({
            ...data,
            [field]: value
        });
    };

    const hasError = (field) => {
        return (errors && errors[field] !== undefined)
    };

    const onSubmit = e => {
        e.preventDefault();

        setSuccess(false);

        if (validate()) {
            setSuccess(false);

            const jsonData = JSON.stringify({
                first_name: data.firstName,
                last_name: data.lastName,
                email: data.email,
                phone: data.phone,
                message: data.message,
                privacy: data.privacy,
                //form_type: dealerContactTypes.vehicleInformation,
                vehicle: {
                    id: vehicle.id,
                    reference: vehicle.reference,
                    identification: vehicle.identification,
                },
                origin: getWindowPathLocation(context),
            });

            submit(dealerContactTypes.vehicleInformation, jsonData)
                .then(result => {
                    if (result) {
                        setSuccess(true);
                        setErrors({});
                        setData(initialData);
                    } else {
                        setErrors({
                            result: 'Probleem met het verzenden van uw aanvraag.'
                        })
                    }
                })
        }
    };

    return (
        <form id="more-info-form" action="" onSubmit={e => onSubmit(e)} className="bg-gray-100 p-3 relative w-full">
            <div className="flex flex-col mb-10">
                <div className="flex justify-between items-center w-full text-gray-800 mb-2">
                    <div className="text-lg text-gray-800 text-1xl ">Meer info aanvraag</div>
                    <i className="hover:spin-animation fad fa-times text-xl cursor-pointer" onClick={onClose}/>
                </div>
                <div className="w-full text-md text-gray-600 ">Wenst u meer info over dit voertuig? Aarzel niet om ons te contacteren</div>
            </div>

            {errors && errors['result'] &&
            <div className="mb-4 px-5 md:px-0" style={{minHeight: 32}}>
                <ErrorIndicator error={errors["result"]}/>
            </div>}


            {success && <div className="fadeOutAfter5Seconds px-5 md:px-0 absolute" style={{top: 40, right: "0.75rem", left: "0.75rem"}}><SuccessIndicator success="Uw aanvraag is verzonden!"/></div>}

            <div className="flex flex-col justify-between">
                <div className="flex flex-col">
                    <div className="flex flex-col md:flex-row mb-5">
                        <InputField containerClass="w-full md:w-1/2 mb-5 md:mb-0 mr-0 md:mr-1"
                                    label="Voornaam"
                                    placeholder="John"
                                    value={data.firstName}
                                    onChange={(value) => setValue(MoreInformationFormFields.firstName, value)}
                                    errorText={errors[MoreInformationFormFields.firstName]}
                                    required={true}/>

                        <InputField containerClass="w-full md:w-1/2 md:mb-0 ml-0 md:ml-1"
                                    label="Naam"
                                    placeholder="Doe"
                                    value={data.lastName}
                                    onChange={(value) => setValue(MoreInformationFormFields.lastName, value)}
                                    errorText={errors[MoreInformationFormFields.lastName]}
                                    required={true}/>
                    </div>

                    <div className="flex flex-col md:flex-row mb-5">
                        <InputField containerClass="w-full md:w-1/2 mb-5 md:mb-0 mr-0 md:mr-1"
                                    label="Email"
                                    placeholder="john.doe@gmail.com"
                                    value={data.email}
                                    onChange={(value) => setValue(MoreInformationFormFields.email, value)}
                                    errorText={errors[MoreInformationFormFields.email]}
                                    required={true}/>

                        <InputField containerClass="w-full md:w-1/2 md:mb-0 ml-0 md:ml-1"
                                    label="Telefoonnummer"
                                    placeholder="0486 12 34 56"
                                    value={data.phone}
                                    onChange={(value) => setValue(MoreInformationFormFields.phone, value)}
                                    errorText={errors[MoreInformationFormFields.phone]}
                                    required={true}/>
                    </div>

                    <div className="mb-5">
                        <TextArea containerClass="md:w-full md:mb-0"
                                  label="Uw vraag"
                                  placeholder="Ik ben geïnteresseerd in de aankoop van deze auto en wil graag weten of deze nog beschikbaar is?"
                                  value={data.message}
                                  heightClass="h-24"
                                  onChange={value => setValue(MoreInformationFormFields.message, value)}/>
                    </div>
                </div>


                <div className="flex mb-3">
                    <div className={`text-primary flex  ${(hasError('privacy') ? "text-red-400" : "")}`}>
                        <input type="checkbox" checked={data.privacy} onChange={e => setValue(MoreInformationFormFields.privacy, e.target.checked)}/>
                        <span className={`mr-1 ml-2 ${(hasError('privacy') ? "text-red-400" : "")}`}>Ik ga akkoord met het </span>
                        <span className={`underline cursor-pointer ${(hasError('privacy') ? "text-red-400" : "")}`} onClick={() => (handlePrivacy) ? handlePrivacy() : null}>privacybeleid</span>.
                    </div>
                </div>


                <div className="flex justify-end">
                    <ButtonWithIcon disabled={loading}
                                    onClick={onSubmit}
                                    loading={loading}
                                    loadingText="Aan het sturen..."
                                    text="Bevestig"
                                    icon="fad fa-paper-plane"
                                    iconPosition="right"
                    />
                </div>
            </div>
        </form>
    );
};

MoreInformation.defaultProps = {

};

export default MoreInformation;
