import { useState } from 'react';
import fetch from 'cross-fetch'
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";


export const VehicleTakeOverMutation = `mutation VehicleTakeOver ($data: String!) {
  vehicleTakeOver (data: $data) {
        ok
        error
  }
}`;

export function useVehicleTakeOver() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { executeRecaptcha } = useGoogleReCaptcha();

    const submit = async (data) => {
        if (!executeRecaptcha) {
            return;
        }
        setLoading(true);

        const token = await executeRecaptcha('vehicle_take_over');

        const tmpData = JSON.parse(data);
        if(!tmpData.form_type) {
            tmpData.form_type = 'vehicle_take_over'
        }
        const parsedData = JSON.stringify(tmpData);

        return fetch('/graphql', {
            method: 'POST',
            headers: {'Content-Type': 'application/json', "Captcha" : token},
            body: JSON.stringify({query: VehicleTakeOverMutation, variables: {data: parsedData}})
        })
            .then(resp => resp.json())
            .then((result) => {
                const {vehicleTakeOver} = result.data;
                setLoading(false);
                if(vehicleTakeOver.ok) {
                    return true;
                } else {
                    setError(vehicleTakeOver.error)
                    return false;
                }

            })
            .catch(err => {
                setError("ERROR");
                setLoading(false);
                return false;
            })
    };

    return {
        submit,
        loading,
        error
    };
}
