import React from 'react';
import DatePicker from "react-date-picker";

const DatePickerField = ({containerClass, label, onChange, value, calendarIcon, clearIcon, inputClass, errorText, noPast, noFuture, required}) => {
    const hasError = errorText !== undefined;

    return (
        <div className={containerClass}>
            {label && <label className={`block tracking-wide ${hasError ? "text-red-400" : "text-gray-700"} text-sm font-bold mb-2`}> {label} {required ? "(*)" : ""} </label>}

            <div className="relative ">
                <DatePicker
                    onChange={date => onChange(date)}
                    value={value}
                    calendarClassName="max-w-full"
                    className={`block appearance-none w-full bg-white border border-gray-400 text-gray-700 ${inputClass} ${hasError ? "bg-red-100 border-red-400" : "bg-white border-gray-400"} pl-4 rounded-none leading-tight focus:outline-none focus:bg-white focus:border-primary`}
                    calendarIcon={<i className={calendarIcon}/>}
                    clearIcon={clearIcon}
                    format="dd/MM/y"
                    minDate={noPast ? new Date() : ''}
                    maxDate={noFuture ? new Date() : ''}
                />
            </div>
            {hasError && <div className="text-red-400 text-xs mt-1">{errorText}</div>}
        </div>
    );
};

DatePickerField.defaultProps = {
    calendarIcon: "fad fa-chevron-down",
    clearIcon: null,
    inputClass: "py-3",
    noPast: false,
    noFuture: false,
    required: false
};

export default DatePickerField;
