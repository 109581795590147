import React from 'react';
import {Image} from "../../../../core";
import {renderFooterReviews} from "../FooterTwoCol";

const FooterTwoCom_Preview = () => {
    return (
        <div id="footer-main" className="bg-primary items-center justify-center flex flex-col py-5 px-5 z-50 relative">
            <div className="flex flex-col md:flex-row container">
                <div className="w-full md:w-1/2 border-r-0 md:border-r border-gray-100 pr-8">
                    <div className="flex mb-8 justify-center md:justify-start cursor-pointer">
                        <Image url={require('../../../../core/assets/previews/preview-logo.png')} style={{height: 60}} itemProp="logo"/>
                    </div>

                    <div className="footer-description">
                        <div className="mb-5 text-2xl text-white">Lorem Ipsum is simply dummy text</div>
                        <div className="text-xs mb-10 text-white">
                            It has survived not only five centuries, but also the leap into <br /> electronic typesetting
                        </div>
                        <div className="flex justify-center md:justify-start">
                            <div className="flex flex-row bg-primary  text-white hover:text-gray-300 rounded-none text-center text-sm font-bold border-2 border-gray-800 cursor-pointer outline-none">
                                <div className="flex flex-row justify-between w-full">
                                    <div className='py-3 px-4'>Button</div>
                                    <div className="p-3 icon-wrapper bg-gray-800">
                                        <div className="w-4 h-4">
                                            <i className='fad fa-arrow-right'/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col w-full md:w-1/2 pl-8 pt-24 md:pt-12 text-white justify-between">
                    <div className="flex">
                        <div className="mr-2" style={{minWidth: 130}}>
                            <h4 className="flex mb-2 hover:underline cursor-pointer" itemProp="brand"><a href="#" target="_self">Audi</a></h4>
                            <h4 className="flex mb-2 hover:underline cursor-pointer" itemProp="brand"><a href="#" target="_self">Volkswagen</a></h4>
                            <h4 className="flex mb-2 hover:underline cursor-pointer" itemProp="brand"><a href="#" target="_self">Skoda</a></h4>
                            <h4 className="flex mb-2 hover:underline cursor-pointer" itemProp="brand"><a href="#" target="_self">Seat</a></h4>
                        </div>
                        <div className="">
                            <div className="flex mb-2 hover:underline cursor-pointer"><a href="#" target="_self">Link1</a></div>
                            <div className="flex mb-2 hover:underline cursor-pointer"><a href="#" target="_self">Link2</a></div>
                            <div className="flex mb-2 hover:underline cursor-pointer"><a href="#" target="_self">Link3</a></div>
                            <div className="flex mb-2 hover:underline cursor-pointer"><a href="#" target="_self">Link4</a></div>
                        </div>
                    </div>

                    {renderFooterReviews()}
                </div>
            </div>

        </div>
    );
};

export default FooterTwoCom_Preview;
