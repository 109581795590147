import React, {useContext} from 'react';
import Fade from "react-reveal/Fade";
import Sort from "./filter/Sort";
import TopBar from "./filter/TopBar";
import DataContext from "../../../store/DataContext";

const ListFilterTopBar = ({filters, sorter, filteredVehicles, onRemoveFilter, onClearFilters, onToggleSmallFilters}) => {
    const contextData = useContext(DataContext);

    return (
        <div className="flex flex-col top-container top-0">
            <div className="pt-0 md:pt-5 bg-white">
                <div className="filters-bar flex flex-col border border-gray-100 ">
                    <div className="flex flex-row text-gray-600 items-center justify-between  bg-gray-100 p-2">
                        <div className="flex flex-row">

                            <div>{filteredVehicles.length === 1 ? `${filteredVehicles.length} wagen` : `${filteredVehicles.length} wagens`}</div>

                            {filters.length > 0 &&
                            <Fade duration={200}>
                                <div className="hover:spin-animation ml-5 border-l border-gray-400 pl-5 cursor-pointer text-primary hover:underline"
                                     onClick={onClearFilters}><i className="fad fa-times mr-2"/>Filters verwijderen
                                </div>
                            </Fade>}

                        </div>
                        <div className="flex flex-row items-center">
                            <Sort onChange={(sort) => contextData.setSorter(sort)}/>
                            <div className="ml-5 block md:hidden" onClick={onToggleSmallFilters}><i className="far fa-filter hover:text-primary"/></div>
                        </div>
                    </div>

                    {(filters.length > 0 || sorter) &&
                    <div className="flex flex-row text-gray-600 items-center justify-between pl-2 mt-2 mb-2">
                        <div className="flex flex-row items-center w-full" style={{height: 36, maxWidth: "100%"}}>
                            <TopBar
                                filters={filters}
                                sorter={sorter}
                                onRemove={onRemoveFilter}
                                onRemoveSort={() => contextData.setSorter(undefined)}
                            />
                        </div>
                    </div>}
                </div>
            </div>
            <div id="divider" className="hidden md:block bg-white border-b border-transparent w-full" style={{height: 20}}/>
        </div>
    );
};

export default ListFilterTopBar;
