import React from 'react';
import {DefaultImage, DefaultText,  DefaultTitle} from "../../../core/constants";

const ContactFormTwoCol = ({title, text, image, expert_name, expert_title, context}) => {
    return <div>not implemented</div>
};

ContactFormTwoCol.defaultProps = {
    title: DefaultTitle,
    text: DefaultText,
    image: DefaultImage,
    expert_name: "",
    expert_title: "",
};

ContactFormTwoCol.human = {
    "nl": "",
    "fr": "",
    "en": "",
};

ContactFormTwoCol.preview = ContactFormTwoCol;

export default ContactFormTwoCol;
