import React, {useState} from 'react'
import BlockComponent from "./BlockComponent";
import {findIndex} from "lodash";
import {getComponentsForContentType} from "../../../utils";


const BlockContainer = ({block, context, onEditContent, onDelete, onMoveUp, onMoveDown, onChangeBlockVariant}) => {
    const [hovered, setHovered] = useState(false);
    const contentTypeComponents = getComponentsForContentType( block.contentType);

    const handleChangeComponentVariant = () => {
        const currentIndex = findIndex(contentTypeComponents, (o) => o.name ===  block.componentName);
        const nextIndex = ((currentIndex + 1) < contentTypeComponents.length) ? currentIndex + 1 : 0;
        const variant = contentTypeComponents[nextIndex];
        onChangeBlockVariant(block.id, variant.name);
    };

    return (

        <div className="builder-relative"
              onMouseEnter={() => setHovered(true)}
             onMouseLeave={() => setHovered(false)}
            >
            {hovered &&
            <div className="builder-absolute builder-border builder-border-blue-600 builder-cursor-move builder-top-0 builder-left-0 builder-right-0 builder-bottom-0" style={{zIndex: 110}}>
                <div className="builder-flex builder-flex-row builder-m-2 builder-justify-between builder-text-xs">
                    <div className="builder-flex builder-flex-row builder-text-xs">

                        {contentTypeComponents.length > 1 &&
                        <div className="builder-bg-primary builder-mr-2 builder-p-2 builder-cursor-pointer builder-text-white builder-rounded builder-text-center" style={{minWidth: 40}}
                             onClick={handleChangeComponentVariant}>
                            <i className="fal fa-arrow-left"/>
                        </div>}

                        {contentTypeComponents.length > 1 &&
                        <div className="builder-bg-primary builder-p-2 builder-cursor-pointer builder-text-white builder-rounded builder-text-center" style={{minWidth: 40}}
                             onClick={handleChangeComponentVariant}>
                            <i className="fal fa-arrow-right"/>
                        </div>}

                    </div>
                    <div className="builder-flex builder-flex-row builder-justify-end builder-text-xs">
                        <div className="builder-bg-primary builder-mr-2 builder-p-2 builder-cursor-pointer builder-text-white builder-rounded builder-flex"
                             onClick={() => onEditContent(block.id)}>
                            <div onClick={() => onMoveUp(block.id)} className="builder-flex builder-flex-1 builder-items-center builder-justify-center builder-text-xs" style={{width: 20}}>
                                <i className="fal fa-arrow-up mr-2"></i>
                            </div>
                            <div onClick={() => onMoveDown(block.id)} className="builder-flex builder-flex-1 builder-items-center builder-justify-center builder-text-xs" style={{width: 20}}>
                                <i className="fal fa-arrow-down"></i>
                            </div>
                        </div>


                        <div className="builder-bg-primary builder-mr-2 builder-p-2 builder-cursor-pointer builder-text-white builder-rounded"
                             onClick={() => onEditContent(block.id)}>
                            <i className="fal fa-font-case"/> <span className="font-medium text-xs ml-1">Content</span>
                        </div>

                        <div className="builder-bg-primary builder-p-2 builder-cursor-pointer builder-text-white builder-rounded"
                             onClick={() => onDelete(block.id)}>
                            <i className="fas fa-trash-alt"/>
                        </div>
                    </div>
                </div>




            </div>}


            <BlockComponent block={block} context={context}/>


        </div>
    )
};

export default BlockContainer;
