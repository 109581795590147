import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './css/app.css';
import "react-toggle/style.css"
import {resetSessionToDefault} from "./data/session";
import {GraphQLClient, ClientContext} from 'graphql-hooks'

const client = new GraphQLClient({
    url: '/graphql',
    credentials: 'same-origin',
    headers: {'Accept-Language': 'nl'},
    onError: ({operation, result}) => {
        if (result.error && result.error.graphQLErrors) {
            const error = result.error.graphQLErrors[0];
            if (error.code === "403") {
                resetSessionToDefault();
                window.location.reload()
            }
        }
    }
});

ReactDOM.render(
    <ClientContext.Provider value={client}>
        <App/>
    </ClientContext.Provider>,
    document.getElementById('root')
);



