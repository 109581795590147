import {useMutation, useQuery} from "graphql-hooks";
import React from "react";
import ReactPlayer from 'react-player'
import {isImage, isVideo} from "../../../utils";

export const FilesQuery = `{
    files {
      uuid,   
      file
      url
    }
}`;

export const DeleteMutation =
    `mutation Delete($uuid: ID!) { 
        fileDelete(uuid: $uuid) { 
            ok          
        }
    }`;


const FilePicker = ({type, onSelect}) => {
    const {loading, data, refetch} = useQuery(FilesQuery);
    const [publishMutation] = useMutation(DeleteMutation);

    if (loading) {
        return <div>Loading...</div>
    }

    const handleSelect = (file) => {
        onSelect(file)
    };

    const handleDeleteFile = (file) => {
        publishMutation({
            variables: {
                uuid: file.uuid,
            }

        }).then(() => {
            refetch().then(() => {
            });
        })
    };

    const renderItem = (file) => {
        if (isImage(file.url)) {
            return (
                <img src={file.url} style={{width: 150, maxHeight: 180}} alt={file.uuid} onClick={() => handleSelect(file)}/>
            );
        } else if (isVideo(file.url)) {
            return (
                <div>
                    <ReactPlayer url={file.url} playing={true} width={150} height={180}/>
                </div>
            );
        }

    }


    const {files} = data;
    return (
        <div className="builder-flex builder-flex-wrap">
            {files.map((file) => {

                    const valid = (type === 'image') ? isImage(file.url) : isVideo(file.url);
                    if (!valid) {
                        return null;
                    }

                    return (
                        <div className="builder-m-2 builder-bg-gray-100" onClick={() => handleSelect(file)}>
                            <div className="builder-flex builder-justify-between p-2">
                                <div>{(isImage(file.url)) ? 'image' : 'video'}</div>
                                <div><i className="fas fa-trash-alt builder-text-red-500 builder-cursor-pointer" onClick={(e) => {
                                    e.stopPropagation()
                                    handleDeleteFile(file)
                                }}></i></div>
                            </div>
                            <div className="builder-flex builder-justify-center" style={{width: 150, maxHeight: 180}}>

                                {renderItem(file)}
                            </div>
                        </div>


                    )
                }
            )}
        </div>
    )
};

export default FilePicker;
