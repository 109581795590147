import React from 'react';
import {Image} from "../../../../core";

const BannerImage_FullWidth_Preview = () => {
    return (
        <Image url={require('../../../../core/assets/previews/tires-in-snow-2.jpg')} style={{width: "100%", minHeight: 100}}/>
    );
};

export default BannerImage_FullWidth_Preview;
