import React from 'react';
import {filter, head, map, isArray, orderBy, forEach, pull} from "lodash";
import {useTranslation} from "react-i18next";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import {useIsMobile} from "../../../../hooks/useIsMobile";




const ExtraCard = ({context, segment, availableVehicles, onClick, cardWidth}) => {
    const {t} = useTranslation()

    return <div className="flex relative rounded-10 bg-primary-light justify-center items-center cursor-pointer" style={{width: cardWidth}} onClick={onClick}>
        <div className="flex flex-col font-semibold title text-white text-32 px-20">
            <div>{t('There are')}</div>
            <div className="text-primary">{availableVehicles} {t('other vehicles available')}</div>
            <div>{t('in the')} <span className="capitalize">{segment.name}</span> {t('offer')}</div>
        </div>
        <div className="flex justify-end absolute bottom-0 left-0 right-0">
            <div className="px-4 py-4 inline-block items-center bg-secondary-two font-secondary font-normal text-primary rounded-tl-10 rounded-br-10 text-14">
                {t('Go to all vehicles')} <i className="fal fa-long-arrow-right ml-2 font-normal"/>
            </div>
        </div>
    </div>
}

const SegmentSelector = ({segments, vehiclesBySegment, renderVehicle, onClick, onVehicleClick, context, renderSegment}) => {
    const {t} = useTranslation()
    const {isMobile} = useIsMobile();
    const {width} = useWindowDimensions();

    const cardWidth = isMobile ? 290 : 320;

    const calculateNumberOfCards = () => {
        let numberOfCards = 3
        if (width >= 1476) numberOfCards = 3
        if (width >= 1670) numberOfCards = 4
        if (width >= 2000) numberOfCards = 5
        if (width >= 2500) numberOfCards = 6
        return numberOfCards
    }

    return (
        <div className="flex flex-col flex-1">
            {map(segments, (segment) => {
                let vehiclesForSegment = (vehiclesBySegment[segment.key]) ? vehiclesBySegment[segment.key] : undefined

                if (segment.count > 0) {
                    if (renderSegment && typeof renderSegment === 'function') {
                        return renderSegment(segment, vehiclesForSegment, onClick, onVehicleClick, renderVehicle)
                    } else {
                        if (isMobile) {
                            const vehiclesToShowMobile = 3;
                            return (
                                <div key={`segment-main-${segment.key}`} className="mb-5 ">
                                    <div className="flex flex-row md:flex-col lg:flex-row justify-between items-center md:items-start lg:items-center mb-5 px-5 md:px-0">
                                        <h1 className="text-primary font-semibold text-20 lg:text-36 cursor-pointer hover:text-primary-light" onClick={() => onClick(segment)}>{segment.name}</h1>
                                    </div>


                                    <div className="overflow-x-auto">
                                        <div className="flex flex-nowrap px-5 md:px-0 " style={{width: 'min-content'}}>
                                            {map(vehiclesForSegment.slice(0, vehiclesToShowMobile), (vehicle, i) => {
                                                const specsInTwoCols = true;
                                                return <div key={`segment-vehicle-${i}`} className="segment-vehicle mr-5" style={{width: cardWidth}}>
                                                    {(renderVehicle) ? renderVehicle(vehicle, onVehicleClick, specsInTwoCols) : null}
                                                </div>
                                            })}
                                            {vehiclesForSegment.length > vehiclesToShowMobile && <ExtraCard context={context}
                                                                                                            segment={segment}
                                                                                                            cardWidth={cardWidth}
                                                                                                            onClick={() => onClick(segment)}
                                                                                                            availableVehicles={segment.count - vehiclesToShowMobile}
                                            />}
                                        </div>
                                    </div>

                                    <div className="block text-primary-light font-secondary cursor-pointer hover:underline mx-5 py-5 border-b border-secondary-lighter"
                                         onClick={() => onClick(segment)}>
                                        {t('All')} <span className="capitalize">{segment.name.toLowerCase()}</span> {t('vehicles')}
                                        <i className="fal fa-long-arrow-right ml-2 font-normal"/>
                                    </div>
                                </div>
                            )
                        }


                        if (!isMobile) {
                            let vehiclesToShowDesktop = calculateNumberOfCards()
                            return (
                                <div key={`segment-main-${segment.key}`} className="mb-5 ">
                                    <div className="flex flex-row md:flex-col lg:flex-row justify-between items-center md:items-start lg:items-center mb-5 px-5 md:px-0">
                                        <h1 className="text-primary font-semibold text-20 lg:text-36 cursor-pointer hover:text-primary-light" onClick={() => onClick(segment)}>{segment.name}</h1>


                                        <div className="text-primary-light font-secondary cursor-pointer hover:underline" onClick={() => onClick(segment)}>
                                            {t('All')} <span className="capitalize">{segment.name.toLowerCase()}</span> {t('vehicles')}
                                            <i className="fal fa-long-arrow-right ml-2 font-normal"/>
                                        </div>
                                    </div>

                                    <div
                                        className={`md:grid ${width > 1475 ? "" : `grid-cols-3 ${width > 1670 ? "xl:grid-cols-4" : "xl:grid-cols-3"} ${width >= 2000 ? "xl:grid-cols-5" : ""} ${width >= 2500 ? "xl:grid-cols-6" : ""} gap-4 -mr-5`} overflow-x-auto`}>
                                        <div
                                            className={`${width > 1475 ? `grid grid-cols-3 ${width > 1670 ? "xl:grid-cols-4" : "xl:grid-cols-3"} ${width >= 2000 ? "xl:grid-cols-5" : ""} ${width >= 2500 ? "xl:grid-cols-6" : ""} gap-4` : "flex flex-nowrap"} px-5 md:px-0`}
                                            style={{width: width > 1475 ? "auto" : 'min-content'}}>
                                            {map(vehiclesForSegment.slice(0, vehiclesToShowDesktop), (vehicle, i) => {
                                                return <div key={`segment-vehicle-${i}`} className={`${width > 1475 ? "mr-0" : "mr-5"}`} style={{width: width > 1475 ? 'auto' : cardWidth}}>
                                                    {(renderVehicle) ? renderVehicle(vehicle, onVehicleClick) : null}
                                                </div>
                                            })}
                                            {width < 1476 && vehiclesForSegment.length > vehiclesToShowDesktop && <div className="flex mr-5">
                                                <ExtraCard context={context}
                                                           segment={segment}
                                                           cardWidth={cardWidth}
                                                           onClick={() => onClick(segment)}
                                                           availableVehicles={segment.count - vehiclesToShowDesktop}
                                                />
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    }
                }
            })}
        </div>
    )
};

export default SegmentSelector;
