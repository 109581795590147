import React from "react"
import Takeover from "../../../core/components/vehicle/vehicle-basic-takeover/Takeover";
import {navigatePage} from "../../../core/utils";


const VehicleTakeOver = ({context}) => {
    return (
        <Takeover handlePrivacy={() => navigatePage(context, context.urls.privacy)}
                  context={context}/>
    )
};

VehicleTakeOver.defaultProps = {};

VehicleTakeOver.human = {
    "nl": "",
    "fr": "",
    "en": "",
};

VehicleTakeOver.preview = VehicleTakeOver;

export default VehicleTakeOver;
