import React from 'react';
import VehicleCard from "../../../../core/components/vehicle/VehicleCard";
import {preview_context, preview_vehicle} from "../../../../core/constants";

const vehicles = [preview_vehicle, preview_vehicle, preview_vehicle]

const VehicleSlider_Preview = () => {
    return <div>
        <div className="flex flex-col w-full bg-white z-20 relative">
            <div className="relative">
                <div className="flex flex-col w-full">
                    <div className="" style={{maxWidth: "100%", textAlign: "-webkit-center", minHeight: 500}}>
                        <div className="flex px-5 items-center justify-between">
                            {vehicles.map((vehicle, i) => {
                                return <VehicleCard key={vehicle.reference}
                                                    vehicle={vehicle}
                                                    cardClass="vehicle-card-preview"
                                                    context={preview_context}
                                />
                            })}

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="flex flex-row text-center items-center text-xl md:text-3xl justify-center hover:underline mt-5 cursor-pointer">
            <i className="far fa-plus mr-3"/>Ontdek onze voorraad
        </div>
    </div>;
};

export default VehicleSlider_Preview;
